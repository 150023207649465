import React, { useState, useCallback, useEffect } from 'react';
import { industryInput1 } from '../data/searchFlowText';
import { useSelector, useDispatch } from 'react-redux';
import Navigation from '../components/navigation/navigation';
import TextField from '@mui/material/TextField';
import ACTIONS from '../../../redux/action';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
import RadioInput from '../components/searchInputs/radioInput';
import { useNavigate } from 'react-router-dom';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

const Industry = ({ ...props }) => {
  const [industryError, setIndustryError] = React.useState(false);
  const companyStore = useSelector((state) => state.company_store);
  const [inputError, setInputError] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredIndustries, setFilteredIndustries] = useState(
    industryInput1()
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNext = useCallback(
    (inc) => {
      props.handleNext(inc);
    },
    [props]
  );

  const handleBack = useCallback(
    (decr) => {
      props.handleBack(decr);
    },
    [props]
  );

  useEffect(() => {
    const inputField = document.getElementById('industryinput');
    if (companyStore.industry === 'otherservices') {
      inputField.scrollIntoView({ behavior: 'smooth' });
      inputField.focus();
    }
    if (industryError || inputError) {
      const errorField = document.getElementById('error-message');
      if (errorField) {
        errorField.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [companyStore.industry, industryError, inputError]);


  const inputCheck = () => {
    if (
      companyStore.industry === 'otherservices' &&
      companyStore.industryinput === ''
    ) {
      setInputError(true);
      return false;
    } else if (
      companyStore.industry === 'otherservices' &&
      companyStore.industryinput !== ''
    ) {
      setInputError(false);
      return true;
    }
    if (companyStore.industry === '') {
      setIndustryError(true);
      return false;
    } else {
      setIndustryError(false);
      return true;
    }
  };

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    setIndustryError(false);
    setInputError(false);

    if (term.length >= 2) {
      const filtered = industryInput1().filter((industry) =>
        industry.label.toLowerCase().includes(term.toLowerCase())
      );

      const otherservices = industryInput1().find(
        (industry) => industry.value === 'otherservices'
      );
      if (
        !filtered.find((industry) => industry.value === 'otherservices') &&
        otherservices
      ) {
        filtered.push(otherservices);
      }

      setFilteredIndustries(filtered);
    } else {
      setFilteredIndustries(industryInput1());
    }
  };

  // useEffect(() => {
  //   console.log('Selected Industry: ', companyStore.industry);
  // }, [companyStore.industry]);

  return (
    <div className='stepContainer'>
      {companyStore.taxform === 'idk' ? (
        <div className='SFInput'>
          <div className='SFWarningText'>
            <TipsAndUpdatesIcon
              fontSize='medium'
              sx={{
                color: 'white',
                backgroundColor: '#1c32f3',
                marginLeft: 0.5,
                px: 1.5,
                py: 0.75,
                borderRadius: 2,
                boxSizing: 'content-box',
              }}
            />
            <p style={{ fontWeight: '600', fontSize: '16px', margin: '8px' }}>
              {i18n.t('searchflow.vafredirecttext')}
            </p>
            <p style={{ fontWeight: '300', fontSize: '16px', margin: '8px' }}>
              {i18n.t('searchflow.vafredirecttext2')}
            </p>
            <p style={{ fontWeight: '300', fontSize: '16px', margin: '8px' }}>
              {i18n.t('searchflow.vafredirecttext3')}
            </p>
          </div>
        </div>
      ) : (
        <div className='SFInput'>
          <p className='SFSubTitle'>
            <span className='SFSubTitle'>
              {i18n.t('company.showindustrya')}
            </span>{' '}
            <span className='questionTracker'>4/8</span>
          </p>
          <p className='SFSubTitle'>{i18n.t('company.search')}</p>
          <TextField
            variant='outlined'
            value={searchTerm}
            onChange={handleSearch}
            label={i18n.t('company.searchindustry')}
          />
          <hr />
          <RadioInput
            storeID='industry'
            dataText={filteredIndustries}
            error={industryError}
          />

          {companyStore.industry === 'otherservices' && (
            <div
              className='SFMarginTop1rem SFInputWidth100'
              style={{ marginBottom: '16px' }}
            >
              <TextField
                variant='outlined'
                value={companyStore.industryinput}
                onChange={(event) => {
                  dispatch(
                    ACTIONS.addCompanyInfo({
                      id: event.target.id,
                      data: event.target.value,
                      stateObject: 'company_store',
                    })
                  );
                  setInputError(false);
                  setIndustryError(false);
                }}
                error={inputError}
                id='industryinput'
                name='industryinput'
                label={i18n.t('company.companyindustrylabel')}
              />
            </div>
          )}
          {industryError ? (
            <span
              style={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}
            >
              {i18n.t('searchflow.choosetocontinue')}
            </span>
          ) : null}
          {inputError ? (
            <span
              style={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}
            >
              {i18n.t('searchflow.choosetocontinue2')}
            </span>
          ) : null}
        </div>
      )}

      <Navigation
        step={4}
        handleBack={() => handleBack()}
        handleNext={() => {
          const specialIndustries = [
            'engineer', 'logistics', 'manufacturing', 'education',
            'startup', 'realestate', 'lawyer', 'sport', 'otherservices'
          ];
          if (companyStore.taxform === 'idk') {
            companyStore.activeStep = 1;
            navigate('/company/request/vaf');
          } else if (inputCheck()) {
            if (specialIndustries.includes(companyStore.industry)) {
              handleNext(2);
            } else {
              handleNext(1);
            }
          }
        }}
        openHelpForm={() => props.openHelpForm()}
        disabledProp={
          companyStore.taxform === 'idk'
            ? false
            : companyStore.industry !== ''
              ? false
              : true
        }
      />
    </div>
  );
};

export default withTranslation()(Industry);
