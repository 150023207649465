import React from 'react';
import {
  bankingPrefText,
  invoicingPrefText,
  softwarePrefText,
   providerSpecialPreferencesList,
   providerEtradePreferencesList,
   providerVehiclePreferencesList,
   providerRetailPreferencesList,
   providerWholesalePreferencesList,
   providerTravelPreferencesList,
   providerCourierPreferencesList,
} from '../../../../data/providerText';
import PreferencesInputs from '../inputs/preferencesInputs';
import PreferencesInputs2 from '../inputs/preferencesInputs2';
import PreferencesInputs3 from '../inputs/preferencesInputs3';
import i18n from '../../../../../utils/i18n';


const Preferences = ({ submitToDB }) => {
  React.useEffect(()=>{
    // console.log('PROVRETAILPREFS', providerRetailPreferencesList());
  },[])
  return (
    <div className='PaddingBot100'>
      <div className='ProviderInputContainerCentered'>
        {/* <p className='ProviderInputTitle'>
          {i18n.t('providerdashboard.restrictionstext1')}
        </p>
        <p className='ProviderInputSubTitle'>
          {i18n.t('providerdashboard.restrictionstext2')}
        </p> */}
        <div className='RestrictionsNotification'>
          <h2>{i18n.t('provider.warning')}</h2>
          <p>{i18n.t('providerdashboard.restrictionstext3')}</p>
        </div>
        <p className='RestrictionTitle'>{i18n.t('provider.bankingrestr')}</p>
        <PreferencesInputs
          submitToDB={submitToDB}
          dataText={bankingPrefText()}
          storeObj='provider_banking_filter'
        />
        <p className='RestrictionTitle'>{i18n.t('provider.invoicingrestr')}</p>
        <PreferencesInputs
          submitToDB={submitToDB}
          dataText={invoicingPrefText()}
          storeObj='provider_invoicing_filter'
        />
        <p className='RestrictionTitle'>{i18n.t('provider.softwarerestr')}</p>
        <PreferencesInputs
          submitToDB={submitToDB}
          dataText={softwarePrefText()}
          storeObj='provider_software_filter'
        />
        <p className='RestrictionTitle'>Extra szűrők</p>
        <PreferencesInputs2
          submitToDB={submitToDB}
          dataText={providerSpecialPreferencesList()}
          storeObj='provider_special_industry_restrictions'
        />
        <p className='RestrictionTitle'>Webshop szűrők</p>
        <PreferencesInputs3
          submitToDB={submitToDB}
          dataText={providerEtradePreferencesList()}
          storeObj='provider_special_industry_restrictions'
        />
        <p className='RestrictionTitle'>Gépjárműipari szűrő</p>
        <PreferencesInputs3
          submitToDB={submitToDB}
          dataText={providerVehiclePreferencesList()}
          storeObj='provider_special_industry_restrictions'
        />
        <p className='RestrictionTitle'>Kiskereskedelmi szűrő</p>
        <PreferencesInputs3
          submitToDB={submitToDB}
          dataText={providerRetailPreferencesList()}
          storeObj='provider_special_industry_restrictions'
        />
        <p className='RestrictionTitle'>Nagykereskedelmi szűrő</p>
        <PreferencesInputs3
          submitToDB={submitToDB}
          dataText={providerWholesalePreferencesList()}
          storeObj='provider_special_industry_restrictions'
        />
        <p className='RestrictionTitle'>Szállás és véndéglátóipari szűrő</p>
        <PreferencesInputs3
          submitToDB={submitToDB}
          dataText={providerTravelPreferencesList()}
          storeObj='provider_special_industry_restrictions'
        />
        <p className='RestrictionTitle'>Futárszolgálat szűrő</p>
        <PreferencesInputs3
          submitToDB={submitToDB}
          dataText={providerCourierPreferencesList()}
          storeObj='provider_special_industry_restrictions'
        />
      </div>
    </div>
  );
};

export default Preferences;
