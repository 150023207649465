import React, { useCallback, useEffect, useState } from 'react';
import Navigation from "../components/navigation/navigation";
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
import { FormControlLabel, Checkbox, FormGroup, TextField, RadioGroup, Radio, FormControl } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import ACTIONS from "../../../redux/action";

const IndustrySpecific = ({ ...props }) => {
    const companyStore = useSelector((state) => state.company_store);
    const dispatch = useDispatch();

    const [error, setError] = useState('');

    const [vehicleIndustry, setVehicleIndustry] = useState({
        differentialvat: {
            automotivepartstrading: false,
            trading: false,
            service: false,
            other: false,
        },
    });

    const [etradeIndustry, setEtradeIndustry] = useState({
        etradesells: {
            physicalproducts: false,
            services: false,
            intellectualproperty: false,
            other: false,
            otherinput: '',
        },
        etrademultiplecourier: {
            mpl: false,
            gls: false,
            dpd: false,
            sprinter: false,
            expressone: false,
            tntexpress: false,
            fedex: false,
            ups: false,
            dhlexpress: false,
            other: false,
        },
        etrademultipledropoff: {
            foxpost: false,
            alzabox: false,
            packeta: false,
            glscsomagpont: false,
            dpdpickupcsomagpont: false,
            mplpostapont: false,
            other: false,
        },
        etradepayment: {
            transfer: false,
            card: false,
            // benefit: false,
        },
        matchinginvoices: {
            status: '',
            matchingmethod: ''
        },
        deadlinecompliance: {
            status: '',
        },
        actualstore: {
            status: '',
            actualstorecount: null,
        },
        stockhandling: {
            status: '',
        },
    });

    const [constructionIndustry, setConstructionIndustry] = useState({
        constructiontype: {
            specializedworks: false,
            generalcontracting: false,
            constructionprojects: false,
            other: false,
            otherinput: '',
        },
        constructionsiteover180days: {
            status: '',
        },
    });

    const [agricultureIndustry, setAgricultureIndustry] = useState({
        agriculturetype: {
            livestockfarming: false,
            cropproduction: false,
            other: false,
            otherinput: '',
        },
    });

    const [ITIndustry, setITIndustry] = useState({
        ittype: {
            wagereview: false,
            projectwork: false,
            ownprodsoftdev: false,
            other: false,
            otherinput: '',
        }
    });

    const [artIndustry, setArtIndustry] = useState({
        arttype: {
            createartworks: false,
            tradeartworks: false,
            performingartist: false,
            other: false,
            otherinput: '',
        },
        // ekho: {
        //     status: '',
        // },
    });

    // const [educationIndustry, setEducationIndustry] = useState({
    //     noneduactivity: {
    //         status: '',
    //     },
    // });

    const [beautyIndustry, setBeautyIndustry] = useState({
        beautyproductsales: {
            status: '',
        },
    });

    const [travelIndustry, setTravelIndustry] = useState({
        travelpayment: {
            transfer: false,
            card: false,
            benefit: false,
        },
        seasonalunits: {
            status: '',
        },
        matchinginvoicestravel: {
            status: '',
            matchingmethod: ''
        },
        deadlinecompliancetravel: {
            status: '',
        },
    });

    // const [tradeIndustry, setTradeIndustry] = useState({
    //     tradepayment: {
    //         transfer: false,
    //         card: false,
    //         // benefit: false,
    //     },
    // });

    const [retailIndustry, setRetailIndustry] = useState({
        retailpayment: {
            transfer: false,
            card: false,
        },
        matchinginvoicesretail: {
            status: '',
            matchingmethod: ''
        },
        deadlinecomplianceretail: {
            status: '',
        },
    });

    const [wholesaleIndustry, setWholesaleIndustry] = useState({
        wholesalepayment: {
            transfer: false,
            card: false,
        },
        matchinginvoiceswholesale: {
            status: '',
            matchingmethod: ''
        },
        deadlinecompliancewholesale: {
            status: '',
        },
    });

    const [financeIndustry, setFinanceIndustry] = useState({
        complianceauditreview: {
            status: '',
        },
    });

    const [medicineIndustry, setMedicineIndustry] = useState({
        medicinetype: {
            doctor: false,
            pharmacy: false,
            nurse: false,
            vet: false,
            other: false,
            otherinput: '',
        },
        medicinepayment: {
            // transfer: false,
            // card: false,
            benefit: false,
        },
    });

    const [courierIndustry, setCourierIndustry] = useState({
        matchinginvoicescourier: {
            status: '',
            matchingmethod: ''
        },
        deadlinecompliancecourier: {
            status: '',
        },
    });

    useEffect(() => {
        if (companyStore.industryspecifics?.vehicle) {
            setVehicleIndustry(companyStore.industryspecifics.vehicle);
        }
        if (companyStore.industryspecifics?.etrade) {
            setEtradeIndustry(companyStore.industryspecifics.etrade);
        }
        if (companyStore.industryspecifics?.construction) {
            setConstructionIndustry(companyStore.industryspecifics.construction);
        }
        if (companyStore.industryspecifics?.IT) {
            setITIndustry(companyStore.industryspecifics.IT);
        }
        if (companyStore.industryspecifics?.agriculture) {
            setAgricultureIndustry(companyStore.industryspecifics.agriculture);
        }
        if (companyStore.industryspecifics?.art) {
            setArtIndustry(companyStore.industryspecifics.art);
        }
        // if (companyStore.industryspecifics?.education) {
        //     setEducationIndustry(companyStore.industryspecifics.education);
        // }
        if (companyStore.industryspecifics?.beauty) {
            setBeautyIndustry(companyStore.industryspecifics.beauty);
        }
        if (companyStore.industryspecifics?.travel) {
            setTravelIndustry(companyStore.industryspecifics.travel);
        }
        // if (companyStore.industryspecifics?.trade) {
        //     setTradeIndustry(companyStore.industryspecifics.trade);
        // }
        if (companyStore.industryspecifics?.retail) {
            setRetailIndustry(companyStore.industryspecifics.retail);
        }
        if (companyStore.industryspecifics?.wholesale) {
            setWholesaleIndustry(companyStore.industryspecifics.wholesale);
        }
        if (companyStore.industryspecifics?.finance) {
            setFinanceIndustry(companyStore.industryspecifics.finance);
        }
        if (companyStore.industryspecifics?.medicine) {
            setMedicineIndustry(companyStore.industryspecifics.medicine);
        }
        if (companyStore.industryspecifics?.courier) {
            setCourierIndustry(companyStore.industryspecifics.courier);
        }
    }, [companyStore.industryspecifics]);

    const handleVehicleChange = useCallback(
        (e) => {
            const { name, checked } = e.target;

            const updatedVehicleIndustry = {
                ...vehicleIndustry,
                differentialvat: {
                    ...vehicleIndustry.differentialvat,
                    [name]: checked,
                    otherinput: name === 'other' && !checked ? '' : vehicleIndustry.differentialvat.otherinput,
                },
            };

            const status =
                updatedVehicleIndustry.differentialvat.automotivepartstrading ||
                    updatedVehicleIndustry.differentialvat.trading ||
                    updatedVehicleIndustry.differentialvat.service ||
                    updatedVehicleIndustry.differentialvat.other
                    ? 'true'
                    : '';

            setVehicleIndustry((prev) => ({
                ...prev,
                differentialvat: {
                    ...updatedVehicleIndustry.differentialvat,
                    status: status,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'vehicle',
                    data: {
                        ...updatedVehicleIndustry,
                        differentialvat: {
                            ...updatedVehicleIndustry.differentialvat,
                            status: status,
                        },
                    },
                })
            );
        },
        [dispatch, vehicleIndustry]
    );

    const handleVehicleOtherInputChange = useCallback(
        (e) => {
            const { value } = e.target;
            setVehicleIndustry((prev) => ({
                ...prev,
                differentialvat: {
                    ...prev.differentialvat,
                    otherinput: value,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'vehicle',
                    data: {
                        ...vehicleIndustry,
                        differentialvat: {
                            ...vehicleIndustry.differentialvat,
                            otherinput: value,
                        },
                    },
                })
            );
        },
        [dispatch, vehicleIndustry]
    );

    const handleEtradeSellsChange = useCallback(
        (e) => {
            const { name, checked } = e.target;
            setEtradeIndustry((prev) => ({
                ...prev,
                etradesells: {
                    ...prev.etradesells,
                    [name]: checked,
                    otherInput: name === 'other' && !checked ? '' : prev.etradesells.otherinput,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'etrade',
                    data: {
                        ...etradeIndustry,
                        etradesells: {
                            ...etradeIndustry.etradesells,
                            [name]: checked,
                            otherinput: name === 'other' && !checked ? '' : etradeIndustry.etradesells.otherinput,
                        },
                    },
                })
            );
        },
        [dispatch, etradeIndustry]
    );

    const handleEtradeSellsOtherInputChange = useCallback(
        (e) => {
            const { value } = e.target;
            setEtradeIndustry((prev) => ({
                ...prev,
                etradesells: {
                    ...prev.etradesells,
                    otherinput: value,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'etrade',
                    data: {
                        ...etradeIndustry,
                        etradesells: {
                            ...etradeIndustry.etradesells,
                            otherinput: value,
                        },
                    },
                })
            );
        },
        [dispatch, etradeIndustry]
    );

    const handleEtradeCourierChange = useCallback(
        (e) => {
            const { name, checked } = e.target;
            setEtradeIndustry((prev) => ({
                ...prev,
                etrademultiplecourier: {
                    ...prev.etrademultiplecourier,
                    [name]: checked,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'etrade',
                    data: {
                        ...etradeIndustry,
                        etrademultiplecourier: {
                            ...etradeIndustry.etrademultiplecourier,
                            [name]: checked,
                        },
                    },
                })
            );
        },
        [dispatch, etradeIndustry]
    );

    const handleEtradeDropoffChange = useCallback(
        (e) => {
            const { name, checked } = e.target;
            setEtradeIndustry((prev) => ({
                ...prev,
                etrademultipledropoff: {
                    ...prev.etrademultipledropoff,
                    [name]: checked,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'etrade',
                    data: {
                        ...etradeIndustry,
                        etrademultipledropoff: {
                            ...etradeIndustry.etrademultipledropoff,
                            [name]: checked,
                        },
                    },
                })
            );
        },
        [dispatch, etradeIndustry]
    );

    const handleEtradePaymentChange = useCallback(
        (e) => {
            const { name, checked } = e.target;
            setEtradeIndustry((prev) => ({
                ...prev,
                etradepayment: {
                    ...prev.etradepayment,
                    [name]: checked,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'etrade',
                    data: {
                        ...etradeIndustry,
                        etradepayment: {
                            ...etradeIndustry.etradepayment,
                            [name]: checked,
                        },
                    },
                })
            );
        },
        [dispatch, etradeIndustry]
    );

    const handleEtradeMatchingInvoiceChange = useCallback(
        (e) => {
            const { value } = e.target;
            const status = value !== '' ? 'true' : '';

            setEtradeIndustry((prev) => ({
                ...prev,
                matchinginvoices: {
                    ...prev.matchinginvoices,
                    matchingmethod: value,
                    status: status,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'etrade',
                    data: {
                        ...etradeIndustry,
                        matchinginvoices: {
                            ...etradeIndustry.matchinginvoices,
                            matchingmethod: value,
                            status: status,
                        },
                    },
                })
            );
        },
        [dispatch, etradeIndustry]
    );

    const handleDeadlineComplianceChange = useCallback(
        (e) => {
            const { value } = e.target;

            setEtradeIndustry((prev) => ({
                ...prev,
                deadlinecompliance: {
                    status: value,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'etrade',
                    data: {
                        ...etradeIndustry,
                        deadlinecompliance: {
                            status: value,
                        },
                    },
                })
            );
        },
        [dispatch, etradeIndustry]
    );

    const handleActualStoreChange = useCallback(
        (e) => {
            const { value } = e.target;
            let count = null;
            if (value === 'true') count = 1;
            if (value === 'false') count = '';

            setEtradeIndustry((prev) => ({
                ...prev,
                actualstore: {
                    status: value,
                    actualstorecount: count,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'etrade',
                    data: {
                        ...etradeIndustry,
                        actualstore: {
                            status: value,
                            actualstorecount: count,
                        },
                    },
                })
            );
        },
        [dispatch, etradeIndustry]
    );

    const handleActualStoreCountChange = useCallback(
        (e) => {
            const { value } = e.target;
            const count = Number(value);

            if (count <= 0) {
                setError(i18n.t('searchflow.physicalstorecounterror'));
            } else {
                setError('');
            }

            setEtradeIndustry((prev) => ({
                ...prev,
                actualStore: {
                    ...prev.actualstore,
                    actualstorecount: count,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'etrade',
                    data: {
                        ...etradeIndustry,
                        actualstore: {
                            ...etradeIndustry.actualstore,
                            actualstorecount: count,
                        },
                    },
                })
            );
        },
        [dispatch, etradeIndustry]
    );

    const handleStockHandlingChange = useCallback(
        (e) => {
            const { value } = e.target;
            setEtradeIndustry((prev) => ({
                ...prev,
                stockhandling: {
                    status: value,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'etrade',
                    data: {
                        ...etradeIndustry,
                        stockhandling: {
                            status: value,
                        },
                    },
                })
            );
        },
        [dispatch, etradeIndustry]
    );

    const handleConstructionChange = useCallback(
        (e) => {
            const { name, checked } = e.target;
            setConstructionIndustry((prev) => ({
                ...prev,
                constructiontype: {
                    ...prev.constructiontype,
                    [name]: checked,
                    otherinput: name === 'other' && !checked ? '' : prev.constructiontype.otherinput,
                }
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'construction',
                    data: {
                        ...constructionIndustry,
                        constructiontype: {
                            ...constructionIndustry.constructiontype,
                            [name]: checked,
                            otherinput: name === 'other' && !checked ? '' : constructionIndustry.constructiontype.otherinput,
                        }
                    },
                })
            );
        },
        [dispatch, constructionIndustry]
    );

    const handleConstructionOtherInputChange = useCallback(
        (e) => {
            const { value } = e.target;
            setConstructionIndustry((prev) => ({
                ...prev,
                constructiontype: {
                    ...prev.constructiontype,
                    otherinput: value,
                }
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'construction',
                    data: {
                        ...constructionIndustry,
                        constructiontype: {
                            ...constructionIndustry.constructiontype,
                            otherinput: value,
                        }
                    },
                })
            );
        },
        [dispatch, constructionIndustry]
    );

    // const handleConstructionSiteOver180DaysChange = useCallback(
    //     (e) => {
    //         const { value } = e.target;
    //         setConstructionIndustry((prev) => ({
    //             ...prev,
    //             constructionsiteover180days: {
    //                 status: value,
    //             },
    //         }));

    //         dispatch(
    //             ACTIONS.updateNestedObject({
    //                 stateObject: 'company_store',
    //                 stateObjectID: 'industryspecifics',
    //                 id: 'construction',
    //                 data: {
    //                     ...constructionIndustry,
    //                     constructionsiteover180days: {
    //                         status: value,
    //                     },
    //                 },
    //             })
    //         );
    //     },
    //     [dispatch, constructionIndustry]
    // );

    const handleAgricultureChange = useCallback(
        (e) => {
            const { name, checked } = e.target;
            setAgricultureIndustry((prev) => ({
                ...prev,
                agriculturetype: {
                    ...prev.agriculturetype,
                    [name]: checked,
                    otherinput: name === 'other' && !checked ? '' : prev.agriculturetype.otherinput,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'agriculture',
                    data: {
                        ...agricultureIndustry,
                        agriculturetype: {
                            ...agricultureIndustry.agriculturetype,
                            [name]: checked,
                            otherinput: name === 'other' && !checked ? '' : agricultureIndustry.agriculturetype.otherinput,
                        },
                    },
                })
            );
        },
        [dispatch, agricultureIndustry]
    );

    const handleAgricultureOtherInputChange = useCallback(
        (e) => {
            const { value } = e.target;
            setAgricultureIndustry((prev) => ({
                ...prev,
                agriculturetype: {
                    ...prev.agriculturetype,
                    otherinput: value,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'agriculture',
                    data: {
                        ...agricultureIndustry,
                        agriculturetype: {
                            ...agricultureIndustry.agriculturetype,
                            otherinput: value,
                        },
                    },
                })
            );
        },
        [dispatch, agricultureIndustry]
    );

    const handleITChange = useCallback(
        (e) => {
            const { name, checked } = e.target;
            setITIndustry((prev) => ({
                ...prev,
                ittype: {
                    ...prev.ittype,
                    [name]: checked,
                    otherinput: name === 'other' && !checked ? '' : prev.ittype.otherinput,
                }
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'IT',
                    data: {
                        ...ITIndustry,
                        ittype: {
                            ...ITIndustry.ittype,
                            [name]: checked,
                            otherinput: name === 'other' && !checked ? '' : ITIndustry.ittype.otherinput,
                        }
                    },
                })
            );
        },
        [dispatch, ITIndustry]
    );

    const handleITOtherInputChange = useCallback(
        (e) => {
            const { value } = e.target;
            setITIndustry((prev) => ({
                ...prev,
                ittype: {
                    ...prev.ittype,
                    otherinput: value,
                }
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'IT',
                    data: {
                        ...ITIndustry,
                        ittype: {
                            ...ITIndustry.ittype,
                            otherinput: value,
                        }
                    },
                })
            );
        },
        [dispatch, ITIndustry]
    );

    const handleArtChange = useCallback(
        (e) => {
            const { name, checked } = e.target;
            setArtIndustry((prev) => ({
                ...prev,
                arttype: {
                    ...prev.arttype,
                    [name]: checked,
                    otherinput: name === 'other' && !checked ? '' : prev.arttype.otherinput,
                }
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'art',
                    data: {
                        ...artIndustry,
                        arttype: {
                            ...artIndustry.arttype,
                            [name]: checked,
                            otherinput: name === 'other' && !checked ? '' : artIndustry.arttype.otherinput,
                        }
                    },
                })
            );
        },
        [dispatch, artIndustry]
    );

    const handleArtOtherInputChange = useCallback(
        (e) => {
            const { value } = e.target;
            setArtIndustry((prev) => ({
                ...prev,
                arttype: {
                    ...prev.arttype,
                    otherinput: value,
                }
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'art',
                    data: {
                        ...artIndustry,
                        arttype: {
                            ...artIndustry.arttype,
                            otherinput: value,
                        }
                    },
                })
            );
        },
        [dispatch, artIndustry]
    );

    // const handleEkhoChange = useCallback(
    //     (e) => {
    //         const { value } = e.target;
    //         setArtIndustry((prev) => ({
    //             ...prev,
    //             ekho: {
    //                 status: value,
    //             },
    //         }));

    //         dispatch(
    //             ACTIONS.updateNestedObject({
    //                 stateObject: 'company_store',
    //                 stateObjectID: 'industryspecifics',
    //                 id: 'art',
    //                 data: {
    //                     ...artIndustry,
    //                     ekho: {
    //                         status: value,
    //                     },
    //                 },
    //             })
    //         );
    //     },
    //     [dispatch, artIndustry]
    // );

    // const handleEducationChange = useCallback(
    //     (e) => {
    //         const { value } = e.target;
    //         setEducationIndustry((prev) => ({
    //             ...prev,
    //             noneduactivity: {
    //                 status: value,
    //             },
    //         }));

    //         dispatch(
    //             ACTIONS.updateNestedObject({
    //                 stateObject: 'company_store',
    //                 stateObjectID: 'industryspecifics',
    //                 id: 'education',
    //                 data: {
    //                     ...educationIndustry,
    //                     noneduactivity: {
    //                         status: value,
    //                     },
    //                 },
    //             })
    //         );
    //     },
    //     [dispatch, educationIndustry]
    // );

    const handleBeautyProductSalesChange = useCallback(
        (e) => {
            const { value } = e.target;
            setBeautyIndustry((prev) => ({
                ...prev,
                beautyproductsales: {
                    status: value,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'beauty',
                    data: {
                        ...beautyIndustry,
                        beautyproductsales: {
                            status: value,
                        },
                    },
                })
            );
        },
        [dispatch, beautyIndustry]
    );

    const handleTravelPaymentChange = useCallback(
        (e) => {
            const { name, checked } = e.target;
            setTravelIndustry((prev) => ({
                ...prev,
                travelpayment: {
                    ...prev.travelpayment,
                    [name]: checked,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'travel',
                    data: {
                        ...travelIndustry,
                        travelpayment: {
                            ...travelIndustry.travelpayment,
                            [name]: checked,
                        },
                    },
                })
            );
        },
        [dispatch, travelIndustry]
    );

    const handleSeasonalUnitsChange = useCallback(
        (e) => {
            const { value } = e.target;
            setTravelIndustry((prev) => ({
                ...prev,
                seasonalunits: {
                    status: value,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'travel',
                    data: {
                        ...travelIndustry,
                        seasonalunits: {
                            status: value,
                        },
                    },
                })
            );
        },
        [dispatch, travelIndustry]
    );

    const handleMatchingInvoicesTravelChange = useCallback(
        (e) => {
            const { value } = e.target;
            const status = value !== '' ? 'true' : '';

            setTravelIndustry((prev) => ({
                ...prev,
                matchinginvoicestravel: {
                    ...prev.matchinginvoicestravel,
                    matchingmethod: value,
                    status: status,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'travel',
                    data: {
                        ...travelIndustry,
                        matchinginvoicestravel: {
                            ...travelIndustry.matchinginvoicestravel,
                            matchingmethod: value,
                            status: status,
                        },
                    },
                })
            );
        },
        [dispatch, travelIndustry]
    );

    const handleDeadlineComplianceTravelChange = useCallback(
        (e) => {
            const { value } = e.target;

            setTravelIndustry((prev) => ({
                ...prev,
                deadlinecompliancetravel: {
                    status: value,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'travel',
                    data: {
                        ...travelIndustry,
                        deadlinecompliancetravel: {
                            status: value,
                        },
                    },
                })
            );
        },
        [dispatch, travelIndustry]
    );

    // const handleTradePaymentChange = useCallback(
    //     (e) => {
    //         const { name, checked } = e.target;
    //         setTradeIndustry((prev) => ({
    //             ...prev,
    //             tradepayment: {
    //                 ...prev.tradepayment,
    //                 [name]: checked,
    //             },
    //         }));

    //         dispatch(
    //             ACTIONS.updateNestedObject({
    //                 stateObject: 'company_store',
    //                 stateObjectID: 'industryspecifics',
    //                 id: 'trade',
    //                 data: {
    //                     ...tradeIndustry,
    //                     tradepayment: {
    //                         ...tradeIndustry.tradepayment,
    //                         [name]: checked,
    //                     },
    //                 },
    //             })
    //         );
    //     },
    //     [dispatch, tradeIndustry]
    // );

    const handleRetailPaymentChange = useCallback(
        (e) => {
            const { name, checked } = e.target;
            setRetailIndustry((prev) => ({
                ...prev,
                retailpayment: {
                    ...prev.retailpayment,
                    [name]: checked,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'retail',
                    data: {
                        ...retailIndustry,
                        retailpayment: {
                            ...retailIndustry.retailpayment,
                            [name]: checked,
                        },
                    },
                })
            );
        },
        [dispatch, retailIndustry]
    );

    const handleWholesalePaymentChange = useCallback(
        (e) => {
            const { name, checked } = e.target;
            setWholesaleIndustry((prev) => ({
                ...prev,
                wholesalepayment: {
                    ...prev.wholesalepayment,
                    [name]: checked,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'wholesale',
                    data: {
                        ...wholesaleIndustry,
                        wholesalepayment: {
                            ...wholesaleIndustry.wholesalepayment,
                            [name]: checked,
                        },
                    },
                })
            );
        },
        [dispatch, wholesaleIndustry]
    );

    const handleFinanceIndustryChange = useCallback(
        (e) => {
            const { value } = e.target;
            setFinanceIndustry((prev) => ({
                ...prev,
                complianceauditreview: {
                    status: value,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'finance',
                    data: {
                        ...financeIndustry,
                        complianceauditreview: {
                            status: value,
                        },
                    },
                })
            );
        },
        [dispatch, financeIndustry]
    );

    const handleMedicineChange = useCallback(
        (e) => {
            const { name, checked } = e.target;
            setMedicineIndustry((prev) => ({
                ...prev,
                medicinetype: {
                    ...prev.medicinetype,
                    [name]: checked,
                    otherinput: name === 'other' && !checked ? '' : prev.medicinetype.otherinput,
                }
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'medicine',
                    data: {
                        ...medicineIndustry,
                        medicinetype: {
                            ...medicineIndustry.medicinetype,
                            [name]: checked,
                            otherinput: name === 'other' && !checked ? '' : medicineIndustry.medicinetype.otherinput,
                        }
                    },
                })
            );
        },
        [dispatch, medicineIndustry]
    );

    const handleMedicinePaymentChange = useCallback(
        (e) => {
            const { name, checked } = e.target;
            setMedicineIndustry((prev) => ({
                ...prev,
                medicinepayment: {
                    ...prev.medicinepayment,
                    [name]: checked,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'medicine',
                    data: {
                        ...medicineIndustry,
                        medicinepayment: {
                            ...medicineIndustry.medicinepayment,
                            [name]: checked,
                        },
                    },
                })
            );
        },
        [dispatch, medicineIndustry]
    );

    const handleMedicineOtherInputChange = useCallback(
        (e) => {
            const { value } = e.target;
            setMedicineIndustry((prev) => ({
                ...prev,
                medicinetype: {
                    ...prev.medicinetype,
                    otherinput: value,
                }
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'medicine',
                    data: {
                        ...medicineIndustry,
                        medicinetype: {
                            ...medicineIndustry.medicinetype,
                            otherinput: value,
                        }
                    },
                })
            );
        },
        [dispatch, medicineIndustry]
    );

    const handleMatchingInvoicesRetailChange = useCallback(
        (e) => {
            const { value } = e.target;
            const status = value !== '' ? 'true' : '';

            setRetailIndustry((prev) => ({
                ...prev,
                matchinginvoicesretail: {
                    ...prev.matchinginvoicesretail,
                    matchingmethod: value,
                    status: status,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'retail',
                    data: {
                        ...retailIndustry,
                        matchinginvoicesretail: {
                            ...retailIndustry.matchinginvoicesretail,
                            matchingmethod: value,
                            status: status,
                        },
                    },
                })
            );
        },
        [dispatch, retailIndustry]
    );

    const handleDeadlineComplianceRetailChange = useCallback(
        (e) => {
            const { value } = e.target;

            setRetailIndustry((prev) => ({
                ...prev,
                deadlinecomplianceretail: {
                    status: value,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'retail',
                    data: {
                        ...retailIndustry,
                        deadlinecomplianceretail: {
                            status: value,
                        },
                    },
                })
            );
        },
        [dispatch, retailIndustry]
    );

    const handleMatchingInvoicesWholesaleChange = useCallback(
        (e) => {
            const { value } = e.target;
            const status = value !== '' ? 'true' : '';

            setWholesaleIndustry((prev) => ({
                ...prev,
                matchinginvoiceswholesale: {
                    ...prev.matchinginvoiceswholesale,
                    matchingmethod: value,
                    status: status,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'wholesale',
                    data: {
                        ...wholesaleIndustry,
                        matchinginvoiceswholesale: {
                            ...wholesaleIndustry.matchinginvoiceswholesale,
                            matchingmethod: value,
                            status: status,
                        },
                    },
                })
            );
        },
        [dispatch, wholesaleIndustry]
    );

    const handleDeadlineComplianceWholesaleChange = useCallback(
        (e) => {
            const { value } = e.target;

            setWholesaleIndustry((prev) => ({
                ...prev,
                deadlinecompliancewholesale: {
                    status: value,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'wholesale',
                    data: {
                        ...wholesaleIndustry,
                        deadlinecompliancewholesale: {
                            status: value,
                        },
                    },
                })
            );
        },
        [dispatch, wholesaleIndustry]
    );

    const handleMatchingInvoicesCourierChange = useCallback(
        (e) => {
            const { value } = e.target;
            const status = value !== '' ? 'true' : '';

            setCourierIndustry((prev) => ({
                ...prev,
                matchinginvoicescourier: {
                    ...prev.matchinginvoicescourier,
                    matchingmethod: value,
                    status: status,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'courier',
                    data: {
                        ...courierIndustry,
                        matchinginvoicescourier: {
                            ...courierIndustry.matchinginvoicescourier,
                            matchingmethod: value,
                            status: status,
                        },
                    },
                })
            );
        },
        [dispatch, courierIndustry]
    );

    const handleDeadlineComplianceCourierChange = useCallback(
        (e) => {
            const { value } = e.target;

            setCourierIndustry((prev) => ({
                ...prev,
                deadlinecompliancecourier: {
                    status: value,
                },
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'courier',
                    data: {
                        ...courierIndustry,
                        deadlinecompliancecourier: {
                            status: value,
                        },
                    },
                })
            );
        },
        [dispatch, courierIndustry]
    );

    const handleNext = useCallback(
        (inc) => {
            if (etradeIndustry.actualstore.status === 'true' && !etradeIndustry.actualstore.actualstorecount) {
                setError(i18n.t('searchflow.physicalstorecounterror'));
            } else {
                setError('');
                props.handleNext(inc);
            }
        },
        [props, etradeIndustry]
    );

    const handleBack = useCallback(
        (decr) => {
            props.handleBack(decr);
        },
        [props]
    );

    const renderVehicleIndustryContent = companyStore.industry === 'vehicle';
    const renderEtradeIndustryContent = companyStore.industry === 'etrade';
    const renderConstructionIndustryContent = companyStore.industry === 'construction';
    const renderITIndustryContent = companyStore.industry === 'IT';
    const renderAgricultureIndustryContent = companyStore.industry === 'agriculture';
    const renderArtIndustryContent = companyStore.industry === 'art';
    // const renderEducationIndustryContent = companyStore.industry === 'education';
    const renderBeautyIndustryContent = companyStore.industry === 'beauty';
    const renderTravelIndustryContent = companyStore.industry === 'travel';
    // const renderTadeIndustryContent = companyStore.industry === 'trade';
    const renderRetailIndustryContent = companyStore.industry === 'retail';
    const renderWholesaleIndustryContent = companyStore.industry === 'wholesale';
    const renderFinanceIndustryContent = companyStore.industry === 'finance';
    const renderMedicineIndustryContent = companyStore.industry === 'medicine';
    const renderCourierIndustryContent = companyStore.industry === 'courier';

    useEffect(() => {
        console.log('SPECIFICS', companyStore.industryspecifics);
    }, [companyStore, travelIndustry, retailIndustry]);

    return (
        <div className='stepContainer'>
            {/* VEHICLE INDUSTRY */}
            {renderVehicleIndustryContent && (
                <div className='SFInput'>
                    <p className='SFIndustrySpecSubTitle' style={{ marginTop: '0px' }}>
                        {i18n.t('searchflow.differentialvatquestion')}
                        <span className='questionTracker'>5/8</span>
                    </p>
                    <FormGroup>
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={!!vehicleIndustry?.differentialvat?.automotivepartstrading}
                                    onChange={handleVehicleChange}
                                    name='automotivepartstrading'
                                />
                            }
                            label={i18n.t('searchflow.automotivepartstrading')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={!!vehicleIndustry?.differentialvat?.trading}
                                    onChange={handleVehicleChange}
                                    name='trading'
                                />
                            }
                            label={i18n.t('searchflow.vehicletrading')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={!!vehicleIndustry?.differentialvat?.service}
                                    onChange={handleVehicleChange}
                                    name='service'
                                />
                            }
                            label={i18n.t('searchflow.vehicleservice')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={!!vehicleIndustry?.differentialvat?.other}
                                    onChange={handleVehicleChange}
                                    name='other'
                                />
                            }
                            label={i18n.t('industrylabels.other')}
                            labelPlacement="start"
                        />
                    </FormGroup>
                    {vehicleIndustry?.differentialvat?.other && (
                        <TextField
                            value={vehicleIndustry.differentialvat.otherinput || ''}
                            variant="outlined"
                            label={i18n.t('searchflow.vehicletypeplaceholder')}
                            onChange={handleVehicleOtherInputChange}
                            fullWidth
                            margin="normal"
                        />
                    )}
                </div>
            )}

            {/* ETRADE INDUSTRY */}
            {renderEtradeIndustryContent && (
                <div className='SFInput'>
                    <p className='SFIndustrySpecSubTitle' style={{ marginTop: '0px' }}>
                        {i18n.t('searchflow.etradetypequestion')}
                        <span className='questionTracker'>5a/8</span>
                    </p>
                    <FormGroup>
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etradesells.physicalproducts}
                                    onChange={handleEtradeSellsChange}
                                    name='physicalproducts'
                                />
                            }
                            label={i18n.t('searchflow.physicalproducts')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etradesells.services}
                                    onChange={handleEtradeSellsChange}
                                    name='services'
                                />
                            }
                            label={i18n.t('searchflow.etradeservices')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etradesells.intellectualproperty}
                                    onChange={handleEtradeSellsChange}
                                    name='intellectualproperty'
                                />
                            }
                            label={i18n.t('searchflow.intellectualproperty')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etradesells.other}
                                    onChange={handleEtradeSellsChange}
                                    name='other'
                                />
                            }
                            label={i18n.t('industrylabels.other')}
                            labelPlacement="start"
                        />
                    </FormGroup>
                    {etradeIndustry.etradesells.other && (
                        <TextField
                            value={etradeIndustry.etradesells.otherinput}
                            variant="outlined"
                            label={i18n.t('searchflow.etradeotherplaceholder')}
                            onChange={handleEtradeSellsOtherInputChange}
                            fullWidth
                            margin="normal"
                        />
                    )}
                    <p className='SFIndustrySpecSubTitle'>
                        {i18n.t('searchflow.etradecourierquestion')}
                        <span className='questionTracker'>5b/8</span>
                    </p>
                    <FormGroup>
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etrademultiplecourier.mpl}
                                    onChange={handleEtradeCourierChange}
                                    name='mpl'
                                />
                            }
                            label='Magyar Posta (MPL)'
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etrademultiplecourier.gls}
                                    onChange={handleEtradeCourierChange}
                                    name='gls'
                                />
                            }
                            label='GLS'
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etrademultiplecourier.dpd}
                                    onChange={handleEtradeCourierChange}
                                    name='dpd'
                                />
                            }
                            label='DPD'
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etrademultiplecourier.sprinter}
                                    onChange={handleEtradeCourierChange}
                                    name='sprinter'
                                />
                            }
                            label='Sprinter'
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etrademultiplecourier.expressone}
                                    onChange={handleEtradeCourierChange}
                                    name='expressone'
                                />
                            }
                            label='Express One'
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etrademultiplecourier.tntexpress}
                                    onChange={handleEtradeCourierChange}
                                    name='tntexpress'
                                />
                            }
                            label='TNT Express'
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etrademultiplecourier.fedex}
                                    onChange={handleEtradeCourierChange}
                                    name='fedex'
                                />
                            }
                            label='FedEx'
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etrademultiplecourier.ups}
                                    onChange={handleEtradeCourierChange}
                                    name='ups'
                                />
                            }
                            label='UPS'
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etrademultiplecourier.dhlexpress}
                                    onChange={handleEtradeCourierChange}
                                    name='dhlexpress'
                                />
                            }
                            label='DHL Express'
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etrademultiplecourier.other}
                                    onChange={handleEtradeCourierChange}
                                    name='other'
                                />
                            }
                            label='Egyéb'
                            labelPlacement="start"
                        />
                    </FormGroup>
                    <p className='SFIndustrySpecSubTitle'>
                        {i18n.t('searchflow.etradedropoffpointquestion')}
                        <span className='questionTracker'>5c/8</span>
                    </p>
                    <FormGroup>
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etrademultipledropoff.foxpost}
                                    onChange={handleEtradeDropoffChange}
                                    name='foxpost'
                                />
                            }
                            label='FOXPOST'
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etrademultipledropoff.alzabox}
                                    onChange={handleEtradeDropoffChange}
                                    name='alzabox'
                                />
                            }
                            label='AlzaBox'
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etrademultipledropoff.packeta}
                                    onChange={handleEtradeDropoffChange}
                                    name='packeta'
                                />
                            }
                            label='Packeta'
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etrademultipledropoff.glscsomagpont}
                                    onChange={handleEtradeDropoffChange}
                                    name='glscsomagpont'
                                />
                            }
                            label='GLS CsomagPont'
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etrademultipledropoff.dpdpickupcsomagpont}
                                    onChange={handleEtradeDropoffChange}
                                    name='dpdpickupcsomagpont'
                                />
                            }
                            label='DPD Pickup Csomagont'
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etrademultipledropoff.mplpostapont}
                                    onChange={handleEtradeDropoffChange}
                                    name='mplpostapont'
                                />
                            }
                            label='MPL PostaPont'
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etrademultipledropoff.other}
                                    onChange={handleEtradeDropoffChange}
                                    name='other'
                                />
                            }
                            label='Egyéb'
                            labelPlacement="start"
                        />
                    </FormGroup>
                    <p className='SFIndustrySpecSubTitle'>
                        {i18n.t('searchflow.etradepaymentmethodsquestion')}
                        <span className='questionTracker'>5d/8</span>
                    </p>
                    <FormGroup>
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etradepayment.transfer}
                                    onChange={handleEtradePaymentChange}
                                    name='transfer'
                                />
                            }
                            label={i18n.t('searchflow.transfer')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etradepayment.card}
                                    onChange={handleEtradePaymentChange}
                                    name='card'
                                />
                            }
                            label={i18n.t('searchflow.card')}
                            labelPlacement="start"
                        />
                        {/* <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={etradeIndustry.etradepayment.benefit}
                                    onChange={handleEtradePaymentChange}
                                    name='benefit'
                                />
                            }
                            label={i18n.t('searchflow.benefit')}
                            labelPlacement="start"
                        /> */}
                    </FormGroup>

                    <p className='SFIndustrySpecSubTitle'>
                        {i18n.t('searchflow.etradeinvoicematchingquestion')}
                        <span className='questionTracker'>5e/8</span>
                    </p>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={etradeIndustry.matchinginvoices.matchingmethod}
                            onChange={handleEtradeMatchingInvoiceChange}
                        >
                            <FormControlLabel
                                className='SFRadioInput'
                                value="false"
                                control={<Radio />}
                                label={i18n.t('searchflow.invoicematchingmyself')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="true"
                                control={<Radio />}
                                label={i18n.t('searchflow.invoicematchingaccountant')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="idk"
                                control={<Radio />}
                                label={i18n.t('general.idk')}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </FormControl>

                    {etradeIndustry.matchinginvoices.matchingmethod === 'true' && (
                        <>
                            <p className='SFIndustrySpecSubTitle'>
                                {i18n.t('searchflow.deadlinecompliancequestion')}
                            </p>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    value={etradeIndustry.deadlinecompliance.status}
                                    onChange={handleDeadlineComplianceChange}
                                    style={{
                                        paddingLeft: '20px',
                                        marginTop: '10px'
                                    }}
                                >
                                    <FormControlLabel
                                        className='SFRadioInput'
                                        value="true"
                                        control={<Radio />}
                                        label={i18n.t('general.yes')}
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        className='SFRadioInput'
                                        value="false"
                                        control={<Radio />}
                                        label={i18n.t('general.no')}
                                        labelPlacement="start"
                                    />
                                </RadioGroup>
                                {etradeIndustry.deadlinecompliance.status === 'false' && (
                                    <div style={{
                                        fontSize: '14px',
                                        fontStyle: 'italic',
                                        fontWeight: '500',
                                        color: 'red',
                                        marginTop: '8px',
                                        marginLeft: '34px'
                                    }}>
                                        {i18n.t('searchflow.custompricewarning')}
                                    </div>
                                )}
                            </FormControl>
                        </>
                    )}

                    <p className='SFIndustrySpecSubTitle'>
                        {i18n.t('searchflow.etradephysicalstorequestion')}
                        <span className='questionTracker'>5f/8</span>
                    </p>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={etradeIndustry.actualstore.status}
                            onChange={handleActualStoreChange}
                        >
                            <FormControlLabel
                                className='SFRadioInput'
                                value="false"
                                control={<Radio />}
                                label={i18n.t('general.no')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="true"
                                control={<Radio />}
                                label={i18n.t('general.yes')}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                        {etradeIndustry.actualstore.status === 'true' && (
                            <TextField
                                value={etradeIndustry.actualstore.actualstorecount || ''}
                                variant="outlined"
                                label={i18n.t('searchflow.physicalstorecounterror')}
                                onChange={handleActualStoreCountChange}
                                fullWidth
                                margin="normal"
                                type="number"
                                inputProps={{ min: 1 }}
                                onWheel={(e) => e.target.blur()}
                                error={!!error}
                                helperText={error}
                            />
                        )}
                    </FormControl>

                    <p className='SFIndustrySpecSubTitle'>
                        {i18n.t('searchflow.etradestockhandlingquestion')}
                        <span className='questionTracker'>5g/8</span>
                    </p>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={etradeIndustry.stockhandling.status}
                            onChange={handleStockHandlingChange}
                        >
                            <FormControlLabel
                                className='SFRadioInput'
                                value="false"
                                control={<Radio />}
                                label={i18n.t('searchflow.orderonly')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="true"
                                control={<Radio />}
                                label={i18n.t('searchflow.orderandstock')}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </FormControl>

                </div>
            )}

            {/* CONSTRUCTION INDUSTRY */}
            {renderConstructionIndustryContent && (
                <div className='SFInput'>
                    <p className='SFIndustrySpecSubTitle' style={{ marginTop: '0px' }}>
                        {i18n.t('searchflow.constructiontypequestion')}
                        <span className='questionTracker'>5/8</span>
                    </p>
                    <FormGroup>
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={constructionIndustry.constructiontype.specializedworks}
                                    onChange={handleConstructionChange}
                                    name='specializedworks'
                                />
                            }
                            label={i18n.t('searchflow.specializedworks')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={constructionIndustry.constructiontype.generalcontracting}
                                    onChange={handleConstructionChange}
                                    name='generalcontracting'
                                />
                            }
                            label={i18n.t('searchflow.generalcontracting')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={constructionIndustry.constructiontype.constructionprojects}
                                    onChange={handleConstructionChange}
                                    name='constructionprojects'
                                />
                            }
                            label={i18n.t('searchflow.constructionprojects')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={constructionIndustry.constructiontype.other}
                                    onChange={handleConstructionChange}
                                    name='other'
                                />
                            }
                            label={i18n.t('industrylabels.other')}
                            labelPlacement="start"
                        />
                    </FormGroup>
                    {constructionIndustry.constructiontype.other && (
                        <TextField
                            value={constructionIndustry.constructiontype.otherinput}
                            variant="outlined"
                            label={i18n.t('searchflow.constructiontypeotherplaceholder')}
                            onChange={handleConstructionOtherInputChange}
                            fullWidth
                            margin="normal"
                        />
                    )}
                </div>
            )}

            {/* AGRICULTURE INDUSTRY */}
            {renderAgricultureIndustryContent && (
                <div className='SFInput'>
                    <p className='SFIndustrySpecSubTitle' style={{ marginTop: '0px' }}>
                        {i18n.t('searchflow.agricultureindustryquestion')}
                        <span className='questionTracker'>5/8</span>
                    </p>
                    <FormGroup>
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={agricultureIndustry.agriculturetype.livestockfarming}
                                    onChange={handleAgricultureChange}
                                    name='livestockfarming'
                                />
                            }
                            label={i18n.t('searchflow.livestockfarming')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={agricultureIndustry.agriculturetype.cropproduction}
                                    onChange={handleAgricultureChange}
                                    name='cropproduction'
                                />
                            }
                            label={i18n.t('searchflow.cropproduction')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={agricultureIndustry.agriculturetype.other}
                                    onChange={handleAgricultureChange}
                                    name='other'
                                />
                            }
                            label={i18n.t('industrylabels.other')}
                            labelPlacement="start"
                        />
                    </FormGroup>
                    {agricultureIndustry.agriculturetype.other && (
                        <TextField
                            value={agricultureIndustry.agriculturetype.otherinput}
                            variant="outlined"
                            label={i18n.t('searchflow.agricultureotherplaceholder')}
                            onChange={handleAgricultureOtherInputChange}
                            fullWidth
                            margin="normal"
                        />
                    )}
                </div>
            )}

            {/* IT INDUSTRY */}
            {renderITIndustryContent && (
                <div className='SFInput'>
                    <p className='SFIndustrySpecSubTitle' style={{ marginTop: '0px' }}>
                        {i18n.t('searchflow.itindustryquestion')}
                        <span className='questionTracker'>5/8</span>
                    </p>
                    <FormGroup>
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={ITIndustry.ittype.wagereview}
                                    onChange={handleITChange}
                                    name='wagereview'
                                />
                            }
                            label={i18n.t('searchflow.wagereview')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={ITIndustry.ittype.projectwork}
                                    onChange={handleITChange}
                                    name='projectwork'
                                />
                            }
                            label={i18n.t('searchflow.projectwork')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={ITIndustry.ittype.ownprodsoftdev}
                                    onChange={handleITChange}
                                    name='ownprodsoftdev'
                                />
                            }
                            label={i18n.t('searchflow.ownprodsoftdev')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={ITIndustry.ittype.other}
                                    onChange={handleITChange}
                                    name='other'
                                />
                            }
                            label={i18n.t('industrylabels.other')}
                            labelPlacement="start"
                        />
                    </FormGroup>
                    {ITIndustry.ittype.other && (
                        <TextField
                            value={ITIndustry.ittype.otherinput}
                            variant="outlined"
                            label={i18n.t('searchflow.itotherplaceholder')}
                            onChange={handleITOtherInputChange}
                            fullWidth
                            margin="normal"
                        />
                    )}
                </div>
            )}

            {/* ART INDUSTRY */}
            {renderArtIndustryContent && (
                <div className='SFInput'>
                    <p className='SFIndustrySpecSubTitle' style={{ marginTop: '0px' }}>
                        {i18n.t('searchflow.artindustryquestion')}
                        <span className='questionTracker'>5/8</span>
                    </p>
                    <FormGroup>
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={artIndustry.arttype.createartworks}
                                    onChange={handleArtChange}
                                    name='createartworks'
                                />
                            }
                            label={i18n.t('searchflow.createartworks')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={artIndustry.arttype.performingartist}
                                    onChange={handleArtChange}
                                    name='performingartist'
                                />
                            }
                            label={i18n.t('searchflow.performingartist')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={artIndustry.arttype.tradeartworks}
                                    onChange={handleArtChange}
                                    name='tradeartworks'
                                />
                            }
                            label={i18n.t('searchflow.tradeartworks')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={artIndustry.arttype.other}
                                    onChange={handleArtChange}
                                    name='other'
                                />
                            }
                            label={i18n.t('industrylabels.other')}
                            labelPlacement="start"
                        />
                    </FormGroup>
                    {artIndustry.arttype.other && (
                        <TextField
                            value={artIndustry.arttype.otherinput}
                            variant="outlined"
                            label={i18n.t('searchflow.artotherplaceholder')}
                            onChange={handleArtOtherInputChange}
                            fullWidth
                            margin="normal"
                        />
                    )}
                </div>
            )}

            {/* BEAUTY INDUSTRY */}
            {renderBeautyIndustryContent && (
                <div className='SFInput'>
                    <p className='SFIndustrySpecSubTitle' style={{ marginTop: '0px' }}>
                        {i18n.t('searchflow.beautyindustryquestion')}
                        <span className='questionTracker'>5/8</span>
                    </p>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={beautyIndustry.beautyproductsales.status}
                            onChange={handleBeautyProductSalesChange}
                        >
                            <FormControlLabel
                                className='SFRadioInput'
                                value="yes"
                                control={<Radio />}
                                label={i18n.t('general.yes')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="no"
                                control={<Radio />}
                                label={i18n.t('general.no')}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
            )}

            {/* TRAVEL INDUSTRY */}
            {renderTravelIndustryContent && (
                <div className='SFInput'>
                    <p className='SFIndustrySpecSubTitle' style={{ marginTop: '0px' }}>
                        {i18n.t('searchflow.travelpaymentquestion')}
                        <span className='questionTracker'>5a/8</span>
                    </p>
                    <FormGroup>
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={travelIndustry.travelpayment.transfer}
                                    onChange={handleTravelPaymentChange}
                                    name='transfer'
                                />
                            }
                            label={i18n.t('searchflow.transfer')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={travelIndustry.travelpayment.card}
                                    onChange={handleTravelPaymentChange}
                                    name='card'
                                />
                            }
                            label={i18n.t('searchflow.card')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={travelIndustry.travelpayment.benefit}
                                    onChange={handleTravelPaymentChange}
                                    name='benefit'
                                />
                            }
                            label={i18n.t('searchflow.benefit')}
                            labelPlacement="start"
                        />
                    </FormGroup>

                    <p className='SFIndustrySpecSubTitle'>
                        {i18n.t('searchflow.seasonalunitsquestion')}
                        <span className='questionTracker'>5b/8</span>
                    </p>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={travelIndustry.seasonalunits.status}
                            onChange={handleSeasonalUnitsChange}
                        >
                            <FormControlLabel
                                className='SFRadioInput'
                                value="yes"
                                control={<Radio />}
                                label={i18n.t('general.yes')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="no"
                                control={<Radio />}
                                label={i18n.t('general.no')}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                        {travelIndustry.seasonalunits.status === 'yes' && (
                            <div style={{
                                fontSize: '14px',
                                fontStyle: 'italic',
                                fontWeight: '500',
                                color: 'red',
                                marginTop: '8px',
                                marginLeft: '14px'
                            }}>
                                {i18n.t('searchflow.seasonalunitsmessage')}
                            </div>
                        )}
                    </FormControl>

                    <p className='SFIndustrySpecSubTitle'>
                        {i18n.t('searchflow.etradeinvoicematchingquestion')}
                        <span className='questionTracker'>5c/8</span>
                    </p>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={travelIndustry.matchinginvoicestravel.matchingmethod}
                            onChange={handleMatchingInvoicesTravelChange}
                        >
                            <FormControlLabel
                                className='SFRadioInput'
                                value="false"
                                control={<Radio />}
                                label={i18n.t('searchflow.invoicematchingmyself')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="true"
                                control={<Radio />}
                                label={i18n.t('searchflow.invoicematchingaccountant')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="idk"
                                control={<Radio />}
                                label={i18n.t('general.idk')}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </FormControl>

                    {travelIndustry.matchinginvoicestravel.matchingmethod === 'true' && (
                        <>
                            <p className='SFIndustrySpecSubTitle'>
                                {i18n.t('searchflow.deadlinecompliancequestion')}
                            </p>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    value={travelIndustry.deadlinecompliancetravel.status}
                                    onChange={handleDeadlineComplianceTravelChange}
                                    style={{
                                        paddingLeft: '20px',
                                        marginTop: '10px'
                                    }}
                                >
                                    <FormControlLabel
                                        className='SFRadioInput'
                                        value="true"
                                        control={<Radio />}
                                        label={i18n.t('general.yes')}
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        className='SFRadioInput'
                                        value="false"
                                        control={<Radio />}
                                        label={i18n.t('general.no')}
                                        labelPlacement="start"
                                    />
                                </RadioGroup>
                                {travelIndustry.deadlinecompliancetravel.status === 'false' && (
                                    <div style={{
                                        fontSize: '14px',
                                        fontStyle: 'italic',
                                        fontWeight: '500',
                                        color: 'red',
                                        marginTop: '8px',
                                        marginLeft: '34px'
                                    }}>
                                        {i18n.t('searchflow.custompricewarning')}
                                    </div>
                                )}
                            </FormControl>
                        </>
                    )}
                </div>
            )}

            {/* TRADE INDUSTRY */}
            {/* {renderTadeIndustryContent && (
                <div className='SFInput'>
                    <p className='SFIndustrySpecSubTitle' style={{ marginTop: '0px' }}>
                        {i18n.t('searchflow.tradepaymentquestion')}
                        <span className='questionTracker'>5/8</span>
                    </p>
                    <FormGroup>
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={tradeIndustry.tradepayment.transfer}
                                    onChange={handleTradePaymentChange}
                                    name='transfer'
                                />
                            }
                            label={i18n.t('searchflow.transfer')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={tradeIndustry.tradepayment.card}
                                    onChange={handleTradePaymentChange}
                                    name='card'
                                />
                            }
                            label={i18n.t('searchflow.card')}
                            labelPlacement="start"
                        />
                    </FormGroup>
                </div>
            )} */}

            {/* RETAIL INDUSTRY */}
            {renderRetailIndustryContent && (
                <div className='SFInput'>
                    <p className='SFIndustrySpecSubTitle' style={{ marginTop: '0px' }}>
                        {i18n.t('searchflow.tradepaymentquestion')}
                        <span className='questionTracker'>5a/8</span>
                    </p>
                    <FormGroup>
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={retailIndustry.retailpayment.transfer}
                                    onChange={handleRetailPaymentChange}
                                    name='transfer'
                                />
                            }
                            label={i18n.t('searchflow.transfer')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={retailIndustry.retailpayment.card}
                                    onChange={handleRetailPaymentChange}
                                    name='card'
                                />
                            }
                            label={i18n.t('searchflow.card')}
                            labelPlacement="start"
                        />
                    </FormGroup>

                    <p className='SFIndustrySpecSubTitle'>
                        {i18n.t('searchflow.etradeinvoicematchingquestion')}
                        <span className='questionTracker'>5b/8</span>
                    </p>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={retailIndustry.matchinginvoicesretail.matchingmethod}
                            onChange={handleMatchingInvoicesRetailChange}
                        >
                            <FormControlLabel
                                className='SFRadioInput'
                                value="false"
                                control={<Radio />}
                                label={i18n.t('searchflow.invoicematchingmyself')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="true"
                                control={<Radio />}
                                label={i18n.t('searchflow.invoicematchingaccountant')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="idk"
                                control={<Radio />}
                                label={i18n.t('general.idk')}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </FormControl>

                    {retailIndustry.matchinginvoicesretail.matchingmethod === 'true' && (
                        <>
                            <p className='SFIndustrySpecSubTitle'>
                                {i18n.t('searchflow.deadlinecompliancequestion')}
                            </p>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    value={retailIndustry.deadlinecomplianceretail.status}
                                    onChange={handleDeadlineComplianceRetailChange}
                                    style={{
                                        paddingLeft: '20px',
                                        marginTop: '10px'
                                    }}
                                >
                                    <FormControlLabel
                                        className='SFRadioInput'
                                        value="true"
                                        control={<Radio />}
                                        label={i18n.t('general.yes')}
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        className='SFRadioInput'
                                        value="false"
                                        control={<Radio />}
                                        label={i18n.t('general.no')}
                                        labelPlacement="start"
                                    />
                                </RadioGroup>
                                {retailIndustry.deadlinecomplianceretail.status === 'false' && (
                                    <div style={{
                                        fontSize: '14px',
                                        fontStyle: 'italic',
                                        fontWeight: '500',
                                        color: 'red',
                                        marginTop: '8px',
                                        marginLeft: '34px'
                                    }}>
                                        {i18n.t('searchflow.custompricewarning')}
                                    </div>
                                )}
                            </FormControl>
                        </>
                    )}
                </div>
            )}

            {/* WHOLESALE INDUSTRY */}
            {renderWholesaleIndustryContent && (
                <div className='SFInput'>
                    <p className='SFIndustrySpecSubTitle' style={{ marginTop: '0px' }}>
                        {i18n.t('searchflow.tradepaymentquestion')}
                        <span className='questionTracker'>5a/8</span>
                    </p>
                    <FormGroup>
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={wholesaleIndustry.wholesalepayment.transfer}
                                    onChange={handleWholesalePaymentChange}
                                    name='transfer'
                                />
                            }
                            label={i18n.t('searchflow.transfer')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={wholesaleIndustry.wholesalepayment.card}
                                    onChange={handleWholesalePaymentChange}
                                    name='card'
                                />
                            }
                            label={i18n.t('searchflow.card')}
                            labelPlacement="start"
                        />
                    </FormGroup>

                    <p className='SFIndustrySpecSubTitle'>
                        {i18n.t('searchflow.etradeinvoicematchingquestion')}
                        <span className='questionTracker'>5b/8</span>
                    </p>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={wholesaleIndustry.matchinginvoiceswholesale.matchingmethod}
                            onChange={handleMatchingInvoicesWholesaleChange}
                        >
                            <FormControlLabel
                                className='SFRadioInput'
                                value="false"
                                control={<Radio />}
                                label={i18n.t('searchflow.invoicematchingmyself')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="true"
                                control={<Radio />}
                                label={i18n.t('searchflow.invoicematchingaccountant')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="idk"
                                control={<Radio />}
                                label={i18n.t('general.idk')}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </FormControl>

                    {wholesaleIndustry.matchinginvoiceswholesale.matchingmethod === 'true' && (
                        <>
                            <p className='SFIndustrySpecSubTitle'>
                                {i18n.t('searchflow.deadlinecompliancequestion')}
                            </p>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    value={wholesaleIndustry.deadlinecompliancewholesale.status}
                                    onChange={handleDeadlineComplianceWholesaleChange}
                                    style={{
                                        paddingLeft: '20px',
                                        marginTop: '10px'
                                    }}
                                >
                                    <FormControlLabel
                                        className='SFRadioInput'
                                        value="true"
                                        control={<Radio />}
                                        label={i18n.t('general.yes')}
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        className='SFRadioInput'
                                        value="false"
                                        control={<Radio />}
                                        label={i18n.t('general.no')}
                                        labelPlacement="start"
                                    />
                                </RadioGroup>
                                {wholesaleIndustry.deadlinecompliancewholesale.status === 'false' && (
                                    <div style={{
                                        fontSize: '14px',
                                        fontStyle: 'italic',
                                        fontWeight: '500',
                                        color: 'red',
                                        marginTop: '8px',
                                        marginLeft: '34px'
                                    }}>
                                        {i18n.t('searchflow.custompricewarning')}
                                    </div>
                                )}
                            </FormControl>
                        </>
                    )}
                </div>
            )}

            {/* FINANCE INDUSTRY */}
            {renderFinanceIndustryContent && (
                <div className='SFInput'>
                    <p className='SFIndustrySpecSubTitle' style={{ marginTop: '0px' }}>
                        {i18n.t('searchflow.financeindustryquestion')}
                        <span className='questionTracker'>5/8</span>
                    </p>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={financeIndustry.complianceauditreview.status}
                            onChange={handleFinanceIndustryChange}
                        >
                            <FormControlLabel
                                className='SFRadioInput'
                                value="yes"
                                control={<Radio />}
                                label={i18n.t('general.yes')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="no"
                                control={<Radio />}
                                label={i18n.t('general.no')}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
            )}

            {/* MEDICINE INDUSTRY */}
            {renderMedicineIndustryContent && (
                <div className='SFInput'>
                    <p className='SFIndustrySpecSubTitle' style={{ marginTop: '0px' }}>
                        {i18n.t('searchflow.medicinetypequestion')}
                        <span className='questionTracker'>5a/8</span>
                    </p>
                    <FormGroup>
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={medicineIndustry.medicinetype.doctor}
                                    onChange={handleMedicineChange}
                                    name='doctor'
                                />
                            }
                            label={i18n.t('searchflow.doctor')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={medicineIndustry.medicinetype.pharmacy}
                                    onChange={handleMedicineChange}
                                    name='pharmacy'
                                />
                            }
                            label={i18n.t('searchflow.pharmacy')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={medicineIndustry.medicinetype.nurse}
                                    onChange={handleMedicineChange}
                                    name='nurse'
                                />
                            }
                            label={i18n.t('searchflow.nurse')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={medicineIndustry.medicinetype.vet}
                                    onChange={handleMedicineChange}
                                    name='vet'
                                />
                            }
                            label={i18n.t('searchflow.vet')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={medicineIndustry.medicinetype.other}
                                    onChange={handleMedicineChange}
                                    name='other'
                                />
                            }
                            label={i18n.t('industrylabels.other')}
                            labelPlacement="start"
                        />
                    </FormGroup>
                    {medicineIndustry.medicinetype.other && (
                        <TextField
                            value={medicineIndustry.medicinetype.otherinput}
                            variant="outlined"
                            label={i18n.t('searchflow.medicineotherplaceholder')}
                            onChange={handleMedicineOtherInputChange}
                            fullWidth
                            margin="normal"
                        />
                    )}
                    <p className='SFIndustrySpecSubTitle' style={{ marginTop: '0px' }}>
                        {i18n.t('searchflow.medicinepaymentquestion')}
                        <span className='questionTracker'>5b/8</span>
                    </p>
                    <FormGroup>
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={!!medicineIndustry.medicinepayment.card}
                                    onChange={handleMedicinePaymentChange}
                                    name='card'
                                />
                            }
                            label={i18n.t('searchflow.card')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            control={
                                <Checkbox
                                    checked={!!medicineIndustry.medicinepayment.benefit}
                                    onChange={handleMedicinePaymentChange}
                                    name='benefit'
                                />
                            }
                            label={i18n.t('searchflow.benefit')}
                            labelPlacement="start"
                        />
                    </FormGroup>
                </div>
            )}

            {/* COURIER INDUSTRY */}
            {renderCourierIndustryContent && (
                <div className='SFInput'>
                    <p className='SFIndustrySpecSubTitle'>
                        {i18n.t('searchflow.etradeinvoicematchingquestion')}
                        <span className='questionTracker'>5/8</span>
                    </p>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={courierIndustry.matchinginvoicescourier.matchingmethod}
                            onChange={handleMatchingInvoicesCourierChange}
                        >
                            <FormControlLabel
                                className='SFRadioInput'
                                value="false"
                                control={<Radio />}
                                label={i18n.t('searchflow.invoicematchingmyself')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="true"
                                control={<Radio />}
                                label={i18n.t('searchflow.invoicematchingaccountant')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="idk"
                                control={<Radio />}
                                label={i18n.t('general.idk')}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </FormControl>

                    {courierIndustry.matchinginvoicescourier.matchingmethod === 'true' && (
                        <>
                            <p className='SFIndustrySpecSubTitle'>
                                {i18n.t('searchflow.deadlinecompliancequestion')}
                            </p>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    value={courierIndustry.deadlinecompliancecourier.status}
                                    onChange={handleDeadlineComplianceCourierChange}
                                    style={{
                                        paddingLeft: '20px',
                                        marginTop: '10px'
                                    }}
                                >
                                    <FormControlLabel
                                        className='SFRadioInput'
                                        value="true"
                                        control={<Radio />}
                                        label={i18n.t('general.yes')}
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        className='SFRadioInput'
                                        value="false"
                                        control={<Radio />}
                                        label={i18n.t('general.no')}
                                        labelPlacement="start"
                                    />
                                </RadioGroup>
                                {courierIndustry.deadlinecompliancecourier.status === 'false' && (
                                    <div style={{
                                        fontSize: '14px',
                                        fontStyle: 'italic',
                                        fontWeight: '500',
                                        color: 'red',
                                        marginTop: '8px',
                                        marginLeft: '34px'
                                    }}>
                                        {i18n.t('searchflow.custompricewarning')}
                                    </div>
                                )}
                            </FormControl>
                        </>
                    )}
                </div>
            )}

            <Navigation
                step={5}
                handleBack={handleBack}
                handleNext={handleNext}
                openHelpForm={() => props.openHelpForm()}
            />
        </div>
    );
};

export default withTranslation()(IndustrySpecific);
