import React from 'react';
/* import { Button } from "@mui/material"; */
import RegistryInputs from './registryInputs/registryInputs';
import { entryHelpText } from '../data/providerText';
import i18n from '../../utils/i18n';
import { withTranslation } from 'react-i18next';

/* import placeholder from "../../images/icons/placeholder-new.png"; */
import { Paper } from '@mui/material';
import misc from '../../images/misc.png';

import './providerEntry.scss';

const ProviderRegister = () => {
  return (
    <div className='regContainer'>
      <div className='regItem'>
        <div className='regText'>
          <h2>{i18n.t('provider.register')}</h2>
          <p>
            {i18n.t('provider.regtext1')}
            <span className=''>{i18n.t('provider.regtext2')}</span>
            {i18n.t('provider.regtext3')}
          </p>
        </div>
        <Paper className='providerLoginPaper regPaper'>
          <RegistryInputs />
        </Paper>
      </div>
      <div className='regItem'>
        <img
        className='regItemImg'
          src={misc}
          alt='misc img'
          style={{ opacity: '0.6', width: '100%', height: '200px', objectFit: 'cover'  }}
        />

        <div className='regHelpBox'>
          {entryHelpText().map((item) => {
            return (
              <>
                <p>{item.text1}</p>
                <span>{item.text2}</span>
              </>
            );
          })}
          {/*   <Button className="TutorialVideoBtn">
            Mutasd az ingyenes tutorial videót
            <img className="Icon16" src={placeholder} alt="" />
          </Button> */}
        </div>
      </div>
      {/*  <Button className="FacebookEntryBtn">
            <img className="Icon32" src={placeholder} alt="" />
            Regisztrálok Facebook fiókkal
          </Button>
          <Button className="GoogleEntryBtn">
            <img className="Icon32" src={placeholder} alt="" />
            Regisztrálok Google fiókkal
          </Button>
          <p className="EntrySeperator">
            <span>vagy</span>
          </p> */}
    </div>
  );
};

export default withTranslation()(ProviderRegister);
