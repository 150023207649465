import i18n from "../../../utils/i18n";
import Navigation from "../components/navigation/navigation";
import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { bankingInput1 } from "../data/searchFlowText";
import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import ACTIONS from "../../../redux/action";

import { withTranslation } from "react-i18next";

import CheckboxInput from "../components/searchInputs/newCheckboxInput";

const Banks = ({ ...props }) => {
  const companyStore = useSelector((state) => state.company_store);
  const [inputError, setInputError] = useState(false);

  const handleNext = useCallback(
    (inc) => {
      props.handleNext(inc);
    },
    [props]
  );
  const handleBack = useCallback(
    () => {
      const specialIndustries = [
        'engineer', 'logistics', 'manufacturing', 'education',
        'startup', 'realestate', 'lawyer', 'sport', 'otherservices'
      ];
      if (specialIndustries.includes(companyStore.industry)) {
        props.handleBack(2);
      } else {
        props.handleBack(1);
      }
    },
    [props, companyStore.industry]
  );
  const dispatch = useDispatch();
  const useStyles = makeStyles({
    root: {
      "& .MuiInputLabel-root": {
        opacity: "0.7",
      },
      "& .MuiOutlinedInput-input": {
        color: "#424559 !important",
        fontFamily: "Satoshi, sans-serif",
      },
    },
  });
  const classes = useStyles();

  const handleChange = useCallback(
    (event) => {
      let temp = {
        opt1: companyStore.bankingpref.opt1,
        opt2: companyStore.bankingpref.opt2,
        opt3: companyStore.bankingpref.opt3,
        opt4: companyStore.bankingpref.opt4,
        opt5: companyStore.bankingpref.opt5,
        opt6: companyStore.bankingpref.opt6,
        opt7: companyStore.bankingpref.opt7,
        other: companyStore.bankingpref.other,
        bankidk: companyStore.bankingpref.bankidk,
        // bankingOtherInput: '',
      };
      bankingInput1().forEach((item) => {
        //console.log(event.target.id, 'ETV', item.id, 'IID');
        if (event.target.id === item.id) {
          temp[item.id] = !temp[item.id];
        }
      });

      //console.log(temp, 'TEMP');
      dispatch(
        ACTIONS.addCompanyInfo({
          id: "bankingpref",
          data: temp,
          stateObject: "company_store",
        })
      );
    },
    // eslint-disable-next-line
    [companyStore.bankingpref]
  );
  const handleNested = (event, storeId) => {
    dispatch(
      ACTIONS.updateNestedObject({
        stateObject: "company_store",
        stateObjectID: storeId,
        id: event.target.id,
        data: event.target.value,
      })
    );
  };

  const inputCheck = () => {
    if (
      companyStore.bankingpref.other &&
      companyStore.bankingpref.bankingOtherInput === ""
    ) {
      setInputError(true);
      return false;
    } else {
      setInputError(false);
      return true;
    }
  };

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 13) {
        handleNext();
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [props, handleNext]);
  return (
    <div className="stepContainer">
      <div className="SFInput">
        <p className="SFSubTitle">
          <span className="SFSubTitle">
            {i18n.t("searchflow.bankingtitle")}
          </span>{" "}
          <span className="questionTracker">6/8</span>
        </p>
        <CheckboxInput dataText={bankingInput1()} handleCheck={handleChange} />
      </div>
      {companyStore.bankingpref.other && (
        <div
          className="SFMarginTop1Rem SFInputWidth100"
          style={{ margin: "16px auto" }}
        >
          <TextField
            required
            className={`${classes.root}`}
            value={companyStore.bankingpref.bankingOtherInput}
            variant="outlined"
            type={"text"}
            label={i18n.t("industrylabels.other")}
            id="bankingOtherInput"
            name="bankingOtherInput"
            onChange={(event) => {
              handleNested(event, "bankingpref");
            }}
          />
        </div>
      )}
      {inputError ? (
        <p
          style={{
            color: "red",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {i18n.t("company.inputerrormsg")}
        </p>
      ) : null}

      <Navigation
        step={6}
        handleBack={() => {
          handleBack();
        }}
        handleNext={() => {
          inputCheck() && handleNext();
        }}
        openHelpForm={() => props.openHelpForm()}
      />
    </div>
  );
};

export default withTranslation()(Banks);
