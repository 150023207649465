import React, { useEffect, useState } from 'react';
import './dynamicListPages.scss';
import {
	Button,
	Pagination,
	RadioGroup,
	FormControlLabel,
	Radio,
	Dialog,
} from '@mui/material';
import DynamicListPagesFooter from './components/dynamicListPagesFooter';
import DlpProposalAccountant from './assets/DlpProposalAccountant.svg';
import DlpProposalButtonCustomArrow from './assets/DlpProposalButtonCustomArrow.svg';
import DlpMUISwitch from './components/dlpMUISwitch';
import DlpFilterIcon from './assets/DlpFilterIcon.svg';
//import SearchIcon from '@mui/icons-material/Search';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ProviderCard from './components/providerCard/providerCard';
import CheckboxInput from '../searchFlow/components/searchInputs/listCheckboxInput';
import filterIconWhite from '../../images/icons/angle-right.svg';
import axios from '../../shared/axios/axiosProxy';

const FRONT_END_URL =
	process.env.REACT_APP_FRONT_END_URL || 'https://dashboard-hu.bookkeepie.com';

const DynamicListPages = () => {
	const url = new URL(document.URL);
	const urlCities = url && url.searchParams.get('cities');
	const urlIndustry = url && url.searchParams.get('industry');

	const [loadSwitch, setLoadSwitch] = useState(false);

	const [allProviders, setAllProviders] = useState([]);
	const [filterParams, setFilterParams] = useState({
		cities: [],
		industries: [],
		services: [],
		size: '',
		badge: false,
		newlyFounded: false,
		certified: false,
	});
	const [expandCity, setExpandCity] = useState(false);
	const [expandIndustry, setExpandIndustry] = useState(false);
	const [filteredProviders, setFilteredProviders] = useState([]);
	const [isDigitalBadgeSwitchOn, setIsDigitalBadgeSwitchOn] = useState(false);
	const [isNewlyFoundedSwitchOn, setIsNewlyFoundedSwitchOn] = useState(false);
	const [isBkCertifiedSwitchOn, setIsBkCertifiedSwitchOn] = useState(false);
	const [selectedCities, setSelectedCities] = useState({
		Békéscsaba: false,
		Budapest: false,
		Debrecen: false,
		Eger: false,
		Győr: false,
		Kaposvár: false,
		Kecskemét: false,
		Miskolc: false,
		Nyíregyháza: false,
		Pécs: false,
		Salgótarján: false,
		Szeged: false,
		Székesfehérvár: false,
		Szekszárd: false,
		Szolnok: false,
		Szombathely: false,
		Tatabánya: false,
		Veszprém: false,
		Zalaegerszeg: false,
	});
	const [showFilters, setShowFilters] = useState(window.innerWidth > 600);

	const industriesTranslation = {
		medicine: 'Egészségügy',
		etrade: 'E-kereskedelem',
		construction: 'Építőipar',
		courier: 'Futár',
		vehicle: 'Gépjármű Ipar',
		manufacturing: 'Gyártás és Feldolgozó Ipar',
		IT: 'Informatika',
		realestate: 'Ingatlankereskedelem',
		// trade: 'Kis-és nagykereskedelem',
		retail: 'Kiskereskedelem',
		wholesale: 'Nagykereskedelem',
		agriculture: 'Mező- és erdőgazdálkodás, halászat',
		engineer: 'Mérnök',
		art: 'Művészet',
		education: 'Oktatás, Kutatás',
		finance: 'Pénzügy, biztosítás',
		startup: 'Startup',
		travel: 'Szállás, Vendéglátás',
		logistics: 'Szállítás, raktározás',
		beauty: 'Szépségipar',
		lawyer: 'Ügyvédi iroda',
		sport: 'Sport',
		otherservices: 'Egyéb',
	};

	const [selectedIndutries, setSelectedIndustries] = useState({
		medicine: false,
		etrade: false,
		construction: false,
		courier: false,
		vehicle: false,
		manufacturing: false,
		IT: false,
		realestate: false,
		// trade: false,
		retail: false,
		wholesale: false,
		agriculture: false,
		engineer: false,
		art: false,
		education: false,
		finance: false,
		startup: false,
		travel: false,
		logistics: false,
		beauty: false,
		lawyer: false,
		sport: false,
		otherservices: false,
	});

	const [selectedServices, setSelectedServices] = useState({
		accounting: false,
		payroll: false,
		legalentityfounding: false,
		taxconsulting: false,
	});
	const servicesTranslate = {
		accounting: 'Könyvelés',
		payroll: 'Bérszámfejtés',
		legalentityfounding: 'Vállalkozás alapítás',
		taxconsulting: 'Adótanácsadás',
	};
	const [currentPage, setCurrentPage] = useState(1);
	const providersPerPage = 12;
	const indexOfLastReview = currentPage * providersPerPage;
	const indexOfFirstReview = indexOfLastReview - providersPerPage;
	const currentProviders = filteredProviders.slice(
		indexOfFirstReview,
		indexOfLastReview
	);
	const totalPages = Math.ceil(filteredProviders.length / providersPerPage);
	const [officeSize, setOfficeSize] = useState('');

	useEffect(() => {
		if (urlCities) {
			let cityFilter = urlCities.split(',');
			setFilterParams((prev) => ({ ...prev, cities: cityFilter }));
			cityFilter.forEach((city) =>
				setSelectedCities({ ...selectedCities, [city]: true })
			);
			// setTimeout(setLoadSwitch(!loadSwitch), 1000);
			setLoadSwitch(!loadSwitch);
		}
		if (urlIndustry) {
			let industryFilter = urlIndustry.split(',');
			setFilterParams((prev) => ({ ...prev, industries: industryFilter }));
			industryFilter.forEach((industry) =>
				setSelectedIndustries({ ...selectedIndutries, [industry]: true })
			);
			// setTimeout(setLoadSwitch(!loadSwitch), 1000);
			setLoadSwitch(!loadSwitch);
		}
	}, [allProviders]);

	useEffect(() => {
		setFilteredProviders(filterList(allProviders, filterParams));
	}, [loadSwitch]);

	const handleDigitalBadgeSwitchChange = (event) => {
		const { checked } = event.target;

		setFilterParams((prevState) => ({
			...prevState,

			badge: checked,
		}));
		setIsDigitalBadgeSwitchOn(event.target.checked);
	};

	const handleNewlyFoundedSwitchChange = (event) => {
		const { checked } = event.target;

		setFilterParams((prevState) => ({
			...prevState,

			newlyFounded: checked,
		}));
		setIsNewlyFoundedSwitchOn(event.target.checked);
	};

	const handleBkCertifiedSwitchChange = (event) => {
		const { checked } = event.target;

		setFilterParams((prevState) => ({
			...prevState,

			certified: checked,
		}));
		setIsBkCertifiedSwitchOn(event.target.checked);
	};

	const handleCityChange = (event) => {
		const { name, checked } = event.target;
		setFilterParams((prevState) => {
			const newCities = checked
				? [...prevState.cities, name]
				: prevState.cities.filter((city) => city !== name);

			return {
				...prevState,
				cities: newCities,
			};
		});
		setSelectedCities({
			...selectedCities,
			[event.target.name]: event.target.checked,
		});
	};

	const filterList = (providers, filterParams) => {
		let filtered = providers.filter((provider) => {
			const {
				cities,
				industries,
				services,
				size,
				badge,
				newlyFounded,
				certified,
			} = filterParams;

			if (cities.length > 0 && !cities.includes(provider.providerCity)) {
				return false;
			}

			if (
				industries.length > 0 &&
				!industries.every((industry) =>
					provider.providerIndustries.includes(industry)
				)
			) {
				return false;
			}

			if (
				services.length > 0 &&
				!services.every((service) => provider.services.includes(service))
			) {
				return false;
			}

			if (size && provider.packageSize !== size) {
				return false;
			}

			if (badge && provider.providerBadge === 'Nem minősített') {
				return false;
			}

			if (newlyFounded && !provider.newlyFounded) {
				return false;
			}

			if (certified && provider.packageType !== 'premium') {
				return false;
			}

			return true;
		});

		return filtered;
	};

	const resetUrl = () => {
		const url = new URL(window.location.href);
		const pathname = url.pathname;
		window.history.replaceState({}, document.title, pathname);
	};

	const setUrlParams = (filterParams) => {
		const url = new URL(window.location.href);
		filterParams.cities.length > 0 &&
			url.searchParams.append('cities', filterParams.cities);
		filterParams.industries.length > 0 &&
			url.searchParams.append('industry', filterParams.industries);
		filterParams.services.length > 0 &&
			url.searchParams.append('services', filterParams.services);
		window.history.replaceState({}, document.title, url.search);
		return url;
	};

	const dataLayerUpdate = () => {
		window.dataLayer.push({
			event: 'sf_step_view_all_offers',
			event_category: 'listingpage',
		});
	};

	const handleIndustryChange = (event) => {
		const { name, checked } = event.target;
		setFilterParams((prevState) => {
			const newIndustries = checked
				? [...prevState.industries, name]
				: prevState.industries.filter((industry) => industry !== name);
			return {
				...prevState,
				industries: newIndustries,
			};
		});
		setSelectedIndustries({
			...selectedIndutries,
			[event.target.name]: event.target.checked,
		});
	};

	const handleServicesChange = (event) => {
		const { name, checked } = event.target;
		setFilterParams((prevState) => {
			const newServices = checked
				? [...prevState.services, name]
				: prevState.services.filter((serv) => serv !== name);

			return {
				...prevState,
				services: newServices,
			};
		});
		setSelectedServices({
			...selectedServices,
			[event.target.name]: event.target.checked,
		});
	};

	const handleOfficeSizeChange = (event) => {
		setFilterParams((prevState) => ({
			...prevState,

			size: event.target.value,
		}));
		setOfficeSize(event.target.value);
	};

	const sortProviders = () => {
		return filteredProviders;
	};

	const getProviders = async () => {
		await axios
			.get('/api/providerlist')
			.then((response) => {
				setAllProviders(response.data.matchProvs);
				setFilteredProviders(
					response.data.matchProvs.sort((b, a) => {
						const dateA = new Date(a.startDate);
						const dateB = new Date(b.startDate);
						return dateA - dateB;
					})
				);
				//console.log('ALL PROVIDERS', response.data.matchProvs);
			})
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		getProviders();
	}, []);

	useEffect(() => {
		setFilteredProviders(filterList(allProviders, filterParams));
		//eslint-disable-next-line
	}, [
		filterParams.certified,
		filterParams.newlyFounded,
		filterParams.size,
		filterParams.badge,
		filterParams.services,
	]);

	const resetFilter = () => {
		const url = new URL(window.location.href);
		const pathname = url.pathname;
		window.history.replaceState({}, document.title, pathname);
		setFilteredProviders(allProviders);
		setCurrentPage(1);
		setIsDigitalBadgeSwitchOn(false);
		setIsNewlyFoundedSwitchOn(false);
		setIsBkCertifiedSwitchOn(false);
		setSelectedCities({
			Békéscsaba: false,
			Budapest: false,
			Debrecen: false,
			Eger: false,
			Győr: false,
			Kaposvár: false,
			Kecskemét: false,
			Miskolc: false,
			Nyíregyháza: false,
			Pécs: false,
			Salgótarján: false,
			Szeged: false,
			Székesfehérvár: false,
			Szekszárd: false,
			Szolnok: false,
			Szombathely: false,
			Tatabánya: false,
			Veszprém: false,
			Zalaegerszeg: false,
		});
		setSelectedIndustries({
			medicine: false,
			etrade: false,
			construction: false,
			courier: false,
			vehicle: false,
			manufacturing: false,
			IT: false,
			realestate: false,
			// trade: false,
			retail: false,
			wholesale: false,
			agriculture: false,
			engineer: false,
			art: false,
			education: false,
			finance: false,
			startup: false,
			travel: false,
			logistics: false,
			beauty: false,
			lawyer: false,
			sport: false,
			otherservices: false,
		});
		setSelectedServices({
			accounting: false,
			payroll: false,
			legalentityfounding: false,
			taxconsulting: false,
		});
		setOfficeSize('');
		setFilterParams({
			cities: [],
			industries: [],
			services: [],
			size: '',
			badge: false,
			newlyFounded: false,
			certified: false,
		});
	};

	useEffect(() => {
		setFilteredProviders(
			filteredProviders.sort((a, b) => {
				const dateA = new Date(a.startDate);
				const dateB = new Date(b.startDate);
				return dateB - dateA;
			})
		);
		//console.log(filteredProviders, 'filteredProviders');
	}, [filteredProviders]);

	return (
		<>
			<div className="dynamicListPages">
				<Dialog open={expandCity} fullScreen={window.innerWidth < 600}>
					<div className="dlpFilterDialog">
						<div className="dlpBold16 dlpApart">
							Városok
							<span
								className="dlpClosingX"
								onClick={() => setExpandCity(false)}>
								<CloseIcon />
							</span>
						</div>
						<hr />
						<div className="dlpLongList">
							<CheckboxInput
								dataText={selectedCities}
								handleCheck={handleCityChange}
							/>
						</div>
						<div className="dlpSearchButton">
							<Button
								className="dlpSearchButtonCustom dlpBold14"
								onClick={() => {
									resetUrl();
									setFilteredProviders(filterList(allProviders, filterParams));
									setUrlParams(filterParams);
									setExpandCity(false);
								}}>
								Szűrés
							</Button>
						</div>
					</div>
				</Dialog>
				<Dialog open={expandIndustry} fullScreen={window.innerWidth < 600}>
					<div className="dlpFilterDialog">
						<div className="dlpBold16 dlpApart">
							Iparági / szakmai ismeret{' '}
							<span
								className="dlpClosingX"
								onClick={() => setExpandIndustry(false)}>
								<CloseIcon />
							</span>
						</div>
						<hr />
						<div className="dlpLongList">
							<CheckboxInput
								textTranslation={industriesTranslation}
								dataText={selectedIndutries}
								handleCheck={handleIndustryChange}
							/>
						</div>
						<div className="dlpSearchButton">
							<Button
								className="dlpSearchButtonCustom dlpBold14"
								onClick={() => {
									resetUrl();
									setFilteredProviders(filterList(allProviders, filterParams));
									setUrlParams(filterParams);
									setExpandIndustry(false);
								}}>
								Szűrés
							</Button>
						</div>
					</div>
				</Dialog>
				<div className="dlpHeader">
					<div className="dlpHeaderText">
						<div className="dlpHeaderTextUp">
							Könyvelők / {filterParams.cities.join(', ')}{' '}
							{filterParams.cities.length !== 0 && <>/</>}{' '}
							{filterParams.industries
								.map((item) => {
									return industriesTranslation[item];
								})
								.join(', ')}{' '}
							{filterParams.industries.length !== 0 && <>/</>}{' '}
							{filterParams.services
								.map((item) => {
									return servicesTranslate[item];
								})
								.join(', ')}{' '}
						</div>

						<div className="dlpHeaderTextDown">
							<span>
								Nézz körbe Magyarország elérhető legjobb könyvelői között
							</span>
						</div>
					</div>
				</div>

				<div className="dlpDividerMain"></div>

				<div className="dlpContent">
					<div className="dlpContentLeft">
						<div className="dlpFilterTitleContainer">
							<div className="dlpFilterTitle">
								<img
									className="dlpFilterTitleIcon"
									src={DlpFilterIcon}
									alt="Filter Icon"
								/>
								<div className="dlpBold16">Szűrők</div>
							</div>
							<div className="dlpText14">
								Add meg a legfontosabb szűrési feltételeidet!
							</div>
						</div>
						{window.innerWidth <= 600 && (
							<div
								className="dlpFilterToggleButtonMobile"
								style={{ margin: '8px' }}>
								<Button
									onClick={() => setShowFilters(!showFilters)}
									className="dlpFilterToggleButtonCustomMobile">
									Szűrők <img src={filterIconWhite} alt="Filter Icon" />
								</Button>
							</div>
						)}
						{window.innerWidth <= 600 && showFilters && (
							<div className="dlpSearchContainer">
								<div className="dlpSearchCityContainer">
									<div className="dlpBold16">Város</div>

									<div className="dlpWrap">
										{filterParams.cities.map((item, idx) => {
											return (
												<span key={idx} className="dlpExpItem">
													{item}
												</span>
											);
										})}
									</div>
									<span
										className="dlpExpandText"
										onClick={() => setExpandCity(true)}>
										{filterParams.cities.length > 0
											? 'Szűrő módosítása'
											: 'Szűrő hozzáadása'}
									</span>
								</div>

								<div className="dlpDynamicListPagesDivider"></div>

								<div className="dlpSearchIndustryContainer">
									<div className="dlpBold16">Iparági / szakmai ismeret</div>
									<div className="dlpWrap">
										{filterParams.industries.map((item, idx) => {
											return (
												<span key={idx} className="dlpExpItem">
													{industriesTranslation[item]}
												</span>
											);
										})}
									</div>
									<span
										className="dlpExpandText"
										onClick={() => setExpandIndustry(true)}>
										{filterParams.industries.length > 0
											? 'Szűrő módosítása'
											: 'Szűrő hozzáadása'}
									</span>
								</div>

								<div className="dlpDynamicListPagesDivider"></div>

								<div className="dlpSearchServicesContainer">
									<div className="dlpBold16">Szolgáltatások</div>
									<div>
										<CheckboxInput
											textTranslation={servicesTranslate}
											dataText={selectedServices}
											handleCheck={handleServicesChange}
											s
										/>
									</div>
								</div>

								<div className="dlpDynamicListPagesDivider"></div>

								<div className="dlpSearchOfficeSizeCityContainer">
									<div className="dlpBold16">Iroda méret</div>
									<RadioGroup
										aria-label="officeSize"
										name="officeSize"
										value={officeSize}
										onChange={handleOfficeSizeChange}>
										<FormControlLabel
											className="SFRadioInput"
											labelPlacement="start"
											value="office"
											control={
												<Radio
													sx={{
														color: '#505581',
														'&.Mui-checked': {
															color: '#4D5BEC',
														},
													}}
												/>
											}
											label="Nagy könyvelőiroda"
										/>
										<FormControlLabel
											className="SFRadioInput"
											labelPlacement="start"
											value="small_office"
											control={
												<Radio
													sx={{
														color: '#505581',
														'&.Mui-checked': {
															color: '#4D5BEC',
														},
													}}
												/>
											}
											label="Kis könyvelőiroda"
										/>
										<FormControlLabel
											className="SFRadioInput"
											labelPlacement="start"
											value="entrepreneur"
											control={
												<Radio
													sx={{
														color: '#505581',
														'&.Mui-checked': {
															color: '#4D5BEC',
														},
													}}
												/>
											}
											label="Egyéni vállalkozás"
										/>
									</RadioGroup>
								</div>
								<div className="dlpDynamicListPagesDivider"></div>
								<div className="dlpSearchDigitalBadgeContainer">
									<div className="dlpBold16">
										Hitelesített Bookkeepie könyvelők
									</div>
									<div className="dlpSearchSwitchIcon">
										<DlpMUISwitch
											checked={isBkCertifiedSwitchOn}
											onChange={handleBkCertifiedSwitchChange}
											name="certified"
										/>
									</div>
								</div>

								<div className="dlpDynamicListPagesDivider"></div>

								<div className="dlpSearchDigitalBadgeContainer">
									<div className="dlpBold16">Digitális minősítés</div>
									<div className="dlpSearchSwitchIcon">
										<DlpMUISwitch
											checked={isDigitalBadgeSwitchOn}
											onChange={handleDigitalBadgeSwitchChange}
										/>
									</div>
								</div>

								<div className="dlpDynamicListPagesDivider"></div>

								<div className="dlpNewlyFoundedContainer">
									<div className="dlpBold16">Induló vállalkozás</div>
									<div className="dlpSearchSwitchIcon">
										<DlpMUISwitch
											checked={isNewlyFoundedSwitchOn}
											onChange={handleNewlyFoundedSwitchChange}
										/>
									</div>
								</div>
							</div>
						)}
						{window.innerWidth > 600 && (
							<div className="dlpSearchContainer">
								<div className="dlpSearchCityContainer">
									<div className="dlpBold16">Város</div>

									<div className="dlpWrap">
										{filterParams.cities.map((item, idx) => {
											return (
												<span key={idx} className="dlpExpItem">
													{item}
												</span>
											);
										})}
									</div>
									<span
										className="dlpExpandText"
										onClick={() => setExpandCity(true)}>
										{filterParams.cities.length > 0
											? 'Szűrő módosítása'
											: 'Szűrő hozzáadása'}
									</span>
								</div>

								<div className="dlpDynamicListPagesDivider"></div>

								<div className="dlpSearchIndustryContainer">
									<div className="dlpBold16">Iparági / szakmai ismeret</div>
									<div className="dlpWrap">
										{filterParams.industries.map((item, idx) => {
											return (
												<span key={idx} className="dlpExpItem">
													{industriesTranslation[item]}
												</span>
											);
										})}
									</div>
									<span
										className="dlpExpandText"
										onClick={() => setExpandIndustry(true)}>
										{filterParams.industries.length > 0
											? 'Szűrő módosítása'
											: 'Szűrő hozzáadása'}
									</span>
								</div>

								<div className="dlpDynamicListPagesDivider"></div>

								<div className="dlpSearchServicesContainer">
									<div className="dlpBold16">Szolgáltatások</div>
									<div>
										<CheckboxInput
											textTranslation={servicesTranslate}
											dataText={selectedServices}
											handleCheck={handleServicesChange}
											s
										/>
									</div>
								</div>

								<div className="dlpDynamicListPagesDivider"></div>

								<div className="dlpSearchOfficeSizeCityContainer">
									<div className="dlpBold16">Iroda méret</div>
									<RadioGroup
										aria-label="officeSize"
										name="officeSize"
										value={officeSize}
										onChange={handleOfficeSizeChange}>
										<FormControlLabel
											className="SFRadioInput"
											labelPlacement="start"
											value="office"
											control={
												<Radio
													sx={{
														color: '#505581',
														'&.Mui-checked': {
															color: '#4D5BEC',
														},
													}}
												/>
											}
											label="Nagy könyvelőiroda"
										/>
										<FormControlLabel
											className="SFRadioInput"
											labelPlacement="start"
											value="small_office"
											control={
												<Radio
													sx={{
														color: '#505581',
														'&.Mui-checked': {
															color: '#4D5BEC',
														},
													}}
												/>
											}
											label="Kis könyvelőiroda"
										/>
										<FormControlLabel
											className="SFRadioInput"
											labelPlacement="start"
											value="entrepreneur"
											control={
												<Radio
													sx={{
														color: '#505581',
														'&.Mui-checked': {
															color: '#4D5BEC',
														},
													}}
												/>
											}
											label="Egyéni vállalkozás"
										/>
									</RadioGroup>
								</div>
								<div className="dlpDynamicListPagesDivider"></div>
								<div className="dlpSearchDigitalBadgeContainer">
									<div className="dlpBold16">
										Hitelesített Bookkeepie könyvelők
									</div>
									<div className="dlpSearchSwitchIcon">
										<DlpMUISwitch
											checked={isBkCertifiedSwitchOn}
											onChange={handleBkCertifiedSwitchChange}
											name="certified"
										/>
									</div>
								</div>

								<div className="dlpDynamicListPagesDivider"></div>

								<div className="dlpSearchDigitalBadgeContainer">
									<div className="dlpBold16">Digitális minősítés</div>
									<div className="dlpSearchSwitchIcon">
										<DlpMUISwitch
											checked={isDigitalBadgeSwitchOn}
											onChange={handleDigitalBadgeSwitchChange}
										/>
									</div>
								</div>

								<div className="dlpDynamicListPagesDivider"></div>

								<div className="dlpNewlyFoundedContainer">
									<div className="dlpBold16">Induló vállalkozás</div>
									<div className="dlpSearchSwitchIcon">
										<DlpMUISwitch
											checked={isNewlyFoundedSwitchOn}
											onChange={handleNewlyFoundedSwitchChange}
										/>
									</div>
								</div>
							</div>
						)}
						<div
							className="dlpSetDefaultStateButton"
							style={{ cursor: 'pointer', marginTop: '18px' }}
							onClick={resetFilter}>
							Alaphelyzetbe állítás
						</div>
					</div>
					<div className="dlpContentRight">
						<div className="dlpAllProvidersContainer">
							<div className="dlpAllProvidersNumber">
								{sortProviders().length} könyvelő
							</div>
						</div>

						<div className="dlpAllProvidersContainer"></div>

						<div className="dlpAllProviderCards">
							{currentProviders.map((item, i) => {
								if (i < 2) {
									return (
										<div
											style={{ margin: '0px' }}
											className={
												window.innerWidth < 600
													? 'FlexFullCenter'
													: 'ProviderCardContainer'
											}
											key={i}>
											<ProviderCard data={item} index={i} />
										</div>
									);
								} else return null;
							})}
						</div>

						<div className="dlpProposalContainer">
							<div className="dlpProposalTextContainer">
								<div className="dlpProposalText1">
									Tanácstalan vagy? Hadd segítsünk!
								</div>
								<div className="dlpProposalText2">
									Kérj be az összes könyvelőtől ajánlatot és fogadd el azt ami a
									legkedvezőbb számodra!
								</div>
								{window.innerWidth < 600 && (
									<div className="dlpProposalButtonMobile">
										<div className="dlpProposalImage">
											<img src={DlpProposalAccountant} alt="Accountant" />
										</div>
										<Button
											className="dlpProposalButtonCustomMobile"
											onClick={(e) => {
												e.stopPropagation();
												dataLayerUpdate();
												window.location.replace(
													`${FRONT_END_URL}/company/request/1`
												);
											}}>
											Összes ajánlat bekérése&nbsp;&nbsp;
											<img
												src={DlpProposalButtonCustomArrow}
												alt="arrow right"
											/>
										</Button>
									</div>
								)}
							</div>
							<div className="dlpProposalButton">
								<Button
									className="dlpProposalButtonCustom"
									onClick={(e) => {
										e.stopPropagation();
										dataLayerUpdate();
										window.location.replace(
											`${FRONT_END_URL}/company/request/1`
										);
									}}>
									Összes ajánlat bekérése&nbsp;&nbsp;
									<img src={DlpProposalButtonCustomArrow} alt="arrow right" />
								</Button>
							</div>
						</div>
						<div className="dlpAllProviderCards">
							{currentProviders.map((item, i) => {
								if (i >= 2) {
									return (
										<div
											style={{ margin: '0px' }}
											// onClick={() =>
											// 	window.dataLayer.push({
											// 		event: 'sf_step_listing_provider_viewed',
											// 		sourceprovider: item.providerName,
											// 		event_category: 'listingpage',
											// 	})
											// }
											className={
												window.innerWidth < 600
													? 'FlexFullCenter'
													: 'ProviderCardContainer'
											}
											key={i}>
											<ProviderCard data={item} index={i} />
										</div>
									);
								} else return null;
							})}
						</div>
						<Pagination
							count={totalPages}
							page={currentPage}
							onChange={(event, value) => {
								setCurrentPage(value);
							}}
							color="primary"
							className="dlpProvidersPaginator"
							shape="rounded"
							sx={{
								'& .MuiPaginationItem-root': {
									color: '#4D5BEC',
									border: '1px solid #E3E5E9',
									borderRadius: '6px',
								},
								'& .MuiPaginationItem-root.Mui-selected': {
									backgroundColor: '#4D5BEC',
									color: '#ffffff',
									border: '1px solid #E3E5E9',
									borderRadius: '6px',
								},
								'& .MuiPaginationItem-root.Mui-selected:hover': {
									backgroundColor: '#4D5BEC',
									color: '#ffffff',
									border: '1px solid #E3E5E9',
									borderRadius: '6px',
								},
							}}
						/>
					</div>
				</div>
			</div>
			<DynamicListPagesFooter />
		</>
	);
};

export default DynamicListPages;
