import React, { useCallback, useEffect, useRef, useState } from 'react';
import Navigation from "../components/navigation/navigation";
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import ACTIONS from "../../../redux/action";

const IndustrySpecificExtra = ({ ...props }) => {
    const companyStore = useSelector((state) => state.company_store);
    const dispatch = useDispatch();
    const environmentalFeesSectionRef = useRef(null);

    const [cashflowVAT, setCashflowVAT] = useState({ status: '' });
    const [loanReview, setLoanReview] = useState({ reviewcount: null, reviewfrequency: '' });
    const [earlyClosure, setEarlyClosure] = useState({ status: '', earlyclosurefrequency: '' });
    const [preparingStatements, setPreparingStatements] = useState({ status: '' });
    const [invoicing, setInvoicing] = useState({ status: '' });
    const [transfering, setTransfering] = useState({ status: '' });
    const [auditCooperation, setAuditCooperation] = useState({ status: '' });
    const [vatAdjusting, setVatAdjusting] = useState({ status: '' });
    const [projectSupport, setProjectSupport] = useState({ status: '' });
    const [environmentalFees, setEnvironmentalFees] = useState({ status: '', handlingenvfees: '' });

    const general = companyStore.industryspecifics.general;

    useEffect(() => {
        if (general?.cashflowvat) {
            setCashflowVAT(general.cashflowvat);
        }
        if (general?.loanreview) {
            setLoanReview(general.loanreview);
        }
        if (general?.earlyclosure) {
            setEarlyClosure(general.earlyclosure);
        }
        if (general?.preparingstatements) {
            setPreparingStatements(general.preparingstatements);
        }
        if (general?.invoicing) {
            setInvoicing(general.invoicing);
        }
        if (general?.transfering) {
            setTransfering(general.transfering);
        }
        if (general?.auditcooperation) {
            setAuditCooperation(general.auditcooperation);
        }
        if (general?.vatadjusting) {
            setVatAdjusting(general.vatadjusting);
        }
        if (general?.projectsupport) {
            setProjectSupport(general.projectsupport);
        }
        if (general?.environmentalfees) {
            setEnvironmentalFees(general.environmentalfees);
        }
    }, [general]);

    const handleCashflowVATChange = useCallback(
        (e) => {
            const { value } = e.target;
            setCashflowVAT(value);

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        cashflowvat: { status: value },
                    },
                })
            );
        },
        [dispatch, general]
    );

    const handleLoanReviewCountChange = useCallback(
        (e) => {
            const value = e.target.value;
            const reviewcount = value === '' ? null : parseInt(value, 10);
            const newStatus = reviewcount !== null ? 'true' : '';
            const newFrequency = reviewcount > 0 ? loanReview.reviewfrequency : '';

            setLoanReview((prev) => ({
                ...prev,
                reviewcount: reviewcount !== null ? reviewcount : '',
                status: newStatus,
                reviewfrequency: newFrequency,
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        loanreview: {
                            ...loanReview,
                            reviewcount: reviewcount !== null ? reviewcount : null,
                            status: newStatus,
                            reviewfrequency: newFrequency,
                        },
                    },
                })
            );
        },
        [dispatch, loanReview, general]
    );    

    const handleLoanReviewFrequencyChange = useCallback(
        (e) => {
            const { value } = e.target;
            setLoanReview((prev) => ({
                ...prev,
                reviewfrequency: value,
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        loanreview: {
                            ...loanReview,
                            reviewfrequency: value,
                        },
                    },
                })
            );
        },
        [dispatch, loanReview, general]
    );

    const handleEarlyClosureChange = useCallback(
        (e) => {
            const { value } = e.target;
            setEarlyClosure((prev) => ({
                ...prev,
                status: value,
                earlyclosurefrequency: value === 'true'
                    ? prev.earlyclosurefrequency
                    : '',
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        earlyclosure: {
                            status: value,
                            earlyclosurefrequency: value === 'true'
                                ? earlyClosure.earlyclosurefrequency
                                : '',
                        },
                    },
                })
            );
        },
        [dispatch, earlyClosure.earlyclosurefrequency, general]
    );

    const handleEarlyClosureFrequencyChange = useCallback(
        (e) => {
            const { value } = e.target;
            setEarlyClosure((prev) => ({
                ...prev,
                earlyclosurefrequency: value,
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        earlyclosure: {
                            ...earlyClosure,
                            earlyclosurefrequency: value,
                        },
                    },
                })
            );
        },
        [dispatch, earlyClosure, general]
    );

    const handlePreparingStatementsChange = useCallback(
        (e) => {
            const { value } = e.target;
            setPreparingStatements({ status: value });

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        preparingstatements: { status: value },
                    },
                })
            );
        },
        [dispatch, general]
    );

    const handleInvoicingChange = useCallback(
        (e) => {
            const { value } = e.target;
            setInvoicing({ status: value });

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        invoicing: { status: value },
                    },
                })
            );
        },
        [dispatch, general]
    );

    const handleTransferingChange = useCallback(
        (e) => {
            const { value } = e.target;
            setTransfering({ status: value });

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        transfering: { status: value },
                    },
                })
            );
        },
        [dispatch, general]
    );

    const handleAuditCooperationChange = useCallback(
        (e) => {
            const { value } = e.target;
            setAuditCooperation(value);

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        auditcooperation: { status: value },
                    },
                })
            );
        },
        [dispatch, general]
    );

    const handleVatAdjustingChange = useCallback(
        (e) => {
            const { value } = e.target;
            setVatAdjusting({ status: value });

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        vatadjusting: { status: value },
                    },
                })
            );
        },
        [dispatch, general]
    );

    const handleProjectSupportChange = useCallback(
        (e) => {
            const { value } = e.target;
            setProjectSupport({ status: value });

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        projectsupport: { status: value },
                    },
                })
            );
        },
        [dispatch, general]
    );

    const handleEnvironmentalFeesChange = useCallback(
        (e) => {
            const { value } = e.target;
            setEnvironmentalFees((prev) => ({
                ...prev,
                status: value,
                handlingenvfees: value === 'true'
                    ? prev.handlingenvfees
                    : '',
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        environmentalfees: {
                            status: value,
                            handlingenvfees: value === 'true'
                                ? environmentalFees.handlingenvfees
                                : '',
                        },
                    },
                })
            );
        },
        [dispatch, environmentalFees.handlingenvfees, general]
    );

    useEffect(() => {
        if (environmentalFees.status === 'true' && environmentalFeesSectionRef.current) {
            environmentalFeesSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [environmentalFees.status]);

    const handleHandlingEnvFeesChange = useCallback(
        (e) => {
            const { value } = e.target;
            setEnvironmentalFees((prev) => ({
                ...prev,
                handlingenvfees: value,
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        environmentalfees: {
                            ...environmentalFees,
                            handlingenvfees: value,
                        },
                    },
                })
            );
        },
        [dispatch, environmentalFees, general]
    );

    const handleNext = useCallback(
        (inc) => {
            props.handleNext(inc);
        },
        [props]
    );

    const handleBack = useCallback(
        (decr) => {
            props.handleBack(decr);
        },
        [props]
    );

    useEffect(() => {
        console.log('COMPANY STORE', companyStore);
        console.log('SPECIFICS EXTRA', companyStore.industryspecifics);
    }, [companyStore.industryspecifics, companyStore]);

    return (
        <div className='stepContainer'>
            {/* LOAN REVIEW */}
            <div className='SFInput'>
                <p className='SFIndustrySpecSubTitle' style={{ marginTop: '0px' }}>
                    {i18n.t('searchflow.loanreviewquestion')}
                    <span className='questionTracker'>7a/7</span>
                </p>
                <TextField
                    label={i18n.t('searchflow.loanreviewcount')}
                    type="number"
                    value={loanReview.reviewcount !== null ? loanReview.reviewcount : ''}
                    onChange={handleLoanReviewCountChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    inputProps={{ min: 0 }}
                    onWheel={(e) => e.target.blur()}
                />

                {loanReview.reviewcount > 0 && (
                    <div style={{
                        paddingLeft: '20px',
                        marginTop: '10px'
                    }}>
                        <p className='SFIndustrySpecSubTitle'>
                            {i18n.t('searchflow.loanreviewfrequencyquestion')}
                        </p>
                        <RadioGroup
                            value={loanReview.reviewfrequency || ''}
                            onChange={handleLoanReviewFrequencyChange}
                        >
                            <FormControlLabel
                                className='SFRadioInput'
                                value="monthly"
                                control={<Radio />}
                                label={i18n.t('general.monthly')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="quarterly"
                                control={<Radio />}
                                label={i18n.t('general.quarterly')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="semiannually"
                                control={<Radio />}
                                label={i18n.t('general.biannual')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="annually"
                                control={<Radio />}
                                label={i18n.t('general.yearly')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="idk"
                                control={<Radio />}
                                label={i18n.t('general.idk')}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </div>
                )}
            </div>

            {/* CASHFLOW VAT */}
            {companyStore.vatFilter === 'true' && (
                <div className='SFInput'>
                    <p className='SFIndustrySpecSubTitle'>
                        {i18n.t('searchflow.cashflowvatquestion')}
                        <span className='questionTracker'>7b/7</span>
                    </p>
                    <RadioGroup
                        value={cashflowVAT.status || ''}
                        onChange={handleCashflowVATChange}>
                        <FormControlLabel
                            className='SFRadioInput'
                            value="true"
                            control={<Radio />}
                            label={i18n.t('searchflow.cashflowvatyes')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            value="false"
                            control={<Radio />}
                            label={i18n.t('searchflow.cashflowvatno')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            value="idk"
                            control={<Radio />}
                            label={i18n.t('general.idk')}
                            labelPlacement="start"
                        />
                    </RadioGroup>
                </div>
            )}

            {/* EARLY CLOSURE */}
            <div className='SFInput'>
                <p className='SFIndustrySpecSubTitle'>
                    {i18n.t('searchflow.earlyclosurequestion')}
                    <span className='questionTracker'>7c/7</span>
                </p>
                <RadioGroup
                    value={earlyClosure.status || ''}
                    onChange={handleEarlyClosureChange}
                >
                    <FormControlLabel
                        className='SFRadioInput'
                        value="true"
                        control={<Radio />}
                        label={i18n.t('general.yes')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="false"
                        control={<Radio />}
                        label={i18n.t('general.no')}
                        labelPlacement="start"
                    />
                </RadioGroup>
                {earlyClosure.status === 'true' && (
                    <div
                        style={{
                            paddingLeft: '20px',
                            marginTop: '10px'
                        }}>
                        <RadioGroup
                            value={earlyClosure.earlyclosurefrequency}
                            onChange={handleEarlyClosureFrequencyChange}
                        >
                            <FormControlLabel
                                className='SFRadioInput'
                                value="monthly"
                                control={<Radio />}
                                label={i18n.t('general.monthly')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="quarterly"
                                control={<Radio />}
                                label={i18n.t('general.quarterly')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="semiannually"
                                control={<Radio />}
                                label={i18n.t('general.biannual')}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </div>
                )}
            </div>

            {/* PREPARING STATEMENTS */}
            <div className='SFInput'>
                <p className='SFIndustrySpecSubTitle'>
                    {i18n.t('searchflow.preparingstatementsquestion')}
                    <span className='questionTracker'>7d/7</span>
                </p>
                <RadioGroup
                    value={preparingStatements.status}
                    onChange={handlePreparingStatementsChange}
                >
                    <FormControlLabel
                        className='SFRadioInput'
                        value="true"
                        control={<Radio />}
                        label={i18n.t('general.yes')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="false"
                        control={<Radio />}
                        label={i18n.t('general.no')}
                        labelPlacement="start"
                    />
                </RadioGroup>
            </div>

            {/* INVOICING */}
            <div className='SFInput'>
                <p className='SFIndustrySpecSubTitle'>
                    {i18n.t('searchflow.invoicingquestion')}
                    <span className='questionTracker'>7e/7</span>
                </p>
                <RadioGroup
                    value={invoicing.status}
                    onChange={handleInvoicingChange}
                >
                    <FormControlLabel
                        className='SFRadioInput'
                        value="true"
                        control={<Radio />}
                        label={i18n.t('general.yes')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="false"
                        control={<Radio />}
                        label={i18n.t('general.no')}
                        labelPlacement="start"
                    />
                </RadioGroup>
            </div>

            {/* TRANSFERING */}
            <div className='SFInput'>
                <p className='SFIndustrySpecSubTitle'>
                    {i18n.t('searchflow.transferingquestion')}
                    <span className='questionTracker'>7f/7</span>
                </p>
                <RadioGroup
                    value={transfering.status}
                    onChange={handleTransferingChange}
                >
                    <FormControlLabel
                        className='SFRadioInput'
                        value="true"
                        control={<Radio />}
                        label={i18n.t('general.yes')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="false"
                        control={<Radio />}
                        label={i18n.t('general.no')}
                        labelPlacement="start"
                    />
                </RadioGroup>
            </div>

            {/* AUDIT COOPERATION */}
            <div className='SFInput'>
                <p className='SFIndustrySpecSubTitle'>
                    {i18n.t('searchflow.auditcooperationquestion')}
                    <span className='questionTracker'>7g/7</span>
                </p>
                <RadioGroup
                    value={auditCooperation.status || ''}
                    onChange={handleAuditCooperationChange}
                >
                    <FormControlLabel
                        className='SFRadioInput'
                        value="true"
                        control={<Radio />}
                        label={i18n.t('general.yes')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="false"
                        control={<Radio />}
                        label={i18n.t('general.no')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="idk"
                        control={<Radio />}
                        label={i18n.t('general.idk')}
                        labelPlacement="start"
                    />
                </RadioGroup>
            </div>

            {/* VAT ADJUSTING */}
            {companyStore.vatFilter === 'true' && (
                <div className='SFInput'>
                    <p className='SFIndustrySpecSubTitle'>
                        {i18n.t('searchflow.vatadjustingquestion')}
                        <span className='questionTracker'>7h/7</span>
                    </p>
                    <RadioGroup
                        value={vatAdjusting.status}
                        onChange={handleVatAdjustingChange}
                    >
                        <FormControlLabel
                            className='SFRadioInput'
                            value="true"
                            control={<Radio />}
                            label={i18n.t('general.yes')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            value="false"
                            control={<Radio />}
                            label={i18n.t('general.no')}
                            labelPlacement="start"
                        />
                    </RadioGroup>
                </div>
            )}

            {/* PROJECT SUPPORT */}
            <div className='SFInput'>
                <p className='SFIndustrySpecSubTitle'>
                    {i18n.t('searchflow.projectsupportquestion')}
                    <span className='questionTracker'>7i/7</span>
                </p>
                <RadioGroup
                    value={projectSupport.status}
                    onChange={handleProjectSupportChange}
                >
                    <FormControlLabel
                        className='SFRadioInput'
                        value="true"
                        control={<Radio />}
                        label={i18n.t('general.yes')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="false"
                        control={<Radio />}
                        label={i18n.t('general.no')}
                        labelPlacement="start"
                    />
                </RadioGroup>
            </div>

            {/* ENVIRONMENTAL FEES */}
            <div className='SFInput'>
                <p className='SFIndustrySpecSubTitle'>
                    {i18n.t('searchflow.environmentalfeesquestion')}
                    <span className='questionTracker'>7j/7</span>
                </p>
                <RadioGroup
                    value={environmentalFees.status}
                    onChange={handleEnvironmentalFeesChange}
                >
                    <FormControlLabel
                        className='SFRadioInput'
                        value="true"
                        control={<Radio />}
                        label={i18n.t('general.yes')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="false"
                        control={<Radio />}
                        label={i18n.t('general.no')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="idk"
                        control={<Radio />}
                        label={i18n.t('general.idk')}
                        labelPlacement="start"
                    />
                </RadioGroup>
                {environmentalFees.status === 'true' && (
                    <div
                        ref={environmentalFeesSectionRef}
                        style={{
                            paddingLeft: '20px',
                        }}>
                        <p className='SFIndustrySpecSubTitle' style={{ marginTop: '8px' }}>
                            {i18n.t('searchflow.handlingenvironmentalfeesquestion')}
                        </p>
                        <RadioGroup
                            value={environmentalFees.handlingenvfees}
                            onChange={handleHandlingEnvFeesChange}
                        >
                            <FormControlLabel
                                className='SFRadioInput'
                                value="accountant"
                                control={<Radio />}
                                label={i18n.t('searchflow.handlingenvironmentalfeesaccountant')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className='SFRadioInput'
                                value="selfmanaged"
                                control={<Radio />}
                                label={i18n.t('searchflow.handlingenvironmentalfeesself')}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </div>
                )}
            </div>
            <Navigation
                step={14}
                handleBack={handleBack}
                handleNext={handleNext}
                openHelpForm={() => props.openHelpForm()}
            />
        </div>
    )
}

export default withTranslation()(IndustrySpecificExtra);