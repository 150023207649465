import React from 'react';
import {
	Needs,
	CompanyType,
	ForeignTrade,
	Income,
	InvoicesIn,
	InvoicesOut,
	Languages,
	FakeCherryProviders,
	AspProviders,
	Preferences,
	Banks,
	Accounts,
	Software,
	Industry,
	Vat,
	Taxform,
	FlowSwitch,
	//VafContactForm,
} from './newsteps/index.js';
import HorizontalLinearStepper from './components/tracker/tracker.jsx';
import Dialog from '@mui/material/Dialog';
import KnetPlusForm from '../../shared/components/knetPlusForm/knetPlus';
import axios from '../utils/axiosCompanyProxy';
import './searchFlow.scss';
import i18n from '../../utils/i18n.js';
import { withTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import peaking1 from '../../images/peaking.png';
import peaking2 from '../../images/peaking2.png';
import peaking3 from '../../images/peaking3.png';
import peaking4 from '../../images/peaking4.png';
import rocketman from '../../images/Rocketman.png';
import blueaccountant from '../../images/BlueAccountant.png';

//REDUX
import ACTIONS, {
	fetchAspPotentialProviders,
	fetchCherryPotentialProviders,
} from '../../redux/action';
import { connect } from 'react-redux';
import { checkStateChange } from './data/searchFlowText';
import IndustrySpecific from './newsteps/industrySpecific.jsx';
import IndustrySpecificGeneral from './newsteps/industrySpecificGeneral.jsx';
import IndustrySpecificExtra from './newsteps/industrySpecificExtra.jsx';
// hello
const mapStateToProps = (state) => ({
	store: state,
	loading: state.loading,
	company_store: state.company_store,
	match_score_weights: state.match_score_weights,
	potential_providers: state.company_store.potential_providers,
});
const mapDispatchToProps = (dispatch) => ({
	addCompanyInfo: (item) => dispatch(ACTIONS.addCompanyInfo(item)),
	fetchAspPotentialProviders: (companyData, msWeights) =>
		dispatch(fetchAspPotentialProviders(companyData, msWeights)),
	fetchCherryPotentialProviders: (companyData, msWeights) =>
		dispatch(fetchCherryPotentialProviders(companyData, msWeights)),
});

class SearchFlow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeStep: 0,
			reqID: '',
			cherryID: '',
			searchID: '',
			completedSteps: 0,
			kNetPlusSent: false,
			helpForm: false,
			stepData: `1 - ${new Date().toLocaleTimeString(
				i18n.t('general.countryformat'),
				{
					hour12: false,
				}
			)}`,
			viewedProviders: [],
			utmzz: Cookies.get('__utmzz') || null,
			utm_source: '',
			utm_medium: '',
			utm_campaign: '',
			utm_content: '',
			utm_term: '',
			gclid: '',
			dclid: '',
			vacatiOn: false,
		};
	}

	dataLayerUpdate(stepData) {
		window.dataLayer.push({
			event: 'sf_step_changed',
			step: stepData,
			event_category: 'aspflow',
		});
	}

	componentDidMount() {
		const selectedSP = localStorage.getItem('selectedSP');
		selectedSP &&
			this.props.addCompanyInfo({
				id: 'spSelectedFromList',
				data: selectedSP,
				stateObject: 'company_store',
			});
		console.log(selectedSP, 'selectedSP');
		this.dataLayerUpdate(0);

		let cookie = document.cookie;
		if (!cookie.includes('reqsent')) {
			document.cookie = 'reqsent=false';
		}
		if (this.state.utmzz !== null) {
			let utmsplit = this.state.utmzz.split('|');
			for (let i of utmsplit) {
				let key = i.split('=')[0];
				let value = i.split('=')[1];

				if (key === 'utmcsr') {
					//console.log(key, 'SOURCE');
					this.setState({ utm_source: value });
					this.props.addCompanyInfo({
						id: 'utm_source',
						data: value,
						stateObject: 'company_store',
					});
				} else if (key === 'utmccn') {
					//console.log(key, 'CAMPAIGN');
					this.setState({ utm_campaign: value });
					this.props.addCompanyInfo({
						id: 'utm_campaign',
						data: value,
						stateObject: 'company_store',
					});
				} else if (key === 'utmcmd') {
					//console.log(key, 'CAMPAIGN');
					this.setState({ utm_medium: value });
					this.props.addCompanyInfo({
						id: 'utm_medium',
						data: value,
						stateObject: 'company_store',
					});
				}
			}
		}
	}
	componentDidUpdate(_prevProps, prevState) {
		//console.log('reqID: ', this.state.reqID);
		//console.log(this.state.reqID, 'this.state.reqID IN COMPDIDUPDATE');
		this.handleSearchUpdate(prevState.activeStep);

		if (
			this.state.completedSteps >= 18 &&
			checkStateChange(_prevProps.company_store, this.props.company_store) ===
			true
			// && this.checkServices() === true
		) {
			this.props.fetchAspPotentialProviders(
				this.props.company_store,
				this.props.match_score_weights
			);
		}
		if (
			this.state.completedSteps >= 19 &&
			this.state.reqID.length > 0 &&
			checkStateChange(_prevProps.company_store, this.props.company_store)
			// && this.checkServices() === true
		) {
			//this.setState({ reqID: '' });
			this.props.addCompanyInfo({
				id: 'selected_providers',
				data: [],
				stateObject: 'company_store',
			});
		}
	}

	handleNext = (increment = 1) => {
		//console.log(increment, typeof increment, 'INCREMENT');
		if (this.state.activeStep <= 19) {
			if (this.state.searchID === '') {
				this.submitSearchData();
			}
			this.setState({
				activeStep: this.state.activeStep + increment,
			});
			this.dataLayerUpdate(this.state.activeStep + increment);
			if (this.state.completedSteps < this.state.activeStep) {
				this.setState({
					completedSteps: this.state.completedSteps + increment,
				});
			}
		}
		this.props.addCompanyInfo({
			id: 'activeStep',
			data: this.state.activeStep,
			stateObject: 'company_store',
		});
		//console.log(this.props.company_store, 'KOMPANI');
		window.scrollTo(0, 0);
	};
	handleBack = (decr = 1) => {
		// window.dataLayer.push({
		//   event: 'sf_step_changed',
		//   step: this.state.activeStep - decr,
		//   event_category: 'searchflow',
		// });

		//console.log(decr, typeof decr, 'decrement');
		if (this.state.activeStep !== 0) {
			this.setState({ activeStep: this.state.activeStep - decr });
		}
		this.props.addCompanyInfo({
			id: 'activeStep',
			data: this.state.activeStep,
			stateObject: 'company_store',
		});
		window.scrollTo(0, 0);
	};
	handleTracker = (step) => {
		this.setState({ activeStep: step });
	};
	handleKnetPlusContact = () => {
		this.setState({ kNetPlusSent: true });
	};
	openHelpForm = () => {
		this.setState({ helpForm: true });
	};
	closeHelpForm = () => {
		this.setState({ helpForm: false });
	};
	createAspRequest = (companyData) => {
		let timeOfConfirm = this.state.stepData.concat(
			` Req sent - ${new Date().toLocaleTimeString(
				i18n.t('general.countryformat'),
				{
					hour12: false,
				}
			)}`
		);
		let data = Object.assign({}, companyData);
		// let ppArray = [[], [], []];
		// for (var i in data.potential_providers) {
		//   for (let j in i) {
		//     ppArray[i].push(data.potential_providers[i][j]);
		//   }
		// }
		// data.potential_providers = ppArray;
		data.searchID = this.state.searchID;
		if (data.noofemployees === '') {
			data.noofemployees = 0;
		}
		if (data.incominginvoices === '') {
			data.incominginvoices = 0;
		}
		if (data.outgoinginvoices === '') {
			data.outgoinginvoices = 0;
		}

		//console.log(data, 'ASPO REQ DATA');
		//data.lang = this.props.company_store.chosenLanguage;
		axios
			.post(`/api/aspreq`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				// console.log('AXIOS RESPONSE: ', response);
				sessionStorage.setItem('reqEmail', data.email);
				//itt majd nézd már meg kérlek hogy lehet elérni az _id-t a response-ban és az 124 helyett írd be azt
				/* sessionStorage.setItem('reqID', '124'); */
				this.setState({ reqID: response.data.result._id });
				this.props.addCompanyInfo({
					id: 'reqID',
					data: response.data.result._id,
					stateObject: 'company_store',
				});
				this.updateSearchData(timeOfConfirm);
			})
			.catch((error) => {
				console.log('AXIOS ERROR: ', error.response.data);
			});
	};
	requestCallBack = (companyData) => {
		let data = Object.assign({}, companyData);
		//console.log(data, 'ASPO REQ DATA');

		axios
			.post(`/api/aspreq/requestcallback/new`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				console.log('AXIOS RESPONSE: ', response);
				this.setState({ reqID: response.data.result._id });
				this.props.addCompanyInfo({
					id: 'reqID',
					data: response.data.result._id,
					stateObject: 'company_store',
				});
			})
			.catch((error) => {
				console.log('AXIOS ERROR: ', error.response.data);
			});
	};

	createCherryRequest = (companyData) => {
		let timeOfConfirm = this.state.stepData.concat(
			` Req sent - ${new Date().toLocaleTimeString(
				i18n.t('general.countryformat'),
				{
					hour12: false,
				}
			)}`
		);
		let data = Object.assign({}, companyData);
		let ppArray = [];
		for (var i in data.potential_providers) {
			ppArray.push(data.potential_providers[i]);
		}
		data.potential_providers = ppArray;
		data.searchID = this.state.searchID;
		if (data.noofemployees === '') {
			data.noofemployees = 0;
		}
		if (data.incominginvoices === '') {
			data.incominginvoices = 0;
		}
		if (data.outgoinginvoices === '') {
			data.outgoinginvoices = 0;
		}

		//console.log(data, 'CHERRY REQ DATA');
		//data.lang = this.props.company_store.chosenLanguage;
		axios
			.post(`/api/request`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				//sessionStorage.setItem('reqEmail', data.email);
				//itt majd nézd már meg kérlek hogy lehet elérni az _id-t a response-ban és az 124 helyett írd be azt
				//sessionStorage.setItem('reqID', response.data.result._id);
				//console.log('AXIOS RESPONSE: ', response);
				this.setState({ cherryID: response.data.result._id });
				this.props.addCompanyInfo({
					id: 'cherryID',
					data: response.data.result._id,
					stateObject: 'company_store',
				});
				if (this.state.reqID === '') {
					this.setState({ reqID: response.data.result._id });
					this.props.addCompanyInfo({
						id: 'reqID',
						data: response.data.result._id,
						stateObject: 'company_store',
					});
				}
				this.updateSearchData(timeOfConfirm);
			})
			.catch((error) => {
				console.log('AXIOS ERROR: ', error);
			});
	};
	updateRequest = (companyData, reqID = this.state.reqID) => {
		let data = Object.assign({}, companyData);
		if (data.potential_providers) {
			delete data.potential_providers;
		}
		if (data.noofemployees === '') {
			data.noofemployees = 0;
		}
		//console.log(data, this.state.reqID, 'DATA AND REQID IN UPDATE');
		let dataToSend = { reqData: data, reqID: reqID };
		axios
			.patch(`/api/request/addprov`, dataToSend, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				console.log('AXIOS RESPONSE: ', response);
			})
			.catch((error) => {
				console.log('AXIOS ERROR: ', error.response.data);
			});
	};
	updateFakeCherryRequest = (companyData) => {
		let data = Object.assign({}, companyData);
		// if (data.potential_providers) {
		//   delete data.potential_providers;
		// }
		if (data.noofemployees === '') {
			data.noofemployees = 0;
		}
		//console.log(this.state.reqID, 'REQID');
		let dataToSend = { reqData: data, reqID: this.state.reqID };
		//console.log(dataToSend, 'DATA AND REQID IN UPDATE');
		axios
			.patch(`/api/aspreq/addprov/addfakecherry`, dataToSend, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				console.log('AXIOS RESPONSE: ', response);
			})
			.catch((error) => {
				console.log('AXIOS ERROR: ', error.response.data);
			});
	};

	submitSearchData = () => {
		let data = this.props.company_store;
		data.step = this.state.activeStep + 1;
		data.time = this.state.stepData;
		data.returnedProviders = 0;
		data.screenWidth = `${window.innerWidth}`;
		data.lang = this.props.company_store.chosenLanguage;
		axios
			.post(`/api/search`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				console.log('AXIOS RESPONSE: ', response.statusText);
				this.setState({ searchID: response.data.result._id });
				response.data.result._id &&
					this.props.addCompanyInfo({
						id: 'searchID',
						data: response.data.result._id,
						stateObject: 'company_store',
					});
			})
			.catch((error) => {
				console.log('AXIOS ERROR: ', error.response.data);
			});
	};
	updateSearchData = (time) => {
		let data = this.props.company_store;
		data.lang = this.props.company_store.chosenLanguage;
		if (!this.state.requestSent) {
			data.step = this.state.activeStep + 1;
		}
		data.time = time;
		data.reqID = this.state.reqID;
		data.distanceWeight = this.props.store.match_score_weights.distanceWeight;
		data.priceWeight = this.props.store.match_score_weights.priceWeight;
		data.reviewWeight = this.props.store.match_score_weights.reviewWeight;
		data.phoneWeight = this.props.store.match_score_weights.phoneWeight;
		data.availabilityWeight =
			this.props.store.match_score_weights.availabilityWeight;
		data.experienceWeight =
			this.props.store.match_score_weights.experienceWeight;
		data.liabilityInsWeight =
			this.props.store.match_score_weights.liabilityInsWeight;
		data.badgeWeight = this.props.store.match_score_weights.badgeWeight;
		console.log(this.props.potential_providers, 'EEEEE');
		if (
			this.props.potential_providers !== undefined &&
			this.props.potential_providers.constructor === Object &&
			this.props.potential_providers.some((e) => e.length !== 0)
		) {
			let rPCounter = 0;
			this.props.potential_providers.forEach((e) => {
				console.log(e, 'EEEEE');
				rPCounter += e.length;
			});

			data.returnedProviders = rPCounter;
			let tempArray = [[], [], []];
			for (let i in this.props.potential_providers) {
				for (let j in i) {
					tempArray[i].push(this.props.potential_providers[i][j].companyname);
				}
			}
			data.providers = tempArray;
		}
		axios
			.patch(`/api/search/${this.state.searchID}`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				console.log('AXIOS RESPONSE: ', response.statusText);
			})
			.catch((error) => {
				console.log('AXIOS ERROR: ', error.response.data);
			});
	};
	handleSearchUpdate = (prevStep) => {
		if (this.state.activeStep !== prevStep) {
			let timeString = this.state.stepData.concat(
				`, ${this.state.activeStep + 1} - ${new Date().toLocaleTimeString(
					'hu-HU',
					{
						hour12: false,
					}
				)}`
			);
			this.setState({
				stepData: timeString,
			});
			if (this.state.activeStep !== 1) {
				this.updateSearchData(timeString);
			}
		}
		if (prevStep === 'tableUpdate') {
			this.updateSearchData(this.state.stepData);
		}
	};
	updateViewedProviders = (provider) => {
		let data = {};
		let viewedProviders = this.state.viewedProviders;
		if (viewedProviders.length === 0) {
			viewedProviders.push(provider);
		}
		if (
			viewedProviders.length > 0 &&
			!viewedProviders.some((item) => item.name === provider.name)
		) {
			viewedProviders.push(provider);
		}
		data.viewedProviders = viewedProviders;
		this.setState({
			viewedProviders: viewedProviders,
		});
		axios
			.patch(`/api/search/${this.state.searchID}`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				console.log('AXIOS RESPONSE: ', response);
			})
			.catch((error) => {
				console.log('AXIOS ERROR: ', error.response.data);
			});
	};

	stepperCounter = {
		0: 0,
		1: 1,
		2: 1,
		3: 1,
		4: 1,
		5: 1,
		6: 1,
		7: 1,
		8: 1,
		9: 1,
		10: 2,
		11: 2,
		12: 2,
		13: 2,
		14: 2,
		15: 3,
		16: 3,
		17: 3,
		18: 3,
	};

	headerText = {
		0: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext1')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext1a')}</p>
			</div>
		),
		1: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext2')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext2a')}</p>
			</div>
		),
		2: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext2')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext2a')}</p>
			</div>
		),
		3: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext2')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext2a')}</p>
			</div>
		),
		4: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext2')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext2a')}</p>
			</div>
		),
		5: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext2')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext2a')}</p>
			</div>
		),
		6: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext2')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext2a')}</p>
			</div>
		),
		7: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext2')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext2a')}</p>
			</div>
		),
		8: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext2')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext2a')}</p>
			</div>
		),
		9: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext2')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext2a')}</p>
			</div>
		),
		10: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext3')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext3a')}</p>
			</div>
		),
		11: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext3')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext3a')}</p>
			</div>
		),
		12: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext3')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext3a')}</p>
			</div>
		),
		13: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext3')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext3a')}</p>
			</div>
		),
		14: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext3')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext3a')}</p>
			</div>
		),
		15: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext4')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext4a')}</p>
			</div>
		),
		16: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext4')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext4a')}</p>
			</div>
		),
		17: (
			<div>
				<p className="headerSubText">{i18n.t('searchflow.headertext4')}</p>
				<p className="headerText">{i18n.t('searchflow.headertext4a')}</p>
			</div>
		),
		18: null,
		19: null,
	};
	imageSource = {
		0: peaking1,
		1: peaking2,
		2: peaking2,
		3: peaking2,
		4: peaking2,
		5: peaking2,
		6: peaking2,
		7: peaking2,
		8: peaking2,
		9: peaking2,
		10: peaking3,
		11: peaking3,
		12: peaking3,
		13: peaking3,
		14: peaking3,
		15: peaking4,
		16: peaking4,
		17: peaking4,
		18: null,
		19: null,
	};

	getStepContent = (step) => {
		switch (step) {
			case 0: {
				return (
					<Needs
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
					/>
				);
			}

			case 1: {
				return (
					<CompanyType
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
					/>
				);
			}
			case 2: {
				return (
					<Taxform
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
					/>
				);
			}
			case 3: {
				return (
					<Vat
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
					/>
				);
			}
			case 4: {
				return (
					<Industry
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
					/>
				);
			}
			case 5: {
				return (
					<IndustrySpecific
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
					/>
				);
			}
			case 6: {
				return (
					<Banks
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
					/>
				);
			}
			case 7: {
				return (
					<Accounts
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
					/>
				);
			}
			case 8: {
				return (
					<Software
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
					/>
				);
			}
			case 9: {
				return (
					<ForeignTrade
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
					/>
				);
			}
			case 10: {
				return (
					<Income
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
					/>
				);
			}
			case 11: {
				return (
					<InvoicesIn
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
					/>
				);
			}
			case 12: {
				return (
					<InvoicesOut
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
					/>
				);
			}
			case 13: {
				return (
					<IndustrySpecificGeneral
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
					/>
				);
			}
			case 14: {
				return (
					<IndustrySpecificExtra
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
					/>
				);
			}
			case 15: {
				return (
					<Languages
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
					/>
				);
			}
			case 16: {
				return (
					<Preferences
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
						fetchAspPotentialProviders={this.props.fetchAspPotentialProviders}
						fetchCherryPotentialProviders={
							this.props.fetchCherryPotentialProviders
						}
					/>
				);
			}
			case 17: {
				return (
					<FlowSwitch
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						openHelpForm={this.openHelpForm}
						fetchAspPotentialProviders={this.props.fetchAspPotentialProviders}
						fetchCherryPotentialProviders={
							this.props.fetchCherryPotentialProviders
						}
					/>
				);
			}
			case 18: {
				return (
					<AspProviders
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						createAspRequest={this.createAspRequest}
						updateRequest={this.updateRequest}
						reqID={this.state.reqID}
						openHelpForm={this.openHelpForm}
						updateViewedProviders={this.updateViewedProviders}
						handleSearchUpdate={this.handleSearchUpdate}
						discountCode={this.state.discountCode}
						requestCallBack={this.requestCallBack}
					/>
				);
			}
			case 19: {
				return (
					<FakeCherryProviders
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						createAspRequest={this.createAspRequest}
						createRequest={this.createCherryRequest}
						updateRequest={this.updateRequest}
						updateFakeCherryRequest={this.updateFakeCherryRequest}
						reqID={this.state.reqID}
						openHelpForm={this.openHelpForm}
						updateViewedProviders={this.updateViewedProviders}
						handleSearchUpdate={this.handleSearchUpdate}
						discountCode={this.state.discountCode}
						fetchAspPotentialProviders={this.props.fetchAspPotentialProviders}
						requestCallBack={this.requestCallBack}
					/>
				);
			}
			default:
				return i18n.t('general.notfound');
		}
	};

	render() {
		return (
			<div className={'SFContainer'}>
				{/*<Dialog
          open={this.state.vacatiOn}
          onClose={() => this.setState({ vacatiOn: false })}
        >
          <div className='announcement'>
            <span
              className='Xbtn'
              onClick={() => this.setState({ vacatiOn: false })}
            >
              X
            </span>
            Kedves Könyvelőt Keresők! <br />
            <br /> A Bookkeepie Csapata és Könyvelő Partnereink nagy része is
            téli szabadságát tölti (2023.12.21-től 2024.01.02-ig), ebben az
            időben kérlek kicsit lassabb ügyintézésre számítsatok. Amint
            visszatértünk, mindannyiótoknak segítünk!
          </div>
    </Dialog>*/}
				<Dialog
					open={this.props.company_store.idk || this.state.helpForm}
					onClose={() => {
						this.props.company_store.idk === true
							? this.props.addCompanyInfo({
								id: 'idk',
								data: false,
								stateObject: 'company_store',
							})
							: this.setState({ helpForm: false });
					}}
					handleClose={() => {
						this.props.company_store.idk === true
							? this.props.addCompanyInfo({
								id: 'idk',
								data: false,
								stateObject: 'company_store',
							})
							: this.setState({ helpForm: false });
					}}>
					<KnetPlusForm
						kNetPlusSent={this.state.kNetPlusSent}
						handleKnetPlusContact={this.handleKnetPlusContact}
						closeHelpForm={this.closeHelpForm}
						helpForm={this.state.helpForm}
					/>
				</Dialog>
				{this.state.activeStep !== 18 && this.state.activeStep !== 19 && (
					<div className="SFLeftInfoArea">
						<img src={rocketman} alt="RocketMan" className="rocketman" />
					</div>
				)}
				<div
					className={`${this.state.activeStep !== 18 && this.state.activeStep !== 19
						? 'SFContentArea'
						: 'SFOfferArea'
						}`}>
					{this.state.activeStep !== 18 && this.state.activeStep !== 19 && (
						<div className="SFContentHeader">
							<div className="stepperContainer">
								<HorizontalLinearStepper
									activeStep={this.stepperCounter[this.state.activeStep]}
								/>
							</div>
							<div>{this.headerText[this.state.activeStep]}</div>

							<div>
								<img
									className="headerImg"
									alt="header"
									src={this.imageSource[this.state.activeStep]}
								/>
							</div>
						</div>
					)}

					{this.getStepContent(this.state.activeStep)}
				</div>
				{this.state.activeStep !== 18 && this.state.activeStep !== 19 && (
					<div className="SFRightInfoArea">
						<img
							src={blueaccountant}
							alt="Accountant lady"
							className="blueaccountant"
						/>
					</div>
				)}
				<div style={{ display: 'none' }}>
					<p>Hidden fields</p>
					<input type="hidden" />
					<br />
					<input
						type="text"
						id="utm_source"
						name="utm_source"
					// onChange={this.hiddenHandler}
					// value={this.state.utm_source}
					/>
					<br />
					<input
						type="text"
						id="utm_medium"
						name="utm_medium"
					// onChange={this.hiddenHandler}
					// value={this.state.utm_medium}
					/>
					<br />
					<input
						type="text"
						id="utm_campaign"
						name="utm_campaign"
					// onChange={this.hiddenHandler}
					// value={this.state.utm_campaign}
					/>
					<br />
					<input
						type="text"
						id="utm_content"
						name="utm_content"
					// onChange={this.hiddenHandler}
					// value={this.state.utm_content}
					/>
					<br />
					<input
						type="text"
						id="utm_term"
						name="utm_term"
					// onChange={this.hiddenHandler}
					// value={this.state.utm_term}
					/>
					<br />
					<input
						type="text"
						id="gclid"
						name="gclid"
					// onChange={this.hiddenHandler}
					// value={this.state.gclid}
					/>
					<br />
					<input
						type="text"
						id="dclid"
						name="dclid"
					// onChange={this.hiddenHandler}
					// value={this.state.dclid}
					/>
					<br />
				</div>
			</div>
		);
	}
}

export default withTranslation()(
	connect(mapStateToProps, mapDispatchToProps)(SearchFlow)
);
