import i18n from '../../../utils/i18n';
import React from 'react';
import { forwardRef } from 'react';
import { legalDocuments } from '../../../shared/data/legalDocuments';
/* import hungary from "../../images/icons/hungary.png"; */
import uk from '../../../images/icons/united-kingdom.png';
import russia from '../../../images/icons/russia.png';
import romania from '../../../images/icons/romania.png';
import china from '../../../images/icons/china.png';
import spain from '../../../images/icons/spain.png';
import france from '../../../images/icons/france.png';
import germany from '../../../images/icons/germany.png';
//material table icons
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';

export const trackerData = () => [
  { label: i18n.t('searchflow.steppertext1') },
  { label: i18n.t('searchflow.steppertext2') },
  { label: i18n.t('searchflow.steppertext3') },
  { label: i18n.t('searchflow.steppertext4') },
];

export const preferencesText = () => [
  {
    label: i18n.t('searchflow.preftext1'),
    value: 5,
    id: 'liabilityInsWeight',
    data: 5,
  },
  {
    label: i18n.t('searchflow.preftext2'),
    value: 5,
    id: 'experienceWeight',
    data: 5,
  },
  {
    label: i18n.t('searchflow.preftext3'),
    value: 5,
    id: 'badgeWeight',
    data: 5,
  },
  {
    label: i18n.t('searchflow.preftext4'),
    value: 5,
    id: 'phoneWeight',
    data: 5,
  },
  {
    label: i18n.t('searchflow.preftext6'),
    value: 5,
    id: 'availabilityWeight',
    data: 5,
  },
  {
    label: i18n.t('searchflow.preftext5'),
    value: 5,
    id: 'distanceWeight',
    data: 5,
  },
];

export const newlyFoundedInput = () => [
  {
    label: i18n.t('general.yes'),
    value: true,
    id: 'newlyfounded',
    data: true,
  },
  {
    label: i18n.t('general.no'),
    value: false,
    id: 'newlyfounded',
    data: false,
  },
];

export const vatFilterInput = () => [
  {
    label: i18n.t('general.idk'),
    value: 'idk',
    id: 'vatFilter',
    data: 'idk',
  },
  {
    label: i18n.t('general.yes'),
    value: 'true',
    id: 'vatFilter',
    data: 'true',
  },
  {
    label: i18n.t('general.no'),
    value: 'false',
    id: 'vatFilter',
    data: 'false',
  },
];

export const vatInput = () => [
  {
    label: i18n.t('general.idk'),
    value: 'idk',
    id: 'vatPayment',
    data: 'idk',
  },
  {
    label: i18n.t('general.monthly'),
    value: 'monthly',
    id: 'vatPayment',
    data: 'monthly',
  },
  {
    label: i18n.t('general.quarterly'),
    value: 'quarterly',
    id: 'vatPayment',
    data: 'quarterly',
  },
  {
    label: i18n.t('general.yearly'),
    value: 'yearly',
    id: 'vatPayment',
    data: 'yearly',
  },
];
export const TaxformInput = () => [
  {
    label: i18n.t('general.idk'),
    value: 'idk',
    data: 'idk',
    id: 'taxform',
  },
  {
    label: i18n.t('general.taxform1'),
    value: 'kiva',
    data: 'kiva',
    id: 'taxform',
  },
  {
    label: i18n.t('general.taxform2'),
    value: 'tao',
    data: 'tao',
    id: 'taxform',
  },
];
export const TaxformInput2 = () => [
  {
    label: i18n.t('general.taxform1'),
    value: 'kiva',
    data: 'kiva',
    id: 'taxform',
  },
  {
    label: i18n.t('general.taxform2'),
    value: 'tao',
    data: 'tao',
    id: 'taxform',
  },
];

export const revenueRangesInput = () => [
  { id: 'yearlyrevenue', value: -10, data: -10, label: i18n.t('general.idk') },
  {
    id: 'yearlyrevenue',
    value: 5,
    data: 5,
    label: i18n.t('general.revfeecat1'),
  },
  {
    id: 'yearlyrevenue',
    value: 10,
    data: 10,
    label: i18n.t('general.revfeecat2'),
  },
  {
    id: 'yearlyrevenue',
    value: 50,
    data: 50,
    label: i18n.t('general.revfeecat3'),
  },
  {
    id: 'yearlyrevenue',
    value: 100,
    data: 100,
    label: i18n.t('general.revfeecat4'),
  },
  {
    id: 'yearlyrevenue',
    value: 200,
    data: 200,
    label: i18n.t('general.revfeecat4a'),
  },
  {
    id: 'yearlyrevenue',
    value: 500,
    data: 500,
    label: i18n.t('general.revfeecat5'),
  },
  {
    id: 'yearlyrevenue',
    value: 1999,
    data: 1999,
    label: i18n.t('general.revfeecat6'),
  },
  {
    id: 'yearlyrevenue',
    value: 2000,
    data: 2000,
    label: i18n.t('general.revfeecat7'),
  },
];

export const foreignTradeInput = () => [
  {
    label: i18n.t('general.yes'),
    value: true,
    id: 'foreign_trade',
    data: true,
  },
  {
    label: i18n.t('general.no'),
    value: false,
    id: 'foreign_trade',
    data: false,
  },
];

export const foreignTradeEUInput = () => [
  {
    label: i18n.t('searchflow.insideeu'),
    value: 'inside',
    id: 'foreigntradeEU',
    data: 'inside',
  },
  {
    label: i18n.t('searchflow.inandoutsideeu'),
    value: 'both',
    id: 'foreigntradeEU',
    data: 'both',
  },
  {
    label: i18n.t('searchflow.outsideeu'),
    value: 'outside',
    id: 'foreigntradeEU',
    data: 'outside',
  },
];

export const languageFilterInput = () => [
  {
    label: i18n.t('general.yes'),
    value: true,
    id: 'languagefilter',
    data: true,
  },
  {
    label: i18n.t('general.no'),
    value: false,
    id: 'languagefilter',
    data: false,
  },
];

export const zipInput = () => [
  { label: i18n.t('general.zip'), id: 'zip_code' },
];

export const employeesInput = () => [
  {
    label: i18n.t('general.noofemployees0'),
    id: 'noofemployees',
    value: '0',
    data: '0',
  },
  {
    label: i18n.t('general.noofemployees1'),
    id: 'noofemployees',
    value: '1',
    data: '1',
  },
  {
    label: i18n.t('general.noofemployees2'),
    id: 'noofemployees',
    value: '2',
    data: '2',
  },
  {
    label: i18n.t('general.noofemployees3'),
    id: 'noofemployees',
    value: '3',
    data: '3',
  },
  {
    label: i18n.t('general.noofemployees4'),
    id: 'noofemployees',
    value: '4',
    data: '4',
  },
  {
    label: i18n.t('general.noofemployees5'),
    id: 'noofemployees',
    value: '5',
    data: '5',
  },
];

export const incomingInvoicesInput = () => [
  {
    label: i18n.t('searchflow.invoiceinput9'),
    id: 'incominginvoices',
    value: 'idk',
    data: 'idk',
  },
  {
    label: i18n.t('searchflow.invoiceinput0'),
    id: 'incominginvoices',
    value: '0',
    data: '0',
  },
  {
    label: i18n.t('searchflow.invoiceinput1'),
    id: 'incominginvoices',
    value: '10',
    data: '10',
  },
  {
    label: i18n.t('searchflow.invoiceinput2'),
    id: 'incominginvoices',
    value: '20',
    data: '20',
  },
  {
    label: i18n.t('searchflow.invoiceinput3'),
    id: 'incominginvoices',
    value: '50',
    data: '50',
  },
  {
    label: i18n.t('searchflow.invoiceinput4'),
    id: 'incominginvoices',
    value: '100',
    data: '100',
  },
  {
    label: i18n.t('searchflow.invoiceinput5'),
    id: 'incominginvoices',
    value: '200',
    data: '200',
  },
  {
    label: i18n.t('searchflow.invoiceinput6'),
    id: 'incominginvoices',
    value: '500',
    data: '500',
  },
  {
    label: i18n.t('searchflow.invoiceinput7'),
    id: 'incominginvoices',
    value: '1000',
    data: '1000',
  },
  {
    label: i18n.t('searchflow.invoiceinput8'),
    id: 'incominginvoices',
    value: '2000',
    data: '2000',
  },
];
export const outgoingInvoicesInput = () => [
  {
    label: i18n.t('searchflow.invoiceinput9'),
    id: 'outgoinginvoices',
    value: 'idk',
    data: 'idk',
  },
  {
    label: i18n.t('searchflow.invoiceinput0'),
    id: 'outgoinginvoices',
    value: '0',
    data: '0',
  },
  {
    label: i18n.t('searchflow.invoiceinput1'),
    id: 'outgoinginvoices',
    value: '10',
    data: '10',
  },
  {
    label: i18n.t('searchflow.invoiceinput2'),
    id: 'outgoinginvoices',
    value: '20',
    data: '20',
  },
  {
    label: i18n.t('searchflow.invoiceinput3'),
    id: 'outgoinginvoices',
    value: '50',
    data: '50',
  },
  {
    label: i18n.t('searchflow.invoiceinput4'),
    id: 'outgoinginvoices',
    value: '100',
    data: '100',
  },
  {
    label: i18n.t('searchflow.invoiceinput5'),
    id: 'outgoinginvoices',
    value: '200',
    data: '200',
  },
  {
    label: i18n.t('searchflow.invoiceinput6'),
    id: 'outgoinginvoices',
    value: '500',
    data: '500',
  },
  {
    label: i18n.t('searchflow.invoiceinput7'),
    id: 'outgoinginvoices',
    value: '1000',
    data: '1000',
  },
  {
    label: i18n.t('searchflow.invoiceinput8'),
    id: 'outgoinginvoices',
    value: '2000',
    data: '2000',
  },
];
export const companyPhoneInput = () => [
  { label: i18n.t('general.phone'), id: 'companyPhone' },
];

export const industryInput1 = () => [
  {
    label: i18n.t('industrylabels.IT'),
    value: 'IT',
    data: 'IT',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.construction'),
    value: 'construction',
    data: 'construction',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.etrade'),
    value: 'etrade',
    data: 'etrade',
    id: 'industry',
  },
  // {
  //   label: i18n.t('industrylabels.trade'),
  //   value: 'trade',
  //   data: 'trade',
  //   id: 'industry',
  // },
  {
    label: i18n.t('industrylabels.retail'),
    value: 'retail',
    data: 'retail',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.wholesale'),
    value: 'wholesale',
    data: 'wholesale',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.art'),
    value: 'art',
    data: 'art',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.engineer'),
    value: 'engineer',
    data: 'engineer',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.education'),
    value: 'education',
    data: 'education',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.logistics'),
    value: 'logistics',
    data: 'logistics',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.beauty'),
    value: 'beauty',
    data: 'beauty',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.medicine'),
    value: 'medicine',
    data: 'medicine',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.travel'),
    value: 'travel',
    data: 'travel',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.manufacturing'),
    value: 'manufacturing',
    data: 'manufacturing',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.finance'),
    value: 'finance',
    data: 'finance',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.agriculture'),
    value: 'agriculture',
    data: 'agriculture',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.startup'),
    value: 'startup',
    data: 'startup',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.vehicle'),
    value: 'vehicle',
    data: 'vehicle',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.realestate'),
    value: 'realestate',
    data: 'realestate',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.courier'),
    value: 'courier',
    data: 'courier',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.lawyer'),
    value: 'lawyer',
    data: 'lawyer',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.sport'),
    value: 'sport',
    data: 'sport',
    id: 'industry',
  },
  {
    label: i18n.t('industrylabels.otherservices'),
    value: 'otherservices',
    data: 'otherservices',
    id: 'industry',
  },
  //{ label: i18n.t('industrylabels.cantdecide'), value: 'other' },
];

export const singleCompanyInput = () => [
  {
    label: i18n.t('searchflow.generaltext4'),
    value: 'singlecompany',
  },
  { label: i18n.t('searchflow.generaltext3'), value: 'multiplecompany' },
];

export const companyTypeInput = () => [
  {
    id: 'companytype',
    value: 'kft',
    data: 'kft',
    label: i18n.t('companylabels.kft'),
  },
  {
    id: 'companytype',
    value: 'bt',
    data: 'bt',
    label: i18n.t('companylabels.bt'),
  },
  {
    id: 'companytype',
    value: 'singleprimary',
    data: 'singleprimary',
    label: i18n.t('companylabels.singleprimary'),
  },
  {
    id: 'companytype',
    value: 'kiva',
    data: 'kiva',
    label: i18n.t('companylabels.kiva'),
  },
  {
    id: 'companytype',
    value: 'singlesecondary',
    data: 'singlesecondary',
    label: i18n.t('companylabels.singlesecondary'),
  },
  {
    id: 'companytype',
    value: 'vszja',
    data: 'vszja',
    label: i18n.t('companylabels.vszja'),
  },
  {
    id: 'companytype',
    value: 'zrt',
    data: 'zrt',
    label: i18n.t('companylabels.zrt'),
  },
  {
    id: 'companytype',
    value: 'kkt',
    data: 'kkt',
    label: i18n.t('companylabels.kkt'),
  },
  {
    id: 'companytype',
    value: 'nyrt',
    data: 'nyrt',
    label: i18n.t('companylabels.nyrt'),
  },
  {
    id: 'companytype',
    value: 'condo',
    data: 'condo',
    label: i18n.t('companylabels.condo'),
  },
  {
    id: 'companytype',
    value: 'citycouncil',
    data: 'citycouncil',
    label: i18n.t('companylabels.citycouncil'),
  },
  {
    id: 'companytype',
    value: 'civil',
    data: 'civil',
    label: i18n.t('companylabels.civil'),
  },
  // {
  //   id: 'companytype',
  //   value: 'idk',
  //   data: 'idk',
  //   label: i18n.t('general.idk'),
  // },
];

export const companyTypeFoundingInput = () => [
  {
    id: 'companytype',
    value: 'kft',
    label: i18n.t('companylabels.kft'),
    data: 'kft',
  },
  {
    id: 'companytype',
    value: 'bt',
    label: i18n.t('companylabels.bt'),
    data: 'bt',
  },
  {
    id: 'companytype',
    value: 'singleprimary',
    data: 'singleprimary',
    label: i18n.t('companylabels.singleprimary'),
  },
  {
    id: 'companytype',
    value: 'kiva',
    data: 'kiva',
    label: i18n.t('companylabels.kiva'),
  },
  {
    id: 'companytype',
    value: 'singlesecondary',
    data: 'singlesecondary',
    label: i18n.t('companylabels.singlesecondary'),
  },
  {
    id: 'companytype',
    value: 'vszja',
    data: 'vszja',
    label: i18n.t('companylabels.vszja'),
  },
  {
    id: 'companytype',
    value: 'idk',
    data: 'idk',
    label: i18n.t('general.idk'),
  },
];

export const oneTimeServicesInput = () => [
  {
    label: i18n.t('general.taxconsultingsimple'),
    id: 'taxconsultingsimple',
    priceID: 'taxconsultingPriceSimple',
  },
  {
    label: i18n.t('general.legalentityfounding'),
    id: 'legalentityfounding',
    priceID: 'foundingPrice',
    oneOff: true,
  },
  // {
  //   label: i18n.t('general.taxconsultingcomplex'),
  //   id: 'taxconsultingcomplex',
  //   priceID: 'taxconsultingPriceComplex',
  // },
  // {
  //   label: i18n.t('general.liquidation2'),
  //   id: 'liquidation',
  //   priceID: 'liquidationPrice',
  //   oneOff: true,
  // },
];
export const OfferServicesInput = () => [
  {
    label: i18n.t('general.accounting'),
    id: 'accounting',
    priceID: 'accountingPrice',
  },
  {
    label: i18n.t('general.payroll'),
    id: 'payroll',
    priceID: 'payrollPrice',
  },
  {
    label: i18n.t('general.taxconsultingsimple'),
    id: 'taxconsultingsimple',
    priceID: 'taxconsultingPriceSimple',
  },
  {
    label: i18n.t('general.legalentityfounding'),
    id: 'legalentityfounding',
    priceID: 'foundingPrice',
    oneOff: true,
  },
  // {
  //   label: i18n.t('general.taxconsultingcomplex'),
  //   id: 'taxconsultingcomplex',
  //   priceID: 'taxconsultingPriceComplex',
  // },
  // {
  //   label: i18n.t('general.liquidation2'),
  //   id: 'liquidation',
  //   priceID: 'liquidationPrice',
  //   oneOff: true,
  // },
];

export const contServicesInput = () => [
  {
    label: i18n.t('general.accounting'),
    id: 'accounting',
    priceID: 'accountingPrice',
  },
  {
    label: i18n.t('general.payroll'),
    id: 'payroll',
    priceID: 'payrollPrice',
  },
  // {
  //   label: i18n.t('general.audit'),
  //   id: 'audit',
  //   priceID: 'auditPrice',
  //   oneOff: true,
  // },
  // {
  //   label: i18n.t('general.taxconsultingcont'),
  //   id: 'taxconsulting',
  //   priceID: 'taxconsultingPrice',
  // },
];
export const accServiceInput = () => [
  {
    label: i18n.t('general.accounting'),
    id: 'accounting',
    priceID: 'accountingPrice',
  },
];
export const singleServiceInput = () => [
  {
    label: i18n.t('general.taxconsultingsimple'),
    id: 'taxconsultingsimple',
    priceID: 'taxconsultingPriceSimple',
  },
  {
    label: i18n.t('general.legalentityfounding'),
    id: 'legalentityfounding',
    priceID: 'foundingPrice',
    oneOff: true,
  },
];

export const servicesInput = () => [
  {
    label: i18n.t('general.idk'),
    id: 'idk',
  },
  {
    label: i18n.t('general.accounting'),
    id: 'accounting',
    priceID: 'accountingPrice',
  },
  {
    label: i18n.t('general.payroll'),
    id: 'payroll',
    priceID: 'payrollPrice',
  },
  {
    label: i18n.t('general.taxconsulting'),
    id: 'taxconsulting',
    priceID: 'taxconsultingPrice',
  },
  {
    label: i18n.t('general.audit'),
    id: 'audit',
    priceID: 'auditPrice',
    oneOff: true,
  },
  {
    label: i18n.t('general.legalentityfounding'),
    id: 'legalentityfounding',
    priceID: 'foundingPrice',
    oneOff: true,
  },
  {
    label: i18n.t('general.liquidation'),
    id: 'liquidation',
    priceID: 'liquidationPrice',
    oneOff: true,
  },
];

export const bankingInput1 = () => [
  {
    label: i18n.t('searchflow.idkyet'),
    id: 'bankidk',
    value: 'bankidk',
    data: 'bankidk',
  },
  {
    label: i18n.t('searchflow.bankingoptions1'),
    id: 'opt1',
    value: 'opt1',
    data: 'opt1',
  },
  {
    label: i18n.t('searchflow.bankingoptions3'),
    id: 'opt3',
    value: 'opt3',
    data: 'opt3',
  },
  {
    label: i18n.t('searchflow.bankingoptions2'),
    id: 'opt2',
    value: 'opt2',
    data: 'opt2',
  },
  {
    label: i18n.t('searchflow.bankingoptions4'),
    id: 'opt4',
    value: 'opt4',
    data: 'opt4',
  },
  {
    label: i18n.t('searchflow.bankingoptions5'),
    id: 'opt5',
    value: 'opt5',
    data: 'opt5',
  },
  {
    label: i18n.t('searchflow.bankingoptions6'),
    id: 'opt6',
    value: 'opt6',
    data: 'opt6',
  },
  {
    label: i18n.t('searchflow.bankingoptions7'),
    id: 'opt7',
    value: 'opt7',
    data: 'opt7',
  },
  {
    label: i18n.t('searchflow.bankingoptions8'),
    id: 'opt8',
    value: 'opt8',
    data: 'opt8',
  },
  {
    label: i18n.t('searchflow.other'),
    id: 'other',
    value: 'other',
    data: 'other',
  },
];
export const bankingInput2 = () => [
  {
    label: i18n.t('searchflow.bankingoptions5'),
    id: 'opt5',
    value: 'opt5',
  },
  {
    label: i18n.t('searchflow.bankingoptions6'),
    id: 'opt6',
    value: 'opt6',
  },
  {
    label: i18n.t('searchflow.bankingoptions7'),
    id: 'opt7',
    value: 'opt7',
  },
  {
    label: i18n.t('searchflow.bankingoptions8'),
    id: 'opt8',
    value: 'opt8',
  },
  {
    label: i18n.t('searchflow.other'),
    id: 'other',
    value: 'other',
  },
];

export const invoicingInput1 = () => [
  {
    label: i18n.t('searchflow.idkyet'),
    id: 'invoicingidk',
    value: 'invoicingidk',
  },
  {
    label: i18n.t('searchflow.invoicingoptions1'),
    id: 'opt1',
    value: 'opt1',
  },
  {
    label: i18n.t('searchflow.invoicingoptions2'),
    id: 'opt2',
    value: 'opt2',
  },
  {
    label: i18n.t('searchflow.invoicingoptions3'),
    id: 'opt3',
    value: 'opt3',
  },
  {
    label: i18n.t('searchflow.invoicingoptions7'),
    id: 'opt7',
    value: 'opt7',
  },
  {
    label: i18n.t('searchflow.invoicingoptions4'),
    id: 'opt4',
    value: 'opt4',
  },
  {
    label: i18n.t('searchflow.invoicingoptions6'),
    id: 'opt6',
    value: 'opt6',
  },
  {
    label: i18n.t('searchflow.invoicingoptions5'),
    id: 'opt5',
    value: 'opt5',
  },
  {
    label: i18n.t('searchflow.other'),
    id: 'other',
    value: 'other',
  },
];
export const invoicingInput2 = () => [
  {
    label: i18n.t('searchflow.invoicingoptions4'),
    id: 'opt4',
    value: 'opt4',
  },
  {
    label: i18n.t('searchflow.invoicingoptions6'),
    id: 'opt6',
    value: 'opt6',
  },
  {
    label: i18n.t('searchflow.invoicingoptions5'),
    id: 'opt5',
    value: 'opt5',
  },
  {
    label: i18n.t('searchflow.other'),
    id: 'other',
    value: 'other',
  },
];

export const softwareInput = () => [
  { label: i18n.t('searchflow.softwareoptions1'), id: 'opt1', value: 'opt1' },
  { label: i18n.t('searchflow.softwareoptions2'), id: 'opt2', value: 'opt2' },
  { label: i18n.t('searchflow.softwareoptions3'), id: 'opt3', value: 'opt3' },
  { label: i18n.t('searchflow.softwareoptions4'), id: 'opt4', value: 'opt4' },
  { label: i18n.t('searchflow.softwareoptions5'), id: 'opt5', value: 'opt5' },
  { label: i18n.t('searchflow.softwareoptions6'), id: 'opt6', value: 'opt6' },
  { label: i18n.t('searchflow.softwareoptions7'), id: 'opt7', value: 'opt7' },
  { label: i18n.t('searchflow.softwareoptions8'), id: 'opt8', value: 'opt8' },
  { label: i18n.t('searchflow.softwareoptions9'), id: 'opt9', value: 'opt9' },
  {
    label: i18n.t('searchflow.softwareoptions10'),
    id: 'opt10',
    value: 'opt10',
  },
  {
    label: i18n.t('searchflow.softwareoptions11'),
    id: 'opt11',
    value: 'opt11',
  },
  {
    label: i18n.t('searchflow.softwareoptions12'),
    id: 'opt12',
    value: 'opt12',
  },
  {
    label: i18n.t('searchflow.softwareoptions13'),
    id: 'opt13',
    value: 'opt13',
  },
  {
    label: i18n.t('searchflow.softwareoptions14'),
    id: 'opt14',
    value: 'opt14',
  },
  {
    label: i18n.t('searchflow.softwareoptions15'),
    id: 'opt15',
    value: 'opt15',
  },
  {
    label: i18n.t('searchflow.softwareoptions16'),
    id: 'opt16',
    value: 'opt16',
  },
  {
    label: i18n.t('searchflow.softwareoptions17'),
    id: 'opt17',
    value: 'opt17',
  },
  {
    label: i18n.t('searchflow.softwareoptions18'),
    id: 'opt18',
    value: 'opt18',
  },
  {
    label: i18n.t('searchflow.softwareoptions19'),
    id: 'opt19',
    value: 'opt19',
  },
  {
    label: i18n.t('searchflow.softwareoptions20'),
    id: 'opt20',
    value: 'opt20',
  },
  { label: i18n.t('searchflow.other'), id: 'other', value: 'other' },
];

export var languagesTextProvider = () => [
  {
    name: i18n.t('languages.english'),
    id: 'english',
    value: 'english',
    flag: uk,
    label: i18n.t('languages.english'),
  },
  {
    name: i18n.t('languages.german'),
    id: 'german',
    value: 'german',
    flag: germany,
    label: i18n.t('languages.german'),
  },
  {
    name: i18n.t('languages.spanish'),
    id: 'spanish',
    value: 'spanish',
    flag: spain,
    label: i18n.t('languages.spanish'),
  },
  {
    name: i18n.t('languages.french'),
    id: 'french',
    value: 'french',
    flag: france,
    label: i18n.t('languages.french'),
  },
  {
    name: i18n.t('languages.chinese'),
    id: 'chinese',
    value: 'chinese',
    flag: china,
    label: i18n.t('languages.chinese'),
  },
  {
    name: i18n.t('languages.russian'),
    id: 'russian',
    value: 'russian',
    flag: russia,
    label: i18n.t('languages.russian'),
  },
  {
    name: i18n.t('languages.romanian'),
    id: 'romanian',
    value: 'romanian',
    flag: romania,
    label: i18n.t('languages.romanian'),
  },
];
export var languagesText = () => [
  {
    name: i18n.t('languages.english'),
    id: 'english',
    value: 'english',
    flag: uk,
    label: i18n.t('languages.english'),
  },
  {
    name: i18n.t('languages.german'),
    id: 'german',
    value: 'german',
    flag: germany,
    label: i18n.t('languages.german'),
  },
  {
    name: i18n.t('languages.spanish'),
    id: 'spanish',
    value: 'spanish',
    flag: spain,
    label: i18n.t('languages.spanish'),
  },
  {
    name: i18n.t('languages.french'),
    id: 'french',
    value: 'french',
    flag: france,
    label: i18n.t('languages.french'),
  },
  {
    name: i18n.t('languages.romanian'),
    id: 'romanian',
    value: 'romanian',
    flag: romania,
    label: i18n.t('languages.romanian'),
  },
  {
    name: i18n.t('languages.chinese'),
    id: 'chinese',
    value: 'chinese',
    flag: china,
    label: i18n.t('languages.chinese'),
  },
  {
    name: i18n.t('languages.russian'),
    id: 'russian',
    value: 'russian',
    flag: russia,
    label: i18n.t('languages.russian'),
  },
];
export var languagesText2 = () => [
  {
    name: i18n.t('languages.romanian'),
    id: 'romanian',
    value: 'romanian',
    flag: romania,
    label: i18n.t('languages.romanian'),
  },
  {
    name: i18n.t('languages.chinese'),
    id: 'chinese',
    value: 'chinese',
    flag: china,
    label: i18n.t('languages.chinese'),
  },
  {
    name: i18n.t('languages.russian'),
    id: 'russian',
    value: 'russian',
    flag: russia,
    label: i18n.t('languages.russian'),
  },
];

export const gdprLabelSF = () => [
  <span className='SFSmallText'>
    {i18n.t('gdpr.part1')}
    <a
      className='SFSmallText'
      style={{ color: '#3797f0' }}
      rel='noopener noreferrer'
      target='_blank'
      href={legalDocuments.gdpr}
      key='1'
    >
      {' '}
      {i18n.t('gdpr.part2')}
    </a>{' '}
    {i18n.t('gdpr.part3')}{' '}
    <a
      className='SFSmallText'
      style={{ color: '#3797f0' }}
      rel='noopener noreferrer'
      target='_blank'
      href={legalDocuments.aszf}
      key='2'
    >
      {i18n.t('gdpr.part4')}
    </a>
    {i18n.t('gdpr.part5')}
    <span
      style={{
        color: '#FF0000',
        fontSize: '0.8rem',
        marginLeft: '0.3em',
        fontWeight: 'bold',
      }}
      key='3'
    >
      {i18n.t('gdpr.part6')}
    </span>
  </span>,
];

export const experienceRating = () => [
  { min: 0, max: 1, label: i18n.t('companypotentialproviders.noexp') },
  { min: 1, max: 2.5, label: i18n.t('companypotentialproviders.littleexp') },
  {
    min: 2.5,
    max: 3.5,
    label: i18n.t('companypotentialproviders.experienced'),
  },
  {
    min: 3.5,
    max: 4.5,
    label: i18n.t('companypotentialproviders.veryexperienced'),
  },
  {
    min: 4.5,
    max: 6,
    label: i18n.t('companypotentialproviders.industryprofessional'),
  },
];

export const reviewCategories = () => [
  { label: i18n.t('searchflow.flexibility'), id: 'flexibility' },
  { label: i18n.t('searchflow.professionalism'), id: 'professionalism' },
  { label: i18n.t('searchflow.industryexperience'), id: 'industryexperience' },
  { label: i18n.t('searchflow.precision'), id: 'precision' },
  { label: i18n.t('searchflow.pricevalue'), id: 'pricevalue' },
];

export const sortingText = () => [
  {
    label: i18n.t('searchflow.basedonmatch'),
    id: 'totalmatchscore',
    ascending: false,
  },
  {
    label: i18n.t('searchflow.basedondistance'),
    id: 'providerDistance',
    ascending: true,
  },
  {
    label: i18n.t('searchflow.basedonreview'),
    id: 'providerReview',
    ascending: false,
  },
  {
    label: i18n.t('searchflow.basedonprice'),
    id: 'providerPrice',
    ascending: true,
  },
  {
    label: i18n.t('searchflow.basedonxp'),
    id: 'industryexperience',
    ascending: false,
  },
];

export const searchParamsText = {
  services: {
    label: i18n.t('general.servicelabel'),
    options: [
      { label: i18n.t('general.accounting'), value: 'accounting' },
      { label: i18n.t('general.payroll'), value: 'payroll' },
      { label: i18n.t('general.taxconsulting'), value: 'taxconsulting' },
      {
        label: i18n.t('general.taxconsultingsimple'),
        value: 'taxconsultingsimple',
      },
      {
        label: i18n.t('general.taxconsultingcomplex'),
        value: 'taxconsultingcomplex',
      },
      { label: i18n.t('general.audit'), value: 'audit' },
      {
        label: i18n.t('general.legalentityfounding'),
        value: 'legalentityfounding',
      },
      { label: i18n.t('general.liquidation'), value: 'liquidation' },
    ],
  },
  zip_code: {
    label: i18n.t('general.zip'),
  },
  industry: {
    label: i18n.t('general.industrylabel'),
    options: [...industryInput1()],
  },
  companytype: {
    label: i18n.t('general.companylabel'),
    options: [...companyTypeInput()],
  },
  languagefilter: {
    label: i18n.t('languages.languages'),
    false: ' - ',
    options: [
      { label: i18n.t('languages.hungarian'), value: 'hungarian' },
      { label: i18n.t('languages.english'), value: 'english' },
      { label: i18n.t('languages.german'), value: 'german' },
      { label: i18n.t('languages.french'), value: 'french' },
      { label: i18n.t('languages.chinese'), value: 'chinese' },
      { label: i18n.t('languages.russian'), value: 'russian' },
      { label: i18n.t('languages.romanian'), value: 'romanian' },
      { label: i18n.t('languages.spanish'), value: 'spanish' },
    ],
  },
  newlyfounded: {
    label: i18n.t('company.isnewlyfounded'),
  },
  yearlyrevenue: {
    label: i18n.t('general.yearlyrevlabel'),
    options: [
      { value: 5, label: i18n.t('general.revfee1short') },
      { value: 10, label: i18n.t('general.revfee2short') },
      { value: 50, label: i18n.t('general.revfee3short') },
      { value: 200, label: i18n.t('general.revfee4short') },
      { value: 500, label: i18n.t('general.revfee5short') },
      { value: 1999, label: i18n.t('general.revfee6short') },
      { value: 2000, label: i18n.t('general.revfee7short') },
    ],
  },
  foreign_trade: {
    false: i18n.t('general.none'),
    label: i18n.t('request.foreigntrade'),
    options: [
      { value: 'inside', label: i18n.t('searchflow.insideeu') },
      { value: 'outside', label: i18n.t('searchflow.outsideeu') },
      { value: 'both', label: i18n.t('searchflow.inandoutsideeu') },
    ],
  },
  vatFilter: {
    label: i18n.t('company.vatpayment'),
    options: [
      { value: 'yearly', label: i18n.t('general.yearly') },
      { value: 'monthly', label: i18n.t('general.monthly') },
      { value: 'quarterly', label: i18n.t('general.quarterly') },
    ],
  },
  noofemployees: {
    label: i18n.t('general.noofemployeeslabel'),
  },
  incominginvoices: {
    label: i18n.t('general.incominginvoiceslabel'),
  },
  outgoinginvoices: {
    label: i18n.t('general.outgoinginvoiceslabel'),
  },
};

export const setColor = (percentage) => {
  let color;
  switch (true) {
    case percentage <= 25:
      color = 'red';
      break;
    case percentage > 25 && percentage <= 50:
      color = 'rgb(255, 102, 0)';
      break;
    case percentage > 50 && percentage <= 75:
      color = 'rgb(255, 157, 91)';
      break;
    case percentage > 75 && percentage <= 100:
      color = '#009b60';
      break;
    default:
      color = '#009b60';
  }
  return color;
};

const setExpRating = (exp) => {
  let rating = '';
  experienceRating().forEach((item) => {
    if (exp >= item.min && exp < item.max) {
      rating = item.label;
    }
  });
  return rating;
};

const getDifference = (a, b) => {
  let result = 0;
  result = (100 * (a - b)) / ((a + b) / 2);

  return result;
};

export const setScores = (data) => {
  let displayScores = [];
  let difference = getDifference(data.totalPrice, data.avgPrice);
  //DISTANCE
  if (parseFloat(data.providerDistance) < 10) {
    displayScores.push(
      <li key='0'>
        {data.providerDistance} {i18n.t('general.distancesmall1')}
        <span>{i18n.t('general.distancesmall2')}</span>
      </li>
    );
  } else if (parseFloat(data.providerDistance) >= 10) {
    displayScores.push(
      <li key='0'>
        {data.providerDistance} {i18n.t('general.distancesmall1')}
        <span>{i18n.t('general.distancesmall3')}</span>
      </li>
    );
  }
  //EXPERIENCE
  displayScores.push(
    <li key='1'>
      {setExpRating(data.providerExperience) ===
      i18n.t('companypotentialproviders.industryprofessional') ? (
        <>
          {setExpRating(data.providerExperience)}
          <span>{i18n.t('companypotentialproviders.consideredas')}</span>
        </>
      ) : (
        <>
          {setExpRating(data.providerExperience)}
          <span>{i18n.t('companypotentialproviders.gotit')}</span>
        </>
      )}
    </li>
  );
  //REVIEWS
  if (data.reviewScores.reviewNr === 0) {
    displayScores.push(
      <li key='2'>
        {i18n.t('companypotentialproviders.reviewtext1')}
        <span>{i18n.t('companypotentialproviders.reviewtext2')}</span>
      </li>
    );
  } else if (
    data.reviewScores.reviewNr < 3 &&
    data.reviewScores.reviewScoreAvg >= 4
  ) {
    displayScores.push(
      <li key='2'>
        {i18n.t('companypotentialproviders.reviewtext3')}
        <span>{i18n.t('companypotentialproviders.reviewtext2')}</span>
      </li>
    );
  } else if (
    data.reviewScores.reviewNr >= 3 &&
    data.reviewScores.reviewScoreAvg >= 4.8
  ) {
    displayScores.push(
      <li key='2'>
        {i18n.t('companypotentialproviders.reviewtext4')}
        <span>{i18n.t('companypotentialproviders.reviewtext5')}</span>
      </li>
    );
  } else if (
    data.reviewScores.reviewScoreAvg > 4 &&
    data.reviewScores.reviewScoreAvg < 4.8
  ) {
    displayScores.push(
      <li key='2'>
        {i18n.t('companypotentialproviders.reviewtext6')}
        <span>{i18n.t('companypotentialproviders.reviewtext7')}</span>
      </li>
    );
  }
  //PRICES
  if (difference < -8 && difference > -12) {
    displayScores.push(
      <li key='3'>
        {i18n.t('companypotentialproviders.goodpricetext1')}
        <span>{i18n.t('companypotentialproviders.goodpricetext2')}</span>
      </li>
    );
  } else if (difference < -13 && difference > -17) {
    displayScores.push(
      <li key='3'>
        {i18n.t('companypotentialproviders.goodpricetext1')}
        <span>{i18n.t('companypotentialproviders.goodpricetext3')}</span>
      </li>
    );
  } else if (difference < -17 && difference > -22) {
    displayScores.push(
      <li key='3'>
        {i18n.t('companypotentialproviders.goodpricetext1')}
        <span>{i18n.t('companypotentialproviders.goodpricetext4')}</span>
      </li>
    );
  } else if (difference < -23 && difference > -27) {
    displayScores.push(
      <li key='3'>
        {i18n.t('companypotentialproviders.goodpricetext1')}
        <span>{i18n.t('companypotentialproviders.goodpricetext5')}</span>
      </li>
    );
  } else if (difference < -28 && difference > -32) {
    displayScores.push(
      <li key='3'>
        {i18n.t('companypotentialproviders.goodpricetext1')}
        <span>{i18n.t('companypotentialproviders.goodpricetext6')}</span>
      </li>
    );
  } else if (difference < -32) {
    displayScores.push(
      <li key='3'>
        {i18n.t('companypotentialproviders.goodpricetext1')}
        <span>{i18n.t('companypotentialproviders.goodpricetext7')}</span>
      </li>
    );
  } else if (difference < 8 && difference > -8) {
    displayScores.push(
      <li key='3'>
        {i18n.t('companypotentialproviders.avgpricetext1')}
        <span>{i18n.t('companypotentialproviders.avgpricetext2')}</span>
      </li>
    );
  } else if (difference > 8 && difference < 12) {
    displayScores.push(
      <li key='3'>
        {i18n.t('companypotentialproviders.highpricetext1')}
        <span>{i18n.t('companypotentialproviders.highpricetext3')}</span>
      </li>
    );
  } else if (difference > 13 && difference < 17) {
    displayScores.push(
      <li key='3'>
        {i18n.t('companypotentialproviders.highpricetext1')}
        <span>{i18n.t('companypotentialproviders.highpricetext4')}</span>
      </li>
    );
  } else if (difference > 17 && difference < 22) {
    displayScores.push(
      <li key='3'>
        {i18n.t('companypotentialproviders.highpricetext1')}
        <span>{i18n.t('companypotentialproviders.highpricetext5')}</span>
      </li>
    );
  } else if (difference > 23 && difference < 27) {
    displayScores.push(
      <li key='3'>
        {i18n.t('companypotentialproviders.highpricetext1')}
        <span>{i18n.t('companypotentialproviders.highpricetext6')}</span>
      </li>
    );
  } else if (difference > 28 && difference < 32) {
    displayScores.push(
      <li key='3'>
        {i18n.t('companypotentialproviders.highpricetext2')}
        <span>{i18n.t('companypotentialproviders.highpricetext7')}</span>
      </li>
    );
  } else if (difference > 32) {
    displayScores.push(
      <li key='3'>
        {i18n.t('companypotentialproviders.highpricetext2')}
        <span>{i18n.t('companypotentialproviders.highpricetext8')}</span>
      </li>
    );
  }
  //COMPANYSIZE
  if (data.packageSize === 'office') {
    displayScores.push(
      <li key='4'>
        {i18n.t('companypotentialproviders.companysizetext1')}
        <span>{i18n.t('companypotentialproviders.companysizetext2')}</span>
      </li>
    );
  } else if (data.packageSize === 'small_office') {
    displayScores.push(
      <li key='4'>
        {i18n.t('companypotentialproviders.companysizetext3')}
        <span>{i18n.t('companypotentialproviders.companysizetext4')}</span>
      </li>
    );
  } else if (data.packageSize === 'entrepreneur') {
    displayScores.push(
      <li key='4'>
        {i18n.t('companypotentialproviders.companysizetext5')}
        <span>{i18n.t('companypotentialproviders.companysizetext6')}</span>
      </li>
    );
  }
  //LIABILITY INS
  if (data.providerInsurance !== 'none') {
    displayScores.push(
      <li key='5'>
        {i18n.t('general.liabilityinsurance')}{' '}
        <span>{i18n.t('general.liabilityinsurancesuffix')}</span>
      </li>
    );
  }
  return displayScores;
};
//Check if the data that changed is relevant to provider filtering
//if yes return true so componentDidUpdate in searchFlow.jsx will call fetchPotentialProviders to update the prov list
export const checkStateChange = (prevState, currentState) => {
  let changed = false;
  let relevantFields = [
    'accounting',
    'taxconsulting',
    'taxconsultingsimple',
    'taxconsultingcomplex',
    'legalentityfounding',
    'payroll',
    'audit',
    'liquidation',
    'languages',
    'noofemployees',
    'yearlyrevenue',
    'incominginvoices',
    'outgoinginvoices',
    'industry',
    'companytype',
    'foreign_trade',
    'foreigntradeEU',
    'newlyfounded',
  ];
  for (let i in prevState) {
    if (relevantFields.includes(i) && !changed) {
      if (JSON.stringify(prevState[i]) !== JSON.stringify(currentState[i])) {
        changed = true;
        return changed;
      }
    }
  }
  return changed;
};

export const tableIcons = {
  Add: forwardRef((props, ref) => (
    <AddBox {...props} style={{ color: '#0b0f31' }} ref={ref} />
  )),
  Check: forwardRef((props, ref) => (
    <Check {...props} style={{ color: '#0b0f31' }} ref={ref} />
  )),
  Clear: forwardRef((props, ref) => (
    <Clear {...props} style={{ color: '#0b0f31' }} ref={ref} />
  )),
  Delete: forwardRef((props, ref) => (
    <DeleteOutline {...props} style={{ color: '#0b0f31' }} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} style={{ color: '#0b0f31' }} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => (
    <Edit {...props} style={{ color: '#0b0f31' }} ref={ref} />
  )),
  Export: forwardRef((props, ref) => (
    <SaveAlt {...props} style={{ color: '#0b0f31' }} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => (
    <FilterList {...props} style={{ color: '#0b0f31' }} ref={ref} />
  )),
  FirstPage: forwardRef((props, ref) => (
    <FirstPage {...props} style={{ color: '#0b0f31' }} ref={ref} />
  )),
  LastPage: forwardRef((props, ref) => (
    <LastPage {...props} style={{ color: '#0b0f31' }} ref={ref} />
  )),
  NextPage: forwardRef((props, ref) => (
    <ChevronRight {...props} style={{ color: '#0b0f31' }} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} style={{ color: '#0b0f31' }} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <Clear {...props} style={{ color: '#0b0f31' }} ref={ref} />
  )),
  Search: forwardRef((props, ref) => (
    <Search {...props} style={{ color: '#0b0f31' }} ref={ref} />
  )),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} style={{ color: '#0b0f31' }} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove {...props} style={{ color: '#0b0f31' }} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => (
    <ViewColumn {...props} style={{ color: '#0b0f31' }} ref={ref} />
  )),
};

export const firstStep = () => [
  {
    label: i18n.t('searchflow.step1text1'),
    value: 'existing',
  },
  {
    label: i18n.t('searchflow.step1text2'),
    value: 'founding',
  },
  {
    label: i18n.t('searchflow.step1text3'),
    value: 'questions',
  },
];
export const firstStepB = () => [
  {
    label: i18n.t('searchflow.step1text4'),
    value: 'taxsimple',
  },
  // {
  //   label: i18n.t('searchflow.step1text5'),
  //   value: 'taxcomplex',
  // },
  {
    label: i18n.t('searchflow.step1text6'),
    value: 'blog',
  },
  {
    label: i18n.t('searchflow.step1text7'),
    value: 'noncorp',
  },
];

export const deadlineInput = () => [
  {
    label: i18n.t('searchflow.deadlinetext1'),
    value: '0',
    id: 'deadline',
    data: '0',
  },
  {
    label: i18n.t('searchflow.deadlinetext2'),
    value: '14',
    id: 'deadline',
    data: '14',
  },
  {
    label: i18n.t('searchflow.deadlinetext3'),
    value: '60',
    id: 'deadline',
    data: '60',
  },
  {
    label: i18n.t('searchflow.deadlinetext4'),
    value: '180',
    id: 'deadline',
    data: '180',
  },
  {
    label: i18n.t('searchflow.deadlinetext5'),
    value: true,
    id: 'onlyBrowsing',
    data: true,
  },
];

export const fakeProfiles = [
  {
    name: 'Ildikó',
    values: ['Türelmes', 'Segítőkész'],
    img: 'https://knet-profile-images.s3.eu-west-1.amazonaws.com/profile_6554cecfcfbd8528d4f7819f?337333398',
  },
  {
    name: 'Andrea',
    values: ['Felkészült', 'Digitalizált'],
    img: 'https://knet-profile-images.s3.eu-west-1.amazonaws.com/profile_6515bd1ebcdf9647671ca3e1?335438507',
  },
  {
    name: 'Edit',
    values: ['Empatikus', 'Felkészült'],
    img: 'https://knet-profile-images.s3.eu-west-1.amazonaws.com/profile_600e7bce2b0b916c2190e216?256295431',
  },
  {
    name: 'Beáta',
    values: ['Empatikus', 'Digitalizált'],
    img: 'https://knet-profile-images.s3.eu-west-1.amazonaws.com/profile_6336a6baf09f98526b354180?794568564',
  },
  {
    name: 'Beáta',
    values: ['Segítőkész', 'Türelmes'],
    img: 'https://knet-profile-images.s3.eu-west-1.amazonaws.com/profile_6283a3012616884d2eae350a?122284341',
  },

  {
    name: 'Erika',
    values: ['Empatikus', 'Maximalista'],
    img: 'https://knet-profile-images.s3.eu-west-1.amazonaws.com/profile_64e9c4324f737e7aabe2552b?186597987',
  },
  {
    name: 'Viktória',
    values: ['Digitalizált', 'Felkészült'],
    img: 'https://knet-profile-images.s3.eu-west-1.amazonaws.com/profile_63049e38b460f016c51d05d7?314892239',
  },
  {
    name: 'Edina',
    values: ['Segítőkész', 'Maximalista'],
    img: 'https://knet-profile-images.s3.eu-west-1.amazonaws.com/profile_602cf3adc9c5a7464f2a58ed',
  },
  {
    name: 'Andrea',
    values: ['Felkészült', 'Digitalizált'],
    img: 'https://knet-profile-images.s3.eu-west-1.amazonaws.com/profile_65cd4690a179273445d74005',
  },
];
