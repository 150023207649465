import React from 'react';
import Rating from '@mui/material/Rating';
import { Avatar } from '@mui/material';
import { experienceRating } from '../../../data/searchFlowText';
import { useSelector } from 'react-redux';
// import shieldCheck from '../../../../../images/shield-check.png';
import shieldCheck from '../../../../dynamicListPages/assets/DlpApprovedProvCard.svg';
import buildings from '../../../../../images/icons/buildings.svg';
import StyledButton from '../../../../../shared/components/styledButton/styledButton';
import i18n from '../../../../../utils/i18n';

const CardContentWeb = ({ data, setOpen, ...props }) => {
	const companyStore = useSelector((state) => state.company_store);

	//console.log(data.packageType, 'DATA');
	return (
		<>
			<div className="ProviderCardSection">
				<div className="dlpProviderCardHeader">
					<Avatar
						src={data.providerImage}
						style={{ width: '48px', height: '48px' }}
					/>
					<div>
						<p className="dlpProvHeaderTexts">
							<span style={{ fontSize: '16px' }} className="Bold">
								{data.providerName}
							</span>
							<span>
								<Rating
									name="half-rating-read"
									value={data.providerReview}
									precision={0.1}
									readOnly
									size="medium"
								/>
							</span>
						</p>
						<p className="dlpText12">
							<img src={buildings} alt="buildings" />{' '}
							{data.packageSize === ''
								? i18n.t('general.accountant')
								: data.packageSize === 'entrepreneur'
								? i18n.t('provider.entrepreneuracc')
								: data.packageSize === 'small_office'
								? i18n.t('provider.small_acc_office')
								: i18n.t('provider.large_acc_office')}
						</p>
					</div>
					{data.packageType === 'premium' && (
						<img
							className="dlpApprovedProvCard"
							alt="shieldCheck"
							src={shieldCheck}
						/>
					)}
				</div>
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-evenly',
					}}>
					{data.insurrance === 'none' ? null : (
						<span className="badgecontainer">
							{i18n.t('searchflow.sfpreftext7')}
						</span>
					)}
					{data.digitalBadge !== 'none' && data.digitalBadge !== 'tbd' ? (
						<span className="badgecontainer">
							{i18n.t('searchflow.sfpreftext5')}
						</span>
					) : null}
					<span className="badgecontainer">
						{i18n.t('general.matchscore')}:{' '}
						<span className="ScoreStyle">{data.totalmatchscore}</span>
					</span>
				</div>
				<hr style={{ margin: '8px 0px !important' }} />
			</div>
			<div
				className="ProviderCardSection"
				style={{
					alignItems: 'space-between',
				}}>
				<div className="ProviderCardScores">
					<p>
						{/* <span>{`${i18n.t('general.pricemonthly')}: `}</span> */}
						<span>{`${i18n.t('general.monthlyaccountingfee')}: `}</span>
						<span className="">{`${data.proposedContPrice} ${i18n.t(
							'general.currency'
						)}`}</span>
					</p>

					{/* <p>
						<span>{`${i18n.t('general.pricecalculation')}: `}</span>
						<span className="">{`${data.proposedSinglePrice} ${i18n.t(
							'general.currency'
						)}`}</span>
					</p> */}
				</div>
				{/* <hr style={{ margin: '8px 0px !important' }} /> */}
				<div
					style={{
						marginTop: '12px',
						marginBottom: '6px',
						display: 'flex',
						justifyContent: 'flex-end',
					}}>
					<StyledButton
						onClick={(e) => {
							e.stopPropagation();
							setOpen(true);
						}}
						color={
							companyStore.selected_providers.find(
								({ providerID }) => providerID === data.providerID
							) && props.reqID.length > 0
								? 'Success'
								: 'NewReqBtn'
						}
						isDisabled={
							companyStore.selected_providers.find(
								({ providerID }) => providerID === data.providerID
							) && props.reqID.length > 0
						}>
						{i18n.t('searchflow.reqmsgtitle')}
					</StyledButton>
				</div>
			</div>
		</>
	);
};

export default CardContentWeb;
