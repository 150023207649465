import React, { useCallback, useEffect, useState } from 'react';
import { incomingInvoicesInput } from '../data/searchFlowText';
import { useSelector } from 'react-redux';
import Navigation from '../components/navigation/navigation';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
import RadioInput from '../components/searchInputs/radioInput';

const InvoicesIn = ({ ...props }) => {
  const companyStore = useSelector((state) => state.company_store);
  const [invoiceError, setInvoiceError] = useState(false);

  //console.log(companyStore, 'CS');

  const handleNext = useCallback(
    (inc) => {
      props.handleNext(inc);
    },
    [props]
  );

  const handleBack = useCallback(
    (decr) => {
      props.handleBack(decr);
    },
    [props]
  );

  const checkFields = useCallback(() => {
    return true;
  }, [companyStore.incominginvoices, companyStore.outgoinginvoices]);

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 13) {
        if (companyStore.incominginvoices > 20000) {
          return;
        }
        if (typeof companyStore.incominginvoices !== 'number') {
          return;
        }
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [props, companyStore]);

  useEffect(() => {
    checkFields() && setInvoiceError(false);
    !checkFields() && setInvoiceError(true);
  }, [companyStore.incominginvoices, checkFields]);
  return (
    <div className='stepContainer'>
      <div className='SFInput'>
        <p className='SFSubTitle'>
          <span className='SFSubTitle'>{i18n.t('company.incominglabel1')}</span>{' '}
          <span className='questionTracker'>4/7</span>
        </p>
        <p className='SFSubTitleHelper'>
          {i18n.t('company.incominginvoicestooltip')}
        </p>
        <RadioInput
          storeID='incominginvoices'
          dataText={incomingInvoicesInput()}
        />
        {invoiceError ? (
          <p
            style={{
              color: 'red',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
          >
            {i18n.t('company.invoiceerrormsg')}
          </p>
        ) : null}
      </div>

      <Navigation
        step={11}
        handleBack={() => handleBack()}
        handleNext={() => handleNext()}
        openHelpForm={() => props.openHelpForm()}
        disabledProp={companyStore.incominginvoices !== '' ? false : true}
      />
    </div>
  );
};

export default withTranslation()(InvoicesIn);
