import React, { useEffect } from 'react';
import EmailInput from '../../../../shared/components/emailInput/emailInput';

import TextField from '@mui/material/TextField';
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import i18n from '../../../../utils/i18n';

const ContactInfo = ({ ...props }) => {
	const [email, setEmail] = React.useState('');
	const [phone, setPhone] = React.useState('');
	const [companyctcname, setCompanyCtcName] = React.useState('');
	const dispatch = useDispatch();
	const companyStore = useSelector((state) => state.company_store);

	const dataLayerUpdate = (stepData) => {
		window.dataLayer.push({
			event: 'sf_step_user_email',
			user_email: stepData,
			sf_choice: stepData,
		});
	};

	useEffect(() => {
		setEmail(companyStore.email);
		setPhone(companyStore.companyPhone);
		setCompanyCtcName(companyStore.companyctcname);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="ContactInfoContainer ReqMessageText">
			<div className="ContactNameInput marginBot12">
				<TextField
					disabled={companyStore.submitted}
					onChange={(event) => {
						setCompanyCtcName(event.target.value);
					}}
					onBlur={(event) => {
						dispatch(
							ACTIONS.addCompanyInfo({
								id: event.target.id,
								data: event.target.value,
								stateObject: 'company_store',
							})
						);
					}}
					error={props.nameError}
					value={companyctcname}
					id="companyctcname"
					name="companyctcname"
					label={i18n.t('searchflow.oblignamelabel')}
				/>
				{props.nameError ? (
					<span style={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }}>
						{i18n.t('searchflow.oblignametext')}
					</span>
				) : null}
			</div>
			<div className="ContactInfoInputs">
				<div className="marginBot12">
					<EmailInput
						disabled={companyStore.submitted}
						onChange={(event) => {
							setEmail(event.target.value);
						}}
						onBlur={(event) => {
							dataLayerUpdate(event.target.value);
							dispatch(
								ACTIONS.addCompanyInfo({
									id: event.target.id,
									data: event.target.value,
									stateObject: 'company_store',
								})
							);
						}}
						value={email}
						id="email"
						name="email"
						error={props.emailError}
						label={i18n.t('searchflow.obligmaillabel')}
					/>
					{props.emailError ? (
						<span
							style={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }}>
							{i18n.t('searchflow.obligmailtext')}
						</span>
					) : null}
				</div>
				<div className="marginBot12">
					<PhoneInput
						placeholder={i18n.t('searchflow.phoneplaceholder')}
						country={i18n.t('searchflow.countryshort')}
						masks={{ hu: i18n.t('searchflow.phoneplaceholderdots') }}
						value={phone}
						preferredCountries={[i18n.t('searchflow.countryshort')]}
						specialLabel={i18n.t('searchflow.phonelabel')}
						onChange={(phone) => {
							setPhone(phone);
						}}
						onBlur={() => {
							dispatch(
								ACTIONS.addCompanyInfo({
									id: 'companyPhone',
									data: phone,
									stateObject: 'company_store',
								})
							);
						}}
					/>
					{props.phoneError ? (
						<span
							style={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }}>
							{i18n.t('searchflow.obligphonetext')}
						</span>
					) : null}
				</div>
			</div>
			{props.emailError ? (
				<p
					style={{
						color: 'red',
						fontSize: '14px',
						fontWeight: 'bold',
						margin: '8px 0px',
					}}>
					{i18n.t('searchflow.provideemail')}
				</p>
			) : null}
		</div>
	);
};

export default ContactInfo;
