import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Navigation from '../components/navigation/navigation';
import ACTIONS from '../../../redux/action';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
import check from '../../../images/check.png';
import switch1 from '../../../images/switch1.png';
import switch2 from '../../../images/switch2.png';
import switchon from '../../../images/switchon.png';
import switchoff from '../../../images/switchoff.png';
import LoadingIndicator from '../../../shared/LoadingIndicator/loadingIndicator';
import { TextareaAutosize } from '@mui/material';

const FlowSwitch = ({ ...props }) => {
	const companyStore = useSelector((state) => state.company_store);
	const matchScoreWeights = useSelector((state) => state.match_score_weights);
	const [loading, setLoading] = useState(false);
	const [selectedSwitch, setSelectedSwitch] = useState('asp');
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');

	const dataLayerUpdate = (stepData) => {
		window.dataLayer.push({
			event: 'sf_step_changed',
			event_category: 'aspflow',
			sf_choice: stepData,
		});
	};

	const dataLayerUpdate2 = (stepData) => {
		window.dataLayer.push({
			event: 'sf_step_user_email',
			user_email: stepData,
			sf_choice: stepData,
		});
	};

	useEffect(() => {
		if (companyStore.email) {
			setEmail(companyStore.email);
		}
	}, [companyStore.email]);

	const handleEmailText = (event) => {
		setEmail(event.target.value);
		if (emailError) {
		}
	};

	const validateInput = useCallback((emailValidation) => {
		const validEmailRegex = RegExp(
			/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
		);
		return validEmailRegex.test(emailValidation);
	}, []);

	const handleNext = useCallback(
		async (inc) => {
			if (!email) {
				setEmailError(i18n.t('provider.emailrequiredwarning'));
				return;
			}
			if (email && !validateInput(email)) {
				setEmailError(i18n.t('provider.emailformatwarning'));
				return;
			}
			setEmailError('');
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'email',
					data: email,
					stateObject: 'company_store',
				})
			);
			if (selectedSwitch === 'asp') {
				setLoading(true);
				await props.fetchAspPotentialProviders(companyStore, matchScoreWeights);
				props.handleNext(1);
			} else if (selectedSwitch === 'cherry') {
				setLoading(true);
				await props.fetchCherryPotentialProviders(
					companyStore,
					matchScoreWeights
				);
				props.handleNext(2);
			}
			console.log('THIS IS COMPANYSTORE', companyStore);
		},
		[
			props,
			companyStore,
			dispatch,
			email,
			matchScoreWeights,
			selectedSwitch,
			validateInput,
		]
	);

	const handleBack = useCallback(
		(decr) => {
			props.handleBack(decr);
		},
		[props]
	);

	useEffect(() => {
		function handleKeyDown(e) {
			if (e.keyCode === 13) {
				handleNext();
			}
		}
		document.addEventListener('keydown', handleKeyDown);
		return function cleanup() {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleNext]);

	return (
		<div className="stepContainer">
			<div className="SFInput">
				{loading ? (
					<LoadingIndicator />
				) : (
					<>
						<div className="SFFlowSwitchEmailTitle">
							<strong>{i18n.t('searchflow.giveusyouremail')}</strong>
						</div>
						<div>
							<TextareaAutosize
								className="SFFlowSwitchEmailTextArea"
								onChange={handleEmailText}
                onBlur={(event) => {
                  dataLayerUpdate2(event.target.value)
                }}
								value={email}
							/>
							{emailError && (
								<div className="SFFlowSwitchEmailError">{emailError}</div>
							)}
						</div>
						<p className="SFSubTitle">
							<div>{i18n.t('searchflow.providerswitchtext0')}</div>{' '}
							<span className="questionTracker">3/3</span>
						</p>
						<div className="SwitchContainer">
							<div
								className={
									selectedSwitch === 'asp'
										? 'SwitchItem selectedSwitch'
										: 'SwitchItem'
								}
								onClick={() => {
									dataLayerUpdate('asp');
									setSelectedSwitch('asp');
									dispatch(
										ACTIONS.addCompanyInfo({
											id: 'flowswitchchoice',
											data: 'asp',
											stateObject: 'company_store',
										})
									);
									dispatch(
										ACTIONS.addCompanyInfo({
											id: 'isfakecherry',
											data: false,
											stateObject: 'company_store',
										})
									);
								}}>
								<p className="switchOptionTitle">
									{i18n.t('searchflow.providerswitchtext0a1')}
								</p>
								<p>{i18n.t('searchflow.providerswitchtext0a1a')}</p>

								<p style={{ paddingLeft: '24px' }}>
									<img alt="check" src={check} />{' '}
									{i18n.t('searchflow.providerswitchtext0a2')}
								</p>
								<p style={{ paddingLeft: '24px' }}>
									<img alt="check" src={check} />{' '}
									{i18n.t('searchflow.providerswitchtext0a3')}
								</p>
								<p style={{ paddingLeft: '24px' }}>
									<img alt="check" src={check} />{' '}
									{i18n.t('searchflow.providerswitchtext0a4')}
								</p>

								<img alt="happy" src={switch1} className="switchoptimg1" />
								<img
									alt="happy"
									src={selectedSwitch === 'asp' ? switchon : switchoff}
									className="switchonimg"
									style={{ width: '42px' }}
								/>
							</div>
							<div
								className={
									selectedSwitch === 'cherry'
										? 'SwitchItem selectedSwitch'
										: 'SwitchItem'
								}
								onClick={() => {
									dataLayerUpdate('cherry');
									setSelectedSwitch('cherry');
									dispatch(
										ACTIONS.addCompanyInfo({
											id: 'flowswitchchoice',
											data: 'cherry',
											stateObject: 'company_store',
										})
									);
									dispatch(
										ACTIONS.addCompanyInfo({
											id: 'isfakecherry',
											data: true,
											stateObject: 'company_store',
										})
									);
								}}>
								<p className="switchOptionTitle">
									{i18n.t('searchflow.providerswitchtext0b')}
								</p>
								<p>{i18n.t('searchflow.providerswitchtext0ba')}</p>
								<p style={{ paddingLeft: '24px' }}>
									<img alt="check" src={check} />{' '}
									{i18n.t('searchflow.providerswitchtext0b1')}
								</p>

								<img alt="happy" src={switch2} className="switchoptimg2" />
								<img
									alt="happy"
									src={selectedSwitch === 'cherry' ? switchon : switchoff}
									className="switchonimg"
									style={{ width: '42px' }}
								/>
							</div>
						</div>
						<p className="SFswitchhint">
							{i18n.t('searchflow.providerswitchtext0hint')}
						</p>
					</>
				)}
			</div>
			<Navigation
				step={17}
				handleBack={() => handleBack()}
				handleNext={async () => {
					handleNext();
				}}
				openHelpForm={() => props.openHelpForm()}
			/>
		</div>
	);
};

export default withTranslation()(FlowSwitch);
