import React from 'react';
import {
  providerSpecialPreferencesList,
  providerEtradePreferencesList,
  providerVehiclePreferencesList,
  providerRetailPreferencesList,
  providerWholesalePreferencesList,
  providerTravelPreferencesList,
  providerCourierPreferencesList,
} from '../../data/providerText';
import PreferencesInputs from './input/preferencesInputs';
import ProviderNavigation from '../components/providerNavigation/providerNavigation';
import Dialog from '@mui/material/Dialog';
import StyledButton from '../../../shared/components/styledButton/styledButton';
import MkoeIcon from '../../../images/icons/mkoe-icon-new.png';
import { useSelector } from 'react-redux';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

import { Paper } from '@mui/material';

const ProviderSpecialPreferences = ({ handleNext, handleBack, activeStep, data }) => {
  const [providerSubscription] = useSelector((state) => [
    state.provider_subscription,
  ]);
  const [open, setOpen] = React.useState(
    providerSubscription.partnerCode &&
      providerSubscription.partnerCode.toLowerCase() === 'mkoestart'
      ? true
      : false
  );

  const handleClose = () => {
    setOpen(false);
  };

  // console.log(data, 'data')

  return (
    <div className='PaddingBot100'>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <div className='Mkoepopup'>
          <img src={MkoeIcon} alt='' />
          <h2>{i18n.t('provider.mkoepartnerlabel')}</h2>
          <p>
            {i18n.t('provider.mkoetext1')}
            <b>{i18n.t('provider.mkoetext2')}</b>
            {i18n.t('provider.mkoetext3')} <b>{i18n.t('provider.mkoetext4')}</b>
            {i18n.t('provider.mkoetext5')}
            <b>{i18n.t('provider.mkoetext6')}</b>.
          </p>
          <StyledButton color='Primary' onClick={handleClose}>
            {i18n.t('general.ok')}
          </StyledButton>
        </div>
      </Dialog>
      <div className='ProviderInputContainerCentered'>
        <Paper className='providerPaper'>
          <p className='ProviderInputTitle'>
            {i18n.t('provider.restrictionsoptions')}
          </p>
          <p className='ProviderInputSubTitle'>
            {i18n.t('provider.restrictionstext')}
          </p>
          <div className='RestrictionsNotification'>
            <h2>{i18n.t('provider.warning')}</h2>
            <p>{i18n.t('provider.restrictionstext2')}</p>
          </div>
        </Paper>
        <Paper className='providerPaper'>
          <p className='RestrictionTitle'>Egyéb felárak</p>
          <PreferencesInputs
            dataText={providerSpecialPreferencesList()}
            storeObj='provider_special_industry_restrictions'
          />
        </Paper>

        {data.provider_industry_restrictions.some(e => e.id === 'etrade' && e.on) &&
          <Paper className='providerPaper'>
            <p className='RestrictionTitle'>Webshopokra vonatkozó felárak</p>
            <PreferencesInputs
              dataText={providerEtradePreferencesList()}
              storeObj='provider_special_industry_restrictions'
            />
          </Paper>}

        {data.provider_industry_restrictions.some(e => e.id === 'vehicle' && e.on) &&
          <Paper className='providerPaper'>
            <p className='RestrictionTitle'>Gépjárműiparra vonatkozó felár</p>
            <PreferencesInputs
              dataText={providerVehiclePreferencesList()}
              storeObj='provider_special_industry_restrictions'
            />
          </Paper>}

        {data.provider_industry_restrictions.some(e => e.id === 'retail' && e.on) &&
          <Paper className='providerPaper'>
            <p className='RestrictionTitle'>Kiskereskedelemre vonatkozó felár</p>
            <PreferencesInputs
              dataText={providerRetailPreferencesList()}
              storeObj='provider_special_industry_restrictions'
            />
          </Paper>}

          {data.provider_industry_restrictions.some(e => e.id === 'wholesale' && e.on) &&
          <Paper className='providerPaper'>
            <p className='RestrictionTitle'>Nagykereskedelemre vonatkozó felár</p>
            <PreferencesInputs
              dataText={providerWholesalePreferencesList()}
              storeObj='provider_special_industry_restrictions'
            />
          </Paper>}

          {data.provider_industry_restrictions.some(e => e.id === 'travel' && e.on) &&
          <Paper className='providerPaper'>
            <p className='RestrictionTitle'>Szállás- és vendéglátóiparra vonatkozó felár</p>
            <PreferencesInputs
              dataText={providerTravelPreferencesList()}
              storeObj='provider_special_industry_restrictions'
            />
          </Paper>}

          {data.provider_industry_restrictions.some(e => e.id === 'courier' && e.on) &&
          <Paper className='providerPaper'>
            <p className='RestrictionTitle'>Futárszolgálatra vonatkozó felár</p>
            <PreferencesInputs
              dataText={providerCourierPreferencesList()}
              storeObj='provider_special_industry_restrictions'
            />
          </Paper>}

      </div>
      <ProviderNavigation
        handleBack={() => {
          handleBack();
        }}
        handleNext={() => {
          handleNext();
        }}
        step={activeStep}
      />
    </div>
  );
};

export default withTranslation()(ProviderSpecialPreferences);
