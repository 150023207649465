import React from 'react';
import i18n from '../../utils/i18n';
//IMAGES
import hungary from '../../images/icons/hungary.png';
import uk from '../../images/icons/united-kingdom.png';
import russia from '../../images/icons/russia.png';
import romania from '../../images/icons/romania.png';
import china from '../../images/icons/china.png';
import spain from '../../images/icons/spain.png';
import france from '../../images/icons/france.png';
import germany from '../../images/icons/germany.png';

export var dbTextPairs = () => {
  return {
    accounting: i18n.t('general.accounting'),
    payroll: i18n.t('general.payroll'),
    simplifiedpayroll: i18n.t('general.simplifiedpayroll'),
    taxconsulting: i18n.t('general.taxconsulting'),
    taxconsultingsimple: i18n.t('general.taxconsultingsimple'),
    taxconsultingcomplex: i18n.t('general.taxconsultingcomplex'),
    audit: i18n.t('general.audit'),
    legalentityfounding: i18n.t('general.legalentityfounding'),
    liquidation: i18n.t('general.liquidation'),
    singleprimary: i18n.t('companylabels.singleprimary'),
    singlesecondary: i18n.t('companylabels.singlesecondary'),
    kiva: i18n.t('companylabels.kiva'),
    vszja: i18n.t('companylabels.vszja'),
    bt: i18n.t('companylabels.bt'),
    kft: i18n.t('companylabels.kft'),
    zrt: i18n.t('companylabels.zrt'),
    kkt: i18n.t('companylabels.kkt'),
    nyrt: i18n.t('companylabels.nyrt'),
    condo: i18n.t('companylabels.condo'),
    citycouncyl: i18n.t('companylabels.citycouncil'),
    civil: i18n.t('companylabels.civil'),
    tbd: 'Nem meghatározott',
    construction: i18n.t('industrylabels.construction'),
    vehicle: i18n.t('industrylabels.vehicle'),
    manufacturing: i18n.t('industrylabels.manufacturing'),
    IT: i18n.t('industrylabels.IT'),
    realestate: i18n.t('industrylabels.realestate'),
    // trade: i18n.t('industrylabels.trade'),
    retail: i18n.t('industrylabels.retail'),
    wholesale: i18n.t('industrylabels.wholesale'),
    agriculture: i18n.t('industrylabels.agriculture'),
    education: i18n.t('industrylabels.education'),
    art: i18n.t('industrylabels.art'),
    finance: i18n.t('industrylabels.finance'),
    logistics: i18n.t('industrylabels.logistics'),
    travel: i18n.t('industrylabels.travel'),
    otherservices: i18n.t('industrylabels.otherservices'),
    etrade: i18n.t('industrylabels.etrade'),
    startup: i18n.t('industrylabels.startup'),
    other: i18n.t('industrylabels.other'),
    sport: i18n.t('industrylabels.sport'),
    flexibility: i18n.t('providerpreview.flexibility'),
    professionalism: i18n.t('providerpreview.professionalism'),
    industryexperience: i18n.t('providerpreview.industryexperience'),
    precision: i18n.t('providerpreview.precision'),
    pricevalue: i18n.t('providerpreview.pricevalue'),
  };
};

export var languagesText = () => [
  { name: i18n.t('languages.hungarian'), id: 'hungarian', flag: hungary },
  { name: i18n.t('languages.english'), id: 'english', flag: uk },
  { name: i18n.t('languages.german'), id: 'german', flag: germany },
  { name: i18n.t('languages.french'), id: 'french', flag: france },
  { name: i18n.t('languages.chinese'), id: 'chinese', flag: china },
  { name: i18n.t('languages.russian'), id: 'russian', flag: russia },
  { name: i18n.t('languages.romanian'), id: 'romanian', flag: romania },
  { name: i18n.t('languages.spanish'), id: 'spanish', flag: spain },
];

export var gdprLabelSmall = () => [
  <span style={{ fontSize: '0.75em', margin: '0' }} className='small-text'>
    {i18n.t('gdpr.part1')}
    <a
      rel='noopener noreferrer'
      target='_blank'
      href='https://drive.google.com/file/d/1x4YL5tSFo9aANrmn7go3v8hvmZe5CkBF/view'
      style={{
        color: '#3797f0',
      }}
    >
      {' '}
      {i18n.t('gdpr.part2')}
    </a>{' '}
    {i18n.t('gdpr.part3')}{' '}
    <a
      rel='noopener noreferrer'
      target='_blank'
      href='https://drive.google.com/file/d/1QLXCh7qnGQ8BNItp-MZUAQMjmw6YSCKg/view'
      style={{
        color: '#3797f0',
      }}
    >
      {i18n.t('gdpr.part4')}
    </a>
    {i18n.t('gdpr.part5')}
    <span
      style={{
        color: '#FF0000',
        fontSize: '0.8rem',
        marginLeft: '0.3em',
        fontWeight: 'bold',
      }}
    >
      {i18n.t('gdpr.part6')}
    </span>
  </span>,
];
