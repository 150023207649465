import React, { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { Button, Rating, Pagination } from '@mui/material';

import SendReqFakeCherry from '../components/sendReq/sendReqFakeCherry';

import axios from '../../utils/axiosCompanyProxy';

import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import './providerProfile.scss';

import HandWave from './assets/DlpProviderHandWave.svg';
import Shield from './assets/DlpProviderShield.svg';
import Approved from './assets/DlpApproved.svg';
import StarBlue from './assets/DlpStarBlue.svg';
import LangHU from '../../../images/icons/circle-flags/hu.svg';
import LangGB from '../../../images/icons/circle-flags/gb.svg';
import LangDE from '../../../images/icons/circle-flags/de.svg';
import LangFR from '../../../images/icons/circle-flags/fr.svg';
import LangCN from '../../../images/icons/circle-flags/cn.svg';
import LangRU from '../../../images/icons/circle-flags/ru.svg';
import LangRO from '../../../images/icons/circle-flags/ro.svg';
import LangES from '../../../images/icons/circle-flags/es.svg';
import DlpAttFlexibility from './components/dlpAttFlexibility';
import DlpAttPrecision from './components/dlpAttPrecision';
import DlpAttPricevalue from './components/dlpAttPriceValue';
import DlpAttIndustryExperience from './components/dlpAttIndustryExperience';
import DlpAttIndustryProfessionalism from './components/dlpAttProfessionalism';
import DigBadge from './assets/DlpInfos/DlpDigBadge.svg';
import Industry from './assets/DlpInfos/DlpIndustry.svg';
import NoOfCustomers from './assets/DlpInfos/DlpNoOfCustomers.svg';
import PackageSize from './assets/DlpInfos/DlpPackageSize.svg';
import StarGrey from './assets/DlpInfos/DlpStarGrey.svg';
import WoComplaint from './assets/DlpInfos/DlpWoComplaint.svg';
import NotVerifiedYet from './assets/DlpNotVerifiedYet.svg';
import DlpMUIProfileImageDefault from './assets/DlpMUIProfileImageDefault.svg';
import LongArrowLeft from './assets/DlpProviderLongArrowLeft.svg';


//REDUX
import { useSelector } from 'react-redux';
import './providerProfile.scss';
// import StyledButton from '../../../shared/components/styledButton/styledButton';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ProviderProfile = ({ data, ...props }) => {
	const [reviews, setReviews] = React.useState([]);
	const [open, setOpen] = React.useState(false);

	const companyStore = useSelector((state) => state.company_store);

	const [id, setId] = useState(data.providerID);
	const [providerCompanyName, setProviderCompanyName] = useState('');
	const [ProfileImage, setProfileImage] = useState('');
	const [description, setDescription] = useState('');
	const [liabilityInsurance, setLiabilityInsurance] = useState('');
	const [languages, setLanguages] = useState([]);
	const [packageType, setPackageType] = useState('');
	const [averages, setAverages] = useState({});
	const [packageSize, setPackageSize] = useState('');
	const [digitalBadge, setDigitalBadge] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [industryExperience, setIndustryExperience] = useState({});
	const [showMore, setShowMore] = useState(false);

	const reviewsPerPage = 9;
	const indexOfLastReview = currentPage * reviewsPerPage;
	const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
	const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);
	const totalPages = Math.ceil(reviews.length / reviewsPerPage);

	const { t } = useTranslation();

	const dataLayerUpdate = (provider) => {
		window.dataLayer.push({
			event: 'sf_step_cherry_provider_viewed',
			event_category: 'aspflow',
			viewed_provider: provider,
		});
	};

	useEffect(() => {
		dataLayerUpdate(data);
	}, [data]);

	console.log(data, ' DATA');

	const handlePageChange = (event, value) => {
		event.stopPropagation();
		setCurrentPage(value);
	};

	const handleShowMore = () => {
		setShowMore(true);
	};

	const handleShowLess = () => {
		setShowMore(false);
	};

	useEffect(() => {
		const fetchProviderDetails = async () => {
			try {
				const response = await axios.get(`/api/provider/${id}`);
				console.log('PROVIDER DETAILS', response);
				setProviderCompanyName(
					response.data.provider.provider_info.companyname
				);
				setProfileImage(response.data.provider.provider_media.profileImage);
				setDescription(response.data.provider.provider_info.description);
				setLiabilityInsurance(
					response.data.provider.provider_liability_insurance.accounting
				);
				console.log(
					'LANGUAGES DATA',
					response.data.provider.provider_languages
				);
				setLanguages(response.data.provider.provider_languages || {});
				setPackageType(response.data.provider.provider_package.packageType);
				setPackageSize(response.data.provider.provider_package.packageSize);
				setDigitalBadge(response.data.provider.digital_badge);
				setIndustryExperience(
					response.data.provider.provider_industry_experience
				);
			} catch (error) {
				console.error('Error fetching provider details:', error);
			}
		};
		fetchProviderDetails();
	}, [id]);

	useEffect(() => {
		const fetchProviderReviews = (id) => {
			try {
				let data = JSON.stringify({ providerID: id });
				axios
					.post('/api/review/id', data, {
						headers: {
							'Content-Type': 'application/json',
						},
					})
					.then((response) => {
						console.log('PROVIDER REVIEWS', response);
						setReviews(response.data.review);
						setAverages(pickAttributes(response.data.review));
					})
					.catch((error) => {
						console.log('AXIOS ERROR: ', error.response.data);
					});
			} catch (error) {
				console.error('Error fetching provider reviews:', error);
			}
		};
		fetchProviderReviews(id);
	}, [id]);

	const renderLanguages = (languages) => {
		const languageIcons = {
			hungarian: LangHU,
			english: LangGB,
			german: LangDE,
			french: LangFR,
			chinese: LangCN,
			russian: LangRU,
			romanian: LangRO,
			spanish: LangES,
		};

		const countryCodes = {
			hungarian: 'HU',
			english: 'GB',
			german: 'DE',
			french: 'FR',
			chinese: 'CN',
			russian: 'RU',
			romanian: 'RO',
			spanish: 'ES',
		};

		return Object.keys(languages)
			.filter((language) => languages[language] && language !== 'hungarian')
			.map((language, index) => (
				<img
					key={index}
					src={languageIcons[language]}
					alt={`${language} flag`}
					className={`Lang${countryCodes[language]}`}
				/>
			));
	};

	const calculateAverageRating = (review) => {
		const {
			flexibility,
			industryexperience,
			precision,
			pricevalue,
			professionalism,
		} = review;
		const total =
			flexibility +
			industryexperience +
			precision +
			pricevalue +
			professionalism;
		return Number((total / 5).toFixed(1));
	};

	const pickAttributes = (reviews) => {
		if (reviews.length === 0) return 0;

		const totalFlexibility = reviews.reduce(
			(total, review) => total + review.flexibility,
			0
		);
		const averageFlexibility = (totalFlexibility / reviews.length).toFixed(1);

		const totalIndustryexperience = reviews.reduce(
			(total, review) => total + review.industryexperience,
			0
		);
		const averageIndustryexperience = (
			totalIndustryexperience / reviews.length
		).toFixed(1);

		const totalPrecision = reviews.reduce(
			(total, review) => total + review.precision,
			0
		);
		const averagePrecision = (totalPrecision / reviews.length).toFixed(1);

		const totalPricevalue = reviews.reduce(
			(total, review) => total + review.pricevalue,
			0
		);
		const averagePricevalue = (totalPricevalue / reviews.length).toFixed(1);

		const totalProfessionalism = reviews.reduce(
			(total, review) => total + review.professionalism,
			0
		);
		const averageProfessionalism = (
			totalProfessionalism / reviews.length
		).toFixed(1);

		const numberOfAttributes = 5;

		const totalAverage = (
			(parseFloat(averageFlexibility) +
				parseFloat(averageIndustryexperience) +
				parseFloat(averagePrecision) +
				parseFloat(averagePricevalue) +
				parseFloat(averageProfessionalism)) /
			numberOfAttributes
		).toFixed(1);

		return {
			averageFlexibility: parseFloat(averageFlexibility),
			averageIndustryexperience: parseFloat(averageIndustryexperience),
			averagePrecision: parseFloat(averagePrecision),
			averagePricevalue: parseFloat(averagePricevalue),
			averageProfessionalism: parseFloat(averageProfessionalism),
			totalAverage: parseFloat(totalAverage),
		};
	};

	const renderIndustryExperience = (industryExperience) => {
		return Object.keys(industryExperience)
			.filter((industry) => industryExperience[industry] >= 4)
			.map((industry, index) => (
				<div key={index}>{t(`industrylabels.${industry}`)}</div>
			));
	};

	const sendReqButton = (
		<div>
			<Button
				className="RFPButtonCustom"
				onClick={(e) => {
					e.stopPropagation();
					window.dataLayer.push({
						event: 'sf_step_cherry_provider_viewed',
						event_category: 'aspflow',
						viewed_provider: data,
					});
					setOpen(true);
				}}
				// color={
				// 	companyStore.selected_providers.find(
				// 		({ providerID }) => providerID === data.providerID
				// 	)
				// 		? 'Success'
				// 		: 'Primary'
				// }
				isDisabled={companyStore.selected_providers.find(
					({ providerID }) => providerID === data.providerID
				)}
			>
				{i18n.t('request.reqmsgtitle')}
			</Button>
		</div>
	);
	return (
		<div className="ProviderProfile">
			<div className="ProviderBackButton">
				<Button className="ProviderBackButtonCustom">
					<img
						src={LongArrowLeft}
						alt="Long Arrow Left"
						className="LongArrowLeft"
					/>
					{i18n.t('dlpproviderprofile.backbutton')}
				</Button>
			</div>
			<Dialog
				onClick={(e)=>{
					e.stopPropagation();
				}}
				open={open}
				onClose={() => setOpen(false)}
				handleClose={() => setOpen(false)}
				TransitionComponent={Transition}
				fullScreen={window.innerWidth < 600 ? true : false}>
				<SendReqFakeCherry
					handleClose={() => setOpen(false)}
					createRequest={props.createRequest}
					createAspRequest={props.createAspRequest}
					updateRequest={props.updateRequest}
					updateFakeCherryRequest={props.updateFakeCherryRequest}
					data={data}
					reqID={props.reqID}
				/>
			</Dialog>
			<div className="ProviderContent">
				<div className={`ProviderContentLeft ${showMore ? 'show' : ''}`}>
					<div className="ProviderMainContainer">
						<div className="ProviderMainContainerLeft">
							<div className="ProfileImageContainer">
								{ProfileImage ? (
									<img
										src={ProfileImage}
										alt="Profile"
										className="ProfileImage"
									/>
								) : (
									<img
										src={DlpMUIProfileImageDefault}
										alt="Profile Default"
										className="ProfileImageDefault"
									/>
								)}
								{packageType === 'premium' && (
									<img
										src={Approved}
										alt="Profile"
										className="Approved"></img>
								)}
							</div>
						</div>
						<div className="ProviderMainContainerRight">
							<div className="ProviderProfileName">
								{providerCompanyName}
							</div>
							{reviews.length > 0 ? (
								<div className="ProviderProfileAvgContainer">
									<div className="ProviderProfileAvgStars">
										<Rating
											value={averages.totalAverage || 0}
											precision={0.1}
											readOnly
											sx={{ fontSize: '1rem' }}
										/>
										<div className="ProviderProfileAvgNumber">
											{averages.totalAverage}
										</div>
										<div className="ProviderProfileReviewsNumber">
											({reviews.length})
										</div>
									</div>
								</div>
							) : (
								<div className="NotVerifiedYetConatiner">
									<img
										src={NotVerifiedYet}
										alt="Not Verified Yet"
										className="NotVerifiedYet"
									/>
									<div className="NotVerifiedYetText">
										{i18n.t('dlpproviderprofile.notverifiedyet')}
									</div>
								</div>
							)}
							{/* <div className="dlpRFPButton">
								<Button className="dlpRFPButtonCustom">
									{i18n.t('dlpproviderprofile.rfpbuttoncustom')}
								</Button>
							</div> */}
							<div className="RFPButton">
								{sendReqButton}
							</div>
							{renderLanguages(languages).length > 0 && (
								<div className="LanguagesContainer">
									{renderLanguages(languages)}
								</div>
							)}
						</div>
					</div>
					{reviews.length > 0 && (
						<>
							<div className="DividerContentLeft"></div>
							<div className="AttributesContainer">
								<DlpAttFlexibility inputValue={averages.averageFlexibility} />
								<DlpAttPrecision inputValue={averages.averagePrecision} />
								<DlpAttPricevalue inputValue={averages.averagePricevalue} />
								<DlpAttIndustryExperience
									inputValue={averages.averageIndustryexperience}
								/>
								<DlpAttIndustryProfessionalism
									inputValue={averages.averageProfessionalism}
								/>
							</div>
						</>
					)}
					{!showMore && (
						<div className="ShowMoreButton">
							<Button
								onClick={(e) => {
									e.stopPropagation();
									handleShowMore();
								}
								}
							>
								{i18n.t('dlpproviderprofile.moreinfo')}
							</Button>
						</div>
					)}
					<div className={`HiddenContent ${showMore ? 'show' : ''}`}>
						{renderIndustryExperience(industryExperience).length > 0 && (
							<>
								<div className="DividerContentLeft"></div>
								<div className="IndustryContainer">
									<img src={Industry} alt="Industry" className="Industry" />
									<div className="IndustryText">
										{i18n.t('dlpproviderprofile.expertinindustries')}
									</div>
								</div>
								<div className="IndustryContainerCards">
									{renderIndustryExperience(industryExperience).map(
										(industry, index) => (
											<div key={index} className="IndustryCard">
												{industry}
											</div>
										)
									)}
								</div>
							</>
						)}
						{reviews.length > 0 && (
							<>
								<div className="DividerContentLeft"></div>
								<div className="RatingSumContainer">
									<img src={StarGrey} alt="Star Grey" className="StarGrey" />
									<div className="RatingSumAvgNumber">
										{averages.totalAverage}
									</div>
									<div className="RatingSumText">{i18n.t('general.reviewsmall')}</div>
								</div>
							</>
						)}
						<div className="PackageSizeContainer">
							<img
								src={PackageSize}
								alt="PackageSize"
								className="packageSize"
							/>
							{packageSize === 'entrepreneur' && (
								<div className="PackageSizeText1">{i18n.t('general.entrepreneur')}</div>
							)}
							{packageSize === 'small_office' && (
								<div className="PackageSizeText1">{i18n.t('provider.small_acc_office')}</div>
							)}
							{packageSize === 'office' && (
								<div className="PackageSizeText1">{i18n.t('provider.large_acc_office')}</div>
							)}
						</div>
						{digitalBadge !== 'tbd' &&
							digitalBadge !== 'none' &&
							digitalBadge !== undefined && (
								<div className="DigBadgeContainer">
									<img src={DigBadge} alt="DigBadge" className="DigBadge" />
									<div className="DigBadgeTextContainer">
										<>
											<div className="DigBadgeText1">{i18n.t('dlpproviderprofile.digital')}</div>
											<div className="DigBadgeText1">{i18n.t('dlpproviderprofile.withcertifiaction')}</div>
											<div className="DigBadgeText2">{i18n.t('dlpproviderprofile.has')}</div>
										</>
									</div>
								</div>
							)}
						<div className="DividerContentLeft"></div>
						<div className="WoComplaintContainer">
							<img
								src={WoComplaint}
								alt="WoComplaint"
								className="WoComplaint"
							/>
							<div className="WoComplaintTextContainer">
								<div className="WoComplaintNumber">365</div>
								<div className="WoComplaintText">{i18n.t('dlpproviderprofile.workday')}</div>
								<div className="WoComplaintText">{i18n.t('dlpproviderprofile.complaint')}</div>
								<div className="WoComplaintText">{i18n.t('dlpproviderprofile.without')}</div>
							</div>
						</div>
						<div className="NoOfCustomersContainer">
							<img
								src={NoOfCustomers}
								alt="NoOfCustomers"
								className="NoOfCustomers"
							/>
							<div className="NoOfCustomersTextContainer">
								<div className="NoOfCustomersNumber">15</div>
								<div className="NoOfCustomersText1">{i18n.t('general.bookkeepie')}</div>
								<div className="NoOfCustomersText2">{i18n.t('general.client')}</div>
							</div>
						</div>
						<div className="ShowLessButton">
							<Button
								onClick={(e) => {
									e.stopPropagation();
									handleShowLess();
								}
								}
							>{i18n.t('dlpproviderprofile.lessinfo')}
							</Button>
						</div>
					</div>
				</div>
				<div className="ProviderContentRight">
					{description && (
						<div className="ProviderCard">
							<div className="ProviderIntroduction">
								<img src={HandWave} alt="Hand Wave" className="HandWave" />
								{i18n.t('general.description')}
							</div>
							<div className="ProviderIntroductionText">{description}</div>
						</div>
					)}
					{liabilityInsurance === true && (
						<div className="ProviderCard">
							<div className="ProviderLiabilityInsurance">
								<img src={Shield} alt="Shield" className="Shield" />
								{i18n.t('dlpproviderprofile.hasliabilityinsurance')}
							</div>
							<div className="ProviderLiabilityInsuranceText">
								{i18n.t('dlpproviderprofile.liabilityinsurancetext')}
							</div>
						</div>
					)}
					<div className="ProviderCardLast">
						{reviews.length === 0 ? (
							<div className="ProviderReviewsNull">
								<img src={StarBlue} alt="Star Blue" className="StarBlue" />
								<div className="SumOfReviews">{reviews.length}</div>
								{i18n.t('general.review')}
							</div>
						) : (
							<div className="ProviderReviews">
								<img src={StarBlue} alt="Star Blue" className="StarBlue" />
								<div className="SumOfReviews">{reviews.length}</div>
								{i18n.t('general.review')}
							</div>
						)}
						<div className="ProviderReviewsContainer">
							{currentReviews.map((review, index) => (
								<div key={index} className="ProviderReviewCard">
									<div className="ProviderReviewHeader">
										<div className="ProviderReviewReviewer">
											{review.reviewer}
										</div>
										<div className="ProviderReviewDate">{review.date}</div>
									</div>
									<div className="ProviderReviewAvgContainer">
										<div className="ProviderReviewAvgStars">
											<Rating
												value={calculateAverageRating(review)}
												precision={0.1}
												readOnly
												sx={{ fontSize: '1rem' }}
											/>
										</div>
										<div className="ProviderReviewAvgNumber">
											{calculateAverageRating(review)}
										</div>
									</div>
									<div className="ProviderReviewText">{review.review}</div>
								</div>
							))}
						</div>
						{totalPages > 0 && (
							<Pagination
								count={totalPages}
								page={currentPage}
								onChange={handlePageChange}
								color="primary"
								className="ReviewsPaginator"
								shape="rounded"
								sx={{
									'& .MuiPaginationItem-root': {
										color: '#4D5BEC',
										border: '1px solid #E3E5E9',
										borderRadius: '6px',
									},
									'& .MuiPaginationItem-root.Mui-selected': {
										backgroundColor: '#4D5BEC',
										color: '#ffffff',
										border: '1px solid #E3E5E9',
										borderRadius: '6px',
									},
									'& .MuiPaginationItem-root.Mui-selected:hover': {
										backgroundColor: '#4D5BEC',
										color: '#ffffff',
										border: '1px solid #E3E5E9',
										borderRadius: '6px',
									},
								}}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default withTranslation()(ProviderProfile);
