import React, { useCallback, useEffect, useState } from 'react';
import Navigation from "../components/navigation/navigation";
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import ACTIONS from "../../../redux/action";

const IndustrySpecificGeneral = ({ ...props }) => {
    const companyStore = useSelector((state) => state.company_store);
    const dispatch = useDispatch();

    const [simplifiedPayroll, setSimplifiedPayroll] = useState('');
    const [simplifiedEmployeeCount, setSimplifiedEmployeeCount] = useState('');
    const [registerSimplifiedPayroll, setRegisterSimplifiedPayroll] = useState({ status: '', employeecount: null });
    const [severalLocations, setSeveralLocations] = useState({ status: '', locationcount: null });
    const [companyCar, setCompanyCar] = useState({ status: '', carcount: null });
    const [cashRegister, setCashRegister] = useState({ status: '', cashregistercount: null });
    const [multipleAccounts, setMultipleAccounts] = useState({ status: '', accountcount: null });
    const [customerType, setCustomerType] = useState({ selectedoption: '' });
    const [error, setError] = useState('');
    const [regSimpPayrollError, setRegSimpPayrollError] = useState('');

    const general = companyStore.industryspecifics.general;

    useEffect(() => {
        if (companyStore?.simplifiedpayroll) {
            setSimplifiedPayroll(companyStore.simplifiedpayroll);
        }
        if (companyStore?.simplifiedemployeecount) {
            setSimplifiedEmployeeCount(companyStore.simplifiedemployeecount);
        }
        if (general?.registersimplifiedpayroll) {
            setRegisterSimplifiedPayroll(general.registersimplifiedpayroll);
        }
        if (general?.severallocations) {
            setSeveralLocations(general.severallocations);
        }
        if (general?.companycar) {
            setCompanyCar(general.companycar);
        }
        if (general?.cashregister) {
            setCashRegister(general.cashregister);
        }
        if (general?.multipleaccounts) {
            setMultipleAccounts(general.multipleaccounts);
        }
        if (general?.customertype) {
            setCustomerType(general.customertype);
        }
    }, [general, companyStore.simplifiedpayroll, companyStore.simplifiedemployeecount]);

    const handleSimplifiedPayrollChange = useCallback(
        (e) => {
            const { value } = e.target;
            setSimplifiedPayroll(value);

            if (value === 'false' || value === 'idk') {
                setSimplifiedEmployeeCount('');
                dispatch(
                    ACTIONS.addCompanyInfo({
                        id: 'simplifiedemployeecount',
                        data: '',
                        stateObject: 'company_store',
                    })
                );
                
                setRegisterSimplifiedPayroll((prev) => ({
                    ...prev,
                    status: '',
                }));
    
                dispatch(
                    ACTIONS.updateNestedObject({
                        stateObject: 'company_store',
                        stateObjectID: 'industryspecifics',
                        id: 'general',
                        data: {
                            ...general,
                            registersimplifiedpayroll: {
                                ...general.registersimplifiedpayroll,
                                status: '',
                            },
                        },
                    })
                );
            }

            dispatch(
                ACTIONS.addCompanyInfo({
                    id: 'simplifiedpayroll',
                    data: value,
                    stateObject: 'company_store',
                })
            );
        },
        [dispatch, general]
    );

    const handleSimplifiedEmployeeCountChange = useCallback(
        (e) => {
            const simplifiedemployeecount = parseInt(e.target.value, 10);

            if (!simplifiedemployeecount || simplifiedemployeecount <= 0) {
                setError(i18n.t('providervafform.warningfield'));
            } else {
                setError('');
            }
            setSimplifiedEmployeeCount(simplifiedemployeecount || '');

            dispatch(
                ACTIONS.addCompanyInfo({
                    id: 'simplifiedemployeecount',
                    data: simplifiedemployeecount > 0 ? simplifiedemployeecount : '',
                    stateObject: 'company_store',
                })
            );
        },
        [dispatch]
    );

    const handleRPayrollChange = useCallback(
        (e) => {
            const { value } = e.target;
            setRegisterSimplifiedPayroll((prev) => ({
                ...prev,
                status: value,
            }));

            setRegSimpPayrollError('');

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        registersimplifiedpayroll: {
                            status: value,
                        },
                    },
                })
            );
        },
        [dispatch, general]
    );

    const handleLocationsChange = useCallback(
        (e) => {
            const { value } = e.target;
            setSeveralLocations((prev) => ({
                ...prev,
                status: value,
                locationcount: value === 'true'
                    ? prev.locationcount
                    : null,
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        severallocations: {
                            status: value,
                            locationcount: value === 'true'
                                ? severalLocations.locationcount
                                : null,
                        },
                    },
                })
            );
        },
        [dispatch, severalLocations.locationcount, general]
    );

    const handleLocationCountChange = useCallback(
        (e) => {
            const locationcount = parseInt(e.target.value, 10);
            setSeveralLocations((prev) => ({
                ...prev,
                locationcount: locationcount,
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        severallocations: {
                            ...severalLocations,
                            locationcount: locationcount,
                        },
                    },
                })
            );
        },
        [dispatch, severalLocations, general]
    );

    const handleCompanyCarChange = useCallback(
        (e) => {
            const { value } = e.target;
            setCompanyCar((prev) => ({
                ...prev,
                status: value,
                carcount: value === 'true'
                    ? prev.carcount
                    : null,
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        companycar: {
                            status: value,
                            carcount: value === 'true'
                                ? companyCar.carcount
                                : null,
                        },
                    },
                })
            );
        },
        [dispatch, companyCar.carcount, general]
    );

    const handleCompanyCarCountChange = useCallback(
        (e) => {
            const carcount = parseInt(e.target.value, 10);
            setCompanyCar((prev) => ({
                ...prev,
                carcount: carcount,
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        companycar: {
                            ...companyCar,
                            carcount: carcount,
                        },
                    },
                })
            );
        },
        [dispatch, companyCar, general]
    );

    const handleCashRegisterChange = useCallback(
        (e) => {
            const { value } = e.target;
            setCashRegister((prev) => ({
                ...prev,
                status: value,
                cashregistercount: value === 'true'
                    ? prev.cashregistercount
                    : null,
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        cashregister: {
                            status: value,
                            cashregistercount: value === 'true'
                                ? cashRegister.cashregistercount
                                : null,
                        },
                    },
                })
            );
        },
        [dispatch, cashRegister.cashregistercount, general]
    );

    const handleCashRegisterCountChange = useCallback(
        (e) => {
            const cashregistercount = parseInt(e.target.value, 10);
            setCashRegister((prev) => ({
                ...prev,
                cashregistercount: cashregistercount,
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        cashregister: {
                            ...cashRegister,
                            cashregistercount: cashregistercount,
                        },
                    },
                })
            );
        },
        [dispatch, cashRegister, general]
    );

    const handleMultipleAccountsChange = useCallback(
        (e) => {
            const { value } = e.target;
            setMultipleAccounts((prev) => ({
                ...prev,
                status: value,
                accountcount: value === 'true'
                    ? prev.accountcount
                    : null,
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        multipleaccounts: {
                            status: value,
                            accountcount: value === 'true'
                                ? multipleAccounts.accountcount
                                : null,
                        },
                    },
                })
            );
        },
        [dispatch, multipleAccounts.accountcount, general]
    );

    const handleAccountCountChange = useCallback(
        (e) => {
            const accountcount = parseInt(e.target.value, 10);
            setMultipleAccounts((prev) => ({
                ...prev,
                accountcount: accountcount,
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        multipleaccounts: {
                            ...multipleAccounts,
                            accountcount: accountcount,
                        },
                    },
                })
            );
        },
        [dispatch, multipleAccounts, general]
    );

    const handleCustomerTypeChange = useCallback(
        (e) => {
            const { value } = e.target;
            setCustomerType((prev) => ({
                ...prev,
                selectedoption: value,
            }));

            dispatch(
                ACTIONS.updateNestedObject({
                    stateObject: 'company_store',
                    stateObjectID: 'industryspecifics',
                    id: 'general',
                    data: {
                        ...general,
                        customertype: {
                            selectedoption: value,
                        },
                    },
                })
            );
        },
        [dispatch, general]
    );

    const handleNext = useCallback(
        (inc) => {
            let valid = true;

            if (simplifiedPayroll === 'true' && (!simplifiedEmployeeCount || simplifiedEmployeeCount <= 0)) {
                setError(i18n.t('providervafform.warningfield'));
                valid = false;
            } else {
                setError('');
            }

            if (simplifiedPayroll === 'true' && !registerSimplifiedPayroll.status) {
                setRegSimpPayrollError(i18n.t('providervafform.warningfield'));
                valid = false;
            } else {
                setRegSimpPayrollError('');
            }

            if (valid) {
                props.handleNext(inc);
            }
        },
        [simplifiedPayroll, simplifiedEmployeeCount, registerSimplifiedPayroll.status, props]
    );

    const handleBack = useCallback(
        (decr) => {
            props.handleBack(decr);
        },
        [props]
    );

    useEffect(() => {
        console.log('COMPANY STORE', companyStore);
        console.log('SPECIFICS GEN', companyStore.industryspecifics);
    }, [companyStore.industryspecifics, companyStore]);

    return (
        <div className='stepContainer'>

            {/* SIMPLIFIED PAYROLL */}
            <div className='SFInput'>
                <p className='SFIndustrySpecSubTitle' style={{ marginTop: '0px' }}>
                    {i18n.t('searchflow.simplifiedpayrollquestion')}
                    <span className='questionTracker'>6a/7</span>
                </p>
                <RadioGroup
                    value={simplifiedPayroll}
                    onChange={handleSimplifiedPayrollChange}
                >
                    <FormControlLabel
                        className='SFRadioInput'
                        value="true"
                        control={<Radio />}
                        label={i18n.t('general.yes')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="false"
                        control={<Radio />}
                        label={i18n.t('general.no')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="idk"
                        control={<Radio />}
                        label={i18n.t('general.idk')}
                        labelPlacement="start"
                    />
                </RadioGroup>
                {simplifiedPayroll === 'true' && (
                    <TextField
                        label={i18n.t('searchflow.simplifiedpayrollcount')}
                        type="number"
                        value={simplifiedEmployeeCount || ''}
                        onChange={handleSimplifiedEmployeeCountChange}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        inputProps={{ min: 0 }}
                        onWheel={(e) => e.target.blur()}
                        error={!!error}
                        helperText={error}
                    />
                )}
            </div>

            {/* REGISTER SIMPLIFIED PAYROLL */}
            {simplifiedPayroll === 'true' && (
                <div className='SFInput'
                    style={{
                        paddingLeft: '20px',
                        marginTop: '10px'
                    }}
                >
                    <p className='SFIndustrySpecSubTitle'>
                        {i18n.t('searchflow.registersimplifiedpayrollquestion')}
                    </p>
                    <RadioGroup
                        value={registerSimplifiedPayroll.status}
                        onChange={handleRPayrollChange}
                        error={!!regSimpPayrollError}
                    >
                        <FormControlLabel
                            className='SFRadioInput'
                            value="false"
                            control={<Radio />}
                            label={i18n.t('general.yes')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            value="true"
                            control={<Radio />}
                            label={i18n.t('general.no')}
                            labelPlacement="start"
                        />
                    </RadioGroup>
                    {regSimpPayrollError && (
                        <p style={{ color: '#d32f2f', fontSize: '0.75rem', marginTop: '8px', marginLeft: '14px' }}>
                            {regSimpPayrollError}
                        </p>
                    )}
                </div>
            )}

            {/* SEVERAL LOCATIONS */}
            <div className='SFInput'>
                <p className='SFIndustrySpecSubTitle'>
                    {i18n.t('searchflow.severallocationsquestion')}
                    <span className='questionTracker'>6b/7</span>
                </p>
                <RadioGroup
                    value={severalLocations.status}
                    onChange={handleLocationsChange}
                >
                    <FormControlLabel
                        className='SFRadioInput'
                        value="true"
                        control={<Radio />}
                        label={i18n.t('general.yes')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="false"
                        control={<Radio />}
                        label={i18n.t('general.no')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="idk"
                        control={<Radio />}
                        label={i18n.t('general.idk')}
                        labelPlacement="start"
                    />
                </RadioGroup>
                {severalLocations.status === 'true' && (
                    <TextField
                        label={i18n.t('searchflow.severallocationscount')}
                        type="number"
                        value={severalLocations.locationcount || ''}
                        onChange={handleLocationCountChange}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        inputProps={{ min: 0 }}
                        onWheel={(e) => e.target.blur()}
                    />
                )}
            </div>

            {/* COMPANY CAR */}
            <div className='SFInput'>
                <p className='SFIndustrySpecSubTitle'>
                    {i18n.t('searchflow.companycarquestion')}
                    <span className='questionTracker'>6c/7</span>
                </p>
                <RadioGroup
                    value={companyCar.status}
                    onChange={handleCompanyCarChange}
                >
                    <FormControlLabel
                        className='SFRadioInput'
                        value="true"
                        control={<Radio />}
                        label={i18n.t('general.yes')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="false"
                        control={<Radio />}
                        label={i18n.t('general.no')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="idk"
                        control={<Radio />}
                        label={i18n.t('general.idk')}
                        labelPlacement="start"
                    />
                </RadioGroup>
                {companyCar.status === 'true' && (
                    <TextField
                        label={i18n.t('searchflow.companycarcount')}
                        type="number"
                        value={companyCar.carcount || ''}
                        onChange={handleCompanyCarCountChange}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        inputProps={{ min: 0 }}
                        onWheel={(e) => e.target.blur()}
                    />
                )}
            </div>

            {/* CASH REGISTER */}
            <div className='SFInput'>
                <p className='SFIndustrySpecSubTitle'>
                    {i18n.t('searchflow.cashregisterquestion')}
                    <span className='questionTracker'>6d/7</span>
                </p>
                <RadioGroup
                    value={cashRegister.status}
                    onChange={handleCashRegisterChange}
                >
                    <FormControlLabel
                        className='SFRadioInput'
                        value="true"
                        control={<Radio />}
                        label={i18n.t('general.yes')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="flase"
                        control={<Radio />}
                        label={i18n.t('general.no')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="idk"
                        control={<Radio />}
                        label={i18n.t('general.idk')}
                        labelPlacement="start"
                    />
                </RadioGroup>
                {cashRegister.status === 'true' && (
                    <TextField
                        label={i18n.t('searchflow.cashregistercount')}
                        type="number"
                        value={cashRegister.cashregistercount || ''}
                        onChange={handleCashRegisterCountChange}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        inputProps={{ min: 0 }}
                        onWheel={(e) => e.target.blur()}
                    />
                )}
            </div>

            {/* MULTIPLE ACCOUNTS */}
            <div className='SFInput'>
                <p className='SFIndustrySpecSubTitle'>
                    {i18n.t('searchflow.multipleaccountsquestion')}
                    <span className='questionTracker'>6e/7</span>
                </p>
                <RadioGroup
                    value={multipleAccounts.status}
                    onChange={handleMultipleAccountsChange}
                >
                    <FormControlLabel
                        className='SFRadioInput'
                        value="false"
                        control={<Radio />}
                        label={i18n.t('searchflow.singleaccount')}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        className='SFRadioInput'
                        value="true"
                        control={<Radio />}
                        label={i18n.t('searchflow.multipleaccounts')}
                        labelPlacement="start"
                    />
                </RadioGroup>
                {multipleAccounts.status === 'true' && (
                    <TextField
                        label={i18n.t('searchflow.accountcount')}
                        type="number"
                        value={multipleAccounts.accountcount || ''}
                        onChange={handleAccountCountChange}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        inputProps={{ min: 0 }}
                        onWheel={(e) => e.target.blur()}
                    />
                )}

                {/* CUSTOMER TYPE */}
                <div className='SFInput'>
                    <p className='SFIndustrySpecSubTitle'>
                        {i18n.t('searchflow.customertypequestion')}
                        <span className='questionTracker'>6f/7</span>
                    </p>
                    <RadioGroup
                        value={customerType.selectedoption}
                        onChange={handleCustomerTypeChange}
                    >
                        <FormControlLabel
                            className='SFRadioInput'
                            value="private"
                            control={<Radio />}
                            label={i18n.t('searchflow.privatecustomers')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            value="mainlyprivate"
                            control={<Radio />}
                            label={i18n.t('searchflow.mainlyprivatecustomers')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            value="mixed"
                            control={<Radio />}
                            label={i18n.t('searchflow.mixedcustomers')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            value="business"
                            control={<Radio />}
                            label={i18n.t('searchflow.businesscustomers')}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className='SFRadioInput'
                            value="idk"
                            control={<Radio />}
                            label={i18n.t('general.idk')}
                            labelPlacement="start"
                        />
                    </RadioGroup>
                </div>
            </div>
            <Navigation
                step={13}
                handleBack={handleBack}
                handleNext={handleNext}
                openHelpForm={() => props.openHelpForm()}
            />
        </div>
    );
};

export default withTranslation()(IndustrySpecificGeneral);
