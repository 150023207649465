import React from 'react';
import Rating from '@mui/material/Rating';
import { Avatar } from '@mui/material';
import { experienceRating } from '../../../../searchFlow/data/searchFlowText';
import { useSelector } from 'react-redux';
// import shieldCheck from '../../../../../images/shield-check.png';
import ApprovedProvCard from '../../../assets/DlpApprovedProvCard.svg';
import FullyOnline from '../../../assets/DlpFullyOnline.svg';
import DigitalAccountant from '../../../assets/DlpDigitalAccountant.svg';
import DlpMUIProfileImageDefault from '../../../assets/DlpMUIProfileImageDefault.svg';
// import checkcircle from '../../../../../images/check-circle-small.png';
// import award from '../../../../../images/award.png';
import buildings from '../../../../../images/icons/buildings.svg';
import StyledButton from '../../../../../shared/components/styledButton/styledButton';
import i18n from '../../../../../utils/i18n';
import { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';

const CardContentWeb = ({ data, setOpenForward, ...props }) => {
	const industrylabels = {
		construction: 'Építőipar',
		engineer: 'Mérnök',
		vehicle: 'Gépjármű Ipar',
		manufacturing: 'Gyártás és Feldolgozó Ipar',
		IT: 'Informatika',
		realestate: 'Ingatlankereskedelem',
		// trade: 'Kis-és nagykereskedelem',
		retail: 'Kiskereskedelem',
		wholesale: 'Nagykereskedelem',
		agriculture: 'Mező- és erdőgazdálkodás, halászat',
		education: 'Oktatás, Kutatás',
		art: 'Művészet',
		medicine: 'Egészségügy',
		beauty: 'Szépségipar',
		finance: 'Pénzügy, biztosítás',
		travel: 'Szállás, Vendéglátás',
		logistics: 'Szállítás, raktározás',
		courier: 'Futár',
		otherservices: 'Egyéb',
		etrade: 'E-kereskedelem',
		startup: 'Startup',
		lawyer: 'Ügyvédi iroda',
		sport: 'Sport',
		other: 'Egyéb',
		cantdecide: 'Nem tudok ezek közül választani',
		MK: 'Magyar Könyvelők',
		OET: 'Országos Egyesülete Tag',
		certified: 'Mérlegképes',
		accountant: 'könyvelő',
	};

	//console.log(data, 'PROVIDERDATA');
	return (
		<>
			<div className="dlpProviderCardSection">
				<div className="dlpProviderCardHeader">
					<img
						src={data.providerImage || DlpMUIProfileImageDefault}
						alt="profile"
						style={{
							width: '48px',
							height: '48px',
							position: 'relative',
							objectFit: 'cover',
							objectPosition: 'top',
							borderRadius: '50%',
						}}
					/>
					<div>
						<p className="dlpProvHeaderTexts">
							<span style={{ fontSize: '16px' }} className="dlpBold16">
								{data.providerName}
							</span>{' '}
							<span>
								<Rating
									name="half-rating-read"
									value={data.reviewAvg}
									precision={0.1}
									readOnly
									size="medium"
								/>
								{data.reviewNr > 0 && (
									<>
										{data.reviewAvg} ({data.reviewNr})
									</>
								)}
							</span>
						</p>
						<p className="dlpText12">
							<img src={buildings} alt="buildings" />{' '}
							{data.packageSize === ''
								? i18n.t('general.accountant')
								: data.packageSize === 'entrepreneur'
								? i18n.t('provider.entrepreneuracc')
								: data.packageSize === 'small_office'
								? i18n.t('provider.small_acc_office')
								: i18n.t('provider.large_acc_office')}
						</p>
					</div>
					{data.packageType === 'premium' && (
						<img
							className="dlpApprovedProvCard"
							alt="approved prov card"
							src={ApprovedProvCard}
						/>
					)}
				</div>
			</div>
			<div className="dlpExpContainer">
				{data.providerExperience.length > 0 &&
					data.providerExperience.map((expItem, idx) => {
						if (idx < 4) {
							return (
								<span key={idx} className="dlpExpItem">
									{industrylabels[expItem]}
								</span>
							);
						} else return null;
					})}
				{data.providerExperience.length > 3 && (
					<span className="dlpExpItem">
						{' '}
						+ {data.providerExperience.length - 4}{' '}
					</span>
				)}
			</div>

			<hr style={{ margin: '6px 0px !important' }} />
			<div className="dlpProviderCardBottom">
				<div>
					<img src={FullyOnline} alt="fully online" className="fullyOnline" />{' '}
					<span className="dlpText12">{i18n.t('general.fullyonline')}</span>
					{data.providerBadge !== 'Nem minősített' && (
						<>
							<br />
							<img
								src={DigitalAccountant}
								alt="digital accountant"
								className="digitalAccountant"
							/>{' '}
							<span className="dlpText12">
								{i18n.t('general.digitalaccountant')}
							</span>
						</>
					)}
				</div>
				<StyledButton
					onClick={(e) => {
						setOpenForward(true);
						e.stopPropagation();
						// dataLayerUpdate(data.providerName);
						// localStorage.setItem('selectedSP', data.providerName);
						// window.location.replace(`${FRONT_END_URL}/company/request/1`);
					}}
					color={'NewReqBtn'}>
					{i18n.t('providerdashboard.requestcapital')}
				</StyledButton>
			</div>
		</>
	);
};

export default CardContentWeb;
