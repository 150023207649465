import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import i18n from '../../utils/i18n';
import './providerVafForm.scss';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Slider,
  Box,
} from '@mui/material';

import Alert from '@mui/material/Alert';
import StyledButton from '../../shared/components/styledButton/styledButton';
import dayjs from 'dayjs';
import blueaccountant from '../../images/BlueAccountant.png';
import LoadingIndicator from '../../shared/LoadingIndicator/loadingIndicator';

const BACKEND_API = process.env.REACT_APP_BACKEND_API;

const RequestFormUpdate = () => {
  const { id } = useParams();
  const [tempFormData, setTempFormData] = useState({
    services: [],
    languages: {},
  });
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [servicesPicked, setServicesPicked] = useState([]);
  const [languagesPicked, setLanguagesPicked] = useState({});
  const [industryinput, setIndustryinput] = useState('');
  const [errors, setErrors] = useState({});
  const [formValid, setFormValid] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sliderMarks = [
    { value: 1, label: 'könnyű' },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5, label: 'nehéz' },
  ];

  const fetchData = (url, method, data) => {
    let fetchParams = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    return fetch(url, fetchParams);
  };

  const fetchData2 = (url, method, jwt, data) => {
    let fetchParams = {
      method: method,
      headers: {
        'Content-Type': 'application/json',

        Authorization: `Bearer ${jwt}`,
      },
    };
    data && (fetchParams.body = JSON.stringify(data));
    return fetch(url, fetchParams);
  };

  //console.log(tempFormData, 'tempFormData');

  useEffect(() => {
    setTempFormData((prevState) => ({
      ...prevState,
      services: servicesPicked,
    }));
  }, [servicesPicked]);

  useEffect(() => {
    setTempFormData((prevState) => ({
      ...prevState,
      languages: languagesPicked,
    }));
  }, [languagesPicked]);

  const fetchDataDetails = async () => {
    try {
      const response = await fetch(`${BACKEND_API}/api/aspreq/${id}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setTempFormData({
        ...data,
        chosendate: data.chosendate.toString(),
        yearlyrevenue: data.yearlyrevenue.toString(),
        incominginvoices: data.incominginvoices.toString(),
        industry: data.industry.toString(),
        companytype: data.companytype,
        taxform: data.taxform,
        vatFilter:
          data.vatFilter === 'true' || data.vatFilter === true
            ? true
            : data.vatFilter === 'false' || data.vatFilter === false
            ? false
            : '',
        vatPayment: data.vatPayment,
        foreign_trade:
          data.foreign_trade === 'true' || data.foreign_trade === true
            ? true
            : data.foreign_trade === 'false' || data.foreign_trade === false
            ? false
            : '',
        foreigntradeEU: data.foreigntradeEU,
        industryinput: data.industryinput || '',
        wouldProviderTakeit:
          data.wouldProviderTakeit === 'true' ||
          data.wouldProviderTakeit === true
            ? true
            : data.wouldProviderTakeit === 'false' ||
              data.wouldProviderTakeit === false
            ? false
            : '',
      });
      setServicesPicked(data.services);
      setLanguagesPicked(data.languages);
      if (data.industry === 'otherservices') {
        setIndustryinput(data.industryinput || '');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchDataDetails();
    }
  }, [id]);

  useEffect(() => {
    const isFormValid = validateForm();
    setFormValid(isFormValid);
  }, [tempFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === 'noofemployees' && value < 0) {
      newValue = 0;
    }

    if (
      name === 'vatFilter' ||
      name === 'foreign_trade' ||
      name === 'wouldProviderTakeit'
    ) {
      newValue =
        value === 'true' || value === 'false' ? value === 'true' : value;
    }

    if (name === 'industry') {
      if (newValue === 'otherservices') {
        setIndustryinput('');
        setTempFormData({
          ...tempFormData,
          [name]: newValue,
          industryinput: '',
        });
      } else {
        setTempFormData({
          ...tempFormData,
          [name]: newValue,
          industryinput: '',
        });
      }
    } else if (name === 'vatFilter') {
      setTempFormData({
        ...tempFormData,
        [name]: newValue,
        vatPayment: newValue ? tempFormData.vatPayment : '',
      });
      setErrors({ ...errors, vatPayment: false });
    } else if (name === 'foreign_trade') {
      setTempFormData({
        ...tempFormData,
        [name]: newValue,
        foreigntradeEU: newValue ? tempFormData.foreigntradeEU : '',
      });
      setErrors({ ...errors, foreigntradeEU: false });
    } else {
      setTempFormData({ ...tempFormData, [name]: newValue });
    }
  };

  const handleOtherIndustryChange = (e) => {
    setIndustryinput(e.target.value);
    setTempFormData({ ...tempFormData, industryinput: e.target.value });
  };

  const handleServiceChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setServicesPicked((prevServices) => [...prevServices, value]);
    } else {
      setServicesPicked((prevServices) =>
        prevServices.filter((service) => service !== value)
      );
    }
  };

  const handleLanguageChange = (event) => {
    const { value, checked } = event.target;
    setTempFormData((prevFormData) => ({
      ...prevFormData,
      languages: {
        ...prevFormData.languages,
        [value]: checked,
      },
    }));
  };

  const saveData = async (isVafCompletedValue) => {
    try {
      const response = await fetch(`${BACKEND_API}/api/aspreq/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          updateParams: {
            ...tempFormData,
            vatFilter: Boolean(tempFormData.vatFilter),
            foreign_trade: Boolean(tempFormData.foreign_trade),
            wouldProviderTakeit: Boolean(tempFormData.wouldProviderTakeit),
            isVafCompleted: isVafCompletedValue,
            leadtype: 'vaf',
            industryinput:
              tempFormData.industry === 'otherservices' ? industryinput : '',
          },
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return true;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    await saveData(false);
    setSaveSuccess(true);
    setTimeout(() => setSaveSuccess(false), 3000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Form is valid');
      setIsLoading(true);
      const updateResult = await saveData(true);
      if (updateResult) {
        await updateDueDate(id, {
          date: dayjs(Date.now()),
          todo: 'VAF lezárult, emailt küldeni',
        });
        setTempFormData((prevFormData) => ({
          ...prevFormData,
          isVafCompleted: true,
        }));
        await sendMailOnDone(tempFormData);
        setIsLoading(false);
        setAlertOn(true);
        setTimeout(() => {
          setAlertOn(false);
          setUpdateSuccess(false);
        }, 3000);
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!tempFormData.companyctcname) newErrors.companyctcname = true;
    if (!tempFormData.email) newErrors.email = true;
    if (!tempFormData.companyPhone) newErrors.companyPhone = true;
    if (!tempFormData.zip_code) newErrors.zip_code = true;
    if (!tempFormData.chosendate) newErrors.chosendate = true;
    if (!tempFormData.reqMessage) newErrors.reqMessage = true;

    if (
      tempFormData.noofemployees === null ||
      tempFormData.noofemployees === undefined
    )
      newErrors.noofemployees = true;
    if (!tempFormData.yearlyrevenue) newErrors.yearlyrevenue = true;
    if (!tempFormData.incominginvoices) newErrors.incominginvoices = true;
    if (!tempFormData.outgoinginvoices) newErrors.outgoinginvoices = true;
    if (!tempFormData.industry) newErrors.industry = true;
    if (tempFormData.industry === 'otherservices' && !industryinput)
      newErrors.industryinput = true;
    if (!tempFormData.companytype) newErrors.companytype = true;
    if (
      (tempFormData.companytype === 'bt' ||
        tempFormData.companytype === 'kft') &&
      !tempFormData.taxform
    )
      newErrors.taxform = true;
    if (tempFormData.vatFilter === '') newErrors.vatFilter = true;
    if (tempFormData.vatFilter && !tempFormData.vatPayment)
      newErrors.vatPayment = true;

    if (tempFormData.foreign_trade === '') newErrors.foreign_trade = true;
    if (tempFormData.foreign_trade && !tempFormData.foreigntradeEU)
      newErrors.foreigntradeEU = true;
    if (tempFormData.wouldProviderTakeit === '')
      newErrors.wouldProviderTakeit = true;

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const services = [
    {
      value: 'legalentityfounding',
      label: i18n.t('providervafform.legalentityfounding'),
    },
    // { value: 'soleproprietorshipfounding', label: i18n.t('providervafform.soleproprietorshipfounding') },
    // { value: 'partnershipfounding', label: i18n.t('providervafform.partnershipfounding') },
    { value: 'accounting', label: i18n.t('providervafform.accounting') },
    { value: 'payroll', label: i18n.t('providervafform.payroll') },
  ];

  const languages = [
    { value: 'english', label: i18n.t('providervafform.english') },
    { value: 'german', label: i18n.t('providervafform.german') },
    { value: 'french', label: i18n.t('providervafform.french') },
    { value: 'spanish', label: i18n.t('providervafform.spanish') },
    { value: 'romanian', label: i18n.t('providervafform.romanian') },
    { value: 'chinese', label: i18n.t('providervafform.chinese') },
    { value: 'russian', label: i18n.t('providervafform.russian') },
  ];

  const updateDueDate = async (reqID, newTodo) => {
    await fetchData2(
      `${BACKEND_API}/api/aspreq/duedate/${reqID}`,
      'PATCH',
      localStorage.getItem('JWT'),
      {
        newTodo,
      }
    )
      .then(async (response) => {
        let resp = await response.json();

        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const sendMailOnDone = async (data) => {
    console.log('funkcion belul');
    //   try {
    //     console.log('tryon belul');
    //     const response = await fetch(`${BACKEND_API}/api/aspreq/vafmail/new`, {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify(data),
    //     });
    //     console.log(response, 'resp');
    //     if (!response.ok) {
    //       throw new Error('Network response was not ok');
    //     }
    //     const resp = await response.json();
    //     console.log(resp, 'resp');
    //   } catch (error) {
    //     console.error('Error:', error);
    //   }
    // };
    await fetchData(`${BACKEND_API}/api/aspreq/vafmail/new`, 'POST', data)
      .then((response) => {
        console.log('INSIDE THEN');
        let resp = response.json();

        console.log(resp, 'RISZPONZ!!!');
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  return (
    <>
      <div className='vafFormContainer'>
        <div className='vafForm'>
          {updateSuccess ? (
            <div>
              <div className='vafFormContentHeader'>
                <div className='vafTitle'>
                  Vállalkozási- és adózási <br></br>
                  formaválasztó tanácsadás
                </div>
                <div>
                  <img
                    className='vafFormHeaderImg'
                    alt='header'
                    src={blueaccountant}
                  />
                </div>
              </div>

              {isLoading && (
                <div className='vafFormOverlay'>
                  <LoadingIndicator text={i18n.t('general.loading2')} />
                </div>
              )}
              {alertOn && (
                <div className='vafFormOverlay'>
                  <Alert severity='success' className='vafFormOverlayMessage'>
                    {i18n.t('providervafform.updatesuccess')}
                  </Alert>
                </div>
              )}

              <div className='vafFormContent'>
                <label className='vafLabel'>
                  {i18n.t('providervafform.vafformservices')}
                </label>
                <FormControl
                  component='fieldset'
                  className='vafCheckboxContainer'
                  variant='outlined'
                  error={errors.services}
                  disabled={tempFormData.isVafCompleted}
                >
                  <FormGroup>
                    {services.map((service) => (
                      <FormControlLabel
                        key={service.value}
                        label={service.label}
                        labelPlacement='start'
                        control={
                          <Checkbox
                            checked={servicesPicked.indexOf(service.value) > -1}
                            onChange={handleServiceChange}
                            value={service.value}
                            classes={{ root: 'vafCheckbox' }}
                          />
                        }
                        classes={{
                          root:
                            servicesPicked.indexOf(service.value) > -1
                              ? 'vafFormControlLabelChecked'
                              : 'vafFormControlLabel',
                        }}
                      />
                    ))}
                  </FormGroup>
                </FormControl>

                <label htmlFor='providerHowWasIt' className='vafLabel'>
                  {i18n.t('providervafform.providerhowwasit')}
                </label>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Box style={{ width: '250px' }}>
                    <Slider
                      aria-label='Temperature'
                      defaultValue={parseInt(tempFormData.providerHowWasIt)}
                      getAriaValueText={(value) => `${value}`}
                      valueLabelDisplay='auto'
                      step={1}
                      marks={sliderMarks}
                      onChange={(e) => {
                        setTempFormData((prevFormData) => ({
                          ...prevFormData,
                          providerHowWasIt: e.target.value,
                        }));
                      }}
                      min={1}
                      max={5}
                      id='providerHowWasIt'
                      name='providerHowWasIt'
                    />
                  </Box>
                </div>

                <label htmlFor='wouldProviderTakeit' className='vafLabel'>
                  {i18n.t('providervafform.wouldprovidertakeit')}
                </label>
                <FormControl
                  variant='outlined'
                  error={errors.wouldProviderTakeit}
                  disabled={tempFormData.isVafCompleted}
                >
                  <Select
                    id='wouldProviderTakeit'
                    name='wouldProviderTakeit'
                    className='vafSelectMenu'
                    variant='outlined'
                    onChange={handleChange}
                    value={
                      tempFormData.wouldProviderTakeit !== undefined
                        ? tempFormData.wouldProviderTakeit.toString()
                        : ''
                    }
                  >
                    <MenuItem value={true}>{i18n.t('general.yes')}</MenuItem>
                    <MenuItem value={false}>{i18n.t('general.no')}</MenuItem>
                  </Select>
                </FormControl>
                {errors.wouldProviderTakeit && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptions')}
                  </Alert>
                )}
                <label htmlFor='providerWhatToImprove' className='vafLabel'>
                  {i18n.t('providervafform.providerwhattoimprove')}
                </label>
                <TextField
                  id='providerWhatToImprove'
                  name='providerWhatToImprove'
                  type='text'
                  placeholder='Írd le pár szóban'
                  className='vafTextArea'
                  variant='outlined'
                  value={tempFormData.providerWhatToImprove}
                  onChange={handleChange}
                  multiline
                  minRows={3}
                  disabled={tempFormData.isVafCompleted}
                />
              </div>

              <div className='vafButtonContainer'>
                <>
                  <StyledButton
                    color='NewNavBack'
                    className='vafBtnMaxWidth'
                    onClick={() => setUpdateSuccess(false)}
                    isDisabled={tempFormData.isVafCompleted}
                  >
                    {i18n.t('general.backbutton')}
                  </StyledButton>

                  <StyledButton
                    color='NewReqBtn'
                    className='vafFormBtnMaxWidth'
                    onClick={async (e) => {
                      handleSubmit(e);
                    }}
                    isDisabled={tempFormData.isVafCompleted || !formValid}
                  >
                    {i18n.t('providervafform.update')}
                  </StyledButton>
                </>
              </div>
            </div>
          ) : (
            <form>
              {saveSuccess && (
                <div className='vafFormOverlay'>
                  <Alert severity='success' className='vafFormOverlayMessage'>
                    {i18n.t('providervafform.savesuccess')}
                  </Alert>
                </div>
              )}
              <div className='vafFormContentHeader'>
                <div className='vafTitle'>
                  Vállalkozási- és adózási <br></br>
                  formaválasztó tanácsadás
                </div>
                <div>
                  <img
                    className='vafFormHeaderImg'
                    alt='header'
                    src={blueaccountant}
                  />
                </div>
              </div>

              <div className='vafFormContent'>
                <label htmlFor='companyctcname' className='vafLabel'>
                  {i18n.t('providervafform.companyctcname')}
                </label>
                <TextField
                  id='companyctcname'
                  type='text'
                  name='companyctcname'
                  value={tempFormData.companyctcname}
                  onChange={handleChange}
                  className='vafTextField'
                  variant='outlined'
                  error={errors.companyctcname}
                  disabled={tempFormData.isVafCompleted}
                />
                {errors.companyctcname && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningfield')}
                  </Alert>
                )}

                <label htmlFor='email' className='vafLabel'>
                  {i18n.t('providervafform.email')}
                </label>
                <TextField
                  id='email'
                  type='email'
                  name='email'
                  value={tempFormData.email}
                  onChange={handleChange}
                  className='vafTextField'
                  variant='outlined'
                  error={errors.email}
                  disabled={tempFormData.isVafCompleted}
                />
                {errors.email && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningfield')}
                  </Alert>
                )}

                <label htmlFor='companyPhone' className='vafLabel'>
                  {i18n.t('providervafform.companyphone')}
                </label>
                <TextField
                  type='tel'
                  name='companyPhone'
                  value={tempFormData.companyPhone}
                  onChange={handleChange}
                  className='vafTextField'
                  variant='outlined'
                  error={errors.companyPhone}
                  disabled={tempFormData.isVafCompleted}
                />
                {errors.companyPhone && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningfield')}
                  </Alert>
                )}

                <label htmlFor='zip_code' className='vafLabel'>
                  {i18n.t('providervafform.zipcode')}
                </label>
                <TextField
                  type='number'
                  name='zip_code'
                  value={tempFormData.zip_code}
                  onChange={handleChange}
                  className='vafTextField'
                  variant='outlined'
                  error={errors.zip_code}
                  disabled={tempFormData.isVafCompleted}
                />
                {errors.zip_code && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningfield')}
                  </Alert>
                )}

                <label htmlFor='chosendate' className='vafLabel'>
                  {i18n.t('providervafform.chosendate')}
                </label>
                <FormControl variant='outlined' error={errors.chosendate}>
                  <Select
                    type='text'
                    id='chosendate'
                    name='chosendate'
                    className='vafSelectMenu'
                    variant='outlined'
                    onChange={handleChange}
                    value={tempFormData.chosendate || ''}
                    disabled={tempFormData.isVafCompleted}
                  >
                    <MenuItem value={'asap'}>
                      {i18n.t('providervafform.asap')}
                    </MenuItem>
                    <MenuItem value={'2weeks'}>
                      {i18n.t('providervafform.2weeks')}
                    </MenuItem>
                    <MenuItem value={'2months'}>
                      {i18n.t('providervafform.2months')}
                    </MenuItem>
                    <MenuItem value={'6months'}>
                      {i18n.t('providervafform.6months')}
                    </MenuItem>
                  </Select>
                </FormControl>
                {errors.chosendate && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptions')}
                  </Alert>
                )}

                <label htmlFor='reqMessage' className='vafLabel'>
                  {i18n.t('providervafform.reqmessage')}
                </label>
                <TextField
                  id='reqMessage'
                  name='reqMessage'
                  type='text'
                  className='vafTextArea'
                  variant='outlined'
                  value={tempFormData.reqMessage}
                  onChange={handleChange}
                  multiline
                  minRows={1}
                  error={errors.reqMessage}
                  disabled={tempFormData.isVafCompleted}
                />
                {errors.reqMessage && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningfield')}
                  </Alert>
                )}

                <label htmlFor='noofemployees' className='vafLabel'>
                  {i18n.t('providervafform.noofemployees')}
                </label>
                <TextField
                  id='noofemployees'
                  name='noofemployees'
                  className='vafTextField'
                  variant='outlined'
                  type='number'
                  onChange={handleChange}
                  value={tempFormData.noofemployees}
                  error={errors.noofemployees}
                  InputProps={{
                    inputProps: { min: 0 },
                    onWheel: (e) => e.target.blur(),
                  }}
                  disabled={tempFormData.isVafCompleted}
                />
                {errors.noofemployees && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningfield')}
                  </Alert>
                )}

                <label htmlFor='yearlyrevenue' className='vafLabel'>
                  {i18n.t('providervafform.yearlyrevenue')}
                </label>
                <FormControl
                  variant='outlined'
                  error={errors.yearlyrevenue}
                  disabled={tempFormData.isVafCompleted}
                >
                  <Select
                    id='yearlyrevenue'
                    type='text'
                    name='yearlyrevenue'
                    className='vafSelectMenu'
                    value={tempFormData.yearlyrevenue || ''}
                    onChange={handleChange}
                  >
                    <MenuItem value={5}>
                      {i18n.t('providervafform.0-5million')}
                    </MenuItem>
                    <MenuItem value={10}>
                      {i18n.t('providervafform.5-12million')}
                    </MenuItem>
                    <MenuItem value={50}>
                      {i18n.t('providervafform.12-50million')}
                    </MenuItem>
                    <MenuItem value={100}>
                      {i18n.t('providervafform.50-100million')}
                    </MenuItem>
                    <MenuItem value={200}>
                      {i18n.t('providervafform.100-200million')}
                    </MenuItem>
                    <MenuItem value={500}>
                      {i18n.t('providervafform.200-500million')}
                    </MenuItem>
                    <MenuItem value={1999}>
                      {i18n.t('providervafform.500-2000million')}
                    </MenuItem>
                    <MenuItem value={2000}>
                      {i18n.t('providervafform.2000+million')}
                    </MenuItem>
                  </Select>
                </FormControl>
                {errors.yearlyrevenue && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptions')}
                  </Alert>
                )}

                <label htmlFor='incominginvoices' className='vafLabel'>
                  {i18n.t('providervafform.incominginvoices')}
                </label>
                <FormControl
                  variant='outlined'
                  error={errors.incominginvoices}
                  disabled={tempFormData.isVafCompleted}
                >
                  <Select
                    id='incominginvoices'
                    type='text'
                    name='incominginvoices'
                    className='vafSelectMenu'
                    variant='outlined'
                    value={tempFormData.incominginvoices || ''}
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>{i18n.t('providervafform.0')}</MenuItem>
                    <MenuItem value={10}>
                      {i18n.t('providervafform.between1-10')}
                    </MenuItem>
                    <MenuItem value={20}>
                      {i18n.t('providervafform.between11-20')}
                    </MenuItem>
                    <MenuItem value={50}>
                      {i18n.t('providervafform.between21-50')}
                    </MenuItem>
                    <MenuItem value={100}>
                      {i18n.t('providervafform.between51-100')}
                    </MenuItem>
                    <MenuItem value={200}>
                      {i18n.t('providervafform.between101-200')}
                    </MenuItem>
                    <MenuItem value={500}>
                      {i18n.t('providervafform.between201-500')}
                    </MenuItem>
                    <MenuItem value={1000}>
                      {i18n.t('providervafform.between501-1000')}
                    </MenuItem>
                    <MenuItem value={2000}>
                      {i18n.t('providervafform.above1000')}
                    </MenuItem>
                  </Select>
                </FormControl>
                {errors.incominginvoices && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptions')}
                  </Alert>
                )}

                <label htmlFor='outgoinginvoices' className='vafLabel'>
                  {i18n.t('providervafform.outgoinginvoices')}
                </label>
                <FormControl
                  variant='outlined'
                  error={errors.outgoinginvoices}
                  disabled={tempFormData.isVafCompleted}
                >
                  <Select
                    id='outgoinginvoices'
                    type='text'
                    name='outgoinginvoices'
                    className='vafSelectMenu'
                    variant='outlined'
                    value={tempFormData.outgoinginvoices || ''}
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>{i18n.t('providervafform.0')}</MenuItem>
                    <MenuItem value={10}>
                      {i18n.t('providervafform.between1-10')}
                    </MenuItem>
                    <MenuItem value={20}>
                      {i18n.t('providervafform.between11-20')}
                    </MenuItem>
                    <MenuItem value={50}>
                      {i18n.t('providervafform.between21-50')}
                    </MenuItem>
                    <MenuItem value={100}>
                      {i18n.t('providervafform.between51-100')}
                    </MenuItem>
                    <MenuItem value={200}>
                      {i18n.t('providervafform.between101-200')}
                    </MenuItem>
                    <MenuItem value={500}>
                      {i18n.t('providervafform.between201-500')}
                    </MenuItem>
                    <MenuItem value={1000}>
                      {i18n.t('providervafform.between501-1000')}
                    </MenuItem>
                    <MenuItem value={2000}>
                      {i18n.t('providervafform.above1000')}
                    </MenuItem>
                    {/* <MenuItem value={0}>
                      {i18n.t('providervafform.hasnoidea')}
                    </MenuItem> */}
                  </Select>
                </FormControl>
                {errors.outgoinginvoices && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptions')}
                  </Alert>
                )}

                <label htmlFor='industry' className='vafLabel'>
                  {i18n.t('providervafform.industry')}
                </label>
                <FormControl
                  variant='outlined'
                  error={errors.industry}
                  disabled={tempFormData.isVafCompleted}
                >
                  <Select
                    id='industry'
                    name='industry'
                    className='vafSelectMenu'
                    variant='outlined'
                    onChange={handleChange}
                    value={tempFormData.industry || ''}
                  >
                    <MenuItem value={'IT'}>
                      {i18n.t('providervafform.it')}
                    </MenuItem>
                    <MenuItem value={'construction'}>
                      {i18n.t('providervafform.construction')}
                    </MenuItem>
                    <MenuItem value={'etrade'}>
                      {i18n.t('providervafform.etrade')}
                    </MenuItem>
                    {/* <MenuItem value={'trade'}>
                      {i18n.t('providervafform.trade')}
                    </MenuItem> */}
                    <MenuItem value={'retail'}>
                      {i18n.t('providervafform.retail')}
                    </MenuItem>
                    <MenuItem value={'wholesale'}>
                      {i18n.t('providervafform.wholesale')}
                    </MenuItem>
                    <MenuItem value={'art'}>
                      {i18n.t('providervafform.art')}
                    </MenuItem>
                    <MenuItem value={'education'}>
                      {i18n.t('providervafform.education')}
                    </MenuItem>
                    <MenuItem value={'medicine'}>
                      {i18n.t('providervafform.medicine')}
                    </MenuItem>
                    <MenuItem value={'vehicle'}>
                      {i18n.t('providervafform.vehicle')}
                    </MenuItem>
                    <MenuItem value={'manufacturing'}>
                      {i18n.t('providervafform.manufacturing')}
                    </MenuItem>
                    <MenuItem value={'realestate'}>
                      {i18n.t('providervafform.realestate')}
                    </MenuItem>
                    <MenuItem value={'agriculture'}>
                      {i18n.t('providervafform.agriculture')}
                    </MenuItem>
                    <MenuItem value={'finance'}>
                      {i18n.t('providervafform.finance')}
                    </MenuItem>
                    <MenuItem value={'travel'}>
                      {i18n.t('providervafform.travel')}
                    </MenuItem>
                    <MenuItem value={'startup'}>
                      {i18n.t('providervafform.startup')}
                    </MenuItem>
                    <MenuItem value={'logistics'}>
                      {i18n.t('providervafform.logistics')}
                    </MenuItem>
                    <MenuItem value={'courier'}>
                      {i18n.t('providervafform.courier')}
                    </MenuItem>
                    <MenuItem value={'beauty'}>
                      {i18n.t('providervafform.beauty')}
                    </MenuItem>
                    <MenuItem value={'otherservices'}>
                      {i18n.t('providervafform.otherservices')}
                    </MenuItem>
                    <MenuItem value={'lawyer'}>
                      {i18n.t('providervafform.lawyer')}
                    </MenuItem>
                    <MenuItem value={'sport'}>
                      {i18n.t('providervafform.sport')}
                    </MenuItem>
                  </Select>
                </FormControl>
                {errors.industry && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptions')}
                  </Alert>
                )}

                {tempFormData.industry === 'otherservices' && (
                  <>
                    <label htmlFor='industryinput' className='vafLabel'>
                      {i18n.t('providervafform.industryinput')}
                    </label>
                    <TextField
                      id='industryinput'
                      name='industryinput'
                      type='text'
                      value={industryinput}
                      onChange={handleOtherIndustryChange}
                      multiline
                      minRows={1}
                      className='vafTextArea'
                      variant='outlined'
                      error={errors.industryinput}
                      disabled={tempFormData.isVafCompleted}
                    />
                    {errors.industryinput && (
                      <Alert severity='warning'>
                        {i18n.t('providervafform.warningfield')}
                      </Alert>
                    )}
                  </>
                )}

                <label htmlFor='companytype' className='vafLabel'>
                  {i18n.t('providervafform.companytype')}
                </label>
                <FormControl
                  variant='outlined'
                  error={errors.companytype}
                  disabled={tempFormData.isVafCompleted}
                >
                  <Select
                    id='companytype'
                    type='text'
                    name='companytype'
                    className='vafSelectMenu'
                    variant='outlined'
                    onChange={handleChange}
                    value={tempFormData.companytype || ''}
                  >
                    <MenuItem value={'singleprimary'}>
                      {i18n.t('providervafform.singleprimary')}
                    </MenuItem>
                    <MenuItem value={'kiva'}>
                      {i18n.t('providervafform.kivaatalany')}
                    </MenuItem>
                    <MenuItem value={'singlesecondary'}>
                      {i18n.t('providervafform.singlesecondary')}
                    </MenuItem>
                    <MenuItem value={'vszja'}>
                      {i18n.t('providervafform.vszja')}
                    </MenuItem>
                    <MenuItem value={'bt'}>
                      {i18n.t('providervafform.bt')}
                    </MenuItem>
                    <MenuItem value={'kft'}>
                      {i18n.t('providervafform.kft')}
                    </MenuItem>
                    <MenuItem value={'zrt'}>
                      {i18n.t('providervafform.zrt')}
                    </MenuItem>
                    <MenuItem value={'kkt'}>
                      {i18n.t('providervafform.kkt')}
                    </MenuItem>
                    <MenuItem value={'nyrt'}>
                      {i18n.t('providervafform.nyrt')}
                    </MenuItem>
                    <MenuItem value={'condo'}>
                      {i18n.t('providervafform.condo')}
                    </MenuItem>
                    <MenuItem value={'citycouncil'}>
                      {i18n.t('providervafform.citycouncil')}
                    </MenuItem>
                    <MenuItem value={'civil'}>
                      {i18n.t('providervafform.civil')}
                    </MenuItem>
                  </Select>
                </FormControl>
                {errors.companytype && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptions')}
                  </Alert>
                )}

                {(tempFormData.companytype === 'bt' ||
                  tempFormData.companytype === 'kft') && (
                  <div>
                    <label htmlFor='taxform' className='vafLabel'>
                      {i18n.t('providervafform.taxform')}
                    </label>
                    <FormControl
                      variant='outlined'
                      error={errors.taxform}
                      style={{ width: '100%' }}
                      disabled={tempFormData.isVafCompleted}
                    >
                      <Select
                        type='text'
                        id='taxform'
                        name='taxform'
                        className='vafSelectMenu'
                        variant='outlined'
                        onChange={handleChange}
                        value={tempFormData.taxform || ''}
                      >
                        <MenuItem value={'kiva'}>
                          {i18n.t('providervafform.kiva')}
                        </MenuItem>
                        <MenuItem value={'tao'}>
                          {i18n.t('providervafform.tao')}
                        </MenuItem>
                        <MenuItem value={'idk'}>
                          {i18n.t('providervafform.idk')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {errors.taxform && (
                      <Alert severity='warning'>
                        {i18n.t('providervafform.warningoptions')}
                      </Alert>
                    )}
                  </div>
                )}

                <label htmlFor='vatFilter' className='vafLabel'>
                  {i18n.t('providervafform.vatfilter')}
                </label>
                <FormControl
                  variant='outlined'
                  error={errors.vatFilter}
                  disabled={tempFormData.isVafCompleted}
                >
                  <Select
                    type='text'
                    id='vatFilter'
                    name='vatFilter'
                    className='vafSelectMenu'
                    variant='outlined'
                    onChange={handleChange}
                    // value={(() => {
                    //   if (tempFormData.vatFilter === true) {
                    //     return 'true';
                    //   } else if (tempFormData.vatFilter === false) {
                    //     return 'false';
                    //   } else {
                    //     return '';
                    //   }
                    // })()}
                    value={
                      tempFormData.vatFilter !== undefined
                        ? tempFormData.vatFilter.toString()
                        : ''
                    }
                  >
                    <MenuItem value={true}>
                      {i18n.t('providervafform.yes')}
                    </MenuItem>
                    <MenuItem value={false}>
                      {i18n.t('providervafform.no')}
                    </MenuItem>
                  </Select>
                </FormControl>
                {errors.vatFilter && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptions')}
                  </Alert>
                )}

                {tempFormData.vatFilter &&
                  tempFormData.vatFilter !== 'false' && (
                    <>
                      <label htmlFor='vatPayment' className='vafLabel'>
                        {i18n.t('providervafform.vatpayment')}
                      </label>
                      <FormControl
                        variant='outlined'
                        error={errors.vatPayment}
                        disabled={tempFormData.isVafCompleted}
                      >
                        <Select
                          type='text'
                          id='vatPayment'
                          name='vatPayment'
                          className='vafSelectMenu'
                          variant='outlined'
                          onChange={handleChange}
                          value={tempFormData.vatPayment || ''}
                          error={errors.vatPayment}
                        >
                          <MenuItem value={'monthly'}>
                            {i18n.t('providervafform.monthly')}
                          </MenuItem>
                          <MenuItem value={'quarterly'}>
                            {i18n.t('providervafform.quarterly')}
                          </MenuItem>
                          <MenuItem value={'yearly'}>
                            {i18n.t('providervafform.yearly')}
                          </MenuItem>
                          <MenuItem value={'idk'}>
                            {i18n.t('providervafform.idk')}
                          </MenuItem>
                        </Select>
                      </FormControl>
                      {errors.vatPayment && (
                        <Alert severity='warning'>
                          {i18n.t('providervafform.warningoptions')}
                        </Alert>
                      )}
                    </>
                  )}

                <label className='vafLabel'>
                  {i18n.t('providervafform.languages')}
                </label>
                <FormControl
                  component='fieldset'
                  className='vafCheckboxContainer'
                  variant='outlined'
                  error={errors.languages}
                  disabled={tempFormData.isVafCompleted}
                >
                  <FormGroup>
                    {languages.map((language) => (
                      <FormControlLabel
                        key={language.value}
                        label={language.label}
                        control={
                          <Checkbox
                            checked={
                              tempFormData.languages[language.value] || false
                            }
                            onChange={handleLanguageChange}
                            value={language.value}
                            classes={{ root: 'vafCheckbox' }}
                          />
                        }
                        classes={{
                          root: tempFormData.languages[language.value]
                            ? 'vafFormControlLabelChecked'
                            : 'vafFormControlLabel',
                        }}
                        labelPlacement='start'
                      />
                    ))}
                  </FormGroup>
                </FormControl>
                {errors.languages && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptionsatleastone')}
                  </Alert>
                )}

                <label htmlFor='foreign_trade' className='vafLabel'>
                  {i18n.t('providervafform.foreigntrade')}
                </label>
                <FormControl
                  variant='outlined'
                  error={errors.foreign_trade}
                  disabled={tempFormData.isVafCompleted}
                >
                  <Select
                    type='text'
                    id='foreign_trade'
                    name='foreign_trade'
                    className='vafSelectMenu'
                    variant='outlined'
                    onChange={handleChange}
                    value={
                      tempFormData.foreign_trade !== undefined
                        ? tempFormData.foreign_trade.toString()
                        : ''
                    }
                  >
                    <MenuItem value={true}>
                      {i18n.t('providervafform.yes')}
                    </MenuItem>
                    <MenuItem value={false}>
                      {i18n.t('providervafform.no')}
                    </MenuItem>
                  </Select>
                </FormControl>
                {errors.foreign_trade && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptions')}
                  </Alert>
                )}

                {tempFormData.foreign_trade !== null &&
                tempFormData.foreign_trade !== undefined &&
                tempFormData.foreign_trade === true ? (
                  <>
                    <label htmlFor='foreigntradeEU' className='vafLabel'>
                      {i18n.t('providervafform.foreigntradeeu')}
                    </label>
                    <FormControl
                      variant='outlined'
                      error={errors.foreigntradeEU}
                      disabled={tempFormData.isVafCompleted}
                    >
                      <Select
                        type='text'
                        id='foreigntradeEU'
                        name='foreigntradeEU'
                        className='vafSelectMenu'
                        variant='outlined'
                        onChange={handleChange}
                        value={tempFormData.foreigntradeEU || ''}
                      >
                        <MenuItem value={'inside'}>
                          {i18n.t('providervafform.inside')}
                        </MenuItem>
                        <MenuItem value={'outside'}>
                          {i18n.t('providervafform.outside')}
                        </MenuItem>
                        <MenuItem value={'both'}>
                          {i18n.t('providervafform.both')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {errors.foreigntradeEU && (
                      <Alert severity='warning'>
                        {i18n.t('providervafform.warningoptions')}
                      </Alert>
                    )}
                  </>
                ) : null}
              </div>

              <div className='vafButtonContainer'>
                {tempFormData.isVafCompleted ? (
                  <div className='vafFormClosed'>
                    {i18n.t('providervafform.vafformclosed')}
                  </div>
                ) : (
                  <>
                    <StyledButton
                      color='NewNavBack'
                      className='vafBtnMaxWidth'
                      onClick={(e) => handleSave(e)}
                      isDisabled={tempFormData.isVafCompleted}
                    >
                      {i18n.t('providervafform.save')}
                    </StyledButton>

                    <StyledButton
                      color='NewReqBtn'
                      className='vafFormBtnMaxWidth'
                      onClick={async (e) => {
                        e.preventDefault();
                        setUpdateSuccess(true);
                      }}
                      // isDisabled={tempFormData.isVafCompleted || !formValid}
                    >
                      {i18n.t('general.forwardbutton')}
                    </StyledButton>
                  </>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default RequestFormUpdate;
