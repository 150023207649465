import React, { useEffect, useCallback, useState, useRef } from 'react';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
//import DialogContent from '@mui/material/DialogContent';
import { TextField, Button } from '@mui/material';
// import { Avatar } from '@mui/material';
// import StarRateIcon from '@mui/icons-material/StarRate';
//import { fakeProfiles } from '../data/searchFlowText';
import Navigation from '../components/navigation/navigation';
import SendReq from '../components/sendReq/sendReq';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
// import NoProviderFound from './noProviderFound';
import VafContactForm from './vafContactForm';
import CheckboxInput3 from '../components/searchInputs/checkboxInput3';
import makeStyles from '@mui/styles/makeStyles';
import ACTIONS from '../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import LoadingIndicator from '../../../shared/LoadingIndicator/loadingIndicator';
import winner from '../../../images/winner.png';
import mobileWinner from '../../../images/mobileWinner.png';
import tag from '../../../images/tag.png';
//import logo from '../../../images/logo.png';
import shield from '../../../images/shield.png';
import wallet from '../../../images/wallet.png';
import check from '../../../images/check-circle.png';
import checkmark from '../../../images/check.png';
import switchon from '../../../images/switchon2.png';
import switchoff from '../../../images/switchoff2.png';
import info from '../../../images/info.png';
import times from '../../../images/times.png';
// import furtheravatars from '../../../images/furtheravatars.png';
import { accServiceInput, singleServiceInput } from '../data/searchFlowText';
import axios from '../../utils/axiosCompanyProxy';
import Snackbar from '@mui/material/Snackbar';
// import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const useStyles = makeStyles({
  marginBottom: '6px',
  root: {
    '& .MuiInputLabel-root': {
      opacity: '0.7',
      marginTop: '-6px',
    },
    '& .MuiOutlinedInput-input': {
      color: '#424559 !important',
      background: 'white !important',
    },
  },
});

const AspProviders = ({ ...props }) => {
  const requestCallBack = props.requestCallBack;

  // eslint-disable-next-line
  const [providerCount, setProviderCount] = useState(0);
  // eslint-disable-next-line
  const [matchCount, setMatchCount] = useState();
  const [priceSingleCalc, setPriceSingleCalc] = useState();
  const [priceContCalcMin, setPriceContCalcMin] = useState();
  const [priceContCalcMax, setPriceContCalcMax] = useState();
  //const [priceFakeContCalc, setPriceFakeContCalc] = useState();
  const [originalSinglePrice, setOriginalSinglePrice] = useState();
  const [snackValidOpen, setSnackValidOpen] = useState(false);
  const [snackInvalidOpen, setSnackInvalidOpen] = useState(false);
  const [originalContMinPrice, setOriginalContMinPrice] = useState();
  const [originalContMaxPrice, setOriginalContMaxPrice] = useState();
  const [selectedProvs, setSelectedProvs] = useState('');
  const [selectedProvsError, setSelectedProvsError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [etradeBelow, setEtradeBelow] = useState(false);
  const [etradeAbove, setEtradeAbove] = useState(false);
  const [isCommission, setIsCommission] = useState();
  const [isDiscounted, setIsDiscounted] = useState(false);
  const [usedDiscount, setUsedDiscount] = useState();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [couponCode, setCouponCode] = React.useState();
  const [wrongCode, setWrongCode] = React.useState();
  //const [isPromo, setIsPromo] = React.useState(false);
  const [companyStore, potentialProviders, loading] = useSelector((state) => [
    state.company_store,
    state.company_store.potential_providers,
    state.loading,
  ]);
  // console.log(
  //   potentialProviders,
  //   'potentialProviders',
  //   typeof potentialProviders,
  //   potentialProviders.length
  // );
  const [randomNumber, setRandomNumber] = useState(
    Math.ceil(Math.random() * 8)
  );

  const dialogRef = useRef(null);

  const dataLayerUpdate = (stepData) => {
    window.dataLayer.push({
      event: stepData,
      event_category: 'aspflow',
    });
  };

  const randomlist = [
    [0, 3, 5],
    [2, 4, 6],
    [3, 7, 8],
    [4, 8, 1],
    [0, 5, 6],
    [1, 6, 7],
    [2, 7, 8],
    [3, 6, 8],
    [0, 4, 8],
  ];

  const [priceObj, setPriceObj] = useState({
    accounting: '',
    taxconsultingsimple: '',
    legalentityfounding: '',
  });

  const couponCheck = useCallback(
    (data) => {
      axios
        .post(`/api/coupons/get`, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          setWrongCode(false);
          setIsDiscounted(true);
          setSnackValidOpen(true);

          dispatch(
            ACTIONS.addCompanyInfo({
              id: 'discount',
              data: response.data,
              stateObject: 'company_store',
            })
          );
          console.log('AXIOS RESPONSE: ', response);
        })
        .catch((error) => {
          setWrongCode(true);
          setIsDiscounted(false);
          setSnackInvalidOpen(true);
        });
    },
    [dispatch]
  );

  const handleBack = useCallback(
    (decr) => {
      if (
        companyStore.legalentityfounding ||
        companyStore.taxconsultingsimple
      ) {
        props.handleBack(2);
      } else {
        props.handleBack(1);
      }
    },
    [props]
  );

  const getFakeContPrice = async () => {
    await dispatch(
      ACTIONS.addCompanyInfo({
        id: 'vafprice',
        data: potentialProviders[0][0]?.fakeTaxConSimple,
        stateObject: 'company_store',
      })
    );
    await dispatch(
      ACTIONS.addCompanyInfo({
        id: 'foundingprice',
        data: potentialProviders[0][0]?.fakeFounding,
        stateObject: 'company_store',
      })
    );
    if (potentialProviders[0].length !== 0) {
      setPriceObj((prevState) => ({
        ...prevState,
        taxconsultingsimple: potentialProviders[0][0]?.fakeTaxConSimple,
        legalentityfounding: potentialProviders[0][0]?.fakeFounding,
        accounting: potentialProviders[0][0]?.contMinPrice,
      }));
    }
    if (!companyStore.accounting) {
      setPriceObj((prevState) => ({
        ...prevState,
        accounting: 0,
      }));
    }
  };

  useEffect(() => {
    getFakeContPrice();
  }, []);

  useEffect(() => {
    let provCounter = 0;
    potentialProviders.forEach((e) => (provCounter += e.length));
    setProviderCount(provCounter);
  }, [companyStore]);

  useEffect(() => {
    if (isDiscounted && usedDiscount) {
      setPriceContCalcMin(originalContMinPrice - usedDiscount);
      setPriceContCalcMax(originalContMaxPrice - usedDiscount);
    }
  }, [usedDiscount, isDiscounted]);

  // console.log(companyStore, 'CS');
  // console.log(originalContMinPrice, 'originalContMinPrice');
  // console.log(priceContCalcMin, 'priceContCalcMin');
  // console.log(originalContMaxPrice, 'originalContMaxPrice');
  // console.log(priceContCalcMax, 'priceContCalcMax');

  // const getTotalPrice = useCallback(() => {
  //   let single = 0;
  //   let cont = 0;
  //   if (priceSingleCalc) {
  //     single = parseInt(priceSingleCalc.replace('.', ''));
  //   }
  //   if (priceContCalc) {
  //     cont = parseInt(priceContCalc.replace('.', ''));
  //   }
  //   //console.log(priceContCalc, 'priceContCalc in gettotal');
  //   return numberWithCommas(single + cont);
  // }, [priceSingleCalc, priceContCalc]);

  // const getOGTotalPrice = useCallback(() => {
  //   let single = 0;
  //   let cont = 0;
  //   if (priceSingleCalc) {
  //     single = parseInt(priceSingleCalc.replace('.', ''));
  //   }
  //   if (originalContPrice) {
  //     cont = parseInt(originalContPrice.replace('.', ''));
  //   }
  //   // console.log(priceContCalc, 'priceContCalc in OG');
  //   return numberWithCommas(single + cont);
  // }, [priceSingleCalc, originalContPrice]);

  const etradeCheck = useCallback(() => {
    if (
      (companyStore.industry === 'etrade' &&
        parseInt(companyStore.outgoinginvoices) > 200) ||
      (companyStore.industry === 'etrade' &&
        parseInt(companyStore.incominginvoices) > 200)
    ) {
      setEtradeAbove(true);
    } else if (
      (companyStore.industry === 'etrade' &&
        parseInt(companyStore.outgoinginvoices) <= 200) ||
      (companyStore.industry === 'etrade' &&
        parseInt(companyStore.incominginvoices) <= 200)
    ) {
      setEtradeBelow(true);
    } else {
      setEtradeAbove(false);
      setEtradeBelow(false);
    }
  }, [companyStore]);

  useEffect(() => {
    etradeCheck();
  }, [companyStore]);

  useEffect(() => {
    if (companyStore.discount) {
      setUsedDiscount(companyStore.discount.discount);
      if (companyStore.discount.referral) {
        if (
          companyStore.companytype === 'singleprimary' ||
          companyStore.companytype === 'singlesecondary' ||
          companyStore.companytype === 'kiva' ||
          companyStore.companytype === 'vszja'
        ) {
          setUsedDiscount(companyStore.discount.discount);
        } else {
          setUsedDiscount(companyStore.discount.discountLarge);
        }
      }
      if (companyStore.discount.companyType === 'any') {
        setIsDiscounted(true);
      }
      if (
        companyStore.discount.companyType.includes(companyStore.companytype)
      ) {
        setIsDiscounted(true);
      }
    }
    // eslint-disable-next-line
  }, [
    setIsDiscounted,
    companyStore.companytype,
    companyStore.discount,
    setUsedDiscount,
  ]);

  useEffect(() => {
    getOutputValues();
  }, [couponCode, wrongCode]);

  useEffect(() => {
    if (companyStore.potProvCount !== providerCount)
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'potProvCount',
          data: providerCount,
          stateObject: 'company_store',
        })
      );
  }, [providerCount, companyStore.potProvCount, dispatch]);

  const numberRoundOutput = (data) => {
    return Math.round(data * 0.01) * 100;
  };

  const getOutputValues = useCallback(() => {
    let mCount = [];
    let rCount = 0;
    let rAvg = 0;
    let reviewCount = 0;
    //console.log(companyStore);
    let evMinPrice = potentialProviders[0][0]
      ? potentialProviders[0][0].contMinPrice
      : 0;
    let evMaxPrice = potentialProviders[0][0]
      ? potentialProviders[0][0].contMaxPrice
      : 0;
    let officeMinPrice = potentialProviders[1][0]
      ? potentialProviders[1][0].contMinPrice
      : 0;
    let officeMaxPrice = potentialProviders[1][0]
      ? potentialProviders[1][0].contMaxPrice
      : 0;
    let entMinPrice = potentialProviders[2][0]
      ? potentialProviders[2][0].contMinPrice
      : 0;
    let entMaxPrice = potentialProviders[2][0]
      ? potentialProviders[2][0].contMaxPrice
      : 0;

    let singlePrice = potentialProviders[0][0]
      ? potentialProviders[0][0].singleEndPrice
      : 0;

    Object.entries(potentialProviders[0]).forEach((entry) => {
      entry[1].providerExperience > 3 && mCount.push(entry[1]);
      if (entry[1].reviewScores.reviewNr > 0) {
        rCount += entry[1]?.reviewScores.reviewNr;
      }
      if (entry[1].providerReview > 0) {
        rAvg += entry[1]?.providerReview;
        reviewCount++;
      }
    });
    Object.entries(potentialProviders[1]).forEach((entry) => {
      entry[1].providerExperience > 3 && mCount.push(entry[1]);
      if (entry[1].reviewScores.reviewNr > 0) {
        rCount += entry[1]?.reviewScores.reviewNr;
      }
      if (entry[1].providerReview > 0) {
        rAvg += entry[1]?.providerReview;
        reviewCount++;
      }
    });
    Object.entries(potentialProviders[2]).forEach((entry) => {
      entry[1].providerExperience > 3 && mCount.push(entry[1]);
      if (entry[1].reviewScores.reviewNr > 0) {
        rCount += entry[1]?.reviewScores.reviewNr;
      }
      if (entry[1].providerReview > 0) {
        rAvg += entry[1]?.providerReview;
        reviewCount++;
      }
    });

    setMatchCount(mCount.length);
    const formatedSinglePrice = numberWithCommas(
      parseInt(numberRoundOutput(singlePrice))
    );
    const formatedContMinPrice = parseInt(numberRoundOutput(evMinPrice));
    const formatedContMaxPrice = parseInt(numberRoundOutput(evMaxPrice));
    const formatedContMinPrice1 = parseInt(numberRoundOutput(officeMinPrice));
    const formatedContMaxPrice1 = parseInt(numberRoundOutput(officeMaxPrice));
    const formatedContMinPrice2 = parseInt(numberRoundOutput(entMinPrice));
    const formatedContMaxPrice2 = parseInt(numberRoundOutput(entMaxPrice));

    if (selectedProvs === 'ev') {
      setOriginalContMinPrice(formatedContMinPrice);
      setOriginalContMaxPrice(formatedContMaxPrice);
      setPriceContCalcMin(formatedContMinPrice);
      setPriceContCalcMax(formatedContMaxPrice);
    } else if (selectedProvs === 'office') {
      setOriginalContMinPrice(formatedContMinPrice1);
      setOriginalContMaxPrice(formatedContMaxPrice1);
      setPriceContCalcMin(formatedContMinPrice1);
      setPriceContCalcMax(formatedContMaxPrice1);
    } else if (selectedProvs === 'enterprise') {
      setOriginalContMinPrice(formatedContMinPrice2);
      setOriginalContMaxPrice(formatedContMaxPrice2);
      setPriceContCalcMin(formatedContMinPrice2);
      setPriceContCalcMax(formatedContMaxPrice2);
    }
    setPriceSingleCalc(formatedSinglePrice);
    setOriginalSinglePrice(formatedSinglePrice);

    if (isDiscounted) {
      if (companyStore.discount && companyStore.discount.singleDeduction) {
        setPriceContCalcMin(originalContMinPrice - usedDiscount);
        setPriceContCalcMax(originalContMaxPrice - usedDiscount);
      }
    }
    if (!companyStore.accounting) {
      setOriginalContMinPrice(0);
      setOriginalContMaxPrice(0);
      setPriceContCalcMin(0);
      setPriceContCalcMax(0);
    }
    return;
    // eslint-disable-next-line
  }, [
    selectedProvs,
    potentialProviders,
    isDiscounted,
    companyStore,
    companyStore.accounting,
    companyStore.payroll,
    companyStore.audit,
    companyStore.taxconsulting,
    companyStore.taxconsultingsimple,
    companyStore.taxconsultingcomplex,
    companyStore.legalentityfounding,
    companyStore.liquidation,
    companyStore.singleEndPrice,
    companyStore.contEndPrice,
    couponCode,
    companyStore.discount,
  ]);

  useEffect(() => {
    potentialProviders && getOutputValues();
  }, [potentialProviders, selectedProvs, companyStore.discount]);

  const numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackValidOpen(false);
    setSnackInvalidOpen(false);
  };

  return (
    <div className='finalStepContainer'>
      <Snackbar
        open={snackValidOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity='success'
          variant='filled'
          sx={{ width: '100%' }}
        >
          {i18n.t('searchflow.couponvalidated')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackInvalidOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity='error'
          variant='filled'
          sx={{ width: '100%' }}
        >
          {i18n.t('searchflow.couponinvalid')}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        TransitionComponent={Transition}
        fullScreen={window.innerWidth < 600 ? true : false}
        style={{ boxShadow: 'none !important' }}
      >
        <SendReq
          isCommission={isCommission}
          createRequest={props.createRequest}
          updateRequest={props.updateRequest}
          reqID={props.reqID}
          createAspRequest={props.createAspRequest}
          handleClose={() => setOpen(false)}
          priceSingleCalc={priceSingleCalc}
          priceContCalc={
            etradeAbove
              ? 'Egyéni ajánlat'
              : `${priceContCalcMin} - ${priceContCalcMax} `
          }
          setIsDisabled={setIsDisabled}
          isDiscounted={isDiscounted}
          originalSinglePrice={originalSinglePrice}
          originalContPrice={
            etradeAbove
              ? 'Egyéni ajánlat'
              : `${originalContMinPrice} - ${originalContMaxPrice}`
          }
        />
      </Dialog>
      <div>
        <div>
          {loading ? (
            <div style={{ width: '100%' }}>
              <LoadingIndicator />
            </div>
          ) : potentialProviders &&
            potentialProviders[0].length === 0 &&
            potentialProviders[1].length === 0 &&
            potentialProviders[2].length === 0 ? (
            <VafContactForm requestCallBack={requestCallBack} />
          ) : (
            //ACTUAL RENDERED OFFER PAGE
            <div>
              <div className='SFResultHeader'>
                {/* <p className='bkoffertitle'>
                  {i18n.t('searchflow.sfresultsubtext4')}{' '}
                  <a href='https://www.bookkeepie.com/hu'>
                    <img alt='header' src={logo} />
                  </a>{' '}
                </p> */}
                <p className='bkoffergoodnews'>
                  {i18n.t('searchflow.sfresulttext1a')}
                </p>
                <p className='resultnrline'>
                  {i18n.t('searchflow.sfresulttext1b')}
                </p>
                {/* <p className='resultnrline'>
                  {providerCount} {i18n.t('searchflow.sfresulttext3')}
                </p> */}
                {/* <div className='providerCards'>
                  <div className='fakeprofile'>
                    <div className='fakeprofiletop'>
                      {randomNumber > 0 && (
                        <Avatar
                          alt='profile avatar'
                          sx={{ width: 48, height: 48, borderRadius: '8px' }}
                          src={fakeProfiles[randomlist[randomNumber][0]].img}
                        />
                      )}

                      <p className='fakeprofilename'>
                        {randomNumber &&
                          fakeProfiles[randomlist[randomNumber][0]].name}
                        <br />
                        <span className='bkp'>Bookkeepie Partner</span>
                      </p>

                      <span className='SFresultStars'>
                        <StarRateIcon
                          sx={{
                            color: '#FFC842',
                            width: '12px',
                            height: '12px',
                          }}
                        />
                        <StarRateIcon
                          sx={{
                            color: '#FFC842',
                            width: '12px',
                            height: '12px',
                          }}
                        />
                        <StarRateIcon
                          sx={{
                            color: '#FFC842',
                            width: '12px',
                            height: '12px',
                          }}
                        />
                        <StarRateIcon
                          sx={{
                            color: '#FFC842',
                            width: '12px',
                            height: '12px',
                          }}
                        />
                        <StarRateIcon
                          sx={{
                            color: '#FFC842',
                            width: '12px',
                            height: '12px',
                          }}
                        />
                      </span>
                    </div>
                    <div className='prioContainer'>
                      {fakeProfiles[randomNumber].values.map((value) => (
                        <span className='qualityItem'>{value}</span>
                      ))}
                    </div>
                  </div>
                  <div className='fakeprofile'>
                    <div className='fakeprofiletop'>
                      {randomNumber && (
                        <Avatar
                          alt='profile avatar'
                          sx={{ width: 48, height: 48, borderRadius: '8px' }}
                          src={fakeProfiles[randomlist[randomNumber][1]].img}
                        />
                      )}

                      <p className='fakeprofilename'>
                        {randomNumber &&
                          fakeProfiles[randomlist[randomNumber][1]].name}
                        <br />
                        <span className='bkp'>Bookkeepie Partner</span>
                      </p>

                      <span className='SFresultStars'>
                        <StarRateIcon
                          sx={{
                            color: '#FFC842',
                            width: '12px',
                            height: '12px',
                          }}
                        />
                        <StarRateIcon
                          sx={{
                            color: '#FFC842',
                            width: '12px',
                            height: '12px',
                          }}
                        />
                        <StarRateIcon
                          sx={{
                            color: '#FFC842',
                            width: '12px',
                            height: '12px',
                          }}
                        />
                        <StarRateIcon
                          sx={{
                            color: '#FFC842',
                            width: '12px',
                            height: '12px',
                          }}
                        />
                        <StarRateIcon
                          sx={{
                            color: '#FFC842',
                            width: '12px',
                            height: '12px',
                          }}
                        />
                      </span>
                    </div>
                    <div className='prioContainer'>
                      {fakeProfiles[randomlist[randomNumber][1]].values.map(
                        (value) => (
                          <span className='qualityItem'>{value}</span>
                        )
                      )}
                    </div>
                  </div>
                  <div className='fakeprofile'>
                    <div className='fakeprofiletop'>
                      {randomNumber > 0 && (
                        <Avatar
                          alt='profile avatar'
                          sx={{ width: 48, height: 48, borderRadius: '8px' }}
                          src={fakeProfiles[randomlist[randomNumber][2]].img}
                        />
                      )}

                      <p className='fakeprofilename'>
                        {randomNumber &&
                          fakeProfiles[randomlist[randomNumber][2]].name}
                        <br />
                        <span className='bkp'>Bookkeepie Partner</span>
                      </p>

                      <span className='SFresultStars'>
                        <StarRateIcon
                          sx={{
                            color: '#FFC842',
                            width: '12px',
                            height: '12px',
                          }}
                        />
                        <StarRateIcon
                          sx={{
                            color: '#FFC842',
                            width: '12px',
                            height: '12px',
                          }}
                        />
                        <StarRateIcon
                          sx={{
                            color: '#FFC842',
                            width: '12px',
                            height: '12px',
                          }}
                        />
                        <StarRateIcon
                          sx={{
                            color: '#FFC842',
                            width: '12px',
                            height: '12px',
                          }}
                        />
                        <StarRateIcon
                          sx={{
                            color: '#FFC842',
                            width: '12px',
                            height: '12px',
                          }}
                        />
                      </span>
                    </div>
                    <div className='prioContainer'>
                      {fakeProfiles[randomlist[randomNumber][2]].values.map(
                        (value) => (
                          <span className='qualityItem'>{value}</span>
                        )
                      )}
                    </div>
                  </div>
                  <div className='furtherfakes'>
                    <img alt='furtheravatars' src={furtheravatars} />

                    <p className='fakeprofilename' style={{ marginLeft: 0 }}>
                      {providerCount} Hitelesített
                      <br />
                      <span className='bkp'>Bookkeepie Partner</span>
                    </p>
                  </div>
                </div> */}
                <img
                  className='headerImg'
                  alt='header'
                  src={window.innerWidth > 600 ? winner : mobileWinner}
                />
              </div>

              <div className='SFResultBody'>
                <div className='SFOfferContainer'>
                  {companyStore.accounting && (
                    <div>
                      <p className='SFoffertitle'>
                        {i18n.t('searchflow.sfresulttext2')}
                      </p>
                      <p className='SFoffertext'>
                        {i18n.t('searchflow.sfresulttext2a')}
                      </p>
                    </div>
                  )}
                  <div className='SFCheckInputNoPadding'>
                    <CheckboxInput3
                      // dataText={
                      //   companyStore.noofemployees === '0' ||
                      //   companyStore.noofemployees === 0
                      //     ? accServiceInput()
                      //     : OfferServicesInput()
                      // }
                      dataText={accServiceInput()}
                      lastStep={true}
                      priceObj={priceObj}
                    />
                  </div>
                  {selectedProvsError && (
                    <p
                      style={{
                        fontWeight: '600',
                        fontSize: '14px',
                        margin: '0 12px 6px',
                        color: 'red',
                      }}
                    >
                      {i18n.t('searchflow.pleasechoosegrp')}
                    </p>
                  )}
                  {companyStore.accounting && (
                    <div className='SwitchContainer'>
                      {potentialProviders[0].length !== 0 && (
                        <div
                          className={
                            selectedProvs === 'ev'
                              ? 'providerSwitch selectedSwitch'
                              : 'providerSwitch'
                          }
                          onClick={() => {
                            setSelectedProvs('ev');
                            dispatch(
                              ACTIONS.addCompanyInfo({
                                id: 'selectedProvGrp',
                                data: 'ev',
                                stateObject: 'company_store',
                              })
                            );
                          }}
                        >
                          <img
                            src={selectedProvs === 'ev' ? switchon : switchoff}
                            alt='radio button'
                            className='provswitchonimg'
                          />
                          <p className='SFchoicetitle'>
                            {/* <img src={info} alt='info' />{' '} */}
                            {i18n.t('searchflow.sfresultchoicetitle1')}
                          </p>
                          <p className='SFchoicetext'>
                            <img alt='check' src={checkmark} />{' '}
                            {i18n.t('searchflow.sfresultchoiceopt1')}
                          </p>
                          <p className='SFchoicetext'>
                            <img alt='check' src={checkmark} />{' '}
                            {i18n.t('searchflow.sfresultchoiceopt2')}
                          </p>
                          <p className='SFchoicetext'>
                            <img alt='check' src={checkmark} />{' '}
                            {i18n.t('searchflow.sfresultchoiceopt3')}
                          </p>
                          <p className='SFchoicetext'>
                            <img alt='cross' src={times} />
                            {'  '}
                            {i18n.t('searchflow.sfresultchoiceopt4')}
                          </p>
                          <p
                            className='SFchoicePriceContainer'
                            style={!isDiscounted ? { marginTop: '20px' } : null}
                          >
                            <span className='SFchoicePriceText'>
                              {i18n.t('searchflow.priceresulttext5')} (nettó)
                            </span>
                            <span className='SFchoicePrice'>
                              {numberWithCommas(
                                potentialProviders[0][0].contMinPrice
                              )}
                              -
                              {numberWithCommas(
                                potentialProviders[0][0].contMaxPrice
                              )}
                            </span>
                          </p>
                          {isDiscounted && (
                            <p className='SFchoicePriceContainer'>
                              <span className='SFchoicePriceText'>
                                {i18n.t('searchflow.priceresulttext4')} (nettó)
                              </span>
                              <span className='SFchoicePrice'>
                                {numberWithCommas(
                                  potentialProviders[0][0].contMinPrice -
                                    usedDiscount
                                )}
                                -
                                {numberWithCommas(
                                  potentialProviders[0][0].contMaxPrice -
                                    usedDiscount
                                )}
                              </span>
                            </p>
                          )}
                        </div>
                      )}
                      {potentialProviders[1].length !== 0 && (
                        <div
                          className={
                            selectedProvs === 'office'
                              ? 'providerSwitch selectedSwitch'
                              : 'providerSwitch'
                          }
                          onClick={() => {
                            setSelectedProvs('office');
                            dispatch(
                              ACTIONS.addCompanyInfo({
                                id: 'selectedProvGrp',
                                data: 'office',
                                stateObject: 'company_store',
                              })
                            );
                          }}
                        >
                          <img
                            src={
                              selectedProvs === 'office' ? switchon : switchoff
                            }
                            alt='radio button'
                            className='provswitchonimg'
                          />
                          <p className='SFchoicetitle'>
                            {/* <img src={info} alt='info' />{' '} */}
                            {i18n.t('searchflow.sfresultchoicetitle2')}
                          </p>
                          <p className='SFchoicetext'>
                            <img alt='check' src={checkmark} />{' '}
                            {i18n.t('searchflow.sfresultchoiceopt5')}
                          </p>
                          <p className='SFchoicetext'>
                            <img alt='check' src={checkmark} />{' '}
                            {i18n.t('searchflow.sfresultchoiceopt6')}
                          </p>
                          <p className='SFchoicetext'>
                            <img alt='check' src={checkmark} />{' '}
                            {i18n.t('searchflow.sfresultchoiceopt2')}
                          </p>
                          <p className='SFchoicetext'>
                            <img alt='check' src={checkmark} />{' '}
                            {i18n.t('searchflow.sfresultchoiceopt7')}
                          </p>
                          <p
                            className='SFchoicePriceContainer'
                            style={!isDiscounted ? { marginTop: '20px' } : null}
                          >
                            <span className='SFchoicePriceText'>
                              {i18n.t('searchflow.priceresulttext5')} (nettó)
                            </span>
                            <span className='SFchoicePrice'>
                              {numberWithCommas(
                                potentialProviders[1][0].contMinPrice
                              )}
                              -
                              {numberWithCommas(
                                potentialProviders[1][0].contMaxPrice
                              )}
                            </span>
                          </p>
                          {isDiscounted && (
                            <p className='SFchoicePriceContainer'>
                              <span className='SFchoicePriceText'>
                                {i18n.t('searchflow.priceresulttext4')} (nettó)
                              </span>
                              <span className='SFchoicePrice'>
                                {numberWithCommas(
                                  potentialProviders[1][0].contMinPrice -
                                    usedDiscount
                                )}
                                -
                                {numberWithCommas(
                                  potentialProviders[1][0].contMaxPrice -
                                    usedDiscount
                                )}
                              </span>
                            </p>
                          )}
                        </div>
                      )}
                      {potentialProviders[2].length !== 0 && (
                        <div
                          className={
                            selectedProvs === 'enterprise'
                              ? 'providerSwitch selectedSwitch'
                              : 'providerSwitch'
                          }
                          onClick={() => {
                            setSelectedProvs('enterprise');
                            dispatch(
                              ACTIONS.addCompanyInfo({
                                id: 'selectedProvGrp',
                                data: 'enterprise',
                                stateObject: 'company_store',
                              })
                            );
                          }}
                        >
                          <img
                            src={
                              selectedProvs === 'enterprise'
                                ? switchon
                                : switchoff
                            }
                            alt='radio button'
                            className='provswitchonimg'
                          />
                          <p className='SFchoicetitle'>
                            {/* <img src={info} alt='info' />{' '} */}
                            {i18n.t('searchflow.sfresultchoicetitle3')}
                          </p>
                          <p className='SFchoicetext'>
                            <img alt='check' src={checkmark} />{' '}
                            {i18n.t('searchflow.sfresultchoiceopt8')}
                          </p>
                          <p className='SFchoicetext'>
                            <img alt='check' src={checkmark} />{' '}
                            {i18n.t('searchflow.sfresultchoiceopt9')}
                          </p>
                          <p className='SFchoicetext'>
                            <img alt='check' src={checkmark} />{' '}
                            {i18n.t('searchflow.sfresultchoiceopt10')}
                          </p>
                          <p className='SFchoicetext'>
                            <img alt='cross' src={times} />
                            {'  '}
                            {i18n.t('searchflow.sfresultchoiceopt11')}
                          </p>
                          <p
                            className='SFchoicePriceContainer'
                            style={!isDiscounted ? { marginTop: '20px' } : null}
                          >
                            <span className='SFchoicePriceText'>
                              {i18n.t('searchflow.priceresulttext5')} (nettó)
                            </span>
                            <span className='SFchoicePrice'>
                              {numberWithCommas(
                                potentialProviders[2][0].contMinPrice
                              )}
                              -
                              {numberWithCommas(
                                potentialProviders[2][0].contMaxPrice
                              )}
                            </span>
                          </p>
                          {isDiscounted && (
                            <p className='SFchoicePriceContainer'>
                              <span className='SFchoicePriceText'>
                                {i18n.t('searchflow.priceresulttext4')} (nettó)
                              </span>
                              <span className='SFchoicePrice'>
                                {numberWithCommas(
                                  potentialProviders[2][0].contMinPrice -
                                    usedDiscount
                                )}
                                -
                                {numberWithCommas(
                                  potentialProviders[2][0].contMaxPrice -
                                    usedDiscount
                                )}{' '}
                              </span>
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <div className='SFOfferCheckboxContainer'>
                    <div>
                      <div>
                        <div>
                          <div className='SFCheckInputNoPadding'>
                            <CheckboxInput3
                              // dataText={
                              //   companyStore.noofemployees === '0' ||
                              //   companyStore.noofemployees === 0
                              //     ? accServiceInput()
                              //     : OfferServicesInput()
                              // }
                              dataText={singleServiceInput()}
                              lastStep={true}
                              priceObj={priceObj}
                            />
                          </div>

                          {etradeBelow && (
                            <p
                              style={{
                                fontWeight: '600',
                                fontSize: '12px',
                                margin: '0 12px',
                                color: 'red',
                              }}
                            >
                              {i18n.t('searchflow.etradeunder200invoice')}
                            </p>
                          )}
                          {etradeAbove && (
                            <p
                              style={{
                                fontWeight: '600',
                                fontSize: '12px',
                                margin: '0 12px',
                                color: 'red',
                              }}
                            >
                              {i18n.t('searchflow.etradeover200invoice')}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {window.innerWidth > 600 && (
                    <div>
                      <Navigation
                        step={18}
                        handleBack={() => handleBack()}
                        openHelpForm={() => props.openHelpForm()}
                        onClickHandler={() => {
                          if (companyStore.accounting && selectedProvs === '') {
                            setSelectedProvsError(true);
                          } else {
                            setSelectedProvsError(false);
                            setIsCommission(true);
                            setOpen(true);
                            dataLayerUpdate('sf_step_offer_rfp_form_opened');
                          }
                        }}
                        onClickHandlerFalse={() => {
                          if (companyStore.accounting && selectedProvs === '') {
                            setSelectedProvsError(true);
                          } else {
                            setSelectedProvsError(false);
                            setIsCommission(false);
                            setOpen(true);
                            dataLayerUpdate(
                              'sf_step_offer_save_for_later_form_opened'
                            );
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className='discountinfoContainer'>
                  <div className='discountDiv'>
                    <span>
                      <img src={tag} alt='tag' />
                      {i18n.t('searchflow.discountlabel')}
                    </span>
                    <p> {i18n.t('searchflow.discounttext')}</p>
                    <TextField
                      id='discountCode'
                      name='discountCode'
                      variant='outlined'
                      value={couponCode}
                      type={'text'}
                      className={`${classes.root}`}
                      onChange={(event) => {
                        setCouponCode(event.target.value);
                        console.log(event.target.value);
                      }}
                      onBlur={(event) => {
                        dispatch(
                          ACTIONS.addCompanyInfo({
                            id: event.target.id,
                            data: event.target.value,
                            stateObject: 'company_store',
                          })
                        );
                      }}
                    />{' '}
                    <Button
                      style={
                        couponCode === ''
                          ? { backgroundColor: 'rgba(80, 85, 129, 0.1)' }
                          : null
                      }
                      className={'NewCouponCheck'}
                      disabled={couponCode === '' ? true : false}
                      onClick={() => {
                        couponCheck({
                          couponCode: couponCode,
                          flow: 'search',
                        });
                        setCouponCode('');
                      }}
                    >
                      {i18n.t('searchflow.checkcoupon')}
                    </Button>
                  </div>
                  <div className='infoDiv'>
                    <p className='SFinfoTitle'>
                      {i18n.t('searchflow.reasontolovebk')}
                    </p>
                    <div className='SFinfoContent'>
                      <span>
                        <img alt='wallet' src={wallet} />
                        {i18n.t('searchflow.sfpaylater')}
                      </span>
                      <p>{i18n.t('searchflow.sfpaylaterinfo')}</p>
                      <hr />
                      <span>
                        <img alt='check' src={check} />
                        {i18n.t('searchflow.trustedproviders')}
                      </span>
                      <p>{i18n.t('searchflow.trustedproviderstext')}</p>
                      <hr />
                      <span>
                        <img alt='shield' src={shield} />
                        {i18n.t('searchflow.bkguarantee')}
                      </span>
                      <p>{i18n.t('searchflow.bkguaranteetext')}</p>
                      <hr />
                      <span>
                        <img alt='wallet' src={wallet} />
                        {i18n.t('searchflow.nohiddenfees')}
                      </span>
                      <p>{i18n.t('searchflow.nohiddenfeesinfo')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {window.innerWidth <= 600 && (
        <Navigation
          step={18}
          handleBack={() => handleBack()}
          openHelpForm={() => props.openHelpForm()}
          onClickHandler={() => {
            if (selectedProvs === '') {
              setSelectedProvsError(true);
            } else {
              setSelectedProvsError(false);
              setIsCommission(true);
              setOpen(true);
            }
          }}
          onClickHandlerFalse={() => {
            if (selectedProvs === '') {
              setSelectedProvsError(true);
            } else {
              setSelectedProvsError(false);
              setIsCommission(false);
              setOpen(true);
            }
          }}
        />
      )}
    </div>
  );
};

export default withTranslation()(AspProviders);
