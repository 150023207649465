import React from 'react';
import LinearStepper from './components/linearStepper/linearStepper';
import './providerData.scss';
import ProviderInfo from './providerInfo/providerInfo';
import ProviderServices from './providerServices/providerServices';
import ProviderSkills from './providerSkills/providerSkills';
import ProviderRestrictions from './providerRestrictions/providerRestrictions';
import ProviderExperience from './providerExperience/providerExperience';
import ProviderDescription from './providerDescription/providerDescription';
import ProviderSocial from './providerSocial/providerSocial';
import ProviderPricing from './providerPricing/providerPricing';
import ProviderPackage from './providerPackage/providerPackage';
import SelectPayment from './selectPayment/selectPayment';
import ProviderPreferences from './providerPreferences/providerPreferences';
import ProviderSpecialPreferences from './providerSpecialPreferences/providerSpecialPreferences';
import { withRouter } from '../../withRouter';
import axios from '../../shared/axios/axiosProxy';
import i18n from '../../utils/i18n';
import { withTranslation } from 'react-i18next';

//REDUX
import ACTIONS, { fetchProviderData } from '../../redux/action';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
	store: state,
	provider_info: state.provider_info,
	userData: state.userData,
	providerDataFetched: state.providerDataFetched,
});

const mapDispatchToProps = (dispatch) => ({
	addProviderInfo: (item) => dispatch(ACTIONS.addProviderInfo(item)),
	fetchProviderData: (JWT) => dispatch(fetchProviderData(JWT)),
});
class ProviderData extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			JWT: localStorage.getItem('JWT'),
			activeStep: 0,
			regMode: false,
			guide: '',
		};
	}
	componentDidMount() {
		//console.log(this.props.store, 'STORE')
		if (!this.props.providerDataFetched) {
			this.props.fetchProviderData(this.state.JWT);
		}
	}
	handleNext = (num) => {
		let increment = num ? num : this.state.activeStep + 1;
		this.setState({ activeStep: increment, guide: '' });
		if (!this.props.finishedRegistration && this.state.activeStep !== 10) {
			this.submitToDB();
		}
		if (this.state.activeStep >= 11) {
			this.submitToDB();
			this.props.navigate(`/provider/dashboard`);
		}
		window.scrollTo(0, 0);
	};
	handleBack = (num) => {
		let decrement = num ? num : this.state.activeStep - 1;
		this.setState({ activeStep: decrement, guide: '' });
		window.scrollTo(0, 0);
	};
	getDate = () => {
		let today = new Date(),
			date =
				today.getFullYear() +
				'.' +
				(today.getMonth() + 1 < 10
					? '0' + (today.getMonth() + 1)
					: today.getMonth() + 1) +
				'.' +
				(today.getDate() < 10 ? '0' + today.getDate() : today.getDate());
		return date;
	};

	submitToDB = async (field) => {
		let data = field ? field : this.props.store;
		if (this.state.activeStep === 10 && !this.props.finishedRegistration) {
			data.finishedRegistration = true;
			data.provider_package.startDate = this.getDate();
			data.provider_subscription.activeSubscription = true;
			await this.updateRegStatus();
			await this.updateSubscription();
		}
		const JWT = this.state.JWT;
		axios
			.patch('/api/provider', JSON.stringify(data), {
				headers: {
					Authorization: `Bearer ${JWT}`,
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				console.log('AXIOS RESPONSE: ', response.statusText);
				/* this.props.fetchProviderData(this.state.JWT);  */
			})
			.catch((error) => {
				console.log('AXIOS ERROR: ', error.response.data);
			})
			.catch((error) => console.log('submitToDB error', error));
	};
	updateRegStatus() {
		const JWT = this.state.JWT;
		let data = {
			finishedRegistration: true,
			packageType: this.props.store.provider_package.packageType,
		};
		axios
			.patch(`/api/user/${this.props.userData.id}`, data, {
				headers: {
					Authorization: `Bearer ${JWT}`,
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				console.log('AXIOS RESPONSE: ', response);
			})
			.catch((error) => {
				console.log('AXIOS ERROR: ', error.response.data);
			})
			.catch((error) => console.log('submitToDB error', error));
	}
	updateSubscription = () => {
		let data = {
			package: this.props.store.provider_package,
			'subscription.activeSubscription':
				this.props.store.provider_subscription.activeSubscription,
			'subscription.cardPayment':
				this.props.store.provider_subscription.cardPayment,
			'subscription.partnerCode':
				this.props.store.provider_subscription.partnerCode,
		};
		axios
			.patch(
				`/api/subscription/${this.props.userData.id}`,
				JSON.stringify(data),
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)
			.then((response) => {
				console.log('AXIOS RESPONSE: ', response);
			})
			.catch((error) => {
				console.log('AXIOS ERROR: ', error.response.data);
			})
			.catch((error) => console.log('submitToDB error', error));
	};

	getStepContent = (step) => {
		switch (step) {
			case 0:
				return (
					<ProviderServices
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						activeStep={this.state.activeStep}
						setGuide={this.setGuide}
					/>
				);
			case 1:
				return (
					<ProviderInfo
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						activeStep={this.state.activeStep}
						setGuide={this.setGuide}
					/>
				);
			case 2:
				return (
					<ProviderSkills
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						activeStep={this.state.activeStep}
						setGuide={this.setGuide}
					/>
				);
			case 3:
				return (
					<ProviderRestrictions
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						activeStep={this.state.activeStep}
						setGuide={this.setGuide}
					/>
				);
			case 4:
				return (
					<ProviderPreferences
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						activeStep={this.state.activeStep}
						setGuide={this.setGuide}
					/>
				);
			case 5:
				return (
					<ProviderSpecialPreferences
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						activeStep={this.state.activeStep}
						setGuide={this.setGuide}
						data={this.props.store}
					/>
				);
			case 6:
				return (
					<ProviderExperience
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						activeStep={this.state.activeStep}
						setGuide={this.setGuide}
					/>
				);
			case 7:
				return (
					<ProviderDescription
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						activeStep={this.state.activeStep}
						setGuide={this.setGuide}
						submitToDB={this.submitToDB}
					/>
				);
			case 8:
				return (
					<ProviderSocial
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						activeStep={this.state.activeStep}
						setGuide={this.setGuide}
					/>
				);
			case 9:
				return (
					<ProviderPricing
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						activeStep={this.state.activeStep}
						setGuide={this.setGuide}
					/>
				);
			case 10:
				return (
					<ProviderPackage
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						activeStep={this.state.activeStep}
						setGuide={this.setGuide}
					/>
				);
			case 11:
				return (
					<SelectPayment
						handleNext={this.handleNext}
						handleBack={this.handleBack}
						activeStep={this.state.activeStep}
						setGuide={this.setGuide}
						submitToDB={this.submitToDB}
					/>
				);
			default:
				return <>Error</>;
		}
	};
	setGuide = (prop) => {
		this.setState({ guide: prop });
	};
	render() {
		return (
			<div className="provDataCont">
				<div>
					<h2>
						<span>{i18n.t('general.accountant')}</span>{' '}
						<span style={{ color: 'black' }}>
							{i18n.t('general.registration')}
						</span>
					</h2>
				</div>
				<div className="ProviderDataContainer">
					{/* <div
          className={
            this.state.activeStep === 8 ? 'DisplayNone' : 'ProviderDataHelp '
          }
        >
          <div className='ProviderDataHelpBox'>{this.state.guide}</div>
        </div> */}
					<div className="ProviderDataContent">
						<div className="StepperContainer">
							<h2>
								{this.state.activeStep <= 11
									? `${i18n.t('provider.regflowtrackertext1')} ${
											this.state.activeStep + 1
									  } / 11`
									: `${i18n.t('provider.regflowtrackertext2')}`}
							</h2>
							<LinearStepper activeStep={this.state.activeStep} />
							{this.getStepContent(this.state.activeStep)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(
	withRouter(connect(mapStateToProps, mapDispatchToProps)(ProviderData))
);
