import React, { useCallback } from 'react';
import { TaxformInput, TaxformInput2 } from '../data/searchFlowText';
import Navigation from '../components/navigation/navigation';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
import RadioInput from '../components/searchInputs/radioInput';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

const Taxform = ({ ...props }) => {
  const companyStore = useSelector((state) => state.company_store);
  const navigate = useNavigate();
  const handleNext = useCallback(
    (inc) => {
      props.handleNext(inc);
    },
    [props]
  );

  const handleBack = useCallback(
    (decr) => {
      props.handleBack(decr);
    },
    [props]
  );

  return (
    <div className='stepContainer'>
      {companyStore.companytype === 'idk' ? (
        <div className='SFInput'>
          <div className='SFWarningText'>
            <TipsAndUpdatesIcon
              fontSize='medium'
              sx={{
                color: 'white',
                backgroundColor: '#1c32f3',
                marginLeft: 0.5,
                px: 1.5,
                py: 0.75,
                borderRadius: 2,
                boxSizing: 'content-box',
              }}
            />
            <p style={{ fontWeight: '600', fontSize: '16px', margin: '8px' }}>
              {i18n.t('searchflow.vafredirecttext')}
            </p>
            <p style={{ fontWeight: '300', fontSize: '16px', margin: '8px' }}>
              {i18n.t('searchflow.vafredirecttext2')}
            </p>
            <p style={{ fontWeight: '300', fontSize: '16px', margin: '8px' }}>
              {i18n.t('searchflow.vafredirecttext3')}
            </p>
          </div>
        </div>
      ) : (
        <div className='SFInput'>
          <p className='SFSubTitle'>
            <span className='SFSubTitle'>
              {i18n.t('searchflow.taxformlabel')}
            </span>{' '}
            <span className='questionTracker'>2/8</span>
          </p>
          <RadioInput
            storeID='taxform'

            dataText={companyStore.answer === 'existing'
              ? TaxformInput().filter(option => option.value !== 'idk')
              : TaxformInput()

            }
            stateObject='company_store'
          />
        </div>
      )}

      <Navigation
        step={2}
        handleBack={() => handleBack()}
        handleNext={() => {
          if (companyStore.companytype === 'idk') {
            companyStore.activeStep = 1;
            navigate('/company/request/vaf');
          } else {
            handleNext();
          }
        }}
        openHelpForm={() => props.openHelpForm()}
        disabledProp={
          companyStore.companytype === 'idk'
            ? false
            : companyStore.taxform !== ''
              ? false
              : true
        }
      />
    </div>
  );
};

export default withTranslation()(Taxform);
