import React, { useEffect, useCallback } from 'react';
import Slide from '@mui/material/Slide';
import { Drawer } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { addMatchScore, sortProviderList } from '../../data/helperFunctions';
import ProviderCard from '../components/providerCard/providerCard';
import ProviderProfile from '../providerProfile/providerProfile';
import { sortingText } from '../data/searchFlowText';
import Navigation from '../components/navigation/navigation';
import ProviderTable from '../components/providerTable/providerTable';
import winner from '../../../images/cherrywinner.png';
import mobileWinner from '../../../images/mobileWinner.png';
import logo from '../../../images/logo.png';
// import NoProviderFound from './noProviderFound';
import shield from '../../../images/shield.png';
import wallet from '../../../images/wallet.png';
import check from '../../../images/check-circle.png';
import VafContactForm from './vafContactForm';
import ACTIONS from '../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import StyledButton from '../../../shared/components/styledButton/styledButton';
import LoadingIndicator from '../../../shared/LoadingIndicator/loadingIndicator';
import ProviderCardSelected from '../components/providerCardSelected/providerCardSelected';

import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const FakeCherryProviders = ({ ...props }) => {
	const [providerList, setProviderList] = React.useState([]);
	const [profileData, setProfileData] = React.useState({});
	const [profileIndex, setProfileIndex] = React.useState(0);
	const [profileOpen, setProfileOpen] = React.useState(false);
	const [providerCount, setProviderCount] = React.useState(0);
	const [sortBy, setSortBy] = React.useState(i18n.t('searchflow.basedonmatch'));
	const requestCallBack = props.requestCallBack;

	const dispatch = useDispatch();
	const [companyStore, matchScoreWeights, potentialProviders, loading] =
		useSelector((state) => [
			state.company_store,
			state.match_score_weights,
			state.company_store.potential_providers,
			state.loading,
		]);
	console.log(companyStore, 'companyStore');

	// const dataLayerUpdate = (stepData, provider) => {
	//   window.dataLayer.push({
	//     event: 'sf_step_offerpage',
	//     event_category: 'aspflow',
	//     send_request_data: stepData,
	//     provider_chosen: provider,
	//     sf_choice: 'cherry',
	//   });
	// };

	const handleBack = useCallback(
		(decr) => {
			if (
				companyStore.taxconsultingcomplex ||
				companyStore.spSelectedFromList !== ''
			) {
				props.handleBack(3);
			} else {
				props.handleBack(2);
			}
		},
		[props]
	);
	const showPrices = useCallback(() => {
		if (
			companyStore.incominginvoices > 0 ||
			companyStore.outgoinginvoices > 0 ||
			companyStore.yearlyrevenue > 0
		) {
			return true;
		} else return false;
	}, [
		companyStore.incominginvoices,
		companyStore.outgoinginvoices,
		companyStore.yearlyrevenue,
	]);

	const sortProviders = useCallback(() => {
		//console.log(potentialProviders, 'potentialProviders');
		let pp = addMatchScore(potentialProviders, matchScoreWeights);
		let ppCount = 0;
		let ppList = [];
		for (let i in pp) {
			if (pp[i].providerPrice === 0 || null) continue;
			if (showPrices()) {
				pp[i].providerPrice = numberWithCommas(pp[i].providerPrice);
			} else {
				pp[i].providerPrice = '0';
			}
			ppCount += 1;
			ppList.push(pp[i]);
		}
		sortingText().forEach((item) => {
			if (sortBy === item.label) {
				ppList = sortProviderList(ppList, item.id, item.ascending);
			}
		});
		if (providerCount !== ppCount) {
			setProviderCount(ppCount);
		}

		return ppList;
	}, [
		showPrices,
		sortBy,
		matchScoreWeights,
		potentialProviders,
		providerCount,
	]);

	useEffect(() => {
		if (companyStore.potProvCount !== providerCount)
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'potProvCount',
					data: providerCount,
					stateObject: 'company_store',
				})
			);
	}, [providerCount, companyStore.potProvCount, dispatch]);

	useEffect(() => {
		setProviderList(sortProviders());
	}, [sortProviders]);

	const handleOpen = () => {
		setProfileOpen(true);
	};
	const handleClose = () => {
		setProfileOpen(false);
	};
	const numberWithCommas = (x) => {
		if (x) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
		}
	};
	const selectedProvider = potentialProviders.find(
		(provider) => provider.providerName === companyStore.spSelectedFromList
	);
	//console.log(companyStore);
	return (
		<>
			{' '}
			{potentialProviders.length === 0 ? (
				<VafContactForm requestCallBack={requestCallBack} />
			) : (
				<div className="SFPPContent">
					<Drawer
						anchor="bottom"
						PaperProps={{
							sx: {
								width: '100vw',
								height: '100vh',
								margin: 0,
								padding: 0,
								boxSizing: 'border-box',
								display: 'flex',
								alignItems: 'center',
								'@media (max-width: 600px)': {
									width: '100vw',
									height: '100vh',
								},
							},
						}}
						fullScreen
						open={profileOpen}
						onClose={handleClose}
						TransitionComponent={Transition}
						onClick={handleClose}>
						<ProviderProfile
							setProfileData={setProfileData}
							profileIndex={profileIndex}
							setProfileIndex={setProfileIndex}
							providerList={providerList}
							data={profileData}
							closeProfile={handleClose}
							createRequest={props.createRequest}
							createAspRequest={props.createAspRequest}
							updateRequest={props.updateRequest}
							updateFakeCherryRequest={props.updateFakeCherryRequest}
							reqID={props.reqID}
							updateViewedProviders={props.updateViewedProviders}
						/>
					</Drawer>
					<div className="SFResultHeader" style={{ height: '170px' }}>
						<p className="bkoffertitle">
							{i18n.t('searchflow.sfresultsubtext4')}{' '}
							<a href="https://www.bookkeepie.com/hu">
								<img alt="header" src={logo} />
							</a>{' '}
						</p>
						<p className="bkoffergoodnews">
							{i18n.t('searchflow.sfresulttext1')}
						</p>
						<p className="resultnrline">
							{providerCount} {i18n.t('searchflow.sfresulttext3')}
						</p>
						<div className="providerCards"></div>
						<img
							className="headerImg"
							alt="header figure"
							src={window.innerWidth > 600 ? winner : mobileWinner}
						/>
					</div>
					<div className="SFResultBodyFakeCherry">
						<div className="SFOfferContainerFakeCherry">
							{/* <div className='SFSticky'> */}
							<div>
								<p className="SFoffertitle">
									{i18n.t('searchflow.sfresulttext2')}
								</p>
								<p className="SFoffertextFakeCherry">
									{i18n.t('searchflow.potprovtext2')}{' '}
									<span
										className="dlpExpandText"
										style={{
											cursor: 'pointer',
											color: '#1C32F3',
											textDecoration: 'underline',
										}}
										onClick={async () => {
											await props.fetchAspPotentialProviders(
												companyStore,
												matchScoreWeights
											);
											props.handleBack(1);
										}}>
										{i18n.t('searchflow.potprovtext2a')}
									</span>
								</p>

								<div>
									{selectedProvider && (
										<ProviderCardSelected
											data={selectedProvider}
											openProfile={() => {
												setProfileData(selectedProvider);
												setProfileIndex(0);
												setProfileOpen(true);
											}}
											createRequest={props.createRequest}
											createAspRequest={props.createAspRequest}
											updateRequest={props.updateRequest}
											updateFakeCherryRequest={props.updateFakeCherryRequest}
											reqID={props.reqID}
										/>
									)}
								</div>

								<div
									style={{
										margin: '12px 0px',
										display: 'flex',
										alignItems: 'center',
										justifyContent:
											window.innerWidth < 600 ? 'flex-end' : 'space-between',
									}}>
									<div className="SFSortingHeader">
										{loading ? (
											<div style={{ width: '50%' }}>
												<LoadingIndicator />
											</div>
										) : potentialProviders &&
										  Object.keys(potentialProviders).length === 0 ? (
											<p className="FlexCenter Bold">
												{i18n.t('searchflow.nomatchfound')}
											</p>
										) : null}
										<div className="FlexCenter">
											<span>{i18n.t('searchflow.sortby')}</span>
											<FormControl
												variant="standard"
												sx={{ m: 1, minWidth: 150 }}>
												<Select
													labelId="demo-simple-select-standard-label"
													id="demo-simple-select-standard"
													value={sortBy}
													onChange={(e) => {
														setSortBy(e.target.value);
														dispatch(
															ACTIONS.addCompanyInfo({
																id: 'sortBy',
																data: e.target.value,
																stateObject: 'company_store',
															})
														);
													}}>
													{sortingText().map((item, i) => {
														return (
															<MenuItem value={item.label} key={i}>
																{
																	<span
																		style={{
																			color:
																				item.label === sortBy
																					? '#1a00bd'
																					: null,
																			fontWeight:
																				item.label === sortBy ? 'bold' : null,
																		}}>
																		{item.label}
																	</span>
																}
															</MenuItem>
														);
													})}
												</Select>
											</FormControl>
										</div>
									</div>
									{window.innerWidth < 600 ? null : (
										<StyledButton
											onClick={() => {
												dispatch(
													ACTIONS.addCompanyInfo({
														id: 'tableMode',
														data: !companyStore.tableMode,
														stateObject: 'company_store',
													})
												);
												props.handleSearchUpdate('tableUpdate');
											}}
											color="Transparent"
											className="SquareBtn">
											<span>{companyStore.tableMode ? '☰⠸' : '▤'}</span>
											{companyStore.tableMode
												? i18n.t('searchflow.backtolistview')
												: i18n.t('searchflow.comparison')}
										</StyledButton>
									)}{' '}
								</div>
							</div>
							{/*  TABLEMODE */}

							{companyStore.tableMode ? (
								<ProviderTable
									data={providerList}
									showPrices={showPrices()}
									setProfileIndex={setProfileIndex}
									setProfileData={setProfileData}
									openProfile={handleOpen}
									closeProfile={handleClose}
								/>
							) : (
								<>
									<div className="SFFlexWrapGap8">
										{sortProviders().map((item, i) => {
											return (
												<div
													style={{ margin: '0px' }}
													className={
														window.innerWidth < 600
															? 'FlexFullCenter'
															: 'ProviderCardContainer'
													}
													key={i}>
													<ProviderCard
														data={item}
														index={i}
														openProfile={handleOpen}
														setProfileIndex={setProfileIndex}
														setProfileData={setProfileData}
														createRequest={props.createRequest}
														createAspRequest={props.createAspRequest}
														updateRequest={props.updateRequest}
														updateFakeCherryRequest={
															props.updateFakeCherryRequest
														}
														reqID={props.reqID}
													/>
												</div>
											);
										})}
									</div>
								</>
							)}
							<Navigation
								step={19}
								handleBack={() => handleBack()}
								openHelpForm={() => props.openHelpForm()}
							/>
						</div>
						<div className="SFDiscountinfoContainer">
							<div className="infoDiv">
								<p className="SFinfoTitle">
									{i18n.t('searchflow.reasontolovebk')}
								</p>
								<div className="SFinfoContent">
									<span>
										<img alt="wallet" src={wallet} />
										{i18n.t('searchflow.sfpaylater')}
									</span>
									<p>{i18n.t('searchflow.sfpaylaterinfo')}</p>
									<hr />
									<span>
										<img alt="check" src={check} />
										{i18n.t('searchflow.trustedproviders')}
									</span>
									<p>{i18n.t('searchflow.trustedproviderstext')}</p>
									<hr />
									<span>
										<img alt="shield" src={shield} />
										{i18n.t('searchflow.bkguarantee')}
									</span>
									<p>{i18n.t('searchflow.bkguaranteetext')}</p>
									<hr />
									<span>
										<img alt="wallet" src={wallet} />
										{i18n.t('searchflow.nohiddenfees')}
									</span>
									<p>{i18n.t('searchflow.nohiddenfeesinfo')}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default withTranslation()(FakeCherryProviders);
