import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import StyledButton from '../../shared/components/styledButton/styledButton';
import webpage from '../../images/icons/webpage-new.svg';
import facebook from '../../images/icons/facebook-new.svg';
import linkedin from '../../images/icons/linkedin-new.svg';
import insta from '../../images/icons/insta-new.svg';
// import dashboard from '../../images/icons/houseWhite.png';
// import dashboardBlue from '../../images/icons/dashboardBlue.png';
import requests from '../../images/icons/ballotWhite.png';
import requestsBlue from '../../images/icons/ballotBlue.png';
import restrictions from '../../images/icons/slidersWhite.png';
import restrictionsBlue from '../../images/icons/slidersBlue.png';
import profile from '../../images/icons/userWhite.png';
import profileBlue from '../../images/icons/userBlue.png';
import reviews from '../../images/icons/starWhite.png';
import reviewsBlue from '../../images/icons/starBlue.png';
import subscription from '../../images/icons/shoppingWhite.png';
import subscriptionBlue from '../../images/icons/shoppingBlue.png';
import address from '../../images/icons/telepules_B@2x.png';
import phone from '../../images/icons/telefon_B@2x.png';
import email from '../../images/icons/email_B@2x.png';
import '../providerPayment/paymentType.scss';
import i18n from '../../utils/i18n';
import { legalDocuments } from '../../shared/data/legalDocuments';
/* import hungary from "../../images/icons/hungary.png"; */
import uk from '../../images/icons/united-kingdom.png';
import russia from '../../images/icons/russia.png';
import romania from '../../images/icons/romania.png';
import china from '../../images/icons/china.png';
import spain from '../../images/icons/spain.png';
import france from '../../images/icons/france.png';
import germany from '../../images/icons/germany.png';

/* PROVIDER INFO */
export var providerServicesText = () => [
  { label: i18n.t('general.accounting'), id: 'accounting' },
  { label: i18n.t('general.payroll'), id: 'payroll' },
  { label: i18n.t('general.simplifiedpayroll'), id: 'simplifiedpayroll' },
  { label: i18n.t('general.taxconsulting'), id: 'taxconsulting' },
  {
    label: i18n.t('general.taxconsultingsimplewprice'),
    id: 'taxconsultingsimple',
  },
  {
    label: i18n.t('general.taxconsultingcomplex'),
    id: 'taxconsultingcomplex',
  },
  //{ label: i18n.t('general.audit'), id: 'audit' },
  //{ label: i18n.t('general.legalentityfounding'), id: 'legalentityfounding' },
  { label: i18n.t('general.liquidation'), id: 'liquidation' },
];
export var providerServicesText2 = () => [
  { label: i18n.t('general.accounting'), id: 'accounting' },
  { label: i18n.t('general.payroll'), id: 'payroll' },
  { label: i18n.t('general.taxconsulting'), id: 'taxconsulting' },
  {
    label: i18n.t('general.taxconsultingsimple'),
    id: 'taxconsultingsimple',
  },
  {
    label: i18n.t('general.taxconsultingcomplex'),
    id: 'taxconsultingcomplex',
  },
  { label: i18n.t('general.audit'), id: 'audit' },
  //{ label: i18n.t('general.legalentityfounding'), id: 'legalentityfounding' },
  { label: i18n.t('general.liquidation'), id: 'liquidation' },
];

export var providerExtraInfoText = () => [
  //{ label: i18n.t('general.directcontact'), id: 'directcontact' },
  { label: i18n.t('general.discounts'), id: 'discounts' },
];

/* PROVIDER COMPANY FILTERS */
export var providerFilterSliders = () => [
  {
    label: i18n.t('general.noofemployeeslabel'),
    id: 'noofemployees',
  },
  {
    label: i18n.t('general.yearlyrevenuelabel'),
    id: 'yearlyrevenue',
  },
  {
    label: i18n.t('general.incominginvoiceslabel'),
    id: 'incominginvoices',
  },
  {
    label: i18n.t('general.outgoinginvoiceslabel'),
    id: 'outgoinginvoices',
  },
];

export var subscriptionPackages = () => {
  return {
    entrepreneur: {
      package_name: i18n.t('providersubscription.entrepreneurpack'), //noofemployees = 1
      package_dbName: 'entrepreneur',
      noofemployees: [1, 1],
      basic_yearlyPricePerMonth: 0,
      basic_yearlyPrice: 0,
      basic_monthly: 0,
      basic_discountIfYearly: 0,
      advanced_yearlyPricePerMonth: 6900,
      advanced_yearlyPrice: 82800,
      advanced_monthly: 55200,
      advanced_discountIfYearly: 27600,
      premium_yearlyPricePerMonth: 9900,
      premium_yearlyPrice: 118800,
      premium_monthly: 79200,
      premium_discountIfYearly: 39600,
      mkoestart_yearlyPricePerMonth: 0,
      mkoestart_yearlyPrice: 0,
      mkoestart_monthly: 0,
      mkoestart_discountIfYearly: 0,
    },
    small_office: {
      package_name: i18n.t('providersubscription.smallofficepack'), //noofemployees = 2-5
      package_dbName: 'small_office',
      noofemployees: [2, 5],
      basic_yearlyPricePerMonth: 0,
      basic_yearlyPrice: 0,
      basic_monthly: 0,
      basic_discountIfYearly: 0,
      advanced_yearlyPricePerMonth: 9900,
      advanced_yearlyPrice: 118800,
      advanced_monthly: 79200,
      advanced_discountIfYearly: 39600,
      premium_yearlyPricePerMonth: 14900,
      premium_yearlyPrice: 178800,
      premium_monthly: 118800,
      premium_discountIfYearly: 58800,
      mkoestart_yearlyPricePerMonth: 0,
      mkoestart_yearlyPrice: 0,
      mkoestart_monthly: 0,
      mkoestart_discountIfYearly: 0,
    },
    office: {
      package_name: i18n.t('providersubscription.officepack'), //noofemployees = 6-20
      package_dbName: 'office',
      noofemployees: [6, 20],
      basic_yearlyPricePerMonth: 0,
      basic_yearlyPrice: 0,
      basic_monthly: 0,
      basic_discountIfYearly: 0,
      advanced_yearlyPricePerMonth: 14900,
      advanced_yearlyPrice: 178800,
      advanced_monthly: 118800,
      advanced_discountIfYearly: 58800,
      premium_yearlyPricePerMonth: 19900,
      premium_yearlyPrice: 238800,
      premium_monthly: 159000,
      premium_discountIfYearly: 79200,
      mkoestart_yearlyPricePerMonth: 0,
      mkoestart_yearlyPrice: 0,
      mkoestart_monthly: 0,
      mkoestart_discountIfYearly: 0,
    },
    corporate: {
      package_name: i18n.t('providersubscription.corporatepack'), //noofemployees = above 20
      package_dbName: 'corporate',
      noofemployees: 21, //and above
      basic_yearlyPricePerMonth: 0,
      basic_yearlyPrice: 0,
      basic_monthly: 0,
      basic_discountIfYearly: 0,
      advanced_yearlyPricePerMonth: 19900,
      advanced_yearlyPrice: 238800,
      advanced_monthly: 159000,
      advanced_discountIfYearly: 79200,
      premium_yearlyPricePerMonth: 29900,
      premium_yearlyPrice: 358800,
      premium_monthly: 238800,
      premium_discountIfYearly: 118800,
      mkoestart_yearlyPricePerMonth: 0,
      mkoestart_yearlyPrice: 0,
      mkoestart_monthly: 0,
      mkoestart_discountIfYearly: 0,
    },
  };
};

export var bankDetails = (
  id,
  price,
  subscription,
  image,
  startBankSubscription
) => {
  return [
    <>
      <Grid
        container
        justifyContent='flex-start'
        alignItems='flex-start'
        direction='row'
      >
        <Grid item xs={12}>
          <h2 className='h1-icon-description'>
            <span>
              <img className='title-icon' src={image} alt='bill-icon' />
            </span>
            {i18n.t('providerpayment.bankdetails1/17')}
          </h2>
        </Grid>
        {/*         <Grid container style={{ paddingTop: "2em" }} direction="row" sm={6}>
          <Grid item sm={5} xs={12}>
            <p style={{ margin: "0.2em" }} className="medium-text">
              {i18n.t("providerpayment.bankdetails2/17")}
            </p>
          </Grid>
          <Grid item sm={7} xs={12}>
            <p style={{ margin: "0.2em" }} className="medium-text">
              {i18n.t("providerpayment.bankdetails3/17")}
            </p>
          </Grid>
          <Grid item sm={5} xs={12}>
            <p style={{ margin: "0.2em" }} className="medium-text">
              {i18n.t("providerpayment.bankdetails4/17")}{" "}
            </p>
          </Grid>
          <Grid item sm={7} xs={12}>
            <p style={{ margin: "0.2em" }} className="medium-text">
              {i18n.t("providerpayment.bankdetails5/17")}
            </p>
          </Grid>
          <Grid item sm={5} xs={12}>
            <p style={{ margin: "0.2em" }} className="medium-text">
              {i18n.t("providerpayment.bankdetails6/17")}
            </p>
          </Grid>
          <Grid item sm={7} xs={12}>
            <p style={{ margin: "0.2em" }} className="medium-text">
              {price}
            </p>
          </Grid>
          <Grid item sm={5} xs={12}>
            <p style={{ margin: "0.2em" }} className="medium-text">
              {i18n.t("providerpayment.bankdetails7/17")}
            </p>
          </Grid>
          <Grid item sm={7} xs={12}>
            <p style={{ margin: "0.2em" }} className="medium-text">
              {id}
            </p>
          </Grid>
          <Grid item sm={5} xs={12}>
            <p style={{ margin: "0.2em" }} className="medium-text">
              {i18n.t("providerpayment.bankdetails8/17")}
            </p>
          </Grid>
          <Grid item sm={7} xs={12}>
            <p style={{ margin: "0.2em" }} className="medium-text">
              {subscription}
            </p>
          </Grid>
        </Grid> */}
        {/*         <Grid style={{ padding: "2em" }} container sm={6}>
          <Grid item className="box">
            <p className="box-text">
              <b>{i18n.t("providerpayment.bankdetails9/17")}</b>
            </p>
            <p className="box-text">
              {i18n.t("providerpayment.bankdetails10/17")}
              <b>{i18n.t("providerpayment.bankdetails11/17")}</b>{" "}
              {i18n.t("providerpayment.bankdetails12/17")}{" "}
              <b>{i18n.t("providerpayment.bankdetails13/17")}</b>.
            </p>
            <p className="box-text">
              {i18n.t("providerpayment.bankdetails14/17")}
              <b>{i18n.t("providerpayment.bankdetails15/17")}</b>{" "}
              {i18n.t("providerpayment.bankdetails16/17")}{" "}
              <b>{i18n.t("providerpayment.bankdetails17/17")}</b>.
            </p>
          </Grid>
        </Grid> */}
      </Grid>
      <Grid
        container
        xs={12}
        direction='column'
        alignItems='center'
        justifyContent='center'
      >
        <Grid style={{ textAlign: 'center' }} item xs={12}>
          <p style={{ fontSize: '1em' }}>
            {i18n.t('providerpayment.banktransfertext')}
          </p>
        </Grid>
        <Link to={'/provider/providersummary'}>
          <Button
            className='button-proceed-card'
            onClick={() => {
              startBankSubscription();
            }}
          >
            <Grid style={{ textAlign: 'center' }} item xs={9}>
              <p style={{ fontSize: '1.3em' }}>
                {i18n.t('general.backtoprofile')}
              </p>
            </Grid>
          </Button>
        </Link>
      </Grid>
    </>,
  ];
};

export var cardPaymentConfirm = (
  startCardSubscription,
  finishReg,
  handleClose,
  image
) => {
  return [
    <Grid
      container
      justifyContent='flex-start'
      alignItems='flex-start'
      direction='row'
      style={{ padding: '24px' }}
    >
      <Grid
        container
        xs={12}
        direction='column'
        alignItems='center'
        justifyContent='center'
      >
        <span>
          <img className='title-icon' src={image} alt='simple-pay-logo' />
        </span>
      </Grid>
      <Grid item xs={12}>
        <h2 className='h1-icon-description'>
          {i18n.t('provider.datatransmissiontitle')}
        </h2>
      </Grid>
      <Grid container style={{ paddingTop: '2em' }} direction='row' sm={12}>
        <Grid item xs={12}>
          <p style={{ margin: '0.2em' }} className='medium-text'>
            {i18n.t('provider.datatransmissiontext1')}
          </p>
        </Grid>
        <Grid item xs={12}>
          <p style={{ margin: '0.2em' }} className='medium-text'>
            {i18n.t('provider.datatransmissiontext2')}
          </p>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: '3em' }}>
        <h2 className='h1-icon-description'>
          {i18n.t('provider.recurringcardtitle')}
        </h2>
      </Grid>
      <Grid container style={{ paddingTop: '2em' }} direction='row' sm={12}>
        <Grid item xs={12}>
          <p style={{ margin: '0.2em' }} className='medium-text'>
            {i18n.t('provider.recurringcardtext1')}
          </p>
        </Grid>
        <Grid item xs={12}>
          <p style={{ margin: '0.2em' }} className='medium-text'>
            {i18n.t('provider.recurringcardtext2')}
          </p>
        </Grid>
        <Grid item xs={12}>
          <p style={{ margin: '0.2em' }} className='medium-text'>
            {i18n.t('provider.recurringcardtext3')}
          </p>
        </Grid>
        <Grid item xs={12}>
          <p style={{ margin: '0.2em' }} className='medium-text'>
            {i18n.t('provider.recurringcardtext4')}
          </p>
        </Grid>
        <Grid item xs={12}>
          <p style={{ margin: '0.2em' }} className='medium-text'>
            {i18n.t('provider.recurringcardtext5')}
          </p>
        </Grid>
        <Grid
          container
          xs={12}
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ marginTop: '24px' }}
        >
          <StyledButton
            className='  XXLong'
            color='Primary'
            onClick={(event) => {
              event.preventDefault();
              startCardSubscription();
              finishReg();
              handleClose();
            }}
          >
            {i18n.t('provider.cardpaymentconfirm')}
          </StyledButton>
        </Grid>
        <Grid
          container
          xs={12}
          direction='column'
          alignItems='flex-end'
          justifyContent='flex-end'
        >
          <StyledButton
            className='  Medium'
            color='Transparent'
            onClick={() => {
              handleClose();
            }}
          >
            {i18n.t('general.closebutton')}
          </StyledButton>
        </Grid>
      </Grid>
    </Grid>,
  ];
};

export var providerContactInfo = () => [
  {
    name: 'address',
    img: address,
    get hun() {
      return i18n.t('provider.showaddress');
    },
  },
  {
    name: 'phone',
    img: phone,
    get hun() {
      return i18n.t('provider.showphone');
    },
  },
  {
    name: 'email',
    img: email,
    get hun() {
      return i18n.t('provider.showemail');
    },
  },
  {
    name: 'webpage',
    img: webpage,
    get hun() {
      return i18n.t('provider.showweb');
    },
  },
  {
    name: 'social',
    img: webpage,
    get hun() {
      return i18n.t('provider.showsocial');
    },
  },
];

export var invoicesExtra = () => [
  { label: i18n.t('general.incominginvoices'), id: 'incominginvoiceextra' },
  { label: i18n.t('general.outgoinginvoices'), id: 'outgoinginvoiceextra' },
];
export var foreignTradeExtra = () => [
  { label: i18n.t('general.foreigntradeextra'), id: 'foreigntradeextra' },
];
export var severalLocationsExtra = () => [
  { label: 'Egynél több telephellyel rendelkező vállalkozások havi könyvelési díj felára', id: 'severallocations' },
];
export var revenueExtra = () => [
  { label: i18n.t('general.revfeecat1'), range: [0, 5], id: 'revfeecat1' },
  { label: i18n.t('general.revfeecat2'), range: [5, 10], id: 'revfeecat2' },
  { label: i18n.t('general.revfeecat3'), range: [10, 50], id: 'revfeecat3' },
  { label: i18n.t('general.revfeecat4'), range: [50, 100], id: 'revfeecat4' },
  {
    label: i18n.t('general.revfeecat4a'),
    range: [100, 200],
    id: 'revfeecat4a',
  },
  { label: i18n.t('general.revfeecat5'), range: [200, 500], id: 'revfeecat5' },
  { label: i18n.t('general.revfeecat6'), range: [500, 2000], id: 'revfeecat6' },
  {
    label: i18n.t('general.revfeecat7'),
    range: [2000, 2000],
    id: 'revfeecat7',
  },
];
export var vatExtra = () => [
  { label: i18n.t('general.monthly'), id: 'vatmonthly' },
  { label: i18n.t('general.quarterly'), id: 'vatquarterly' },
  { label: i18n.t('general.yearly'), id: 'vatyearly' },
];
export var etradeExtra = () => [
  { label: 'Milyen felárat alkalmazol, ha az ügyfeled egynél több futárcéggel dolgozik együtt?', id: 'multiplecourier' },
  { label: 'Milyen felárat alkalmazol, ha az ügyfeled egynél több csomag lerakatra szállít?', id: 'multipledropoff' },
  { label: 'Felár átutalásos fizetések esetén', id: 'webshoptransferpayment' },
  { label: 'Felár bankkártyás fizetések esetén', id: 'webshopcardpayment' },
  // { label: 'Felár Szép kártyás fizetések esetén', id: 'webshopbenefitpayment' },
 
];
export var tourismExtra = () => [
  
  { label: 'Felár átutalásos fizetések esetén', id: 'tourismtransferpayment' },
  { label: 'Felár bankkártyás fizetések esetén', id: 'tourismcardpayment' },
  { label: 'Felár Szép kártyás fizetések esetén', id: 'tourismbenefitpayment' },
 
];
// export var tradeExtra = () => [
//   { label: 'Felár átutalásos fizetések esetén', id: 'tradetransferpayment' },
//   { label: 'Felár bankkártyás fizetések esetén', id: 'tradecardpayment' },
 
// ];
export var retailExtra = () => [
  { label: 'Felár átutalásos fizetések esetén', id: 'retailtransferpayment' },
  { label: 'Felár bankkártyás fizetések esetén', id: 'retailcardpayment' },
 
];
export var wholesaleExtra = () => [
  { label: 'Felár átutalásos fizetések esetén', id: 'wholesaletransferpayment' },
  { label: 'Felár bankkártyás fizetések esetén', id: 'wholesalecardpayment' },
 
];
export var medicineExtra = () => [
  { label: 'Felár bankkártyás fizetések esetén', id: 'medicinecardpayment' },
  { label: 'Felár Szép kártyás fizetések esetén', id: 'medicinebenefitpayment' },
 
];
export var payrollPricing = () => [
  {
    get label() {
      return i18n.t('provider.payrollfield');
    },
    id: 'percapitamonthlyprice',
  },
  {
    get label() {
      return i18n.t('provider.simplifiedpayrollfield');
    },
    id: 'simplifiedpayrollprice',
  },
];

export var accountingTaxPricing = () => [
  {
    get label() {
      return i18n.t('provider.taxgeneral');
    },
    id: 'perMonthPrice',
  },
  {
    get label() {
      return i18n.t('general.taxconsultingcomplex');
    },
    id: 'taxConComplexPrice',
  },
];

export var auditPricing = () => [
  {
    get label() {
      return i18n.t('provider.auditunder300m');
    },
    id: 'revUnder300m',
  },
  {
    get label() {
      return i18n.t('provider.audit300m-2b');
    },
    id: 'rev3002B',
  },
  {
    get label() {
      return i18n.t('provider.audit2b-5b');
    },
    id: 'rev25b',
  },
  {
    get label() {
      return i18n.t('provider.auditover5b');
    },
    id: 'revOver5b',
  },
];

export var providerLegalEntityPricing = () => [
  {
    get label() {
      return i18n.t('companylabels.singleprimary');
    },
    id: 'singleprimaryprice',
  },
  {
    get label() {
      return i18n.t('companylabels.singlesecondary');
    },
    id: 'singlesecondaryprice',
  },
  {
    get label() {
      return i18n.t('companylabels.kiva');
    },
    id: 'kivaprice',
  },
  {
    get label() {
      return i18n.t('companylabels.vszja');
    },
    id: 'vszjaprice',
  },
  {
    get label() {
      return i18n.t('companylabels.bt');
    },
    id: 'btprice',
  },
  {
    get label() {
      return i18n.t('companylabels.kft');
    },
    id: 'kftprice',
  },
  {
    get label() {
      return i18n.t('companylabels.zrt');
    },
    id: 'zrtprice',
  },
  {
    get label() {
      return i18n.t('companylabels.kkt');
    },
    id: 'kktprice',
  },
  {
    get label() {
      return i18n.t('companylabels.nyrt');
    },
    id: 'nyrtprice',
  },
  {
    get label() {
      return i18n.t('companylabels.condo');
    },
    id: 'condoprice',
  },
  {
    get label() {
      return i18n.t('companylabels.civil');
    },
    id: 'civilprice',
  },
];

export var liquidationPricing = () => [
  {
    get label() {
      return i18n.t('provider.liquidationlabel');
    },
    id: 'liquidationprice',
  },
];

export const TaxformInput = () => [
  {
    get label() {
      return i18n.t('general.taxform1');
    },
    id: 'kiva',
  },
  {
    get label() {
      return i18n.t('general.taxform2');
    },
    id: 'tao',
  },
];

export var providerCompanyIndustryList = () => [
  { label: i18n.t('industrylabels.construction'), id: 'construction' },
  { label: i18n.t('industrylabels.engineer'), id: 'engineer' },
  { label: i18n.t('industrylabels.vehicle'), id: 'vehicle' },
  { label: i18n.t('industrylabels.manufacturing'), id: 'manufacturing' },
  { label: i18n.t('industrylabels.IT'), id: 'IT' },
  { label: i18n.t('industrylabels.realestate'), id: 'realestate' },
  // { label: i18n.t('industrylabels.trade'), id: 'trade' },
  { label: i18n.t('industrylabels.retail'), id: 'retail' },
  { label: i18n.t('industrylabels.wholesale'), id: 'wholesale' },
  { label: i18n.t('industrylabels.agriculture'), id: 'agriculture' },
  { label: i18n.t('industrylabels.education'), id: 'education' },
  { label: i18n.t('industrylabels.art'), id: 'art' },
  { label: i18n.t('industrylabels.beauty'), id: 'beauty' },
  { label: i18n.t('industrylabels.medicine'), id: 'medicine' },
  { label: i18n.t('industrylabels.finance'), id: 'finance' },
  { label: i18n.t('industrylabels.travel'), id: 'travel' },
  { label: i18n.t('industrylabels.logistics'), id: 'logistics' },
  { label: i18n.t('industrylabels.courier'), id: 'courier' },
  { label: i18n.t('industrylabels.otherservices'), id: 'otherservices' },
  { label: i18n.t('industrylabels.etrade'), id: 'etrade' },
  { label: i18n.t('industrylabels.startup'), id: 'startup' },
  { label: i18n.t('industrylabels.lawyer'), id: 'lawyer' },
  { label: i18n.t('industrylabels.sport'), id: 'sport' },
  // { label: i18n.t('industrylabels.cantdecide'), id: 'cantdecide' },
];

export var providerSpecialPreferencesList = () => [
  { label: i18n.t('provider.specialpreftext1'), id: 'registersimplifiedpayroll' },
  { label: i18n.t('provider.specialpreftext2'), id: 'companycar' },
  { label: i18n.t('provider.specialpreftext3'), id: 'cashregister' },
  { label: i18n.t('provider.specialpreftext4'), id: 'multipleaccounts' },
  { label: i18n.t('provider.specialpreftext5'), id: 'cashflowvat' },
  { label: i18n.t('provider.specialpreftext6'), id: 'loanreview' },
  { label: i18n.t('provider.specialpreftext7'), id: 'earlyclosure' },
  { label: i18n.t('provider.specialpreftext8'), id: 'preparingstatements' },
  { label: i18n.t('provider.specialpreftext9'), id: 'invoicing' },
  { label: i18n.t('provider.specialpreftext10'), id: 'transfering' },
  { label: i18n.t('provider.specialpreftext11'), id: 'auditcooperation' },
  { label: i18n.t('provider.specialpreftext12'), id: 'vatadjusting' },
  { label: i18n.t('provider.specialpreftext13'), id: 'projectsupport' },
  { label: i18n.t('provider.specialpreftext14'), id: 'environmentalfees' },
 
  ];
export var providerEtradePreferencesList = () => [
  { label: i18n.t('provider.specialpreftext15'), id: 'matchinginvoices' },
  { label: i18n.t('provider.specialpreftext16'), id: 'actualstore' },
  { label: i18n.t('provider.specialpreftext17'), id: 'stockhandling' },

  ];
export var providerVehiclePreferencesList = () => [
  { label: i18n.t('provider.specialpreftext18'), id: 'differentialvat' },
  ];
export var providerRetailPreferencesList = () => [
  { label: i18n.t('provider.specialpreftext19'), id: 'matchinginvoicesretail' },
  ];
export var providerWholesalePreferencesList = () => [
  { label: i18n.t('provider.specialpreftext19'), id: 'matchinginvoiceswholesale' },
  ];
export var providerTravelPreferencesList = () => [
  { label: i18n.t('provider.specialpreftext19'), id: 'matchinginvoicestravel' },
  ];
export var providerCourierPreferencesList = () => [
  { label: i18n.t('provider.specialpreftext19'), id: 'matchinginvoicescourier' },
  ];
export var providerCompanyTypeList = () => [
  { label: i18n.t('companylabels.singleprimary'), id: 'singleprimary' },
  { label: i18n.t('companylabels.singlesecondary'), id: 'singlesecondary' },
  { label: i18n.t('companylabels.kiva'), id: 'kiva' },
  { label: i18n.t('companylabels.vszja'), id: 'vszja' },
  { label: i18n.t('companylabels.kft'), id: 'kft' },
  { label: i18n.t('companylabels.kkt'), id: 'kkt' },
  { label: i18n.t('companylabels.bt'), id: 'bt' },
  { label: i18n.t('companylabels.zrt'), id: 'zrt' },
  { label: i18n.t('companylabels.nyrt'), id: 'nyrt' },
  { label: i18n.t('companylabels.condo'), id: 'condo' },
  { label: i18n.t('companylabels.civil'), id: 'civil' },
  { label: i18n.t('companylabels.citycouncil'), id: 'citycouncil' },
];

export const bankingPrefText = () => [
  { label: i18n.t('searchflow.bankingoptions1'), id: 'opt1' },
  { label: i18n.t('searchflow.bankingoptions2'), id: 'opt2' },
  { label: i18n.t('searchflow.bankingoptions3'), id: 'opt3' },
  { label: i18n.t('searchflow.bankingoptions4'), id: 'opt4' },
  { label: i18n.t('searchflow.bankingoptions5'), id: 'opt5' },
  { label: i18n.t('searchflow.bankingoptions6'), id: 'opt6' },
  { label: i18n.t('searchflow.bankingoptions7'), id: 'opt7' },
  { label: i18n.t('searchflow.bankingoptions8'), id: 'opt8' },
  { label: i18n.t('searchflow.other'), id: 'other' },
];

export const invoicingPrefText = () => [
  { label: i18n.t('searchflow.invoicingoptions1'), id: 'opt1' },
  { label: i18n.t('searchflow.invoicingoptions2'), id: 'opt2' },
  { label: i18n.t('searchflow.invoicingoptions3'), id: 'opt3' },
  { label: i18n.t('searchflow.invoicingoptions4'), id: 'opt4' },
  { label: i18n.t('searchflow.invoicingoptions5'), id: 'opt5' },
  { label: i18n.t('searchflow.invoicingoptions6'), id: 'opt6' },
  { label: i18n.t('searchflow.invoicingoptions7'), id: 'opt7' },
  { label: i18n.t('searchflow.other'), id: 'other' },
];

export const softwarePrefText = () => [
  { label: i18n.t('searchflow.softwareoptions1'), id: 'opt1' },
  { label: i18n.t('searchflow.softwareoptions2'), id: 'opt2' },
  { label: i18n.t('searchflow.softwareoptions3'), id: 'opt3' },
  { label: i18n.t('searchflow.softwareoptions4'), id: 'opt4' },
  { label: i18n.t('searchflow.softwareoptions5'), id: 'opt5' },
  { label: i18n.t('searchflow.softwareoptions6'), id: 'opt6' },
  { label: i18n.t('searchflow.softwareoptions7'), id: 'opt7' },
  { label: i18n.t('searchflow.softwareoptions8'), id: 'opt8' },
  { label: i18n.t('searchflow.softwareoptions9'), id: 'opt9' },
  { label: i18n.t('searchflow.softwareoptions10'), id: 'opt10' },
  { label: i18n.t('searchflow.softwareoptions11'), id: 'opt11' },
  { label: i18n.t('searchflow.softwareoptions12'), id: 'opt12' },
  { label: i18n.t('searchflow.softwareoptions13'), id: 'opt13' },
  { label: i18n.t('searchflow.softwareoptions14'), id: 'opt14' },
  { label: i18n.t('searchflow.softwareoptions15'), id: 'opt15' },
  { label: i18n.t('searchflow.softwareoptions16'), id: 'opt16' },
  { label: i18n.t('searchflow.softwareoptions17'), id: 'opt17' },
  { label: i18n.t('searchflow.softwareoptions18'), id: 'opt18' },
  { label: i18n.t('searchflow.softwareoptions19'), id: 'opt19' },
  { label: i18n.t('searchflow.softwareoptions20'), id: 'opt20' },
  { label: i18n.t('searchflow.other'), id: 'other' },
];

export var providerSocialText = () => [
  { label: i18n.t('general.websitelink'), id: 'companywebpage', img: webpage },
  { label: i18n.t('general.facebooklink'), id: 'social', img: facebook },
  { label: i18n.t('general.linkedinlink'), id: 'linkedin', img: linkedin },
  { label: i18n.t('general.instagramlink'), id: 'insta', img: insta },
];
export var providerSpecialRestrictions = () => [
  {
    label: i18n.t('general.foreigntradelabel'),
    label1: i18n.t('general.insideeu'),
    label2: i18n.t('general.outsideeu'),
    id1: 'insideEU',
    id2: 'outsideEU',
  },
  {
    label: i18n.t('general.newlyfoundedcomps'),
    id: 'newlyfounded',
  },
  /*   {
    label: "Nincs korlátozás távolságra, teljesen digitálisan is könyvelek",
    label1: "Helyben könyvelés",
    label2: "Távkönyvelés",
    id1: "local",
    id2: "remote",
  }, */
];
export const entryHelpText = () => [
  {
    text1: i18n.t('provider.entryhelptext1'),
    text2: i18n.t('provider.entryhelptext2'),
  },
  {
    text1: i18n.t('provider.entryhelptext3'),
    text2: i18n.t('provider.entryhelptext4'),
  },
  {
    text1: i18n.t('provider.entryhelptext5'),
    text2: i18n.t('provider.entryhelptext6'),
  },
  {
    text1: i18n.t('provider.entryhelptext7'),
    text2: i18n.t('provider.entryhelptext8'),
  },
];

export const validateInput = (id, value) => {
  const validEmailRegex = RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  const validPassword = RegExp(/^(?=.*[A-Z])+(?=.*[a-z])+(?=.*[0-9])/);
  const validUppercase = RegExp(/^(?=.*[A-Z])/);
  const validLowercase = RegExp(/^(?=.*[a-z])/);
  const validNumber = RegExp(/^(?=.*[0-9])/);
  let errors = {};

  switch (id) {
    case 'email':
      errors.email = validEmailRegex.test(value)
        ? ''
        : i18n.t('provider.emailformatwarning');
      break;

    case 'password':
      if (validPassword.test(value)) {
        errors.password = '';
      }
      if (!validNumber.test(value)) {
        errors.password = i18n.t('provider.pwerrornumber');
      }
      if (!validUppercase.test(value)) {
        errors.password = i18n.t('provider.pwerroruppercase');
      }
      if (!validLowercase.test(value)) {
        errors.password = i18n.t('provider.pwerrorlowercase');
      }
      if (value.length < 8) {
        errors.password = i18n.t('provider.pwerrorlength');
      }
      break;
    default:
      break;
  }
  return errors;
};

export const gdprLabelReg = () => [
  <span style={{ color: 'black' }}>
    {i18n.t('gdpr.part1')}
    <a
      className='GDPRLink'
      rel='noopener noreferrer'
      target='_blank'
      href={legalDocuments.gdpr}
      id='gdpr'
    >
      {' '}
      {i18n.t('gdpr.part2')}
    </a>{' '}
    {i18n.t('gdpr.part3')}{' '}
    <a
      className='GDPRLink'
      rel='noopener noreferrer'
      target='_blank'
      href={legalDocuments.aszf}
      id='aszf'
    >
      {i18n.t('gdpr.part4')}
    </a>
    {i18n.t('gdpr.part5')}
    <span
      className='GDPRLink'
      style={{
        color: '#FF0000',
        marginLeft: '0.3em',
        fontWeight: 'bold',
      }}
    >
      {i18n.t('gdpr.part6')}
    </span>
  </span>,
];

export const loginDataToSave = () => [
  { id: 'id' },
  { id: 'finishedRegistration' },
  { id: 'email' },
];

export const dashboardItems = () => [
  {
    label: i18n.t('general.profile'),
    id: 'profile',
    img: profile,
    imgActive: profileBlue,
  },
  // {
  //   label: i18n.t('general.dashboard'),
  //   id: 'dashboard',
  //   img: dashboard,
  //   imgActive: dashboardBlue,
  // },
  // {
  //   label: i18n.t('general.requests'),
  //   id: 'requests',
  //   img: requests,
  //   imgActive: requestsBlue,
  // },
  {
    label: i18n.t('general.filters'),
    id: 'filters',
    img: restrictions,
    imgActive: restrictionsBlue,
  },

  {
    label: i18n.t('general.reviews'),
    id: 'reviews',
    img: reviews,
    imgActive: reviewsBlue,
  },
  //{ label: i18n.t('general.partneroffers'), id: 'partneroffers' },
  {
    label: i18n.t('general.subscription'),
    id: 'subscription',
    img: subscription,
    imgActive: subscriptionBlue,
  },
  // { label: i18n.t('general.preferences'), id: 'preferences' },
];
export const dashboardItemsMarketing = () => [
  {
    label: i18n.t('general.profile'),
    id: 'profile',
    img: profile,
    imgActive: profileBlue,
  },
  // {
  //   label: i18n.t('general.dashboard'),
  //   id: 'dashboard',
  //   img: dashboard,
  //   imgActive: dashboardBlue,
  // },
  {
    label: i18n.t('general.requests'),
    id: 'requests',
    img: requests,
    imgActive: requestsBlue,
  },
  {
    label: i18n.t('general.filters'),
    id: 'filters',
    img: restrictions,
    imgActive: restrictionsBlue,
  },

  {
    label: i18n.t('general.reviews'),
    id: 'reviews',
    img: reviews,
    imgActive: reviewsBlue,
  },
  //{ label: i18n.t('general.partneroffers'), id: 'partneroffers' },
  {
    label: i18n.t('general.subscription'),
    id: 'subscription',
    img: subscription,
    imgActive: subscriptionBlue,
  },
  // { label: i18n.t('general.preferences'), id: 'preferences' },
];

export const dashboardLabels = () => [
  {
    dashboard: i18n.t('general.dashboard'),
    requests: i18n.t('general.requests'),
    filters: i18n.t('general.filters'),
    profile: i18n.t('general.profile'),
    reviews: i18n.t('general.reviews'),
    partneroffers: i18n.t('general.partneroffers'),
    subscription: i18n.t('general.subscription'),
    preferences: i18n.t('general.preferences'),
  },
];

export const companySizeText = () => [
  {
    label: i18n.t('general.entrepreneurlabel'),
    id: 'packageSize',
    value: 'entrepreneur',
  },
  {
    label: i18n.t('general.smalloffice'),
    id: 'packageSize',
    value: 'small_office',
  },
  {
    label: i18n.t('general.office'),
    id: 'packageSize',
    value: 'office',
  },
  {
    label: i18n.t('general.corporate'),
    id: 'packageSize',
    value: 'corporate',
  },
];

export var providerCompanyInfo = () => [
  {
    get label() {
      return `${i18n.t('general.emaillabel')}*`;
    },
    id: 'email',
    type: 'text',
    required: true,
  },
  {
    get label() {
      return `${i18n.t('general.companyname')}*`;
    },
    id: 'companyname',
    type: 'text',
    required: true,
  },
  {
    get label() {
      return `${i18n.t('general.taxnumber')}*`;
    },
    id: 'taxnumber',
    type: 'text',
    required: true,
  },
  // {
  //   get label() {
  //     return `${i18n.t('general.noofemployees')}*`;
  //   },
  //   id: 'noofemployees',
  //   type: 'number',
  //   required: true,
  // },
  {
    get label() {
      return `${i18n.t('general.phone')}*`;
    },
    id: 'phone',
    type: 'text',
    required: true,
  },
  {
    get label() {
      return `${i18n.t('general.registrationno')}*`;
    },
    id: 'registrationno',
    type: 'text',
    required: true,
  },
  {
    get label() {
      return `${i18n.t('general.authorizedperson')}*`;
    },
    id: 'authorizedperson',
    type: 'text',
    required: true,
  },
  {
    get label() {
      return `${i18n.t('providerdashboard.bookinglink')}`;
    },
    id: 'bookinglink',
    type: 'text',
    required: false,
  },
];

export var providerAddressText = () => [
  {
    get label() {
      return `${i18n.t('general.zip')}*`;
    },
    id: 'zip',
    type: 'number',
    required: true,
  },
  {
    get label() {
      return `${i18n.t('general.city')}*`;
    },
    id: 'city',
    type: 'text',
    required: true,
  },
  {
    get label() {
      return `${i18n.t('general.street')}*`;
    },
    id: 'address',
    type: 'text',
    required: true,
  },
];

export var providerContactText = () => [
  {
    get label() {
      return `${i18n.t('general.zip')}*`;
    },
    id: 'contactzip',
    type: 'number',
  },
  {
    get label() {
      return `${i18n.t('general.city')}*`;
    },
    id: 'contactcity',
    type: 'text',
  },
  {
    get label() {
      return `${i18n.t('general.street')}*`;
    },
    id: 'contactaddress',
    type: 'text',
  },
];

export const providerInfoReqFields = [
  'companyname',
  'taxnumber',
  'zip',
  'city',
  'address',
  'phone',
  //'noofemployees',
  'authorizedperson',
  'registrationno',
];
export const providerContactReqFields = [
  'contactzip',
  'contactcity',
  'contactaddress',
];

export const providerPackageProperties = () => [
  i18n.t('provider.registerindb'),
  i18n.t('provider.entryhelptext7'),
  i18n.t('provider.pickcustomers'),
  i18n.t('provider.customerreviews'),
  i18n.t('provider.ocasionalrfp'),
  i18n.t('provider.cherryrfp'),
  i18n.t('provider.asprfp'),
  i18n.t('provider.introatsocial'),
  i18n.t('provider.showonblog'),
  i18n.t('provider.firstresponse'),
  i18n.t('provider.onlyserious'),
  i18n.t('provider.autocontract'),
  i18n.t('provider.dealingwithcustomers'),
  i18n.t('provider.guarantee'),
];

export const providerPackageList = [
  {
    1: true,
    2: true,
    3: true,
    4: true,
  },
  {
    1: true,
    2: true,
    3: true,
    4: true,
  },
  {
    1: true,
    2: true,
    3: true,
    4: true,
  },
  {
    1: true,
    2: true,
    3: true,
    4: true,
  },
  {
    1: false,
    2: true,
    3: true,
    4: true,
  },
  { 1: false, 2: false, 3: true, 4: true },
  { 1: false, 2: false, 3: false, 4: true },
  { 1: false, 2: false, 3: true, 4: true },
  { 1: false, 2: false, 3: true, 4: true },
  { 1: false, 2: false, 3: false, 4: true },
  { 1: false, 2: false, 3: false, 4: true },
  { 1: false, 2: false, 3: false, 4: true },
  { 1: false, 2: false, 3: false, 4: true },
  { 1: false, 2: false, 3: false, 4: true },
];

export const profileCompanyInfoText = () => [
  {
    label: i18n.t('general.emaillabel'),
    id: 'email',
    storeObject: 'provider_info',
  },
  {
    label: i18n.t('general.companyname'),
    id: 'companyname',
    storeObject: 'provider_info',
  },
  {
    label: i18n.t('general.taxnumber'),
    id: 'taxnumber',
    storeObject: 'provider_info',
  },
  {
    label: i18n.t('general.authorizedperson'),
    id: 'authorizedperson',
    storeObject: 'provider_info',
  },
  {
    label: i18n.t('general.registrationno'),
    id: 'registrationno',
    storeObject: 'provider_info',
  },
  // {
  //   label: i18n.t('general.noofemployees'),
  //   id: 'noofemployees',
  //   storeObject: 'provider_info',
  // },
  {
    label: i18n.t('general.phone'),
    id: 'phone',
    storeObject: 'provider_info',
  },
  {
    label: i18n.t('general.companyaddress'),
    id: '',
    storeObject: 'provider_info',
    address: true,
    ids: ['zip', 'city', 'address'],
  },
  {
    label: i18n.t('providerdashboard.bookinglink'),
    id: 'bookinglink',
    storeObject: 'provider_info',
  },
  {
    label: i18n.t('general.companyaddress'),
    id: 'contact',
    storeObject: 'provider_contact',
    address: true,
    ids: ['contactzip', 'contactcity', 'contactaddress'],
  },
];

export var languagesText = () => [
  { name: i18n.t('languages.english'), id: 'english', flag: uk },
  { name: i18n.t('languages.german'), id: 'german', flag: germany },
  { name: i18n.t('languages.french'), id: 'french', flag: france },
  { name: i18n.t('languages.chinese'), id: 'chinese', flag: china },
  { name: i18n.t('languages.russian'), id: 'russian', flag: russia },
  { name: i18n.t('languages.romanian'), id: 'romanian', flag: romania },
  { name: i18n.t('languages.spanish'), id: 'spanish', flag: spain },
];

export const couponCodes = {
  mkoe15: 0.85,
  szamlazz10: 0.9,
  mbhorkeny: 0.8,
};
