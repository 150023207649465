import React from 'react';
import {
	providerCompanyIndustryList,
	providerCompanyTypeList,
	providerFilterSliders,
	TaxformInput,
} from '../../data/providerText';
import RestrictionInputs from './input/restrictionInputs';
import SpecialRestrictionInputs from './input/specialRestrictionInputs';
import ProviderNavigation from '../components/providerNavigation/providerNavigation';
import RestrictionRangeInputs from './input/restrictionRangeInputs';
import PreferencesInputs from '../providerPreferences/input/preferencesInputs';
import Dialog from '@mui/material/Dialog';
import StyledButton from '../../../shared/components/styledButton/styledButton';
import MkoeIcon from '../../../images/icons/mkoe-icon-new.png';
import { useSelector } from 'react-redux';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

import { Paper } from '@mui/material';

const ProviderRestrictions = ({ handleNext, handleBack, activeStep }) => {
	const [providerRetrictions, providerSubscription] = useSelector((state) => [
		state.provider_restrictions,
		state.provider_subscription,
	]);
	const [error, setError] = React.useState(false);
	const [open, setOpen] = React.useState(
		providerSubscription.partnerCode &&
			providerSubscription.partnerCode.toLowerCase() === 'mkoestart'
			? true
			: false
	);
	const checkCompanySizeRestrictions = () => {
		let filled = true;
		providerFilterSliders().forEach((item) => {
			if (!filled) return;
			providerRetrictions[item.id].forEach((index) => {
				if (!index && index !== 0) {
					filled = false;
					return filled;
				}
			});
		});
		setError(false);
		return filled;
	};
	const handleClose = () => {
		setOpen(false);
	};

	console.log(providerRetrictions, 'providerRetrictions');

	return (
		<div className="PaddingBot100">
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title">
				<div className="Mkoepopup">
					<img src={MkoeIcon} alt="" />
					<h2>{i18n.t('provider.mkoepartnerlabel')}</h2>
					<p>
						{i18n.t('provider.mkoetext1')}
						<b>{i18n.t('provider.mkoetext2')}</b>
						{i18n.t('provider.mkoetext3')} <b>{i18n.t('provider.mkoetext4')}</b>
						{i18n.t('provider.mkoetext5')}
						<b>{i18n.t('provider.mkoetext6')}</b>.
					</p>
					<StyledButton color="Primary" onClick={handleClose}>
						{i18n.t('general.ok')}
					</StyledButton>
				</div>
			</Dialog>
			<div className="ProviderInputContainerCentered">
				<Paper className="providerPaper">
					<p className="ProviderInputTitle">
						{i18n.t('provider.restrictionsoptions')}
					</p>
					<p className="ProviderInputSubTitle">
						{i18n.t('provider.restrictionstext')}
					</p>
					<div className="RestrictionsNotification">
						<h2>{i18n.t('provider.warning')}</h2>
						<p>{i18n.t('provider.restrictionstext2')}</p>
					</div>
				</Paper>
				<Paper className="providerPaper">
					<p className="RestrictionTitle">{i18n.t('provider.industryrest')}</p>
					<PreferencesInputs
						dataText={providerCompanyIndustryList()}
						storeObj="provider_industry_restrictions"
					/>
				</Paper>
				<Paper className="providerPaper">
					<p className="RestrictionTitle">{i18n.t('provider.companyrest')}</p>
					<RestrictionInputs
						dataText={providerCompanyTypeList()}
						storeObj="provider_business_filter"
					/>
					<p className="RestrictionTitle">
						{i18n.t('provider.taxformfilterlabel')}
					</p>
					<RestrictionInputs
						dataText={TaxformInput()}
						storeObj="provider_vatform_restrictions"
					/>
				</Paper>
				<Paper className="providerPaper">
					<p className="RestrictionTitle">{i18n.t('provider.specialrest')}</p>
					<SpecialRestrictionInputs />
				</Paper>
				<Paper className="providerPaper">
					<p className="RestrictionTitle">
						{i18n.t('provider.companysizerest')}
					</p>
					<RestrictionRangeInputs />
					{error ? (
						<span className="ErrorText">
							{i18n.t('provider.pleasefilloutrest')}
						</span>
					) : null}
				</Paper>
			</div>
			<ProviderNavigation
				handleBack={() => {
					handleBack();
				}}
				handleNext={() => {
					if (checkCompanySizeRestrictions()) {
						handleNext();
					} else if (!checkCompanySizeRestrictions()) {
						setError(true);
					}
				}}
				step={activeStep}
			/>
		</div>
	);
};

export default withTranslation()(ProviderRestrictions);
