/* eslint-disable no-unused-vars */
const storeInitialState = {
	/* REGISTRATION DATA  */
	userData: {
		id: '',
		finishedRegistration: false,
		email: '',
	},
	finishedRegistration: false,
	/* STEP 1 - PROVIDER DATA*/
	provider_services: {
		accounting: false,
		taxconsulting: false,
		taxconsultingsimple: false,
		taxconsultingcomplex: false,
		legalentityfounding: false,
		payroll: false,
		audit: false,
		liquidation: false,
		simplifiedpayroll: false,
	},
	provider_liability_insurance: {
		accounting: false,
		taxconsulting: false,
		legalentityfounding: false,
		payroll: false,
		audit: false,
		liquidation: false,
	},
	provider_info: {
		companyname: '',
		zip: '',
		city: '',
		address: '',
		noofemployees: '0',
		registrationno: '',
		taxnumber: '',
		phone: '',
		email: '',
		fax: '',
		companywebpage: '',
		social: '',
		linkedin: '',
		insta: '',
		gdpr: false,
		marketing: false,
		description: '',
		authorizedperson: '',
	},
	provider_languages: {
		hungarian: true,
		english: false,
		german: false,
		french: false,
		chinese: false,
		russian: false,
		romanian: false,
		spanish: false,
	},
	provider_media: {
		profileImage: '',
	},
	provider_diff_contact: { diffc: false },
	provider_contact: {
		contactzip: '',
		contactcity: '',
		contactaddress: '',
		contactphone: '',
		contactemail: '',
		contactfax: '',
		contactwebpage: '',
		social: '',
	},
	/* STEP 2 - COMPANY FILTER*/
	provider_filter: { filterState: false },
	provider_restrictions: {
		noofemployees: [0, 250],
		yearlyrevenue: [0, 2000],
		incominginvoices: [0, 20000],
		outgoinginvoices: [0, 20000],
	},
	provider_vatform_restrictions: {
		kiva: true,
		tao: true,
	},
	provider_special_restrictions: {
		insideEU: true,
		outsideEU: true,
		newlyfounded: true,
		local: true,
		remote: true,
		maxdistance: 501,
	},

	provider_industry_restrictions: [
		{ id: 'construction', on: true, value: 0 },
		{ id: 'engineer', on: true, value: 0 },
		{ id: 'vehicle', on: true, value: 0 },
		{ id: 'manufacturing', on: true, value: 0 },
		{ id: 'IT', on: true, value: 0 },
		{ id: 'realestate', on: true, value: 0 },
		// { id: 'trade', on: true, value: 0 },
		{ id: 'retail', on: true, value: 0 },
		{ id: 'wholesale', on: true, value: 0 },
		{ id: 'agriculture', on: true, value: 0 },
		{ id: 'education', on: true, value: 0 },
		{ id: 'art', on: true, value: 0 },
		{ id: 'beauty', on: true, value: 0 },
		{ id: 'medicine', on: true, value: 0 },
		{ id: 'finance', on: true, value: 0 },
		{ id: 'logistics', on: true, value: 0 },
		{ id: 'courier', on: true, value: 0 },
		{ id: 'travel', on: true, value: 0 },
		{ id: 'otherservices', on: true, value: 0 },
		{ id: 'etrade', on: true, value: 0 },
		{ id: 'startup', on: true, value: 0 },
		{ id: 'lawyer', on: true, value: 0 },
		{ id: 'sport', on: true, value: 0 },
		//{ id: 'other', on: true, value: 0 },
	],
	provider_business_filter: {
		singleprimary: false,
		singlesecondary: false,
		kiva: false,
		vszja: false,
		bt: false,
		kft: false,
		zrt: false,
		kkt: false,
		nyrt: false,
		condo: false,
		citycouncil: false,
		civil: false,
	},
	provider_banking_filter: [
		{ id: 'opt1', on: true, value: 0 },
		{ id: 'opt2', on: true, value: 0 },
		{ id: 'opt3', on: true, value: 0 },
		{ id: 'opt4', on: true, value: 0 },
		{ id: 'opt5', on: true, value: 0 },
		{ id: 'opt6', on: true, value: 0 },
		{ id: 'opt7', on: true, value: 0 },
		{ id: 'opt8', on: true, value: 0 },
		{ id: 'other', on: true, value: 0 },
	],
	provider_invoicing_filter: [
		{ id: 'opt1', on: true, value: 0 },
		{ id: 'opt2', on: true, value: 0 },
		{ id: 'opt3', on: true, value: 0 },
		{ id: 'opt4', on: true, value: 0 },
		{ id: 'opt5', on: true, value: 0 },
		{ id: 'opt6', on: true, value: 0 },
		{ id: 'opt7', on: true, value: 0 },
		{ id: 'other', on: true, value: 0 },
	],
	provider_software_filter: [
		{ id: 'opt1', on: true, value: 0 },
		{ id: 'opt2', on: true, value: 0 },
		{ id: 'opt3', on: true, value: 0 },
		{ id: 'opt4', on: true, value: 0 },
		{ id: 'opt5', on: true, value: 0 },
		{ id: 'opt6', on: true, value: 0 },
		{ id: 'opt7', on: true, value: 0 },
		{ id: 'opt8', on: true, value: 0 },
		{ id: 'opt9', on: true, value: 0 },
		{ id: 'opt10', on: true, value: 0 },
		{ id: 'opt11', on: true, value: 0 },
		{ id: 'opt12', on: true, value: 0 },
		{ id: 'opt13', on: true, value: 0 },
		{ id: 'opt14', on: true, value: 0 },
		{ id: 'opt15', on: true, value: 0 },
		{ id: 'opt16', on: true, value: 0 },
		{ id: 'opt17', on: true, value: 0 },
		{ id: 'opt18', on: true, value: 0 },
		{ id: 'opt19', on: true, value: 0 },
		{ id: 'opt20', on: true, value: 0 },
		{ id: 'other', on: true, value: 0 },
	],

	provider_special_industry_restrictions: [
		{ id: 'registersimplifiedpayroll', on: true, value: 0 },
		{ id: 'companycar', on: true, value: 0 },
		{ id: 'cashregister', on: true, value: 0 },
		{ id: 'multipleaccounts', on: true, value: 0 },
		{ id: 'cashflowvat', on: true, value: 0 },
		{ id: 'loanreview', on: true, value: 0 },
		{ id: 'earlyclosure', on: true, value: 0 },
		{ id: 'preparingstatements', on: true, value: 0 },
		{ id: 'invoicing', on: true, value: 0 },
		{ id: 'transfering', on: true, value: 0 },
		{ id: 'auditcooperation', on: true, value: 0 },
		{ id: 'vatadjusting', on: true, value: 0 },
		{ id: 'projectsupport', on: true, value: 0 },
		{ id: 'environmentalfees', on: true, value: 0 },
		{ id: 'matchinginvoices', on: true, value: 0 },
		{ id: 'actualstore', on: true, value: 0 },
		{ id: 'stockhandling', on: true, value: 0 },
		{ id: 'differentialvat', on: true, value: 0 },
		{ id: 'matchinginvoicestravel', on: true, value: 0 },
		{ id: 'matchinginvoicesretail', on: true, value: 0 },
		{ id: 'matchinginvoiceswholesale', on: true, value: 0 },
		{ id: 'matchinginvoicescourier', on: true, value: 0 },
	],

	/* STEP 3 - PRICING */
	/* ACCOUNTING PRICING */
	provider_acc_monthly_price: {
		singleprimaryprice: '',
		singlesecondaryprice: '',
		kivaprice: '',
		vszjaprice: '',
		btprice: '',
		kftprice: '',
		zrtprice: '',
		kktprice: '',
		nyrtprice: '',
		condoprice: '',
		citycouncilprice: '',
		civilprice: '',
		incominginvoiceextra: '',
		outgoinginvoiceextra: '',
		revfeecat1: '',
		revfeecat2: '',
		revfeecat3: '',
		revfeecat4: '',
		revfeecat4a: '',
		revfeecat5: '',
		revfeecat6: '',
		revfeecat7: '',
		rev10m: '',
		rev1050m: '',
		rev50200m: '',
		rev2002000m: '',
		revover2000m: '',
		foreigntradeextra: '',
		vatmonthly: '',
		vatquarterly: '',
		vatyearly: '',
		severallocations: '',
		multiplecourier: '',
		multipledropoff: '',
		webshoptransferpayment: '',
		webshopcardpayment: '',
		webshopbenefitpayment: '',
		tourismtransferpayment: '',
		tourismcardpayment: '',
		tourismbenefitpayment: '',
	},
	/* PAYROLL PRICING */
	provider_payroll_price: {
		percapitamonthlyprice: '',
		simplifiedpayrollprice: '',
	},
	/* LEGAL ENTITY PRICING */
	provider_founding_price: {
		singleprimaryprice: '',
		singlesecondaryprice: '',
		kivaprice: '',
		vszjaprice: '',
		btprice: '',
		kftprice: '',
		zrtprice: '',
		kktprice: '',
		nyrtprice: '',
		condoprice: '',
		civilprice: '',
	},
	/* TAX CONSULTING PRICING */
	provider_tax_consulting_price: {
		perHourPrice: '',
		perMonthPrice: '',
	},
	/* AUDIT PRICING */
	provider_audit_price: {
		revUnder300m: '',
		rev3002B: '',
		rev25b: '',
		revOver5b: '',
	},
	provider_liquidation_price: {
		liquidationprice: null,
	},
	/* STEP 4 - INDUSTRY KNOWLEDGE */
	provider_industry_experience: {
		construction: 1,
		engineer: 1,
		vehicle: 1,
		manufacturing: 1,
		IT: 1,
		realestate: 1,
		// trade: 1,
		retail: 1,
		wholesale: 1,
		agriculture: 1,
		education: 1,
		art: 1,
		beauty: 1,
		medicine: 1,
		finance: 1,
		logistics: 1,
		courier: 1,
		lawyer: 1,
		travel: 1,
		otherservices: 1,
		etrade: 1,
		sport: 1,
	},
	/* STEP 3 - PACKAGE */
	provider_package: {
		packageType: '',
		packageSize: '',
		packagePrice: null,
		yearly: true,
		startDate: '',
		nextPayment: '',
	},

	provider_subscription: {
		activeSubscription: false,
		cardPayment: false,
		paidUntil: '',
		partnerCode: '',
	},
	/*  ------------------- COMPANY STORE ------------------- */
	company_store: {
		activeStep: 0,
		leadtype: 'asp',
		cherryID: '',
		foundingprice: '',
		vafprice: '',
		answer: '',
		question: '',
		chosendate: '',
		deadline: '',
		startingdate: '',
		accounting: true,
		taxconsulting: false,
		taxconsultingsimple: false,
		taxconsultingcomplex: false,
		legalentityfounding: false,
		payroll: false,
		simplifiedpayroll: '',
		audit: false,
		liquidation: false,
		idk: false,
		languagefilter: false,
		languages: {
			hungarian: true,
			english: false,
			german: false,
			french: false,
			chinese: false,
			russian: false,
			romanian: false,
			spanish: false,
		},
		noofemployees: '0',
		simplifiedemployeecount: '',
		yearlyrevenue: 0,
		incominginvoices: '0',
		outgoinginvoices: '0',
		spSelectedFromList: '',
		industry: '',
		industryinput: '',
		industryspecifics: {
			general: {
				registersimplifiedpayroll: {
					status: '',
				},
				severallocations: {
					status: '',
					locationcount: null,
				},
				companycar: {
					status: '',
					carcount: null,
				},
				cashregister: {
					status: '',
					cashregistercount: null,
				},
				multipleaccounts: {
					status: '',
					accountcount: null,
				},
				customertype: {
					selectedoption: '',
				},
				cashflowvat: {
					status: '',
				},
				loanreview: {
					status: '',
					reviewcount: null,
					reviewfrequency: '',
				},
				earlyclosure: {
					status: '',
					earlyclosurefrequency: '',
				},
				preparingstatements: {
					status: '',
				},
				invoicing: {
					status: '',
				},
				transfering: {
					status: '',
				},
				auditcooperation: {
					status: '',
				},
				vatadjusting: {
					status: '',
				},
				projectsupport: {
					status: '',
				},
				environmentalfees: {
					status: '',
					handlingenvfees: '',
				},
			},
			etrade: {
				etradesells: {
					physicalproducts: false,
					services: false,
					intellectualproperty: false,
					other: false,
					otherinput: '',
				},
				etrademultiplecourier: {
					mpl: false,
					gls: false,
					dpd: false,
					sprinter: false,
					expressone: false,
					tntexpress: false,
					fedex: false,
					ups: false,
					dhlexpress: false,
					other: false,
				},
				etrademultipledropoff: {
					foxpost: false,
					alzabox: false,
					packeta: false,
					glscsomagpont: false,
					dpdpickupcsomagpont: false,
					mplpostapont: false,
					other: false,
				},
				etradepayment: {
					transfer: false,
					card: false,
					benefit: false,
				},
				matchinginvoices: {
					status: '',
					matchingmethod: '',
				},
				deadlinecompliance: {
					status: '',
				},
				actualstore: {
					status: '',
					actualstorecount: null,
				},
				stockhandling: {
					status: '',
				},
			},
			vehicle: {
				differentialvat: {
					status: '',
					automotivepartstrading: false,
					trading: false,
					service: false,
					other: false,
					otherinput: '',
				},
			},
			construction: {
				constructiontype: {
					specializedworks: false,
					generalcontracting: false,
					constructionprojects: false,
					other: false,
					otherinput: '',
				},
			},
			IT: {
				ittype: {
					wagereview: false,
					projectwork: false,
					ownprodsoftdev: false,
					other: false,
					otherinput: '',
				},
			},
			agriculture: {
				agriculturetype: {
					livestockfarming: false,
					cropproduction: false,
					other: false,
					otherinput: '',
				},
			},
			art: {
				arttype: {
					createartworks: false,
					performingartist: false,
					tradeartworks: false,
					other: false,
					otherinput: '',
				},
			},
			beauty: {
				beautyproductsales: {
					status: '',
				},
			},
			travel: {
				travelpayment: {
					transfer: false,
					card: false,
					benefit: false,
				},
				seasonalunits: {
					status: '',
				},
				matchinginvoicestravel: {
					status: '',
					matchingmethod: '',
				},
				deadlinecompliancetravel: {
					status: '',
				},
			},
			// trade: {
			// 	tradepayment: {
			// 		transfer: false,
			// 		card: false,
			// 	},
			// },
			retail: {
				retailpayment: {
					transfer: false,
					card: false,
				},
				matchinginvoicesretail: {
					status: '',
					matchingmethod: '',
				},
				deadlinecomplianceretail: {
					status: '',
				},
			},
			wholesale: {
				wholesalepayment: {
					transfer: false,
					card: false,
				},
				matchinginvoiceswholesale: {
					status: '',
					matchingmethod: '',
				},
				deadlinecompliancewholesale: {
					status: '',
				},
			},
			finance: {
				complianceauditreview: {
					status: '',
				},
			},
			medicine: {
				medicinetype: {
					doctor: false,
					pharmacy: false,
					nurse: false,
					vet: false,
					other: false,
					otherinput: '',
				},
				medicinepayment: {
					card: false,
					benefit: false,
				},
			},
			courier : {
				matchinginvoicescourier: {
					status: '',
					matchingmethod: '',
				},
				deadlinecompliancecourier: {
					status: '',
				},
			},
		},
		companytype: '',
		companyctcname: '',
		companyname: '',
		companyaddress: '',
		companytaxnumber: '',
		zip_code: '0000',
		foreign_trade: false,
		foreigntradeEU: '',
		newlyfounded: false,
		accountingMethodFilter: false,
		accountingMethod: '',
		vatFilter: '',
		vatPayment: '',
		taxform: '',
		taxconform: '',
		potential_providers: undefined,
		selected_providers: [],
		selected: [],
		email: '',
		password: '',
		gdpr: false,
		marketing: false,
		reqMessage: '',
		companyPhone: '',
		tableMode: false,
		onlyBrowsing: false,
		sortBy: 'Relevancia szerint',
		otp: false,
		potProvCount: null,
		isDiscounted: false,
		discountCode: '',
		discount: null,
		discountLarge: null,
		bankingpref: {
			opt1: false,
			opt2: false,
			opt3: false,
			opt4: false,
			opt5: false,
			opt6: false,
			opt7: false,
			opt8: false,
			other: false,
			bankidk: false,
			bankingOtherInput: '',
		},
		invoicingpref: {
			opt1: false,
			opt2: false,
			opt3: false,
			opt4: false,
			opt5: false,
			opt6: false,
			other: false,
			invoicingidk: false,
			invoicingOtherInput: '',
		},
		softwarepref: {
			opt1: false,
			opt2: false,
			opt3: false,
			opt4: false,
			opt5: false,
			opt6: false,
			opt7: false,
			opt8: false,
			opt9: false,
			opt10: false,
			opt11: false,
			opt12: false,
			opt13: false,
			opt14: false,
			opt15: false,
			opt16: false,
			opt17: false,
			opt18: false,
			opt19: false,
			opt20: false,
			other: false,
		},
		multiplecompany: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
		utm_content: '',
		utm_term: '',
		gclid: '',
		dclid: '',
		searchID: '',
		flowswitchchoice: '',
		callbacksource: '',
		selectedProvGrp: '',
		companylegalname: '',
		legaladdress: '',
		reqFromBupa: false,
		isCallBackReq: false,
		isfakecherry: false,
		aspreqcreated: false,
		cherryreqcreated: false,
	},
	required_fields: {
		providerInfoFields: false,
		companyDetailsFields: false,
		accountingPricing: false,
		accountingExtraIncome: false,
		accountingExtraInvoice: false,
		accountingExtraVat: false,
		foreignBusinessExtra: false,
		payroll: false,
		taxconsulting: false,
		audit: false,
		legalentityfounding: false,
		liquidation: false,
		accounting: false,
	},
	company_filters: {
		distanceFilter: [0, 10000],
		priceFilter: [0, 10000000],
		reviewFilter: [0, 5],
		experienceFilter: [0, 5],
	},
	match_score_weights: {
		distanceWeight: 0,
		priceWeight: 0,
		reviewWeight: 3,
		experienceWeight: 0,
		liabilityInsWeight: 0,
		badgeWeight: 0,
		phoneWeight: 0,
		availabilityWeight: 0,
	},
	/*  ------------------- USER STATE ------------------- */
	loggedIn: { status: false },
	loading: true,
	providerDataFetched: false,
	/*  ------------------- REQ STATISTICS ------------------- */
	reqInfo: {
		reqNumber: 0,
		open: 0,
		newCustomer: 0,
		reqSorted: {
			open: [],
			accepted: [],
			modified: [],
			confirmed: [],
			declined: [],
			rejected: [],
		},
	},
	/*  -------------------AUTOFILL PRICES ------------------- */
	priceCategory: {
		category: 'cheap',
	},
	referral: {
		refererEmail: '',
		referees: [],
		gdpr: false,
		terms: false,
		refID: '',
		refCode: '',
		refMailSubject: '',
		refMsg: '',
		refLink: '',
	},
};

export default storeInitialState;
